import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Produttore, saveProduttore } from "./produttoriSlice";
import { useAppDispatch, RootState } from "../../../redux/store";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { TipoPersoneTypes } from "./produttoriSlice";
import { Comune } from "../comuni/comuniSlice";
import MenuItem from "@mui/material/MenuItem";

import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ServizioTypes } from "../kpi/kpiSlice";
import ArrowStepper from "../../common/ArrowStepper";
import {
	testoBottoneProseguimento,
	passiInizioPratica,
} from "./testoBottoneProseguimento";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";

import { Button } from "@mui/material";
interface NuovoProduttoreFormProps {
	tipoPraticaId?: ServizioTypes;
}

const NuovoProduttoreForm = ({ tipoPraticaId }: NuovoProduttoreFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Produttore>();

	const nuovoProduttore: Produttore = {
		nome: "",
		cognome: "",
		ragione_sociale: "",
		tipo_persona: tipoPraticaId == ServizioTypes.OFF_FV ? "PG" : "PF",
		codice_fiscale: undefined,
		piva: undefined,
	};
	const [pesonaFisica, setPesonaFisica] = useState<boolean>(true);

	const listaCampi = Object.keys(nuovoProduttore) as Array<keyof Produttore>;

	const dispatch = useAppDispatch();

	const errorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.produttoriState.produttori.errorsStack
	);

	useEffect(
		() => {
			reset();
			listaCampi.map((field) => {
				setValue(field, nuovoProduttore[field]);
			});
		},
		[
			// listaCampi.forEach((field) => {
			// 	nuovoProduttore[field];
			// }),
			// nuovoProduttore.id,
		]
	);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			errorsStack?.fieldsErrors &&
				errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		errorsStack?.fieldsErrors,
		// nuovoProduttore?.errorsStack?.fieldsErrors,
		// nuovoProduttore.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		setValue("nome", undefined);
		setValue("cognome", undefined);
		setValue("codice_fiscale", undefined);
		setValue("ragione_sociale", undefined);
		setValue("piva", undefined);
		setPesonaFisica(getValues("tipo_persona") == "PF");
	}, [getValues("tipo_persona")]);

	const handlerSaveProduttore = (nuovoProduttore: Produttore) => {
		dispatch(saveProduttore(nuovoProduttore));
	};

	const onSubmit: SubmitHandler<Produttore> = (nuovoProduttore) => {
		handlerSaveProduttore(nuovoProduttore);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{nuovoProduttore?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{nuovoProduttore?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<ReactHookFormSelect
							name="tipo_persona"
							label="tipo persona"
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							disabled={
								tipoPraticaId == ServizioTypes.ENEA_FV ||
								tipoPraticaId == ServizioTypes.OFF_FV
							}
							required
							error={errors?.tipo_persona?.message}
							sx={{ width: "100%" }}
						>
							{Object.entries(TipoPersoneTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography variant="h6" component="div" color="error">
							Inserire i dati del produttore e NON del contraente
						</Typography>
					</Grid>
				</Grid>
				{pesonaFisica ? (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="nome"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								required
								InputLabelProps={{
									shrink: true,
								}}
								{...register("nome")}
								error={!!errors?.nome}
								helperText={errors?.nome?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="cognome"
								required
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("cognome")}
								error={!!errors?.cognome}
								helperText={errors?.cognome?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="codice_fiscale"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								required
								InputLabelProps={{
									shrink: true,
								}}
								{...register("codice_fiscale")}
								error={!!errors?.codice_fiscale}
								helperText={errors?.codice_fiscale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
					</Grid>
				) : (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="ragione sociale"
								required
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("ragione_sociale")}
								error={!!errors?.nome}
								helperText={errors?.nome?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="piva"
								required
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("piva")}
								error={!!errors?.piva}
								helperText={errors?.piva?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
					</Grid>
				)}
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ mt: 2 }}
				>
					Crea
				</Button>
			</form>
		</Box>
	);
};

export default NuovoProduttoreForm;
