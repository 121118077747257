import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as installatoriApi from "../../../api/anagrafiche/installatoriApi";
import { toast } from "react-toastify";

export interface Installatore {
	id?: number;
	ragione_sociale: string;
	nome?: string;
	cognome?: string;
	cellulare?: number;
	telefono_fisso?: number;
	email?: string;
	errorsStack?: ErrorsStack;
}

export interface Installatori {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Installatore[];
	errorsStack: ErrorsStack;
}

export interface InstallatoriStrutturaState {
	installatori: Installatori;
	installatoreCorrenteId?: number;
}

const initialState: InstallatoriStrutturaState = {
	installatori: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchInstallatori = createAsyncThunk(
	"installatori/fetchInstallatori",
	async () => {
		return await installatoriApi.fetchInstallatori();
	}
);

export const getInstallatore = createAsyncThunk(
	"installatori/getInstallatore",
	async (installatoreId: number) => {
		return await installatoriApi.getInstallatore(installatoreId);
	}
);

export const saveInstallatore = createAsyncThunk(
	"installatori/saveInstallatore",
	async (installatoreToSave: Installatore, thunkApi) => {
		return await installatoriApi.saveInstallatore(installatoreToSave);
	}
);

export const deleteInstallatore = createAsyncThunk(
	"installatori/deleteInstallatore",
	async (installatoreToDelete: Installatore, thunkApi) => {
		return await installatoriApi.deleteInstallatore(installatoreToDelete);
	}
);

export const installatoriSlice = createSlice({
	name: "installatori",
	initialState,
	reducers: {
		resetInstallatoreCorrente: (state) => {
			state.installatoreCorrenteId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch Installatori
		builder.addCase(fetchInstallatori.pending, (state, action) => {
			state.installatori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchInstallatori.fulfilled, (state, action) => {
			state.installatori = action.payload;
			state.installatoreCorrenteId = undefined;
			state.installatori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchInstallatori.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.installatori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Installatore
		builder.addCase(getInstallatore.pending, (state, action) => {
			state.installatori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getInstallatore.fulfilled, (state, action) => {
			state.installatori.results = state.installatori.results.map(
				(installatore) => {
					if (installatore.id == action.payload.id) {
						return action.payload;
					} else {
						return installatore;
					}
				}
			);
			state.installatoreCorrenteId = action.payload.id;
			state.installatori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getInstallatore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.installatori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Installatore
		builder.addCase(saveInstallatore.pending, (state, action) => {
			state.installatori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveInstallatore.fulfilled, (state, action) => {
			state.installatori.results = state.installatori.results.filter(
				(installatore) => installatore.id !== action.payload.id
			);

			state.installatori.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.installatoreCorrenteId = action.payload.id;
			state.installatori.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Installatore salvato.");
		});
		builder.addCase(saveInstallatore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.installatori.results = state.installatori.results.map(
				(installatore) => {
					if (installatore.id == action.meta.arg.id) {
						return {
							...installatore,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return installatore;
					}
				}
			);
			state.installatori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
		// cancella Installatore
		builder.addCase(deleteInstallatore.pending, (state, action) => {
			state.installatori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteInstallatore.fulfilled, (state, action) => {
			state.installatori.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.installatori.results = state.installatori.results.filter(
				(installatore) => installatore.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Installatore cancellato.");
		});
		builder.addCase(deleteInstallatore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.installatori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = installatoriSlice.actions;

export const installatoriSliceReducer = installatoriSlice.reducer;
