import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import moment from "moment";

import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { IterConnessione } from "../../iterConnessioneSlice";

import IterConnessioneSpalla from "../IterConnessioneSpalla";
import AzioniGestoreCard from "../datiGenerali/AzioniGestoreCard";
import IterConnessioneGestoreForm from "./IterConnessioneGestoreForm";
import EmailAliasForm from "./EmailAliasForm";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";

interface IterConnessioneDatiPraticaTabProps {
	iterConnessione: IterConnessione;
	produttore: Produttore;
	consentiModifica: boolean;
}

const IterConnessioneDatiPraticaTab = ({
	iterConnessione,
	produttore,
	consentiModifica = false,
}: IterConnessioneDatiPraticaTabProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9} order={{ xs: 2, md: 1 }}>
					{iterConnessione &&
						(iterConnessione.data_richiesta_presa_carico ||
							iterConnessione.data_presa_carico) && (
							<IterConnessioneGestoreForm
								iterConnessione={iterConnessione}
								consentiModifica={
									consentiModifica &&
									utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
								}
							/>
						)}

					{/* <Divider
						textAlign="left"
						sx={{ my: 4, color: theme.palette.primary.main }}
					/>
					{iterConnessione && iterConnessione.data_assegnazione && (
						<IterConnessioneDatiInterniForm
							iterConnessione={iterConnessione}
							consentiModifica={consentiModifica}
						/>
					)} */}

					{produttore && iterConnessione.data_assegnazione && (
						<EmailAliasForm
							produttore={produttore}
							consentiModifica={consentiModifica}
						/>
					)}
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, md: 2 }}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneDatiPraticaTab;
