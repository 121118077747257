import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";
import { Routes_path } from "../../../routerApp";

import moment from "moment";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../authentication/permissionsGroups";
import {
	OrdinamentoLista,
	PreferenzeLista,
	setPreferenzeListaServiziAdempimenti,
} from "../../../userPreference/userPreferenceSlice";

import {
	TipoAdempimento,
	fetchTipiAdempimento,
	fetchServiziAdempimenti,
	ServiziAdempimentiState,
	StatoAdempimentoTypes,
} from "./../adempimentiSlice";

import {
	Produttore,
	resetProduttoreCorrente,
	fetchProduttori,
} from "../../../anagrafiche/produttori/produttoriSlice";

import { Cliente } from "../../../anagrafiche/clienti/clientiSlice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";
import ServiziAdempimentiPageBreadcrumbs from "./ServiziAdempimentiPageBreadcrumbs";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const produttoreFullName = (produttore?: Produttore) => {
	if (produttore) {
		if (produttore.tipo_persona == "PF") {
			return `${produttore.cognome} ${produttore.nome}`;
		} else {
			return `${produttore.ragione_sociale}`;
		}
	} else {
		return "anonimo";
	}
};

// const visualizzaStatoIter = (statoIter?: StatoIter) => {
// 	return statoIter && `${statoIter.stato_desc} (${statoIter.count})`;
// };

const ServiziServiziAdempimentiPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type LocationState = {
		statoServizioAdempimento?: boolean;
		tipoAdempimentoId?: number;
		clienteId?: number;
	};
	const location = useLocation();

	const preferenzeListaServiziAdempimenti = useSelector<
		RootState,
		PreferenzeLista | undefined
	>((state) => state.userPreferenceState.preferenzeListaServiziAdempimenti);

	const [statoServizioAdempimento, setStatoServizioAdempimento] = useState(
		location.state
			? (location.state as LocationState).statoServizioAdempimento
			: preferenzeListaServiziAdempimenti?.stato
		//  : undefined
	);

	const [tipoAdempimentoId, setTipoAdempimentoId] = useState(
		location.state
			? (location.state as LocationState).tipoAdempimentoId
			: // : preferenzeListaServiziAdempimenti?.tipo_servizioAdempimenti
			  undefined
	);

	const [clienteId, setClienteIterId] = useState(
		location.state
			? (location.state as LocationState).clienteId
			: preferenzeListaServiziAdempimenti?.clienteIterId
		// : undefined
	);

	const serviziAdempimenti = useSelector<RootState, ServiziAdempimentiState>(
		(state) => state.adempimentiState.serviziAdempimenti
	);
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	// const statoServiziAdempimenti = useSelector<RootState, StatiIter>(
	// 	(state) => state.kpiState.statiIter
	// );

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const [page, setPage] = useState<number>(
		preferenzeListaServiziAdempimenti?.page || 1
	);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaServiziAdempimenti?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);

	const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
	const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
	const [anchorElTipoAdempimentoFilter, setAnchorElTipoAdempimentoFilter] =
		useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElStatoFilter(null);
		setAnchorElClienteFilter(null);
		setAnchorElTipoAdempimentoFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		dispatch(fetchProduttori({}));
		dispatch(fetchTipiAdempimento());
		dispatch(
			fetchServiziAdempimenti({
				cliente: clienteId,
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		dispatch(
			setPreferenzeListaServiziAdempimenti({
				numeroRecord: numeroRecord,
				page: page,
				// stato: statoServizioAdempimento,
			})
		);
		setRefresh(false);
	}, [page, numeroRecord, search, clienteId, refresh]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	const handlerCreateAdempimento = () => {
		dispatch(resetProduttoreCorrente());
		navigate(Routes_path.PRODUTTORE + "0/" + "ADEM_FV");
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 1,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<ServiziAdempimentiPageBreadcrumbs />
			<Typography variant="h4" gutterBottom>
				Accordi di Servizio per Adempimenti
			</Typography>

			<List sx={{ mt: 2 }}>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b7536", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
						<Grid item xs={2} sm={2} md={1} lg={1}>
							<Typography variant="h5" gutterBottom>
								n°
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Cliente
								</Typography>
								<IconButton
									color={clienteId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElClienteFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElClienteFilter}
									keepMounted
									open={Boolean(anchorElClienteFilter)}
									onClose={handleStatoFilterClose}
								>
									{clienti.map((cliente) => (
										<MenuItem
											key={cliente.id}
											onClick={() => {
												setClienteIterId(cliente.id);
												setAnchorElClienteFilter(null);
											}}
											sx={{
												backgroundColor:
													cliente.id == clienteId ? "#aaa" : "inherit",
											}}
										>
											{cliente.ragione_sociale}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setClienteIterId(undefined);
											setAnchorElClienteFilter(null);
										}}
										sx={{
											backgroundColor: !clienteId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3}>
							<Typography variant="h5">Produttore</Typography>
						</Grid>

						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5">Stato</Typography>
								{/* <IconButton
									color={statoAdempimentoId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElStatoFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton> */}
							</Stack>
							{/* <Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElStatoFilter}
									keepMounted
									open={Boolean(anchorElStatoFilter)}
									onClose={handleStatoFilterClose}
								>
											<MenuItem
												key={`stato_in_corso`}
												value={true}
												onClick={() => {
													setStatoAdempimentoId(true);
													setAnchorElStatoFilter(null);
												}}
												sx={{
													backgroundColor:
														value == statoAdempimentoId ? "#aaa" : "inherit",
												}}
											>
												{value}
											</MenuItem>

									<MenuItem
										onClick={() => {
											setStatoAdempimentoId(undefined);
											setAnchorElStatoFilter(null);
										}}
										sx={{
											backgroundColor: !statoAdempimentoId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box> */}
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={1}>
							<Typography variant="h5" gutterBottom>
								Data Avvio Servizio
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={1}>
							<Typography variant="h5" gutterBottom>
								Data Scadenza Servizio
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						serviziAdempimenti.errorsStack.status == ErrorStatusTypes.PENDING
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{serviziAdempimenti.results?.map((servizioAdempimenti, index) => (
					<ListItemButton
						// key={"iter_" + sopralluogo.id}
						key={index}
						component={Link}
						to={Routes_path.SERVIZIO_ADEMPIMENTI + servizioAdempimenti.id}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={2} sm={2} md={1} lg={1}>
								{servizioAdempimenti.id}
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={3}>
								<Typography noWrap>
									{/* {
										clienti.find((cliente) => cliente.id == servizioAdempimenti.cliente)
											?.ragione_sociale
									} */}
									{servizioAdempimenti.cliente_descrizione}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={3}>
								<Typography noWrap>
									{servizioAdempimenti.produttore_descrizione}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography noWrap>
									{servizioAdempimenti.in_corso ? "In corso" : "Concluso"}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={1}>
								{servizioAdempimenti.data_validita_da
									? moment(servizioAdempimenti.data_validita_da).format(
											"DD/MM/YYYY"
									  )
									: ""}
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={1}>
								{servizioAdempimenti.data_validita_a
									? moment(servizioAdempimenti.data_validita_a).format(
											"DD/MM/YYYY"
									  )
									: ""}
							</Grid>
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={serviziAdempimenti.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						{clienteId ? (
							<Typography noWrap>
								Totale serviziAdempimenti filtrati:{"  "}
								{serviziAdempimenti.count}
							</Typography>
						) : (
							<Typography noWrap>
								Totale serviziAdempimenti:{"  "}
								{serviziAdempimenti.count}
							</Typography>
						)}

						{/* <TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
						/> */}
						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default ServiziServiziAdempimentiPage;
