import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import moment from "moment";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";
import { Sopralluogo } from "../../sopralluoghiSlice";
import SopralluogoEdificioForm from "./SopralluogoEdificioForm";
import AzioniSopralluogoCard from "../AzioniSopralluogoCard";
import AllegatiPage from "../../../../allegati/AllegatiPage";
// import SopralluogoDatiGeneraliForm from "./SopralluogoDatiGeneraliForm";
// import SopralluogoNoteInterneForm from "./SopralluogoNoteInterneForm";
// import SopralluogoDistintaProdottiBox from "./prodotti/SopralluogoDistintaProdottiBox";
// import AzioniClienteCard from "./AzioniClienteCard";
// import AzioniGestoreCard from "./AzioniGestoreCard";
import Divider from "@mui/material/Divider";
import tipi_staffa from "../../../../../../static/images/common/tipi_staffa.png";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";

interface SopralluogoEdificioTabProps {
	sopralluogo: Sopralluogo;
	utente: UtenteLogged;
	// produttore: Produttore;
	consentiModifica: boolean;
}

const SopralluogoEdificioTab = ({
	sopralluogo,
	utente,
	// produttore,
	consentiModifica = false,
}: SopralluogoEdificioTabProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9} order={{ xs: 2, md: 1 }}>
					<Stack spacing={2}>
						{sopralluogo && (
							<SopralluogoEdificioForm
								sopralluogo={sopralluogo}
								// produttore={produttore}
								consentiModifica={consentiModifica}
							/>
						)}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, md: 2 }}>
					<AzioniSopralluogoCard sopralluogo={sopralluogo} />

					{/* {utenteAutorizzato(utente, [
						PermissionTypes.CLIENTI,
						PermissionTypes.OPERATORI,
						// PermissionTypes.GESTORI_ITER,
					]) && <AzioniClienteCard sopralluogo={sopralluogo} />}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard sopralluogo={sopralluogo} />
					)} */}
				</Grid>
			</Grid>
			<Card raised>
				<CardMedia
					component="img"
					image={tipi_staffa}
					// height="430"
					sx={{
						objectFit: "cover",
						// objectPosition: "50% 0%",
					}}
					alt="Sinergie43"
				/>
			</Card>
		</Box>
	);
};

export default SopralluogoEdificioTab;
