import React from "react";

import AppTokenMng from "./AppTokenMng";

import { ToastContainer } from "react-toastify";
import { ThemeProvider, Theme } from "@mui/material/styles";
import { theme as themePRD } from "./theme";
import { theme as themeTST } from "./themeTST";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "react-toastify/dist/ReactToastify.css";

const isTestEnv: boolean = !!process.env.APP_NAME?.startsWith("Sinergie43 TST");

const theme: Theme = isTestEnv ? themeTST : themePRD;

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<ToastContainer autoClose={3000} hideProgressBar pauseOnHover={true} />

				<AppTokenMng />
			</LocalizationProvider>
		</ThemeProvider>
	);
};

export default App;
