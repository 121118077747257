import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

// import { Progetto, chiudiProgetto } from "./progettiSlice";
import {
	IterConnessione,
	saveIterConnessione,
} from "components/main/iterConnessioni/iterConnessioneSlice";
import { Produttore } from "../../../../anagrafiche/produttori/produttoriSlice";

import moment from "moment";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";

// import RigettaProgettoForm from "./RigettaProgettoForm";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface AzioniConsegnaCardProps {
	iterConnessione: IterConnessione;
}

const AzioniConsegnaCard = ({ iterConnessione }: AzioniConsegnaCardProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const [formRigetto, setFormRigetto] = useState<boolean>(false);

	return (
		<Card sx={{ mt: 2, backgroundColor: "#ddd" }}>
			<CardContent>
				{["M"].includes(iterConnessione.stato) && (
					<Stack sx={{ width: "100%" }} spacing={2}>
						<Button
							variant="contained"
							size="large"
							// disabled={!progetto?.progetto_completo}
							sx={{ width: "100%", mt: 1 }}
							onClick={() => {
								dispatch(
									saveIterConnessione({
										iterConnessioneToSave: iterConnessione,
										azione: "consegna_al_cliente",
									})
								);
							}}
						>
							Consegna al Cliente
						</Button>
					</Stack>
				)}
			</CardContent>
		</Card>
	);
};

export default AzioniConsegnaCard;
