import { Utente } from "../../authentication/authenticationSlice";

import { PermissionTypes } from "../../authentication/permissionsGroups";

import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Badge from "@mui/material/Badge";

import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

const PREFIX = "SideMenu";

const classes = {
	iconSelected: `${PREFIX}-iconSelected`,
};

const styleListItem = { justifyContent: "center" };

interface ElementoMenuControllatoProps {
	utente: Utente;
	path: string;
	label: string;
	gruppiAutorizzati: PermissionTypes[];
	open: boolean;
	selected: boolean;
	Icon: any;
	tooggleDrawer: () => void;
}
const ElementoMenuControllato = ({
	utente,
	path,
	label,
	open,
	gruppiAutorizzati,
	selected,
	Icon,
	tooggleDrawer,
}: ElementoMenuControllatoProps) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	// const matches = useMediaQuery("min-width:900px)");
	const mobiletooggleDrawer = () => {
		matches && tooggleDrawer();
	};

	return (
		<>
			{utenteAutorizzato(utente, gruppiAutorizzati) && (
				<ListItem
					component={Link}
					to={path}
					button
					key={path}
					sx={styleListItem}
				>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<Tooltip title={label}>
							{/* <Badge badgeContent={8} color="error"> */}
							<Icon
								className={selected ? classes.iconSelected : ""}
								onClick={mobiletooggleDrawer}
							/>
							{/* </Badge> */}
						</Tooltip>
					</ListItemIcon>

					{/* <Chip
							label="39"
							color="primary"
							sx={{ ml: 0 }}
							variant="outlined"
							size="small"
						/> */}

					{open && (
						<ListItemText
							style={{ marginLeft: 12 }}
							primary={label}
							className={selected ? classes.iconSelected : ""}
							onClick={mobiletooggleDrawer}
						/>
					)}
				</ListItem>
			)}
		</>
	);
};

export default ElementoMenuControllato;
