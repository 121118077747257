import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
	fetchAllegati,
	Allegato,
	Allegati,
	TipoDocumento,
	uploadAllegato,
	AllegatiState,
	fetchTipiDocumento,
} from "./allegatiSlice";
import Button from "@mui/material/Button";
import FolderZipIcon from "@mui/icons-material/FolderZip";
interface ZipDowloadButtonProps {
	allegati: Allegato[];
}
const ZipDowloadButton = ({ allegati }: ZipDowloadButtonProps) => {
	const filesToDownload: { name: string; url: string }[] = allegati.map(
		(allegato) => ({
			name: allegato.file_name,
			url: allegato.file,
		})
	);
	// { name: "file1.txt", url: "https://example.com/file1.txt" },
	// { name: "file2.txt", url: "https://example.com/file2.txt" },
	// Aggiungi ulteriori file da scaricare

	const handleDownloadClick = async () => {
		const zip = new JSZip();

		for (const file of filesToDownload) {
			const response = await fetch(file.url);
			const blob = await response.blob();
			zip.file(file.name, blob);
		}

		const zipBlob = await zip.generateAsync({ type: "blob" });
		saveAs(zipBlob, "files.zip");
	};

	// allegati.map((a) => console.log("---url-> ", a.file));

	return (
		<Button
			variant="contained"
			startIcon={<FolderZipIcon />}
			onClick={handleDownloadClick}
			color="info"
			sx={{ mb: 2, mx: 2 }}
		>
			ZIP
		</Button>
	);
};

export default ZipDowloadButton;
