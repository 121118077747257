import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";

import moment from "moment";

import SezioniCard from "./sezioni/SezioniCard";
import IterConnessioneSpalla from "../IterConnessioneSpalla";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import AzioniProgettistaCard from "./AzioniProgettistaCard";
import AzioniGestoreCard from "../datiGenerali/AzioniGestoreCard";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Progetto, getProgettoFromIter } from "./progettiSlice";
import { IterConnessione } from "../../iterConnessioneSlice";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import IterConnessioneProgettoCard from "./IterConnessioneProgettoCard";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";

import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";

interface IterConnessioneProgettiTabProps {
	iterConnessione: IterConnessione;
	utente: UtenteLogged;

	consentiModifica: boolean;
}

const IterConnessioneProgettiTab = ({
	iterConnessione,
	utente,
	consentiModifica = false,
}: IterConnessioneProgettiTabProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	useEffect(() => {
		iterConnessione.id && dispatch(getProgettoFromIter(iterConnessione.id));
	}, []);

	const progetti: Progetto[] = useSelector<RootState, Progetto[]>((state) =>
		state.progettiState.progetti.results.slice().sort((a, b) => {
			return b.id - a.id;
		})
	);

	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={12} md={8} lg={9}>
					{progetti.map((progetto) => (
						<IterConnessioneProgettoCard
							key={progetto.id}
							progetto={progetto}
							iterConnessione={iterConnessione}
							consentiModifica={
								!progetto.progetto_rigettato && consentiModifica
							}
							iterSemplificato={iterConnessione.iter_semplificato}
						/>
					))}
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />

					{utenteAutorizzato(utente, [
						PermissionTypes.GESTORI_ITER,
						PermissionTypes.PROGETTISTI,
					]) && (
						<AzioniProgettistaCard
							iterConnessione={iterConnessione}
							progetto={progetti.find(
								(progetto) => progetto.progetto_rigettato == false
							)}
						/>
					)}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneProgettiTab;
