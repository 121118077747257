import { handleResponse, handleError, headerset } from "../apiUtils";
import { Installatore } from "../../components/anagrafiche/installatori/installatoriSlice";

const baseUrl = (installatore_id?: number | null) => {
	if (installatore_id) {
		return (
			process.env.API_URL +
			"/api/anagrafiche/installatori/" +
			installatore_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/anagrafiche/installatori/";
	}
};

export function fetchInstallatori() {
	let url = baseUrl();

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getInstallatore(installatoreId: number) {
	let url = baseUrl(installatoreId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveInstallatore(installatoreToSave: Installatore) {
	const installatore = {
		...installatoreToSave,
	};

	return fetch(baseUrl(installatoreToSave.id), {
		method: installatoreToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...installatore,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteInstallatore(installatoreToDelete: Installatore) {
	return fetch(baseUrl(installatoreToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
