import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	ServizioAdempimenti,
	getServizioAdempimenti,
	saveServizioAdempimenti,
	deleteServizioAdempimenti,
} from "../../adempimentiSlice";

import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";
import Checkbox from "@mui/material/Checkbox";

import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";

import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";

import {
	UtenteLogged,
	UtenteApplicativo,
} from "../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";
import {
	Produttore,
	getProduttore,
} from "../../../../anagrafiche/produttori/produttoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";

import { Button, ButtonGroup, Divider } from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface ServizioAdempimentiFormProps {
	servizioAdempimenti: ServizioAdempimenti;
	// produttore?: Produttore;
	consentiModifica: boolean;
}

const ServizioAdempimentiForm = ({
	servizioAdempimenti,
	// produttore,
	consentiModifica = false,
}: ServizioAdempimentiFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<ServizioAdempimenti>();

	const listaCampi = Object.keys(servizioAdempimenti) as Array<
		keyof ServizioAdempimenti
	>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(!servizioAdempimenti.id);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
		(state) => state.authentication.utentiApplicativo.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	const produttoreCorrente: Produttore | undefined = useSelector<
		RootState,
		Produttore | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore?.id == servizioAdempimenti?.produttore
		)
	);

	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	const [keyPod, setKeyPod] = useState<string>(makeid(5));
	const [annulla, setAnnulla] = useState<boolean>(false);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, servizioAdempimenti[field]);
		});
		// setKeyPod(makeid(5));

		setAnnulla(false);
	}, [
		servizioAdempimenti.id,
		annulla,
		listaCampi.forEach((field) => {
			servizioAdempimenti[field];
		}),
	]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			servizioAdempimenti?.errorsStack?.fieldsErrors &&
				servizioAdempimenti.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message:
						servizioAdempimenti.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		servizioAdempimenti?.errorsStack?.fieldsErrors,
		// sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		servizioAdempimenti?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(!servizioAdempimenti.id);
	}, [
		servizioAdempimenti,
		// sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		if (servizioAdempimenti) {
			dispatch(getProduttore(servizioAdempimenti.produttore || 0));
		}
	}, []);

	const handlerSaveServizioAdempimenti = (
		servizioAdempimenti: ServizioAdempimenti
	) => {
		consentiModifica &&
			dispatch(
				saveServizioAdempimenti({
					servizioAdempimentiToSave: getValues(),
				})
			);
		// servizioAdempimenti.id && dispatch(getServizioAdempimenti(servizioAdempimenti.id));
	};

	const onSubmit: SubmitHandler<ServizioAdempimenti> = (
		servizioAdempimenti
	) => {
		handlerSaveServizioAdempimenti(servizioAdempimenti);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)} key={keyPod}>
				{servizioAdempimenti?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{servizioAdempimenti?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}

				<Grid container spacing={2} sx={{ my: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<ReactHookFormSelect
							name="cliente"
							label="Cliente"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.cliente?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="cliente_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{clienti.map((cliente) => {
								return (
									<MenuItem key={cliente.id} value={cliente.id}>
										{cliente.ragione_sociale}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<DesktopDatePicker
							label="data partenza"
							// components={{
							// 	OpenPickerIcon: ClockIcon,
							// }}
							// inputFormat="DD/MM/YY HH:mm"
							// mask={"__/__/__ __:__"}
							// label="Data Progetto"
							// {...register("data_ora_carico_pianificata")}
							value={
								watch("data_validita_da") ||
								servizioAdempimenti.data_validita_da
							}
							disabled={!consentiModifica}
							renderInput={(params: any) => (
								<TextField
									{...params}
									error={!!errors?.data_validita_da}
									helperText={errors?.data_validita_da?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									// {...register("data_ora_fine_turno")}
								/>
							)}
							onChange={(newValue: any) => {
								setEditing(true);
								setValue(
									"data_validita_da",
									newValue ? new Date(newValue.toDate()) : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_validita_da");
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<DesktopDatePicker
							label="data scadenza"
							// components={{
							// 	OpenPickerIcon: ClockIcon,
							// }}
							// inputFormat="DD/MM/YY HH:mm"
							// mask={"__/__/__ __:__"}
							// label="Data Progetto"
							// {...register("data_ora_carico_pianificata")}
							value={
								watch("data_validita_a") || servizioAdempimenti.data_validita_a
							}
							disabled={!consentiModifica}
							renderInput={(params: any) => (
								<TextField
									{...params}
									error={!!errors?.data_validita_a}
									helperText={errors?.data_validita_a?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									// {...register("data_ora_fine_turno")}
								/>
							)}
							onChange={(newValue: any) => {
								setEditing(true);
								setValue(
									"data_validita_a",
									newValue ? new Date(newValue.toDate()) : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_validita_a");
							}}
						/>
					</Grid>
				</Grid>

				<ButtonGroup
					variant="outlined"
					aria-label="outlined button group"
					sx={{ mb: 1 }}
				>
					<Button
						disabled={!consentiModifica}
						// variant="outlined"
						onClick={() => {
							setAnnulla(true);
						}}
					>
						Annulla
					</Button>
					<Button
						disabled={!consentiModifica}
						//  variant="outlined"
						type="submit"
					>
						Salva
					</Button>
					<Button color="warning" onClick={() => setOpenConferma(true)}>
						Elimina Servizio Adempimenti
					</Button>
				</ButtonGroup>
			</form>

			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={() => {
					dispatch(deleteServizioAdempimenti(servizioAdempimenti));
					handleConfermaClose();
				}}
				domanda={"Sicuro di voler eliminare  il servizio Adempimenti"}
				open={openConferma}
			/>
		</Box>
	);
};

export default ServizioAdempimentiForm;
