import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Pod, TipoTensioneTypes } from "../../pods/podsSlice";
import { useAppDispatch, RootState } from "../../../../redux/store";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import PodProdottoCard from "./PodProdottoCard";
import PodProdottoForm from "./PodProdottoForm";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";

import { Button, Divider } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";

interface PodDistintaProdottiBoxProps {
	pod: Pod;
	// produttore: Produttore;
	consentiModifica: boolean;
	// preEsistente: boolean;
}

const PodDistintaProdottiBox = ({
	pod,
	// produttore,
	consentiModifica = false,
}: // preEsistente,
PodDistintaProdottiBoxProps) => {
	const dispatch = useAppDispatch();

	const [openInputProdotto, setOpenInputProdotto] = useState(false);
	const [tipoProdotto, setTipoProdotto] = useState<
		"MF" | "IN" | "SA" | undefined
	>(undefined);
	const handleClosInputProdotto = () => {
		setTipoProdotto(undefined);
		setOpenInputProdotto(false);
	};
	return (
		<Box sx={{ mb: 1 }}>
			{tipoProdotto && (
				<Modal
					open={openInputProdotto}
					onClose={handleClosInputProdotto}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box
						sx={{
							position: "absolute" as "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "70%",
							bgcolor: "background.paper",
							border: "2px solid #000",
							boxShadow: 24,
							pt: 2,
							px: 4,
							pb: 3,
						}}
					>
						<PodProdottoForm
							pod={pod}
							tipoProdotto={tipoProdotto}
							handleClosInputProdotto={handleClosInputProdotto}
							// preEsistente={preEsistente}
						/>
					</Box>
				</Modal>
			)}
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={6} md={6} lg={4}>
					<Typography variant="h6" gutterBottom>
						Moduli Fotovoltaici
						{consentiModifica && (
							<IconButton
								aria-label="fingerprint"
								color="primary"
								// onClick={() => {
								// 	setTipoProdotto("MF");
								// 	setOpenInputProdotto(true);
								// }}
								sx={{ m: 0, ml: 1, p: 0 }}
							>
								{/* <AddCircleOutlineIcon /> */}
							</IconButton>
						)}
					</Typography>
					<Stack>
						{pod.prodotti
							?.filter((prodotto) => prodotto.tipo_prodotto == "MF")
							.map((prodotto) => (
								<PodProdottoCard
									key={"prodotto_" + prodotto.id}
									prodotto={prodotto}
									consentiModifica={consentiModifica}
								/>
							))}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4}>
					<Typography variant="h6" gutterBottom>
						Inverter
						{consentiModifica && (
							<IconButton
								aria-label="fingerprint"
								color="primary"
								// onClick={() => {
								// 	setTipoProdotto("IN");
								// 	setOpenInputProdotto(true);
								// }}
								sx={{ m: 0, ml: 1, p: 0 }}
							>
								{/* <AddCircleOutlineIcon /> */}
							</IconButton>
						)}
					</Typography>
					<Stack>
						{pod.prodotti
							?.filter((prodotto) => prodotto.tipo_prodotto == "IN")
							.map((prodotto) => (
								<PodProdottoCard
									key={"prodotto_" + prodotto.id}
									prodotto={prodotto}
									consentiModifica={consentiModifica}
								/>
							))}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4}>
					<Typography variant="h6" gutterBottom>
						Sistemi di accumulo
						{consentiModifica && (
							<IconButton
								aria-label="fingerprint"
								color="primary"
								// onClick={() => {
								// 	setTipoProdotto("SA");
								// 	setOpenInputProdotto(true);
								// }}
								sx={{ m: 0, ml: 1, p: 0 }}
							>
								{/* <AddCircleOutlineIcon /> */}
							</IconButton>
						)}
					</Typography>
					<Stack>
						{pod.prodotti
							?.filter((prodotto) => prodotto.tipo_prodotto == "SA")
							.map((prodotto) => (
								<PodProdottoCard
									key={"prodotto_" + prodotto.id}
									prodotto={prodotto}
									consentiModifica={consentiModifica}
								/>
							))}
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PodDistintaProdottiBox;
