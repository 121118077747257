import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
} from "../../iterConnessioneSlice";

import { Progetto, saveProgetto } from "./progettiSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import moment from "moment";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

import { Button, Divider } from "@mui/material";

interface RigettaProgettoFormProps {
	progetto: Progetto;
	consentiModifica: boolean;
	annullaRigetto: () => void;
	// saveProgetto: (
	// 	progetto: Progetto
	// ) => void;
}

const RigettaProgettoForm = ({
	progetto,
	consentiModifica = false,
	annullaRigetto,
}: // saveProgetto,
RigettaProgettoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Progetto>();

	const listaCampi = Object.keys(progetto) as Array<keyof Progetto>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, progetto[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			progetto?.errorsStack?.fieldsErrors &&
				progetto.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: progetto.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		progetto?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		progetto?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [
		progetto,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const onSubmit: SubmitHandler<Progetto> = (progetto) => {
		dispatch(
			saveProgetto({
				progettoToSave: progetto,
				azione: "rigetta",
			})
		);
		annullaRigetto();
	};

	return (
		<Box sx={{ my: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{progetto?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{progetto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={12}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							Data Rigetto:
						</Typography>

						<DesktopDatePicker
							value={watch("data_rigetto") || progetto.data_rigetto}
							renderInput={(params: any) => (
								<TextField
									{...params}
									error={!!errors?.data_rigetto}
									helperText={errors?.data_rigetto?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									required
									// {...register("data_ora_fine_turno")}
								/>
							)}
							onChange={(newValue: any) => {
								setEditing(true);
								setValue(
									"data_rigetto",
									newValue ? new Date(newValue.toDate()) : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_rigetto");
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={12}>
						<TextField
							label="Motivo Rigetto"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							// type="number"
							required
							InputLabelProps={{
								shrink: true,
							}}
							{...register("note_rilavorazione")}
							error={!!errors?.note_rilavorazione}
							helperText={errors?.note_rilavorazione?.message?.toString()}
							autoComplete={"off"}
							multiline={true}
							rows={3}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={annullaRigetto}
				>
					Annulla
				</Button>
				<Button disabled={!isDirty} variant="outlined" type="submit">
					Rigetta
				</Button>
			</form>
		</Box>
	);
};

export default RigettaProgettoForm;
