import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../../common/errorsDeclarations";
import * as fineLavoriApi from "../../../../../../api/iterConnessioni/fineLavoriApi";
import moment from "moment";
import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../../../../allegati/allegatiSlice";
import { getIterConnessione } from "../../../iterConnessioneSlice";
import { fetchRichiesteConnessioneFromIter } from "../richiesteConnessioneSlice";

// import { useDispatch } from "react-redux";

moment.locale("it");

export interface FineLavoro {
	id: number;
	iter_connessione?: number;
	gaudi_censimp?: string;
	gaudi_data_registrazione_impianto?: Date | string;
	gaudi_data_registrazione_UP?: Date | string;
	gaudi_verificato?: boolean;
	gaudi_note?: string;

	iter_autorizzativo_data_avvio?: Date | string;
	iter_autorizzativo_data_conclusione?: Date | string;
	iter_autorizzativo_data_avvio_costrizione?: Date | string;
	iter_autorizzativo_data_completamento_costruzione?: Date | string;
	iter_autorizzativo_note?: string;

	regolamento_esercizio_data_disponibilita?: Date | string;
	regolamento_esercizio_data_inoltro?: Date | string;
	regolamento_esercizio_data_convalida?: Date | string;
	regolamento_esercizio_note?: string;

	gaudi_completo: boolean;
	iter_autorizzativo_completo: boolean;
	regolamento_esercizio_completo: boolean;
	fine_lavori_completo: boolean;

	listaDocumentiPerTipo?: DocumentiPerTipo[];
	errorsStack?: ErrorsStack;
}
export interface FineLavoriState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: FineLavoro[];
	errorsStack: ErrorsStack;
}
export interface FineLavoriStrutturaState {
	fineLavori: FineLavoriState;
}

const initialState: FineLavoriStrutturaState = {
	fineLavori: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchFineLavori = createAsyncThunk(
	"fineLavori/fetchFineLavori",
	async () => {
		return await fineLavoriApi.fetchFineLavori();
	}
);

export const getFineLavoro = createAsyncThunk(
	"fineLavori/getFineLavoro",
	async (fineLavoriId: number) => {
		return await fineLavoriApi.getFineLavoro(fineLavoriId);
	}
);

export const getFineLavoroFromIter = createAsyncThunk(
	"fineLavori/getFineLavoroFromIter",
	async (iterId: number) => {
		return await fineLavoriApi.getFineLavoroFromIter(iterId);
	}
);

export const saveFineLavoro = createAsyncThunk(
	"fineLavori/saveFineLavoro",
	async (
		parametri: {
			fineLavoroToSave: FineLavoro;
			azione?: string;
		},
		thunkApi
	) => {
		return await fineLavoriApi
			.saveFineLavoro(parametri.fineLavoroToSave, parametri.azione)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						getFineLavoroFromIter(
							parametri.fineLavoroToSave.iter_connessione || 0
						)
					);
				thunkApi.dispatch(
					fetchRichiesteConnessioneFromIter(
						parametri.fineLavoroToSave.iter_connessione || 0
					)
				);
				thunkApi.dispatch(
					getIterConnessione(parametri.fineLavoroToSave.iter_connessione || 0)
				);
				return response;
			});
	}
);

export const deleteFineLavoro = createAsyncThunk(
	"fineLavori/deleteFineLavoro",
	async (fineLavoriToDelete: FineLavoro, thunkApi) => {
		return await fineLavoriApi.deleteFineLavoro(fineLavoriToDelete);
	}
);

export const fineLavoriSlice = createSlice({
	name: "fineLavoriState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch FineLavori
		builder.addCase(fetchFineLavori.pending, (state, action) => {
			state.fineLavori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchFineLavori.fulfilled, (state, action) => {
			state.fineLavori = action.payload;
			state.fineLavori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchFineLavori.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.fineLavori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get FineLavori
		builder.addCase(getFineLavoro.pending, (state, action) => {
			state.fineLavori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getFineLavoro.fulfilled, (state, action) => {
			state.fineLavori.results = state.fineLavori.results.filter(
				(fineLavori) => fineLavori.id != action.payload.id
			);
			state.fineLavori.results.push(action.payload);

			state.fineLavori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getFineLavoro.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.fineLavori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get FineLavori from Iter
		builder.addCase(getFineLavoroFromIter.pending, (state, action) => {
			state.fineLavori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getFineLavoroFromIter.fulfilled, (state, action) => {
			state.fineLavori.results = state.fineLavori.results.filter(
				(fineLavori) => fineLavori.id != action.payload.id
			);
			state.fineLavori.results.push(action.payload);

			state.fineLavori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getFineLavoroFromIter.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.fineLavori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save FineLavori
		builder.addCase(saveFineLavoro.pending, (state, action) => {
			state.fineLavori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveFineLavoro.fulfilled, (state, action) => {
			state.fineLavori.results = state.fineLavori.results.filter(
				(fineLavori) => fineLavori.id != action.payload.id
			);
			state.fineLavori.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.fineLavori.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("FineLavori salvato.");
		});
		builder.addCase(saveFineLavoro.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.fineLavori.results = state.fineLavori.results.map((fineLavori) => {
				if (fineLavori.id == action.meta.arg.fineLavoroToSave.id) {
					return {
						...fineLavori,
						errorsStack: {
							status: ErrorStatusTypes.ERROR,
							fieldsErrors: JSON.parse(action?.error?.message || ""),
						},
					};
				} else {
					return fineLavori;
				}
			});
			state.fineLavori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = fineLavoriSlice.actions;

export const fineLavoriSliceReducer = fineLavoriSlice.reducer;
