import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import { Clienti, Cliente, fetchClienti } from "../clienti/clientiSlice";
import { EsponiStatoSintetico } from "components/common/EsponiStatoSintetico";

import moment from "moment";

import { UtenteLogged } from "../../authentication/authenticationSlice";

import {
	Adempimento,
	TipoAdempimento,
	fetchTipiAdempimento,
	ServizioAdempimenti,
	saveServizioAdempimenti,
	saveAdempimento,
	fetchAdempimenti,
	AdempimentiState,
	StatoAdempimentoTypes,
} from "../../main/adempimenti/adempimentiSlice";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Produttore } from "./produttoriSlice";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { theme } from "../../theme";
import { Divider, Stack } from "@mui/material";

interface AdempimentiProduttoreGridProps {
	produttore: Produttore;
	consentiModifica?: boolean;
}

const AdempimentiProduttoreGrid = ({
	produttore,
	consentiModifica = false,
}: AdempimentiProduttoreGridProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const clienti: Clienti = useSelector<RootState, Clienti>(
		(state) => state.clientiState.clienti
	);

	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);

	const tipi_adempimenti = useSelector<RootState, TipoAdempimento[]>(
		(state) => state.adempimentiState.tipiAdempimento.results
	);

	const [adempimentoScelta_id, setAdempimentoScelta_id] = useState<
		number | undefined
	>(undefined);
	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		dispatch(fetchClienti());
		dispatch(fetchTipiAdempimento());
	}, []);

	return (
		<Paper elevation={3} sx={{ p: 3 }}>
			<Stack direction="row" spacing={2}>
				{!(
					produttore.servizi_adempimenti &&
					produttore.servizi_adempimenti.length > 0
				) ? (
					consentiModifica ? (
						<Button
							variant="outlined"
							disabled={!consentiModifica}
							onClick={() => {
								const servizioAdempimenti: ServizioAdempimenti = {
									cliente: undefined,
									produttore: produttore.id || 0,
									data_validita_da: new Date(),
									// data di validita_a ad un anno da oggi
									data_validita_a: new Date(
										new Date().setFullYear(new Date().getFullYear() + 1)
									),
									errorsStack: undefined,
								};

								dispatch(
									saveServizioAdempimenti({
										servizioAdempimentiToSave: servizioAdempimenti,
									})
								);
							}}
						>
							Avvia Servizio Adempimenti
						</Button>
					) : (
						<Typography variant="h6">Nessun Servizio Adempimenti</Typography>
					)
				) : (
					<Stack direction="row" spacing={2}>
						<Typography variant="h6">Servizi Adempimenti</Typography>
						{produttore.servizi_adempimenti.map((servizio, index) => (
							<Stack
								key={index}
								direction="row"
								spacing={2}
								alignContent="space-between"
							>
								<Typography variant="h6">
									cliente: {servizio.cliente_descrizione}
								</Typography>
								<Typography variant="caption">dal</Typography>
								<Typography variant="h6">
									{moment(servizio.data_validita_da).format("DD/MM/YYYY")}
								</Typography>
								<Typography variant="caption">al</Typography>
								<Typography variant="h6">
									{moment(servizio.data_validita_a).format("DD/MM/YYYY")}
								</Typography>

								{servizio.in_corso ? (
									<Button
										disabled={!consentiModifica}
										onClick={() => {
											const nuovoAdempimento: Adempimento = {
												produttore: produttore.id,
												cliente: servizio.cliente,
												// tipo_adempimento: 0,
												stato: "P",
												data_scadenza: new Date(),
												data_pianificata: new Date(),
												data_completamento: undefined,
												errorsStack: undefined,
											};
											dispatch(
												saveAdempimento({ adempimentoToSave: nuovoAdempimento })
											);
										}}
									>
										Aggiungi Adempimento
									</Button>
								) : (
									<Button>Rinnova Servizio</Button>
								)}
							</Stack>
						))}
					</Stack>
				)}
			</Stack>
			{produttore.servizi_adempimenti &&
				produttore.servizi_adempimenti.length > 0 && (
					<Box sx={{ mt: 2 }}>
						<Divider>Adempimenti</Divider>
						{/* <Typography gutterBottom variant="subtitle1">
							Adempimenti
						</Typography> */}
						<Grid container spacing={0.5}>
							<Grid item xs={2} sm={2} md={1} lg={1}>
								<Typography
									variant="subtitle1"
									gutterBottom
									sx={{
										color: theme.palette.text.secondary,
									}}
								>
									n°
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={2} lg={2}>
								<Typography
									variant="subtitle1"
									sx={{
										color: theme.palette.text.secondary,
									}}
								>
									Tipo Adempimento
								</Typography>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography
									variant="subtitle1"
									sx={{
										color: theme.palette.text.secondary,
									}}
									gutterBottom
								>
									Cliente
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={1}>
								<Typography
									variant="subtitle1"
									sx={{
										color: theme.palette.text.secondary,
									}}
								>
									Stato
								</Typography>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography
									variant="subtitle1"
									gutterBottom
									sx={{
										color: theme.palette.text.secondary,
									}}
								>
									Data Scadenza
								</Typography>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography
									variant="subtitle1"
									gutterBottom
									sx={{
										color: theme.palette.text.secondary,
									}}
								>
									Data Pianif.
								</Typography>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography
									variant="subtitle1"
									gutterBottom
									sx={{
										color: theme.palette.text.secondary,
									}}
								>
									Data Completamento
								</Typography>
							</Grid>
						</Grid>
						{produttore.adempimenti?.map((adempimento: Adempimento, index) => (
							<Grid
								container
								spacing={0.5}
								key={index}
								sx={{
									backgroundColor: index % 2 === 0 ? "#eeeeee" : "inherit",
								}}
							>
								<Grid item xs={2} sm={2} md={1} lg={1}>
									<IconButton
										key={adempimento.id}
										aria-label="vai all'adempimento"
										size="small"
										component={Link}
										to={Routes_path.ADEMPIMENTO + adempimento.id}
									>
										<Typography variant="body1">{adempimento.id}</Typography>
									</IconButton>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={2}>
									<Typography variant="body1">
										{
											tipi_adempimenti.find(
												(tipo) => tipo.id == adempimento.tipo_adempimento
											)?.nome
										}
									</Typography>
								</Grid>

								<Grid item xs={6} sm={6} md={6} lg={2}>
									<Typography variant="body1">
										{adempimento.cliente_descrizione}
									</Typography>
								</Grid>

								<Grid item xs={6} sm={6} md={6} lg={1}>
									<Typography variant="body1">
										{
											Object.entries(StatoAdempimentoTypes).find(
												([key, value]) => key == adempimento.stato
											)?.[1]
										}
									</Typography>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={2}>
									<Typography
										variant="body1"
										sx={{
											color:
												moment(adempimento.data_pianificata).diff(
													moment(),
													"days"
												) < 15 && adempimento.stato != "C"
													? "red"
													: "inherit",
										}}
									>
										{adempimento.data_pianificata
											? moment(adempimento.data_pianificata).format(
													"DD/MM/YYYY"
											  )
											: ""}
									</Typography>
								</Grid>

								<Grid item xs={6} sm={6} md={6} lg={2}>
									<Typography
										variant="body1"
										sx={{
											color:
												moment(adempimento.data_scadenza).diff(
													moment(),
													"days"
												) < 10 && adempimento.stato != "C"
													? "red"
													: "inherit",
										}}
									>
										{adempimento.data_scadenza
											? moment(adempimento.data_scadenza).format("DD/MM/YYYY")
											: ""}
									</Typography>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={2}>
									<Typography variant="body1">
										{adempimento.data_completamento
											? moment(adempimento.data_completamento).format(
													"DD/MM/YYYY"
											  )
											: ""}
									</Typography>
								</Grid>
							</Grid>
						))}
					</Box>
				)}
		</Paper>
	);
};

export default AdempimentiProduttoreGrid;
