import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Allaccio, saveAllaccio } from "./allaccioSlice";
import { useAppDispatch, RootState } from "../../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../../common/errorsDeclarations";

import { theme } from "../../../../../theme";

import { utenteAutorizzato } from "../../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../../authentication/authenticationSlice";
import moment from "moment";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputAdornment from "@mui/material/InputAdornment";

import { Button, Divider } from "@mui/material";

interface IterConnessioneAllaccioFormProps {
	allaccio: Allaccio;
	consentiModifica: boolean;
	iterSemplificato: boolean;
}

const IterConnessioneAllaccioForm = ({
	allaccio,
	consentiModifica = false,
	iterSemplificato = true,
}: IterConnessioneAllaccioFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Allaccio>();

	const listaCampi = Object.keys(allaccio) as Array<keyof Allaccio>;

	const dispatch = useAppDispatch();

	// const [editing, setEditing] = useState(!iterConnessione.cliente);
	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	// const clienti = useSelector<RootState, Cliente[]>(
	// 	(state) => state.clientiState.clienti.results
	// );

	// const installatori = useSelector<RootState, Installatore[]>(
	// 	(state) => state.installatoriState.installatori.results
	// );

	// const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
	// 	(state) => state.regimiCommercialiState.regimiCommerciali.results
	// );
	// const tipiPratica = useSelector<RootState, TipoPratica[]>(
	// 	(state) => state.tipiPraticaState.tipiPratica.results
	// );
	// const tipiTensione = useSelector<RootState, TipoTensione[]>(
	// 	(state) => state.tipiTensioneState.tipiTensione.results
	// );

	// const gestori = useSelector<RootState, Gestore[]>(
	// 	(state) => state.gestoriState.gestori.results
	// );

	// const comuni = useSelector<RootState, Comune[]>(
	// 	(state) => state.comuniState.comuni.results
	// );

	// const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
	// 	undefined
	// );

	// useEffect(() => {
	// 	setComuneCorrente(
	// 		comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
	// 	);
	// }, [comuni, getValues("comune"), iterConnessione]);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, allaccio[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			allaccio?.errorsStack?.fieldsErrors &&
				allaccio.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: allaccio.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		allaccio?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		allaccio?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [
		allaccio,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveAllaccio = (allaccio: Allaccio) => {
		consentiModifica && dispatch(saveAllaccio({ allaccioToSave: allaccio }));
	};

	const onSubmit: SubmitHandler<Allaccio> = (allaccio) => {
		handlerSaveAllaccio(allaccio);
	};

	return (
		<Box sx={{ my: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{allaccio?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{allaccio?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Box
					sx={{
						borderStyle: "solid",
						borderColor: "#377199",
						borderRadius: 6,

						backgroundColor: allaccio.allaccio_completo
							? "#00aa0022"
							: "inherit",
						p: 3,
						mb: 2,
					}}
				>
					<Typography
						variant="h5"
						sx={{ mt: -1, color: "#377199" }}
						gutterBottom
					>
						Allaccio
					</Typography>
					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Stack>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data allaccio*:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: allaccio.alaccio_data
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{allaccio.alaccio_data
											? moment(allaccio.alaccio_data).format("dddd LL")
											: "inserisci data registrazione impianto"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data Allaccio"
										// {...register("data_ora_carico_pianificata")}
										value={watch("alaccio_data") || allaccio.alaccio_data}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={!!errors?.alaccio_data}
												helperText={errors?.alaccio_data?.message}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"alaccio_data",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors("alaccio_data");
										}}
									/>
								)}
								{!!allaccio.potenza_nominale &&
									allaccio.potenza_nominale >= 11.08 && (
										<Box>
											<Typography
												variant="caption"
												component="div"
												color="text.secondary"
												gutterBottom
												onClick={() => setEditing(true)}
											>
												data ultima taratura SPI
											</Typography>

											{!editing ? (
												<Typography
													variant="body1"
													component="div"
													// color="text.secondary"
													gutterBottom
													onClick={() => setEditing(true)}
													sx={{
														color: allaccio.data_ultima_taratura_spi
															? theme.palette.text.secondary
															: theme.palette.divider,
													}}
												>
													{allaccio.data_ultima_taratura_spi
														? moment(allaccio.data_ultima_taratura_spi).format(
																"dddd LL"
														  )
														: "inserisci data registrazione impianto"}
												</Typography>
											) : (
												<DesktopDatePicker
													// components={{
													// 	OpenPickerIcon: ClockIcon,
													// }}
													// inputFormat="DD/MM/YY HH:mm"
													// mask={"__/__/__ __:__"}
													// label="Data Allaccio"
													// {...register("data_ora_carico_pianificata")}
													value={
														watch("data_ultima_taratura_spi") ||
														allaccio.data_ultima_taratura_spi
													}
													renderInput={(params: any) => (
														<TextField
															{...params}
															error={!!errors?.data_ultima_taratura_spi}
															helperText={
																errors?.data_ultima_taratura_spi?.message
															}
															sx={{ width: "100%" }}
															autoComplete={"off"}
															// {...register("data_ora_fine_turno")}
														/>
													)}
													onChange={(newValue: any) => {
														setEditing(true);
														setValue(
															"data_ultima_taratura_spi",
															newValue ? new Date(newValue.toDate()) : "",
															{
																shouldDirty: true,
															}
														);
														clearErrors("data_ultima_taratura_spi");
													}}
												/>
											)}
										</Box>
									)}

								{!!allaccio.potenza_nominale &&
									allaccio.potenza_nominale >= 20 && (
										<Box>
											<Typography
												variant="caption"
												component="div"
												color="text.secondary"
												gutterBottom
												onClick={() => setEditing(true)}
											>
												data ultima taratura Contatori
											</Typography>

											{!editing ? (
												<Typography
													variant="body1"
													component="div"
													// color="text.secondary"
													gutterBottom
													onClick={() => setEditing(true)}
													sx={{
														color: allaccio.data_ultima_taratura_contatori
															? theme.palette.text.secondary
															: theme.palette.divider,
													}}
												>
													{allaccio.data_ultima_taratura_contatori
														? moment(
																allaccio.data_ultima_taratura_contatori
														  ).format("dddd LL")
														: "inserisci data registrazione impianto"}
												</Typography>
											) : (
												<DesktopDatePicker
													// components={{
													// 	OpenPickerIcon: ClockIcon,
													// }}
													// inputFormat="DD/MM/YY HH:mm"
													// mask={"__/__/__ __:__"}
													// label="Data Allaccio"
													// {...register("data_ora_carico_pianificata")}
													value={
														watch("data_ultima_taratura_contatori") ||
														allaccio.data_ultima_taratura_contatori
													}
													renderInput={(params: any) => (
														<TextField
															{...params}
															error={!!errors?.data_ultima_taratura_contatori}
															helperText={
																errors?.data_ultima_taratura_contatori?.message
															}
															sx={{ width: "100%" }}
															autoComplete={"off"}
															// {...register("data_ora_fine_turno")}
														/>
													)}
													onChange={(newValue: any) => {
														setEditing(true);
														setValue(
															"data_ultima_taratura_contatori",
															newValue ? new Date(newValue.toDate()) : "",
															{
																shouldDirty: true,
															}
														);
														clearErrors("data_ultima_taratura_contatori");
													}}
												/>
											)}
										</Box>
									)}
							</Stack>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={8}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								note appuntamento:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{allaccio.alaccio_appuntamento}
								</Typography>
							) : (
								<TextField
									// disabled={schedaCarico.numero_capi_pianificati}
									variant="outlined"
									// type="number"
									required
									InputLabelProps={{
										shrink: true,
									}}
									{...register("alaccio_appuntamento")}
									error={!!errors?.alaccio_appuntamento}
									helperText={errors?.alaccio_appuntamento?.message?.toString()}
									autoComplete={"off"}
									multiline={true}
									rows={3}
									sx={{ width: "100%" }}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						</Grid>
						<Grid item xs={12}>
							<Button
								// disabled={!isDirty}
								variant="outlined"
								onClick={() => {
									setEditing(false);
									// reset();
								}}
								sx={{ ...(!editing && { display: "none" }) }}
							>
								Annulla
							</Button>
							<Button
								disabled={!isDirty}
								variant="outlined"
								type="submit"
								sx={{ ...(!editing && { display: "none" }), ml: 1 }}
							>
								Salva
							</Button>
						</Grid>
					</Grid>
				</Box>
				<Box
					sx={{
						borderStyle: "solid",
						borderColor: "#377199",
						backgroundColor: allaccio.gse_completo ? "#00aa0022" : "inherit",
						borderRadius: 6,
						p: 3,
						mb: 2,
					}}
				>
					<Typography
						variant="h5"
						sx={{ mt: -1, color: "#377199" }}
						gutterBottom
					>
						Operatore Dispacciamento Contratto di Vendita Energia
					</Typography>

					{!iterSemplificato && (
						<Grid container spacing={2} sx={{ mb: 2 }}>
							<Grid item xs={12} sm={6} md={6} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data registrazione produttore*:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: allaccio.gse_data_registrazione_produttore
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{allaccio.gse_data_registrazione_produttore
											? moment(
													allaccio.gse_data_registrazione_produttore
											  ).format("dddd LL")
											: "inserisci data registrazione produttore"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data Allaccio"
										// {...register("data_ora_carico_pianificata")}
										value={
											watch("gse_data_registrazione_produttore") ||
											allaccio.gse_data_registrazione_produttore
										}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={!!errors?.gse_data_registrazione_produttore}
												helperText={
													errors?.gse_data_registrazione_produttore?.message
												}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"gse_data_registrazione_produttore",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors("gse_data_registrazione_produttore");
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data richiesta contratto*:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: allaccio.gse_data_richiesta_contratto
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{allaccio.gse_data_richiesta_contratto
											? moment(allaccio.gse_data_richiesta_contratto).format(
													"dddd LL"
											  )
											: "inserisci richiesta contratto"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data Allaccio"
										// {...register("data_ora_carico_pianificata")}
										value={
											watch("gse_data_richiesta_contratto") ||
											allaccio.gse_data_richiesta_contratto
										}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={!!errors?.gse_data_richiesta_contratto}
												helperText={
													errors?.gse_data_richiesta_contratto?.message
												}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"gse_data_richiesta_contratto",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors("gse_data_richiesta_contratto");
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									codice contratto*:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: allaccio.gse_codice_contratto
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{allaccio.gse_codice_contratto
											? allaccio.gse_codice_contratto.toString()
											: "inserisci codice contratto"}
									</Typography>
								) : (
									<TextField
										// label="indirizzo"
										// disabled={schedaCarico.numero_capi_pianificati}
										variant="outlined"
										// type="number"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("gse_codice_contratto")}
										error={!!errors?.gse_codice_contratto}
										helperText={errors?.gse_codice_contratto?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										// onBlur={handlerDetailChaingSubmit}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data accettazione contratto*:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: allaccio.gse_data_accettazione_contratto
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{allaccio.gse_data_accettazione_contratto
											? moment(allaccio.gse_data_accettazione_contratto).format(
													"dddd LL"
											  )
											: "inserisci data accettazione contratto"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data Allaccio"
										// {...register("data_ora_carico_pianificata")}
										value={
											watch("gse_data_accettazione_contratto") ||
											allaccio.gse_data_accettazione_contratto
										}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={!!errors?.gse_data_accettazione_contratto}
												helperText={
													errors?.gse_data_accettazione_contratto?.message
												}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"gse_data_accettazione_contratto",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors("gse_data_accettazione_contratto");
										}}
									/>
								)}
							</Grid>
						</Grid>
					)}
					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={6} md={6} lg={3}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								data attivazione contratto*:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: allaccio.gse_data_attivazione_contratto
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{allaccio.gse_data_attivazione_contratto
										? moment(allaccio.gse_data_attivazione_contratto).format(
												"dddd LL"
										  )
										: "inserisci attivazione contratto"}
								</Typography>
							) : (
								<DesktopDatePicker
									// components={{
									// 	OpenPickerIcon: ClockIcon,
									// }}
									// inputFormat="DD/MM/YY HH:mm"
									// mask={"__/__/__ __:__"}
									// label="Data Allaccio"
									// {...register("data_ora_carico_pianificata")}
									value={
										watch("gse_data_attivazione_contratto") ||
										allaccio.gse_data_attivazione_contratto
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.gse_data_attivazione_contratto}
											helperText={
												errors?.gse_data_attivazione_contratto?.message
											}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"gse_data_attivazione_contratto",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("gse_data_attivazione_contratto");
									}}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={12}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								note:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{allaccio.gse_note}
								</Typography>
							) : (
								<TextField
									// label="Motivo Rigetto"
									// disabled={schedaCarico.numero_capi_pianificati}
									variant="outlined"
									// type="number"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("gse_note")}
									error={!!errors?.gse_note}
									helperText={errors?.gse_note?.message?.toString()}
									autoComplete={"off"}
									multiline={true}
									rows={3}
									sx={{ width: "100%" }}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						</Grid>
					</Grid>
					<Button
						// disabled={!isDirty}
						variant="outlined"
						onClick={() => {
							setEditing(false);
							// reset();
						}}
						sx={{ ...(!editing && { display: "none" }) }}
					>
						Annulla
					</Button>
					<Button
						disabled={!isDirty}
						variant="outlined"
						type="submit"
						sx={{ ...(!editing && { display: "none" }), ml: 1 }}
					>
						Salva
					</Button>
				</Box>
				<Box
					sx={{
						borderStyle: "solid",
						borderColor: "#377199",
						backgroundColor: allaccio.siad_completo ? "#00aa0022" : "inherit",
						borderRadius: 6,
						p: 3,
						mb: 2,
					}}
				>
					<Typography
						variant="h5"
						sx={{ mt: -1, color: "#377199" }}
						gutterBottom
					>
						Pratica SIAD modifica impianti Incentivati
					</Typography>

					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								data comunicazione modifica*:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: allaccio.siad_data_comunicazione_modifica
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{allaccio.siad_data_comunicazione_modifica
										? moment(allaccio.siad_data_comunicazione_modifica).format(
												"dddd LL"
										  )
										: "inserisci data"}
								</Typography>
							) : (
								<DesktopDatePicker
									// components={{
									// 	OpenPickerIcon: ClockIcon,
									// }}
									// inputFormat="DD/MM/YY HH:mm"
									// mask={"__/__/__ __:__"}
									// label="Data Allaccio"
									// {...register("data_ora_carico_pianificata")}
									value={
										watch("siad_data_comunicazione_modifica") ||
										allaccio.siad_data_comunicazione_modifica
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.siad_data_comunicazione_modifica}
											helperText={
												errors?.siad_data_comunicazione_modifica?.message
											}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"siad_data_comunicazione_modifica",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("siad_data_comunicazione_modifica");
									}}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								data di modifica accettazione sul portale del GSE*:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: allaccio.siad_data_modifica_accettazione_GSE
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{allaccio.siad_data_modifica_accettazione_GSE
										? moment(
												allaccio.siad_data_modifica_accettazione_GSE
										  ).format("dddd LL")
										: "inserisci data"}
								</Typography>
							) : (
								<DesktopDatePicker
									// components={{
									// 	OpenPickerIcon: ClockIcon,
									// }}
									// inputFormat="DD/MM/YY HH:mm"
									// mask={"__/__/__ __:__"}
									// label="Data Allaccio"
									// {...register("data_ora_carico_pianificata")}
									value={
										watch("siad_data_modifica_accettazione_GSE") ||
										allaccio.siad_data_modifica_accettazione_GSE
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.siad_data_modifica_accettazione_GSE}
											helperText={
												errors?.siad_data_modifica_accettazione_GSE?.message
											}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"siad_data_modifica_accettazione_GSE",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("siad_data_modifica_accettazione_GSE");
									}}
								/>
							)}
						</Grid>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={12}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							note:
						</Typography>

						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								{allaccio.siad_note}
							</Typography>
						) : (
							<TextField
								// label="Motivo Rigetto"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								// type="number"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("siad_note")}
								error={!!errors?.siad_note}
								helperText={errors?.siad_note?.message?.toString()}
								autoComplete={"off"}
								multiline={true}
								rows={3}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					{/* </Grid> */}
					<Button
						// disabled={!isDirty}
						variant="outlined"
						onClick={() => {
							setEditing(false);
							// reset();
						}}
						sx={{ ...(!editing && { display: "none" }) }}
					>
						Annulla
					</Button>
					<Button
						disabled={!isDirty}
						variant="outlined"
						type="submit"
						sx={{ ...(!editing && { display: "none" }), ml: 1 }}
					>
						Salva
					</Button>
				</Box>
			</form>
		</Box>
	);
};

export default IterConnessioneAllaccioForm;
