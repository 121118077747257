import { handleResponse, handleError, headerset } from "../apiUtils";
import { Allegato } from "../../components/allegati/allegatiSlice";

const baseUrl = (
	iterConnessione_id_id?: number | null,
	tipo_documento_id?: number | null
) => {
	let baseUrl = process.env.API_URL + "/api/allegati/allegati_list/";
	if (iterConnessione_id_id) {
		baseUrl += "?iterConnessione_id=" + iterConnessione_id_id;
		if (tipo_documento_id) {
			baseUrl += "&tipo_documento_id=" + tipo_documento_id;
		}
	} else {
		if (tipo_documento_id) {
			baseUrl += "?tipo_documento_id=" + tipo_documento_id;
		}
	}
	return baseUrl;
};

export function fetchAllegati(
	iterConnessione_id?: number | null,
	tipo_documento_id?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = process.env.API_URL + "/api/allegati/allegati_list/";
	if (
		iterConnessione_id != undefined ||
		tipo_documento_id != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (tipo_documento_id != undefined) {
		url += `tipo_documento=${tipo_documento_id}&`;
	}
	if (iterConnessione_id != undefined) {
		url += `iter_connessione=${iterConnessione_id}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteAllegato(allegatoToDelete: Allegato) {
	const url =
		process.env.API_URL + "/api/allegati/allegato/" + allegatoToDelete.id + "/";
	return fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

const uploadBaseUrl = (
	nomeFile: string,
	descrizione?: string,
	tipo_documento?: number | null,
	iterConnessione_id?: number | null,
	progetto_id?: number | null,
	richiesta_connessione_id?: number | null,
	fine_lavori_id?: number | null,
	allaccio_id?: number | null,
	enea_id?: number | null,
	officina_elettrica_id?: number | null,
	autorizzazione_comunale_id?: number | null,
	sopralluogo_id?: number | null,
	sopralluogoElettrico_id?: number | null
) => {
	let uploadBaseUrl = process.env.API_URL + "/api/allegati/allegati_list/";
	if (iterConnessione_id) {
		const subUrl = progetto_id
			? "iter_connessione_progetto"
			: richiesta_connessione_id
			? "iter_connessione_richiesta_connessione"
			: fine_lavori_id
			? "iter_connessione_fine_lavoro"
			: allaccio_id
			? "iter_connessione_allaccio"
			: enea_id
			? "iter_connessione_enea"
			: "iter_connessione";
		uploadBaseUrl = `${process.env.API_URL}/api/allegati/upload/${subUrl}/${iterConnessione_id}/`;
		if (progetto_id) {
			uploadBaseUrl += `${progetto_id}/`;
		} else if (richiesta_connessione_id) {
			uploadBaseUrl += `${richiesta_connessione_id}/`;
		} else if (fine_lavori_id) {
			uploadBaseUrl += `${fine_lavori_id}/`;
		} else if (allaccio_id) {
			uploadBaseUrl += `${allaccio_id}/`;
		} else if (enea_id) {
			uploadBaseUrl += `${enea_id}/`;
		}
		uploadBaseUrl += `${tipo_documento}/${nomeFile}`;
		if (descrizione) {
			uploadBaseUrl += "?descrizione=" + descrizione;
		}
	} else if (officina_elettrica_id) {
		return `${process.env.API_URL}/api/allegati/upload/officina_elettrica/${officina_elettrica_id}/${tipo_documento}/${nomeFile}`;
	} else if (autorizzazione_comunale_id) {
		return `${process.env.API_URL}/api/allegati/upload/autorizzazione_comunale/${autorizzazione_comunale_id}/${tipo_documento}/${nomeFile}`;
	} else if (sopralluogo_id) {
		return `${process.env.API_URL}/api/allegati/upload/sopralluogo/${sopralluogo_id}/${tipo_documento}/${nomeFile}`;
	} else if (sopralluogoElettrico_id) {
		return `${process.env.API_URL}/api/allegati/upload/sopralluogo_elettrico/${sopralluogoElettrico_id}/${tipo_documento}/${nomeFile}`;
	} else {
		return process.env.API_URL + "/api/allegati/allegati_list/";
	}
	return uploadBaseUrl;
};

export function uploadAllegato(
	allegato: Allegato,

	file: File
) {
	console.log("allegato=", allegato);
	return fetch(
		uploadBaseUrl(
			file.name,
			allegato.descrizione,
			allegato.tipo_documento,
			allegato.iter_connessione,
			allegato.progetto,
			allegato.richiesta_connessione,
			allegato.fine_lavori,
			allegato.allaccio,
			allegato.enea,
			allegato.officina_elettrica,
			allegato.autorizzazione_comunale,
			allegato.sopralluogo,
			allegato.sopralluogo_elettrico
		),
		{
			method: "PUT", // PUT to upload.
			headers: {
				"content-type": "application/json",
				Authorization: headerset().Authorization,
			},
			body: file,
		}
	)
		.then(handleResponse)
		.catch(handleError);
}

// #######################################################################

const tipiDocumentoUrl =
	process.env.API_URL + "/api/allegati/tipo_documento_list/";

export function fetchTipiDocumento() {
	return fetch(tipiDocumentoUrl, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
