import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FineLavoro, saveFineLavoro } from "./fineLavoriSlice";
import { useAppDispatch, RootState } from "../../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../../common/errorsDeclarations";

import { theme } from "../../../../../theme";

import { utenteAutorizzato } from "../../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../../authentication/authenticationSlice";
import moment from "moment";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputAdornment from "@mui/material/InputAdornment";

import { Button, Divider } from "@mui/material";

interface IterConnessioneFineLavoroFormProps {
	fineLavoro: FineLavoro;
	consentiModifica: boolean;
	iterSemplificato: boolean;
}

const IterConnessioneFineLavoroForm = ({
	fineLavoro,
	consentiModifica = false,
	iterSemplificato = true,
}: IterConnessioneFineLavoroFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<FineLavoro>();

	const listaCampi = Object.keys(fineLavoro) as Array<keyof FineLavoro>;

	const dispatch = useAppDispatch();

	// const [editing, setEditing] = useState(!iterConnessione.cliente);
	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	// const clienti = useSelector<RootState, Cliente[]>(
	// 	(state) => state.clientiState.clienti.results
	// );

	// const installatori = useSelector<RootState, Installatore[]>(
	// 	(state) => state.installatoriState.installatori.results
	// );

	// const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
	// 	(state) => state.regimiCommercialiState.regimiCommerciali.results
	// );
	// const tipiPratica = useSelector<RootState, TipoPratica[]>(
	// 	(state) => state.tipiPraticaState.tipiPratica.results
	// );
	// const tipiTensione = useSelector<RootState, TipoTensione[]>(
	// 	(state) => state.tipiTensioneState.tipiTensione.results
	// );

	// const gestori = useSelector<RootState, Gestore[]>(
	// 	(state) => state.gestoriState.gestori.results
	// );

	// const comuni = useSelector<RootState, Comune[]>(
	// 	(state) => state.comuniState.comuni.results
	// );

	// const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
	// 	undefined
	// );

	// useEffect(() => {
	// 	setComuneCorrente(
	// 		comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
	// 	);
	// }, [comuni, getValues("comune"), iterConnessione]);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, fineLavoro[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			fineLavoro?.errorsStack?.fieldsErrors &&
				fineLavoro.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: fineLavoro.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		fineLavoro?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		fineLavoro?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [
		fineLavoro,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveFineLavoro = (fineLavoro: FineLavoro) => {
		consentiModifica &&
			dispatch(saveFineLavoro({ fineLavoroToSave: fineLavoro }));
	};

	const onSubmit: SubmitHandler<FineLavoro> = (fineLavoro) => {
		handlerSaveFineLavoro(fineLavoro);
	};

	return (
		<Box sx={{ my: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{fineLavoro?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{fineLavoro?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Box
					sx={{
						borderStyle: "solid",
						borderColor: "#377199",
						backgroundColor: fineLavoro.gaudi_completo
							? "#00aa0022"
							: "inherit",
						borderRadius: 6,
						p: 3,
						mb: 2,
					}}
				>
					<Typography
						variant="h5"
						sx={{ mt: -1, color: "#377199" }}
						gutterBottom
					>
						Gaudì
					</Typography>
					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								codice Impianto*:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: fineLavoro.gaudi_censimp
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{fineLavoro.gaudi_censimp
										? fineLavoro.gaudi_censimp.toString()
										: "inserisci codice Impianto"}
								</Typography>
							) : (
								<TextField
									// label="indirizzo"
									// disabled={schedaCarico.numero_capi_pianificati}
									variant="outlined"
									// type="number"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("gaudi_censimp")}
									error={!!errors?.gaudi_censimp}
									helperText={errors?.gaudi_censimp?.message}
									autoComplete={"off"}
									sx={{ width: "100%" }}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						</Grid>
						{!iterSemplificato && (
							<>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Typography
										variant="caption"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										data di registrazione impianto*:
									</Typography>

									{!editing ? (
										<Typography
											variant="body1"
											component="div"
											// color="text.secondary"
											gutterBottom
											onClick={() => setEditing(true)}
											sx={{
												color: fineLavoro.gaudi_data_registrazione_impianto
													? theme.palette.text.secondary
													: theme.palette.divider,
											}}
										>
											{fineLavoro.gaudi_data_registrazione_impianto
												? moment(
														fineLavoro.gaudi_data_registrazione_impianto
												  ).format("dddd LL")
												: "inserisci data registrazione impianto"}
										</Typography>
									) : (
										<DesktopDatePicker
											// components={{
											// 	OpenPickerIcon: ClockIcon,
											// }}
											// inputFormat="DD/MM/YY HH:mm"
											// mask={"__/__/__ __:__"}
											// label="Data FineLavoro"
											// {...register("data_ora_carico_pianificata")}
											value={
												watch("gaudi_data_registrazione_impianto") ||
												fineLavoro.gaudi_data_registrazione_impianto
											}
											renderInput={(params: any) => (
												<TextField
													{...params}
													error={!!errors?.gaudi_data_registrazione_impianto}
													helperText={
														errors?.gaudi_data_registrazione_impianto?.message
													}
													sx={{ width: "100%" }}
													autoComplete={"off"}
													// {...register("data_ora_fine_turno")}
												/>
											)}
											onChange={(newValue: any) => {
												setEditing(true);
												setValue(
													"gaudi_data_registrazione_impianto",
													newValue ? new Date(newValue.toDate()) : "",
													{
														shouldDirty: true,
													}
												);
												clearErrors("gaudi_data_registrazione_impianto");
											}}
										/>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Typography
										variant="caption"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										data di registrazione Unità Prod.*:
									</Typography>

									{!editing ? (
										<Typography
											variant="body1"
											component="div"
											// color="text.secondary"
											gutterBottom
											onClick={() => setEditing(true)}
											sx={{
												color: fineLavoro.gaudi_data_registrazione_UP
													? theme.palette.text.secondary
													: theme.palette.divider,
											}}
										>
											{fineLavoro.gaudi_data_registrazione_UP
												? moment(fineLavoro.gaudi_data_registrazione_UP).format(
														"dddd LL"
												  )
												: "inserisci data registrazione UP"}
										</Typography>
									) : (
										<DesktopDatePicker
											// components={{
											// 	OpenPickerIcon: ClockIcon,
											// }}
											// inputFormat="DD/MM/YY HH:mm"
											// mask={"__/__/__ __:__"}
											// label="Data FineLavoro"
											// {...register("data_ora_carico_pianificata")}
											value={
												watch("gaudi_data_registrazione_UP") ||
												fineLavoro.gaudi_data_registrazione_UP
											}
											renderInput={(params: any) => (
												<TextField
													{...params}
													error={!!errors?.gaudi_data_registrazione_UP}
													helperText={
														errors?.gaudi_data_registrazione_UP?.message
													}
													sx={{ width: "100%" }}
													autoComplete={"off"}
													// {...register("data_ora_fine_turno")}
												/>
											)}
											onChange={(newValue: any) => {
												setEditing(true);
												setValue(
													"gaudi_data_registrazione_UP",
													newValue ? new Date(newValue.toDate()) : "",
													{
														shouldDirty: true,
													}
												);
												clearErrors("gaudi_data_registrazione_UP");
											}}
										/>
									)}
								</Grid>
							</>
						)}
						<Grid item xs={12} sm={6} md={6} lg={12}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								note:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{fineLavoro.gaudi_note}
								</Typography>
							) : (
								<TextField
									// label="Motivo Rigetto"
									// disabled={schedaCarico.numero_capi_pianificati}
									variant="outlined"
									// type="number"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("gaudi_note")}
									error={!!errors?.gaudi_note}
									helperText={errors?.gaudi_note?.message?.toString()}
									autoComplete={"off"}
									multiline={true}
									rows={3}
									sx={{ width: "100%" }}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						</Grid>
					</Grid>
					<Button
						// disabled={!isDirty}
						variant="outlined"
						onClick={() => {
							setEditing(false);
							// reset();
						}}
						sx={{ ...(!editing && { display: "none" }) }}
					>
						Annulla
					</Button>
					<Button
						disabled={!isDirty}
						variant="outlined"
						type="submit"
						sx={{ ...(!editing && { display: "none" }), ml: 1 }}
					>
						Salva
					</Button>
				</Box>
				{!iterSemplificato && (
					<Box
						sx={{
							borderStyle: "solid",
							borderColor: "#377199",
							borderRadius: 6,

							backgroundColor: fineLavoro.iter_autorizzativo_completo
								? "#00aa0022"
								: "inherit",
							p: 3,
							mb: 2,
						}}
					>
						<Typography
							variant="h5"
							sx={{ mt: -1, color: "#377199" }}
							gutterBottom
						>
							Iter Autorizzativo
						</Typography>
						<Grid container spacing={2} sx={{ mb: 2 }}>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data avvio*:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: fineLavoro.iter_autorizzativo_data_avvio
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{fineLavoro.iter_autorizzativo_data_avvio
											? moment(fineLavoro.iter_autorizzativo_data_avvio).format(
													"dddd LL"
											  )
											: "inserisci data avvio"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data FineLavoro"
										// {...register("data_ora_carico_pianificata")}
										value={
											watch("iter_autorizzativo_data_avvio") ||
											fineLavoro.iter_autorizzativo_data_avvio
										}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={!!errors?.iter_autorizzativo_data_avvio}
												helperText={
													errors?.iter_autorizzativo_data_avvio?.message
												}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"iter_autorizzativo_data_avvio",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors("iter_autorizzativo_data_avvio");
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data conclusione.*:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: fineLavoro.iter_autorizzativo_data_conclusione
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{fineLavoro.iter_autorizzativo_data_conclusione
											? moment(
													fineLavoro.iter_autorizzativo_data_conclusione
											  ).format("dddd LL")
											: "inserisci data conclusione"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data FineLavoro"
										// {...register("data_ora_carico_pianificata")}
										value={
											watch("iter_autorizzativo_data_conclusione") ||
											fineLavoro.iter_autorizzativo_data_conclusione
										}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={!!errors?.iter_autorizzativo_data_conclusione}
												helperText={
													errors?.iter_autorizzativo_data_conclusione?.message
												}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"iter_autorizzativo_data_conclusione",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors("iter_autorizzativo_data_conclusione");
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}></Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data avvio costruzione.*:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color:
												fineLavoro.iter_autorizzativo_data_avvio_costrizione
													? theme.palette.text.secondary
													: theme.palette.divider,
										}}
									>
										{fineLavoro.iter_autorizzativo_data_avvio_costrizione
											? moment(
													fineLavoro.iter_autorizzativo_data_avvio_costrizione
											  ).format("dddd LL")
											: "inserisci data avvio costrizione"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data FineLavoro"
										// {...register("data_ora_carico_pianificata")}
										value={
											watch("iter_autorizzativo_data_avvio_costrizione") ||
											fineLavoro.iter_autorizzativo_data_avvio_costrizione
										}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={
													!!errors?.iter_autorizzativo_data_avvio_costrizione
												}
												helperText={
													errors?.iter_autorizzativo_data_avvio_costrizione
														?.message
												}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"iter_autorizzativo_data_avvio_costrizione",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors("iter_autorizzativo_data_avvio_costrizione");
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data completamento costruzione.*:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color:
												fineLavoro.iter_autorizzativo_data_completamento_costruzione
													? theme.palette.text.secondary
													: theme.palette.divider,
										}}
									>
										{fineLavoro.iter_autorizzativo_data_completamento_costruzione
											? moment(
													fineLavoro.iter_autorizzativo_data_completamento_costruzione
											  ).format("dddd LL")
											: "inserisci data completamento costrizione"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data FineLavoro"
										// {...register("data_ora_carico_pianificata")}
										value={
											watch(
												"iter_autorizzativo_data_completamento_costruzione"
											) ||
											fineLavoro.iter_autorizzativo_data_completamento_costruzione
										}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={
													!!errors?.iter_autorizzativo_data_completamento_costruzione
												}
												helperText={
													errors
														?.iter_autorizzativo_data_completamento_costruzione
														?.message
												}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"iter_autorizzativo_data_completamento_costruzione",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors(
												"iter_autorizzativo_data_completamento_costruzione"
											);
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}></Grid>
							<Grid item xs={12} sm={6} md={6} lg={12}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									note:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{fineLavoro.iter_autorizzativo_note}
									</Typography>
								) : (
									<TextField
										// label="Motivo Rigetto"
										// disabled={schedaCarico.numero_capi_pianificati}
										variant="outlined"
										// type="number"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("iter_autorizzativo_note")}
										error={!!errors?.iter_autorizzativo_note}
										helperText={errors?.iter_autorizzativo_note?.message?.toString()}
										autoComplete={"off"}
										multiline={true}
										rows={3}
										sx={{ width: "100%" }}
										fullWidth
										// onBlur={handlerDetailChaingSubmit}
									/>
								)}
							</Grid>
						</Grid>
						<Button
							// disabled={!isDirty}
							variant="outlined"
							onClick={() => {
								setEditing(false);
								// reset();
							}}
							sx={{ ...(!editing && { display: "none" }) }}
						>
							Annulla
						</Button>
						<Button
							disabled={!isDirty}
							variant="outlined"
							type="submit"
							sx={{ ...(!editing && { display: "none" }), ml: 1 }}
						>
							Salva
						</Button>
					</Box>
				)}
				<Box
					sx={{
						borderStyle: "solid",
						borderColor: "#377199",
						borderRadius: 6,

						backgroundColor: fineLavoro.regolamento_esercizio_completo
							? "#00aa0022"
							: "inherit",
						p: 3,
						mb: 2,
					}}
				>
					<Typography
						variant="h5"
						sx={{ mt: -1, color: "#377199" }}
						gutterBottom
					>
						Regolamento d'esercizo
					</Typography>
					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								data disponibilità*:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: fineLavoro.regolamento_esercizio_data_disponibilita
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{fineLavoro.regolamento_esercizio_data_disponibilita
										? moment(
												fineLavoro.regolamento_esercizio_data_disponibilita
										  ).format("dddd LL")
										: "inserisci data disponibilita"}
								</Typography>
							) : (
								<DesktopDatePicker
									// components={{
									// 	OpenPickerIcon: ClockIcon,
									// }}
									// inputFormat="DD/MM/YY HH:mm"
									// mask={"__/__/__ __:__"}
									// label="Data FineLavoro"
									// {...register("data_ora_carico_pianificata")}
									value={
										watch("regolamento_esercizio_data_disponibilita") ||
										fineLavoro.regolamento_esercizio_data_disponibilita
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.regolamento_esercizio_data_disponibilita}
											helperText={
												errors?.regolamento_esercizio_data_disponibilita
													?.message
											}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"regolamento_esercizio_data_disponibilita",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("regolamento_esercizio_data_disponibilita");
									}}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								data inoltro.*:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: fineLavoro.regolamento_esercizio_data_inoltro
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{fineLavoro.regolamento_esercizio_data_inoltro
										? moment(
												fineLavoro.regolamento_esercizio_data_inoltro
										  ).format("dddd LL")
										: "inserisci data inoltro"}
								</Typography>
							) : (
								<DesktopDatePicker
									// components={{
									// 	OpenPickerIcon: ClockIcon,
									// }}
									// inputFormat="DD/MM/YY HH:mm"
									// mask={"__/__/__ __:__"}
									// label="Data FineLavoro"
									// {...register("data_ora_carico_pianificata")}
									value={
										watch("regolamento_esercizio_data_inoltro") ||
										fineLavoro.regolamento_esercizio_data_inoltro
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.regolamento_esercizio_data_inoltro}
											helperText={
												errors?.regolamento_esercizio_data_inoltro?.message
											}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"regolamento_esercizio_data_inoltro",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("regolamento_esercizio_data_inoltro");
									}}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								data convalida*:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: fineLavoro.regolamento_esercizio_data_convalida
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{fineLavoro.regolamento_esercizio_data_convalida
										? moment(
												fineLavoro.regolamento_esercizio_data_convalida
										  ).format("dddd LL")
										: "inserisci data convalida"}
								</Typography>
							) : (
								<DesktopDatePicker
									// components={{
									// 	OpenPickerIcon: ClockIcon,
									// }}
									// inputFormat="DD/MM/YY HH:mm"
									// mask={"__/__/__ __:__"}
									// label="Data FineLavoro"
									// {...register("data_ora_carico_pianificata")}
									value={
										watch("regolamento_esercizio_data_convalida") ||
										fineLavoro.regolamento_esercizio_data_convalida
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.regolamento_esercizio_data_convalida}
											helperText={
												errors?.regolamento_esercizio_data_convalida?.message
											}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"regolamento_esercizio_data_convalida",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("regolamento_esercizio_data_convalida");
									}}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={12}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								note:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{fineLavoro.regolamento_esercizio_note}
								</Typography>
							) : (
								<TextField
									// label="Motivo Rigetto"
									// disabled={schedaCarico.numero_capi_pianificati}
									variant="outlined"
									// type="number"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("regolamento_esercizio_note")}
									error={!!errors?.regolamento_esercizio_note}
									helperText={errors?.regolamento_esercizio_note?.message?.toString()}
									autoComplete={"off"}
									multiline={true}
									rows={3}
									sx={{ width: "100%" }}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						</Grid>
					</Grid>
					<Button
						// disabled={!isDirty}
						variant="outlined"
						onClick={() => {
							setEditing(false);
							// reset();
						}}
						sx={{ ...(!editing && { display: "none" }) }}
					>
						Annulla
					</Button>
					<Button
						disabled={!isDirty}
						variant="outlined"
						type="submit"
						sx={{ ...(!editing && { display: "none" }), ml: 1 }}
					>
						Salva
					</Button>
				</Box>
			</form>
		</Box>
	);
};

export default IterConnessioneFineLavoroForm;
