import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import {
	Clienti,
	Cliente,
	fetchClienti,
	resetClienteCorrente,
} from "./clientiSlice";

import moment from "moment";

import { ErrorStatusTypes } from "../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import { theme } from "../../theme";

import {
	DataGrid,
	GridToolbar,
	GridRowsProp,
	GridRowModel,
	GridColDef,
	GridCellEditStopParams,
	GridCellEditStopReasons,
	MuiEvent,
	GridToolbarContainer,
	GridRenderCellParams,
	GridValueFormatterParams,
} from "@mui/x-data-grid";

const ClientiGrid = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const clienti: Clienti = useSelector<RootState, Clienti>(
		(state) => state.clientiState.clienti
	);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		dispatch(fetchClienti());
	}, []);

	const rows: GridRowsProp = clienti.results.map((cliente: Cliente) => ({
		id: cliente.id,
		ragione_sociale: cliente.ragione_sociale,
		piva: cliente.piva,
	}));

	const columns: GridColDef[] = [
		{
			field: "ragione_sociale",
			headerName: "Ragione Sociale",
			flex: 0.6,
			type: "string",

			renderCell: (params: GridRenderCellParams<number>) => (
				<IconButton
					key={params.value}
					aria-label="modifica elemento"
					size="small"
					component={Link}
					to={{
						pathname: Routes_path.CLIENTE + params.id,
					}}
					// state={{
					// 	lavorazine_id: params.value,
					// }}
				>
					{params.value}
				</IconButton>
			),
		},
		{
			field: "piva",
			headerName: "Partita Iva",
			flex: 1.2,
			type: "string",
		},
	];

	const gridAutoHeight = true;

	const handlerCreateCliente = () => {
		dispatch(resetClienteCorrente());

		navigate(Routes_path.CLIENTE);
	};

	function CustomToolbar() {
		return (
			//   <GridToolbarContainer>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
			>
				<GridToolbar />
				<Button
					color="primary"
					startIcon={<AddIcon />}
					onClick={(event) => {
						event.preventDefault();
						handlerCreateCliente();
					}}
					component={Link}
					to={Routes_path.CLIENTE}
				>
					Aggiungi nuovo cliente
				</Button>
			</Stack>
			//   </GridToolbarContainer>
		);
	}

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
				// "& .campo-editabile": {
				// 	backgroundColor:  "#bbbbbb11",
				// 	background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
				// 	// borderWidth: "5px",
				// 	// borderColor: "#ff91003d",
				// 	borderColor: "#0000003d",
				// 	// borderStyle: "outset",
				// 	borderWidth: 3,
				// 	borderStyle: "ridge",
				// 	color: "#1a3e72",
				// 	fontWeight: "600",
				// },
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<DataGrid
				rows={rows}
				// density="compact"
				columns={columns}
				components={{
					Toolbar: CustomToolbar,
				}}
				autoHeight={gridAutoHeight}
				// sx={{
				// 	width: "100%",
				// 	minHeight: gridAutoHeight ? "200px" : undefined,
				// 	padding: 0,
				// 	margin: 0,
				// 	fontSize: "0.75rem",
				// }}
				experimentalFeatures={{ newEditingApi: true }}
				onProcessRowUpdateError={(e) => alert(e)}
				onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
					if (params.reason === GridCellEditStopReasons.cellFocusOut) {
						event.defaultMuiPrevented = true;
					}
				}}
				hideFooterPagination={true}
			/>
		</Box>
	);
};

export default ClientiGrid;
