import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
import * as eneaApi from "../../../../../api/iterConnessioni/eneaApi";
import moment from "moment";
import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../../../allegati/allegatiSlice";
import { getIterConnessione } from "../../iterConnessioneSlice";
import { fetchRichiesteConnessioneFromIter } from "../richiesteConnessione/richiesteConnessioneSlice";

// import { useDispatch } from "react-redux";

moment.locale("it");

export interface Enea {
	id: number;
	iter_connessione?: number;
	enea_data_compilazione?: Date | string;
	dichiarazione_enea_rigettata?: boolean;
	codice_cpid?: string;
	enea_completo?: boolean;

	listaDocumentiPerTipo?: DocumentiPerTipo[];
	errorsStack?: ErrorsStack;
}

export interface EneaState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Enea[];
	errorsStack: ErrorsStack;
}
export interface EneaStrutturaState {
	enea: EneaState;
}

const initialState: EneaStrutturaState = {
	enea: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchEnea = createAsyncThunk("enea/fetchEnea", async () => {
	return await eneaApi.fetchEnea();
});

export const getEnea = createAsyncThunk(
	"enea/getEnea",
	async (eneaId: number) => {
		return await eneaApi.getEnea(eneaId);
	}
);

export const getEneaFromIter = createAsyncThunk(
	"enea/getEneaFromIter",
	async (iterId: number) => {
		return await eneaApi.getEneaFromIter(iterId);
	}
);

export const saveEnea = createAsyncThunk(
	"enea/saveEnea",
	async (
		parametri: {
			eneaToSave: Enea;
			azione?: string;
		},
		thunkApi
	) => {
		return await eneaApi
			.saveEnea(parametri.eneaToSave, parametri.azione)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						getEneaFromIter(parametri.eneaToSave.iter_connessione || 0)
					);

				return response;
			});
	}
);

export const deleteEnea = createAsyncThunk(
	"enea/deleteEnea",
	async (eneaToDelete: Enea, thunkApi) => {
		return await eneaApi.deleteEnea(eneaToDelete);
	}
);

export const eneaSlice = createSlice({
	name: "eneaState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Enea
		builder.addCase(fetchEnea.pending, (state, action) => {
			state.enea.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchEnea.fulfilled, (state, action) => {
			state.enea = action.payload;
			state.enea.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchEnea.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.enea.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Enea
		builder.addCase(getEnea.pending, (state, action) => {
			state.enea.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getEnea.fulfilled, (state, action) => {
			state.enea.results = state.enea.results.filter(
				(enea) => enea.id != action.payload.id
			);
			state.enea.results.push(action.payload);

			state.enea.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getEnea.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.enea.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Enea from Iter
		builder.addCase(getEneaFromIter.pending, (state, action) => {
			state.enea.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getEneaFromIter.fulfilled, (state, action) => {
			state.enea.results = state.enea.results.filter(
				(enea) => enea.id != action.payload.id
			);
			state.enea.results.push(action.payload);

			state.enea.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getEneaFromIter.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.enea.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Enea
		builder.addCase(saveEnea.pending, (state, action) => {
			state.enea.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveEnea.fulfilled, (state, action) => {
			state.enea.results = state.enea.results.filter(
				(enea) => enea.id != action.payload.id
			);
			state.enea.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.enea.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Pratica Enea salvata.");
		});
		builder.addCase(saveEnea.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.enea.results = state.enea.results.map((enea) => {
				if (enea.id == action.meta.arg.eneaToSave.id) {
					return {
						...enea,
						errorsStack: {
							status: ErrorStatusTypes.ERROR,
							fieldsErrors: JSON.parse(action?.error?.message || ""),
						},
					};
				} else {
					return enea;
				}
			});
			state.enea.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = eneaSlice.actions;

export const eneaSliceReducer = eneaSlice.reducer;
