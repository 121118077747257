import { Typography, Link } from "@mui/material";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

export default function Copyright() {
	return (
		<Typography
			variant="body2"
			color="textSecondary"
			align="center"
			sx={{ mt: 4 }}
		>
			{`${process.env.APP_NAME} - Powered by `}
			{/* {"Copyright © "} */}
			<IntegrationInstructionsIcon
				sx={{ verticalAlign: "middle", display: "inline-flex" }}
			/>
			<Link
				color="inherit"
				href="https://www.digitalforindustry.com/"
				underline="hover"
			>
				{" "}
				Digital For Industry
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}
