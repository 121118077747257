import {
	useEffect,
	useState,
	useRef,
	ChangeEvent,
	FocusEvent,
	KeyboardEvent,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import moment from "moment";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import { theme } from "../../theme";

import {
	OrdinamentoLista,
	PreferenzeLista,
	setPreferenzeListaPods,
} from "../../userPreference/userPreferenceSlice";
import { fetchPods, PodsState, Pod } from "./podsSlice";
import { RegimeCommerciale } from "../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	StatoIter,
	ServizioTypes,
} from "../../anagrafiche/kpi/kpiSlice";
import {
	Produttore,
	resetProduttoreCorrente,
} from "../../anagrafiche/produttori/produttoriSlice";

// import IterConnessioneAlerChip from "./iterConnessione/IterConnessioneAlerChip";
import { Cliente } from "../../anagrafiche/clienti/clientiSlice";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import {
	UtenteLogged,
	Utente,
	UtenteApplicativo,
} from "../../authentication/authenticationSlice";
import {
	EsponiStatoSintetico,
	statiSintetici,
} from "components/common/EsponiStatoSintetico";
import { TipoTensione } from "../../anagrafiche/tipiTensione/tipiTensioneSlice";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";
import { Comune } from "../../anagrafiche/comuni/comuniSlice";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const produttoreFullName = (produttore?: Produttore) => {
	if (produttore) {
		if (produttore.tipo_persona == "PF") {
			return `${produttore.cognome} ${produttore.nome}`;
		} else {
			return `${produttore.ragione_sociale}`;
		}
	} else {
		return "anonimo";
	}
};

const visualizzaStatoIter = (statoIter?: StatoIter) => {
	return statoIter && `${statoIter.stato_desc} (${statoIter.count})`;
};

interface PodsPageProps {
	// openRicercaIter?: boolean;
	// azioneSuIter?: (iter: IterConnessione) => void;
	// setOpenRicercaIter: (value: boolean) => void;
}

const PodsPage = ({}: // openRicercaIter = false,
// azioneSuIter
PodsPageProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type LocationState = {
		// statoIterId?: string;
		// clienteIterId?: number;
		// iterSemplificato?: string;
		// operatoreId?: number;
		// progettistaId?: number;
	};
	const location = useLocation();
	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);

	const preferenzeListaPods = useSelector<
		RootState,
		PreferenzeLista | undefined
	>((state) => state.userPreferenceState.preferenzeListaPods);

	//
	const pods = useSelector<RootState, PodsState>(
		(state) => state.podsState.pods
	);

	const comuni: Comune[] = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
		(state) => state.authentication.utentiApplicativo.results
	);

	const [page, setPage] = useState<number>(preferenzeListaPods?.page || 1);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaPods?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [ordinamento, setOrdinamento] = useState<OrdinamentoLista | undefined>(
		undefined
	);

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			setRefresh(true);
		}
	};

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);

	const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
	const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
	const [anchorElTipoIterFilter, setAnchorElTipoIterFilter] = useState(null);
	const [anchorElOperatoreFilter, setAnchorElOperatoreFilter] = useState(null);
	const [anchorElProgettistaFilter, setAnchorElProgettistaFilter] =
		useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElStatoFilter(null);
		setAnchorElClienteFilter(null);
		setAnchorElTipoIterFilter(null);
		setAnchorElOperatoreFilter(null);
		setAnchorElProgettistaFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		dispatch(
			fetchPods({
				// statoIter: statoIterId,
				// clienteIter: clienteIterId,
				// iterSemplificato: iterSemplificato,
				// operatore: operatoreId,
				// progettista: progettistaId,
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		dispatch(
			setPreferenzeListaPods({
				numeroRecord: numeroRecord,
				page: page,
				ordinamento: ordinamento,
				// stato: statoIterId,
				// clienteIterId: clienteIterId,
				// iterSemplificato: iterSemplificato,
				// operatoreId: operatoreId,
				// progettistaId: progettistaId,
			})
		);
		setRefresh(false);
	}, [
		page,
		numeroRecord,
		// search,
		// statoIterId,
		// clienteIterId,
		// iterSemplificato,
		// operatoreId,
		// progettistaId,
		refresh,
	]);
	const handlerCreateIterConnessione = (servizio?: ServizioTypes) => {
		dispatch(resetProduttoreCorrente());
		navigate(Routes_path.PRODUTTORE + "0/" + servizio);
	};
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// funzione di gestione sul click della riga che se è ricerca iter chiama la funzione azioneSuIter altrimenti naviga sulla pagina dell'iter
	// const handleClickRow = (iter: IterConnessione) => {
	// 	if (openRicercaIter) {
	// 		azioneSuIter && azioneSuIter(iter);
	// 	} else {
	// 		navigate(Routes_path.ITER_CONNESSIONE + iter.id);
	// 	}
	// };

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<List sx={{ mt: 2 }}>
				<ListItem>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						sx={{ mb: 2 }}
					>
						<Button
							color="primary"
							startIcon={<ReplayIcon />}
							onClick={() => setRefresh(true)}
						>
							aggiorna
						</Button>
						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
							onBlur={() => setRefresh(true)}
							onKeyDown={handleKeyDown}
							sx={{ ml: 2 }}
						/>
					</ButtonGroup>
				</ListItem>

				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid
						container
						// spacing={{ xs: 0.5, md: 1, lg: openRicercaIter ? 4 : 2 }}
						spacing={{ xs: 0.5, md: 1, lg: 2 }}
						sx={{ px: 2 }}
					>
						{/* <Grid item xs={6} sm={6} md={6} lg={openRicercaIter ? 2 : 1}> */}
						<Grid item xs={6} sm={6} md={6} lg={1}>
							<Typography variant="h5" gutterBottom>
								Pod
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Typography variant="h5" gutterBottom>
								Produttore
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={1}>
							<Typography variant="h5" gutterBottom>
								Pot. disponib.
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={1}>
							<Typography variant="h5" gutterBottom>
								Pot. nominale
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography variant="h5" gutterBottom>
								Tipo tensione
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography variant="h5" gutterBottom>
								Regime commerciale
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3}>
							<Typography variant="h5" gutterBottom>
								Indirizzo
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						pods.errorsStack.status == ErrorStatusTypes.PENDING
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{pods.results?.map((pod, index) => (
					<ListItem
						// key={"iter_" + iterConnessione.id}
						key={index}
						// component={Link}
						// onClick={() => handleClickRow(iterConnessione)}
						// onClick={() => {}}
						// to={
						// 	// openRicercaIter
						// 	// 	? ""
						// 	// 	: Routes_path.ITER_CONNESSIONE + iterConnessione.id
						// 	Routes_path.POD + pod.id
						// }
						sx={{
							py: 0,
							color: "black",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={12} sm={12} md={12} lg={1}>
								<IconButton
									key={pod.codice}
									aria-label="vai a commessa"
									size="small"
									component={Link}
									to={{
										pathname: Routes_path.POD + pod.id,
									}}
									// state={{
									// 	lavorazine_id: params.value,
									// }}
								>
									<Typography
										variant="body1"
										sx={{ fontSize: "0.8rem" }}

										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{pod.codice}
									</Typography>
								</IconButton>
							</Grid>
							<Grid item xs={6} sm={6} md={11} lg={2}>
								<Typography
									variant="body1"

									// color="text.secondary"
									// onClick={() => setEditing(true)}
								>
									{pod.produttore_descrizione}
								</Typography>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={1}>
								<Typography
									variant="body1"

									// color="text.secondary"
									// onClick={() => setEditing(true)}
								>
									{Intl.NumberFormat("it-IT", {
										maximumFractionDigits: 2,
									})
										.format(pod.potenza_disponibile || 0)
										.toString() + " kW"}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={1}>
								<Typography
									variant="body1"
									// color="text.secondary"
									// onClick={() => setEditing(true)}
								>
									{Intl.NumberFormat("it-IT", {
										maximumFractionDigits: 2,
									})
										.format(pod.potenza_nominale || 0)
										.toString() + " kW"}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={2}>
								<Typography
									variant="body1"
									// color="text.secondary"
									// onClick={() => setEditing(true)}
								>
									{tipiTensione.find(
										(tipoTensione) => tipoTensione.id == pod.tipo_tensione
									)?.descrizione || ""}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={2}>
								<Typography
									variant="body1"
									// color="text.secondary"
									// onClick={() => setEditing(true)}
								>
									{regimiCommerciali.find(
										(reg) => reg.id == pod.regime_commerciale
									)?.descrizione || "seleziona regime commerciale"}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3}>
								<Typography
									variant="body1"
									sx={{ fontSize: "0.8rem" }}
									// color="text.secondary"
									// onClick={() => setEditing(true)}
								>
									{`${pod.indirizzo} (${
										comuni.find((comune) => comune.id == pod.comune)?.nome
									})`}
								</Typography>
							</Grid>
						</Grid>
					</ListItem>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={pods.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						{/* {statoIterId || clienteIterId ? (
							<Typography noWrap>
								Totale iter filtrati:{"  "}
								{iterConnessioni.count}
							</Typography>
						) : (
							<Typography noWrap>
								Totale iter:{"  "}
								{iterConnessioni.count}
							</Typography>
						)} */}

						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default PodsPage;
