import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import { AutorizzazioneComunale } from "../../components/main/iterConnessioni/iterConnessione/autorizzazioneComunale/autorizzazioneComunaleSlice";

const baseUrl = (
	autorizzazioneComunale_id?: number | null,
	azione?: string
) => {
	if (autorizzazioneComunale_id) {
		let url = `${process.env.API_URL}/api/autorizzazione_comunale/${autorizzazioneComunale_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/autorizzazione_comunale/";
	}
};

export function fetchAutorizzazioneComunale(
	statoAutorizzazioneComunale?: string,
	clienteIter?: number,
	operatore?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	// moment(data_da).format("YYYY-MM-DD");
	if (
		statoAutorizzazioneComunale != undefined ||
		clienteIter != undefined ||
		operatore != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (statoAutorizzazioneComunale != undefined) {
		url += `stato_autorizzazione_comunale=${statoAutorizzazioneComunale}&`;
	}
	if (clienteIter != undefined) {
		url += `cliente=${clienteIter}&`;
	}
	if (operatore != undefined) {
		url += `operatore=${operatore}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getAutorizzazioneComunale(autorizzazioneComunaleId: number) {
	let url = baseUrl(autorizzazioneComunaleId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveAutorizzazioneComunale(
	autorizzazioneComunaleToSave: AutorizzazioneComunale,
	azione?: string
) {
	const data_avvio = autorizzazioneComunaleToSave.data_avvio
		? moment(autorizzazioneComunaleToSave.data_avvio).format("YYYY-MM-DD")
		: null;
	const data_avvio_costruzione =
		autorizzazioneComunaleToSave.data_avvio_costruzione
			? moment(autorizzazioneComunaleToSave.data_avvio_costruzione).format(
					"YYYY-MM-DD"
			  )
			: null;
	const data_completamento_impianto =
		autorizzazioneComunaleToSave.data_completamento_impianto
			? moment(autorizzazioneComunaleToSave.data_completamento_impianto).format(
					"YYYY-MM-DD"
			  )
			: null;
	const data_conclusione = autorizzazioneComunaleToSave.data_conclusione
		? moment(autorizzazioneComunaleToSave.data_conclusione).format("YYYY-MM-DD")
		: null;
	const autorizzazioneComunale = {
		...autorizzazioneComunaleToSave,
		data_avvio: data_avvio,
		data_avvio_costruzione: data_avvio_costruzione,
		data_completamento_impianto: data_completamento_impianto,
		data_conclusione: data_conclusione,
	};
	return fetch(baseUrl(autorizzazioneComunaleToSave.id, azione), {
		method: autorizzazioneComunaleToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...autorizzazioneComunale,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(allacci.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteAutorizzazioneComunale(
	autorizzazioneComunaleToDelete: AutorizzazioneComunale
) {
	return fetch(baseUrl(autorizzazioneComunaleToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////

export function getAutorizzazioneComunaleFromIter(iterId: number) {
	let url =
		process.env.API_URL +
		"/api/autorizzazione_comunale/autorizzazione_comunale_from_iter/" +
		iterId +
		"/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////
