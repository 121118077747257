import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
} from "../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import {
	AutorizzazioneComunale,
	saveAutorizzazioneComunale,
} from "./autorizzazioneComunaleSlice";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";

import { Button, Divider } from "@mui/material";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

interface AutorizzazioneComunaleFormProps {
	iterConnessione: IterConnessione;
	autorizzazioneComunale: AutorizzazioneComunale;
	consentiModifica: boolean;
}

const AutorizzazioneComunaleForm = ({
	iterConnessione,
	autorizzazioneComunale,
	consentiModifica = false,
}: AutorizzazioneComunaleFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<AutorizzazioneComunale>();

	const listaCampi = Object.keys(autorizzazioneComunale) as Array<
		keyof AutorizzazioneComunale
	>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, autorizzazioneComunale[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			autorizzazioneComunale?.errorsStack?.fieldsErrors &&
				autorizzazioneComunale.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message:
						autorizzazioneComunale.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		autorizzazioneComunale?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		autorizzazioneComunale?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [
		autorizzazioneComunale,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveAutorizzazioneComunale = (
		autorizzazioneComunale: AutorizzazioneComunale
	) => {
		consentiModifica &&
			dispatch(
				saveAutorizzazioneComunale({
					autorizzazioneComunaleToSave: autorizzazioneComunale,
				})
			);
	};

	const onSubmit: SubmitHandler<AutorizzazioneComunale> = (
		autorizzazioneComunale
	) => {
		handlerSaveAutorizzazioneComunale(autorizzazioneComunale);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{autorizzazioneComunale?.errorsStack?.fieldsErrors
					?.non_field_errors && (
					<Alert severity="error">
						{autorizzazioneComunale?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							data avvio:
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: autorizzazioneComunale.data_avvio
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{autorizzazioneComunale.data_avvio
									? moment(autorizzazioneComunale.data_avvio).format("dddd LL")
									: "inserisci data avvio"}
							</Typography>
						) : (
							<DesktopDatePicker
								// components={{
								// 	OpenPickerIcon: ClockIcon,
								// }}
								// inputFormat="DD/MM/YY HH:mm"
								// mask={"__/__/__ __:__"}
								// label="Data Progetto"
								// {...register("data_ora_carico_pianificata")}
								value={watch("data_avvio") || autorizzazioneComunale.data_avvio}
								renderInput={(params: any) => (
									<TextField
										{...params}
										error={!!errors?.data_avvio}
										helperText={errors?.data_avvio?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										// {...register("data_ora_fine_turno")}
									/>
								)}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_avvio",
										newValue ? new Date(newValue.toDate()) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_avvio");
								}}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							data avvio costruzione:
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: autorizzazioneComunale.data_avvio_costruzione
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{autorizzazioneComunale.data_avvio_costruzione
									? moment(
											autorizzazioneComunale.data_avvio_costruzione
									  ).format("dddd LL")
									: "inserisci data avvio costruzione"}
							</Typography>
						) : (
							<DesktopDatePicker
								// components={{
								// 	OpenPickerIcon: ClockIcon,
								// }}
								// inputFormat="DD/MM/YY HH:mm"
								// mask={"__/__/__ __:__"}
								// label="Data Progetto"
								// {...register("data_ora_carico_pianificata")}
								value={
									watch("data_avvio_costruzione") ||
									autorizzazioneComunale.data_avvio_costruzione
								}
								renderInput={(params: any) => (
									<TextField
										{...params}
										error={!!errors?.data_avvio_costruzione}
										helperText={errors?.data_avvio_costruzione?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										// {...register("data_ora_fine_turno")}
									/>
								)}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_avvio_costruzione",
										newValue ? new Date(newValue.toDate()) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_avvio_costruzione");
								}}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							data completamento impianto:
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: autorizzazioneComunale.data_completamento_impianto
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{autorizzazioneComunale.data_completamento_impianto
									? moment(
											autorizzazioneComunale.data_completamento_impianto
									  ).format("dddd LL")
									: "inserisci data completamento impianto"}
							</Typography>
						) : (
							<DesktopDatePicker
								// components={{
								// 	OpenPickerIcon: ClockIcon,
								// }}
								// inputFormat="DD/MM/YY HH:mm"
								// mask={"__/__/__ __:__"}
								// label="Data Progetto"
								// {...register("data_ora_carico_pianificata")}
								value={
									watch("data_completamento_impianto") ||
									autorizzazioneComunale.data_completamento_impianto
								}
								renderInput={(params: any) => (
									<TextField
										{...params}
										error={!!errors?.data_completamento_impianto}
										helperText={errors?.data_completamento_impianto?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										// {...register("data_ora_fine_turno")}
									/>
								)}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_completamento_impianto",
										newValue ? new Date(newValue.toDate()) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_completamento_impianto");
								}}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							data conclusione:
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: autorizzazioneComunale.data_conclusione
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{autorizzazioneComunale.data_conclusione
									? moment(autorizzazioneComunale.data_conclusione).format(
											"dddd LL"
									  )
									: "inserisci data conclusione"}
							</Typography>
						) : (
							<DesktopDatePicker
								// components={{
								// 	OpenPickerIcon: ClockIcon,
								// }}
								// inputFormat="DD/MM/YY HH:mm"
								// mask={"__/__/__ __:__"}
								// label="Data Progetto"
								// {...register("data_ora_carico_pianificata")}
								value={
									watch("data_conclusione") ||
									autorizzazioneComunale.data_conclusione
								}
								renderInput={(params: any) => (
									<TextField
										{...params}
										error={!!errors?.data_conclusione}
										helperText={errors?.data_conclusione?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										// {...register("data_ora_fine_turno")}
									/>
								)}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_conclusione",
										newValue ? new Date(newValue.toDate()) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_conclusione");
								}}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							codice Ricevuta:
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: autorizzazioneComunale.codice_ricevuta_protocollo
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{autorizzazioneComunale.codice_ricevuta_protocollo
									? autorizzazioneComunale.codice_ricevuta_protocollo?.toString()
									: "inserisci codice Ricevuta"}
							</Typography>
						) : (
							<TextField
								// label="pod"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("codice_ricevuta_protocollo")}
								error={!!errors?.codice_ricevuta_protocollo}
								helperText={errors?.codice_ricevuta_protocollo?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							note:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								{autorizzazioneComunale.note}
							</Typography>
						) : (
							<TextField
								// label="note"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								multiline={true}
								rows={3}
								{...register("note")}
								error={!!errors?.note}
								helperText={errors?.note?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								onClick={() => setEditing(true)}
							/>
						)}
					</Grid>
				</Grid>
				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						setEditing(false);
						// reset();
					}}
					sx={{ ...(!editing && { display: "none" }) }}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ...(!editing && { display: "none" }), ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default AutorizzazioneComunaleForm;
