import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { useLocation, Link, useNavigate } from "react-router-dom";

import moment from "moment";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { IterConnessione } from "../../iterConnessioneSlice";
import {
	fetchAllegati,
	Allegato,
	Allegati,
	TipoDocumento,
	uploadAllegato,
	AllegatiState,
	fetchTipiDocumento,
} from "../../../../allegati/allegatiSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import AllegatiPage from "../../../../allegati/AllegatiPage";
import ZipDowloadButton from "../../../../allegati/ZipDowloadButton";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";
import AllegatiUploadForm from "../../../../allegati/AllegatiUploadForm";

interface IterConnessioneDocumentiListProps {
	iterConnessione: IterConnessione;
	utente: UtenteLogged;

	consentiModifica: boolean;
}

const IterConnessioneDocumentiList = ({
	iterConnessione,
	utente,
	consentiModifica = false,
}: IterConnessioneDocumentiListProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const [tipoDocumentoId, setTipoDocumentoId] = useState<number | undefined>(
		undefined
	);
	const [page, setPage] = useState<number>(1);
	const [numeroRecord, setNumeroRecord] = useState<number>(10);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [anchorTipoDocumentoFilter, setAnchorTipoDocumentoFilter] =
		useState(null);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handleStatoFilterClose = () => {
		setAnchorTipoDocumentoFilter(null);
		// onClose(selectedValue);
	};

	const allegatoCorrente: AllegatiState | undefined = useSelector<
		RootState,
		AllegatiState | undefined
	>((state) => state.allegati.allegatoCorrente);

	useEffect(() => {
		iterConnessione.id && dispatch(fetchTipiDocumento());
	}, []);
	useEffect(() => {
		iterConnessione.id &&
			dispatch(
				fetchAllegati({
					iterConnessione_id: iterConnessione.id,
					tipo_documento_id: tipoDocumentoId,
					numeroRecord: numeroRecord,
					page: page,
					search: search,
				})
			);
	}, [
		tipoDocumentoId,
		numeroRecord,
		page,
		search,
		allegatoCorrente?.errorsStack.status,
	]);

	const allegati = useSelector<RootState, Allegati>(
		(state) => state.allegati.allegati
	);
	const tipiDocumento = useSelector<RootState, TipoDocumento[]>((state) =>
		state.allegati.tipiDocumento.results.slice().sort((a, b) => {
			return b.nome > a.nome ? -1 : 1;
		})
	);

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	return (
		<Box>
			<List>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
						<Grid item xs={12} sm={12} md={12} lg={4}>
							<Typography variant="h5" gutterBottom display="inline">
								File
							</Typography>
							<ZipDowloadButton allegati={allegati.results} />
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={7}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Tipo documento
								</Typography>
								<IconButton
									color={tipoDocumentoId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorTipoDocumentoFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorTipoDocumentoFilter}
									keepMounted
									open={Boolean(anchorTipoDocumentoFilter)}
									onClose={handleStatoFilterClose}
								>
									{tipiDocumento.map((tipoDocumento) => (
										<MenuItem
											key={tipoDocumento.id}
											onClick={() => {
												setTipoDocumentoId(tipoDocumento.id);
												setAnchorTipoDocumentoFilter(null);
											}}
											sx={{
												backgroundColor:
													tipoDocumento.id == tipoDocumentoId
														? "#aaa"
														: "inherit",
											}}
										>
											{tipoDocumento.nome}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setTipoDocumentoId(undefined);
											setAnchorTipoDocumentoFilter(null);
										}}
										sx={{
											backgroundColor: !tipoDocumentoId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						{/* <Grid item xs={12} sm={12} md={12} lg={3}>
							<Typography variant="h5" gutterBottom>
								Commento
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={10} lg={2}>
							<Typography variant="h5" gutterBottom>
								Autore
							</Typography>
						</Grid> */}
						<Grid item xs={12} sm={6} md={2} lg={1}>
							<Typography variant="h5" gutterBottom>
								Data
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				{allegati.results.map((allegato, index) => (
					<ListItemButton
						key={"allegato_" + index}
						component={Link}
						to={allegato.file}
						target="_blank"
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={12} sm={12} md={8} lg={4}>
								<Typography
									variant="body1"
									gutterBottom
									noWrap
									textOverflow={"ellipsis"}
								>
									{allegato.file_name}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={7}>
								<Typography variant="body1" gutterBottom>
									{allegato.tipo_documento_des?.nome}
								</Typography>
								<Typography variant="caption" gutterBottom>
									{allegato.tipo_documento_des?.descrizione}
								</Typography>
							</Grid>
							{/* <Grid item xs={12} sm={10} md={11} lg={3}>
								<Typography
									variant="body1"
									gutterBottom
									noWrap
									textOverflow={"ellipsis"}
								>
									{allegato.descrizione}
								</Typography>
							</Grid> */}

							{/* <Grid item xs={6} sm={6} md={10} lg={2}>
								{allegato.autore}
							</Grid> */}
							<Grid item xs={6} sm={6} md={2} lg={1}>
								{allegato.data_documento
									? moment(new Date(allegato.data_documento)).format(
											"DD/MM/YYYY"
									  )
									: ""}
							</Grid>
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={allegati.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						{/* {statoIterId || clienteIterId ? (
							<Typography noWrap>
								Totale documenti filtrati:{"  "}
								{allegati.count}
							</Typography>
						) : ( */}
						<Typography noWrap>
							Totale documenti:{"  "}
							{allegati.count}
						</Typography>
						{/* )} */}

						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
						/>
						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default IterConnessioneDocumentiList;
