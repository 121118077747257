import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";

export function fetchStatiIter() {
	const url =
		process.env.API_URL + "/api/iter_connessioni/iter/conteggio_stati/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchStatiConnessioni() {
	const url =
		process.env.API_URL +
		"/api/richieste_connessioni/conteggio_stati_connessioni/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchConteggioStatiSopralluoghi() {
	const url =
		process.env.API_URL + "/api/sopralluoghi/conteggio_stati_sopralluoghi/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchConteggioStatiProgetti() {
	const url =
		process.env.API_URL + "/api/iter_connessioni/conteggio_stati_progetti/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchConteggioStatiIter() {
	const url =
		process.env.API_URL + "/api/iter_connessioni/conteggio_stati_iter/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchConteggioDispacciamentoGse() {
	const url =
		process.env.API_URL + "/api/iter_connessioni/conteggio_dispacciamento_gse/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchConteggioPraticheEnea() {
	const url = process.env.API_URL + "/api/enea/conteggio_pratiche_enea/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchConteggioPraticheOfficinaElettrica() {
	const url =
		process.env.API_URL +
		"/api/officina_elettrica/conteggio_pratiche_officina_elettrica/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchConteggioPraticheAutorizzazioniComunali() {
	const url =
		process.env.API_URL +
		"/api/autorizzazione_comunale/conteggio_pratiche_autorizzazione_comunale/";

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function progettiEmessiKpiFetch(
	data_da?: Date,
	data_a?: Date,
	periodo?: string
) {
	let url = process.env.API_URL + "/api/progetti/progetti_emessi_kpi/?";
	if (data_da != undefined) {
		url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&`;
	}
	if (periodo != undefined) {
		url += `periodo=${periodo}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function progettiRilavoratiKpiFetch(
	data_da?: Date,
	data_a?: Date,
	periodo?: string
) {
	let url = process.env.API_URL + "/api/progetti/progetti_rilavorati_kpi/?";
	if (data_da != undefined) {
		url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&`;
	}
	if (periodo != undefined) {
		url += `periodo=${periodo}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function richiestePreventiviKpiFetch(
	data_da?: Date,
	data_a?: Date,
	periodo?: string
) {
	let url =
		process.env.API_URL +
		"/api/richieste_connessioni/richieste_preventivi_kpi/?";
	if (data_da != undefined) {
		url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&`;
	}
	if (periodo != undefined) {
		url += `periodo=${periodo}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function richiesteRilavorateKpiFetch(
	data_da?: Date,
	data_a?: Date,
	periodo?: string
) {
	let url =
		process.env.API_URL +
		"/api/richieste_connessioni/richieste_rilavorate_kpi/?";
	if (data_da != undefined) {
		url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&`;
	}
	if (periodo != undefined) {
		url += `periodo=${periodo}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function regolamentoEsercizioKpiFetch(
	data_da?: Date,
	data_a?: Date,
	periodo?: string
) {
	let url =
		process.env.API_URL + "/api/fine_lavori/regolamento_esercizio_kpi/?";
	if (data_da != undefined) {
		url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&`;
	}
	if (periodo != undefined) {
		url += `periodo=${periodo}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
export function regolamentoRilavorateKpiFetch(
	data_da?: Date,
	data_a?: Date,
	periodo?: string
) {
	let url =
		process.env.API_URL + "/api/fine_lavori/regolamento_rilavorate_kpi/?";
	if (data_da != undefined) {
		url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&`;
	}
	if (periodo != undefined) {
		url += `periodo=${periodo}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
