import React from "react";
import { useLocation } from "react-router-dom";

import RouterApp, { Routes_path } from "./routerApp";
import SideBar from "./common/sideBar/SideBar";
import TopBar from "./common/topBar/TopBar";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { UtenteLogged, saveUtente } from "./authentication/authenticationSlice";
import {
	PreferenzeUtente,
	PreferenzeUtenteState,
	setDrawerAperto,
} from "./userPreference/userPreferenceSlice";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Copyright from "./common/Copyright";
import { Stack } from "@mui/material";

import logoPiccolo from "../../static/images/logo/logo-I43_biglia.png";
import logoGrande from "../../static/images/logo/logo-I43_lungo.png";

const sideMenuDrawerWidth = 240;
const miniSideMenuDrawerWidth = 70;
const appBarAltezza = 70;

const HeaderSpacer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: 0,
	marginTop: miniSideMenuDrawerWidth,
	// // necessary for content to be below app bar
	// ...theme.mixins.toolbar,
}));

const AppComposer = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const preferenzeUtente = useSelector<RootState, PreferenzeUtente | undefined>(
		(state) => state.userPreferenceState.preferenzeUtente
	);

	const [drawerPreview, setDrawerPreview] = React.useState(false);

	const [drawerIsOpen, setDrawerIsOpen] = React.useState(
		preferenzeUtente ? preferenzeUtente.drawer_aperto : true
		// true
	);

	const tooggleDrawer = () => {
		setDrawerIsOpen(!drawerIsOpen);
		dispatch(setDrawerAperto(!drawerIsOpen));
	};

	const drawerPreviewOn = () => {
		setDrawerPreview(true);
	};

	const drawerPreviewOff = () => {
		setDrawerPreview(false);
	};

	return (
		<Box
			sx={{
				display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
			}}
		>
			<CssBaseline />

			<TopBar
				open={drawerIsOpen || drawerPreview}
				// sideMenuDrawerWidth={sideMenuDrawerWidth}
				sideMenuDrawerWidth={0}
				miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
				appBarAltezza={appBarAltezza}
				logoPiccolo={logoPiccolo}
				tooggleDrawer={tooggleDrawer}
			/>
			{location.pathname != Routes_path.HOME && (
				<SideBar
					drawerPreviewOn={drawerPreviewOn}
					drawerPreviewOff={drawerPreviewOff}
					open={drawerIsOpen || drawerPreview}
					sideMenuDrawerWidth={sideMenuDrawerWidth}
					miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
					tooggleDrawer={tooggleDrawer}
					logoPiccolo={logoPiccolo}
					logoGrande={logoGrande}
					appBarAltezza={appBarAltezza}
				/>
			)}
			<Box
				component="main"
				sx={{ flexGrow: 1, p: 0 }}

				// sx={{
				// 	flexGrow: { lg: 1 },
				// 	p: { xs: 2, lg: 3 },
				// 	width: { sm: "92%", md: "92%", lg: "100%" },
				// }}
			>
				{/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
				<HeaderSpacer />
				{/* <Box sx={{ minHeight: "90vh",p: 0, m: 0 }}> */}
				{/* <Box component="div" sx={{ p: 0, m: 0 }}> */}
				<Box sx={{ minHeight: "50vh", p: 0, m: 0 }}>
					<RouterApp />
					<Copyright />
				</Box>
			</Box>
		</Box>
	);
};

export default AppComposer;
