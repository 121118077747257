import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	TipoTensioneTypes,
	TipologiaEdiliziaTypes,
	TitoloPossessoTypes,
	saveIterConnessione,
} from "../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Link } from "react-router-dom";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";
import IconButton from "@mui/material/IconButton";

import { Button, Divider } from "@mui/material";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface IterConnessioneDatiGeneraliFormProps {
	iterConnessione: IterConnessione;
	produttore: Produttore;
	consentiModifica: boolean;
}

const IterConnessioneDatiGeneraliForm = ({
	iterConnessione,
	produttore,
	consentiModifica = false,
}: IterConnessioneDatiGeneraliFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<IterConnessione>();

	const listaCampi = Object.keys(iterConnessione) as Array<
		keyof IterConnessione
	>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);
	const [forzaCambioGestorePod, setForzaCambioGestorePod] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const installatori = useSelector<RootState, Installatore[]>(
		(state) => state.installatoriState.installatori.results
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);
	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);
	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const gestori = useSelector<RootState, Gestore[]>(
		(state) => state.gestoriState.gestori.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune"), iterConnessione]);

	const [
		comuneUsufruttoreNascitaCorrente,
		setComuneUsufruttoreNascitaCorrente,
	] = useState<Comune | undefined>(undefined);

	useEffect(() => {
		setComuneUsufruttoreNascitaCorrente(
			comuni.find(
				(comuneScelto) =>
					comuneScelto.id == getValues("usufruttore_comune_nascita")
			)
		);
	}, [comuni, getValues("usufruttore_comune_nascita"), iterConnessione]);

	const [comuneUsufruttoreCorrente, setComuneUsufruttoreCorrente] = useState<
		Comune | undefined
	>(undefined);

	useEffect(() => {
		setComuneUsufruttoreCorrente(
			comuni.find(
				(comuneScelto) => comuneScelto.id == getValues("usufruttore_comune")
			)
		);
	}, [comuni, getValues("usufruttore_comune"), iterConnessione]);

	const [keycodice_pod, setKeycodice_pod] = useState<string>(makeid(5));

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, iterConnessione[field]);
		});
		setKeycodice_pod(makeid(5));
	}, [editing, iterConnessione, iterConnessione.id]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			iterConnessione?.errorsStack?.fieldsErrors &&
				iterConnessione.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: iterConnessione.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		iterConnessione?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		iterConnessione?.errorsStack?.status != ErrorStatusTypes.PENDING &&
			setEditing(!iterConnessione.codice_pod);
	}, [
		iterConnessione,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const [editSedecodice_pod, setEditSedecodice_pod] = useState(
		produttore.indirizzo != iterConnessione.indirizzo ||
			produttore.numero_civico != iterConnessione.numero_civico ||
			produttore.cap != iterConnessione.cap ||
			produttore.comune != iterConnessione.comune
	);

	useEffect(() => {
		setEditSedecodice_pod(
			produttore.indirizzo != iterConnessione.indirizzo ||
				produttore.numero_civico != iterConnessione.numero_civico ||
				produttore.cap != iterConnessione.cap ||
				produttore.comune != iterConnessione.comune
		);
	}, [iterConnessione]);

	const [editUsufruttore, setEditUsufruttore] = useState(
		produttore.nome != iterConnessione.usufruttore_nome ||
			produttore.cognome != iterConnessione.usufruttore_cognome ||
			produttore.codice_fiscale != iterConnessione.usufruttore_codice_fiscale ||
			produttore.data_nascita != iterConnessione.usufruttore_data_nascita ||
			produttore.comune_nascita != iterConnessione.usufruttore_comune_nascita ||
			produttore.indirizzo != iterConnessione.usufruttore_indirizzo ||
			produttore.numero_civico != iterConnessione.usufruttore_numero_civico ||
			produttore.cap != iterConnessione.usufruttore_cap ||
			produttore.comune != iterConnessione.usufruttore_comune
	);

	useEffect(() => {
		setEditUsufruttore(
			produttore.nome != iterConnessione.usufruttore_nome ||
				produttore.cognome != iterConnessione.usufruttore_cognome ||
				produttore.codice_fiscale !=
					iterConnessione.usufruttore_codice_fiscale ||
				produttore.data_nascita != iterConnessione.usufruttore_data_nascita ||
				produttore.comune_nascita !=
					iterConnessione.usufruttore_comune_nascita ||
				produttore.indirizzo != iterConnessione.usufruttore_indirizzo ||
				produttore.numero_civico != iterConnessione.usufruttore_numero_civico ||
				produttore.cap != iterConnessione.usufruttore_cap ||
				produttore.comune != iterConnessione.usufruttore_comune
		);
	}, [iterConnessione]);

	useEffect(() => {
		if (!editSedecodice_pod) {
			setValue("indirizzo", produttore.indirizzo, { shouldDirty: true });
			setValue("numero_civico", produttore.numero_civico, {
				shouldDirty: true,
			});
			setValue("cap", produttore.cap, { shouldDirty: true });
			setValue("comune", produttore.comune ? produttore.comune : undefined, {
				shouldDirty: true,
			});
		} else {
			setValue("indirizzo", iterConnessione["indirizzo"], {
				shouldDirty: true,
			});
			setValue("numero_civico", iterConnessione["numero_civico"], {
				shouldDirty: true,
			});
			setValue("cap", iterConnessione["cap"], { shouldDirty: true });
			setValue(
				"comune",
				iterConnessione["comune"] ? iterConnessione["comune"] : undefined,
				{ shouldDirty: true }
			);
		}
	}, [editSedecodice_pod]);

	useEffect(() => {
		if (!editUsufruttore) {
			setValue("usufruttore_nome", produttore.nome, { shouldDirty: true });
			setValue("usufruttore_cognome", produttore.cognome, {
				shouldDirty: true,
			});
			setValue("usufruttore_codice_fiscale", produttore.codice_fiscale, {
				shouldDirty: true,
			});
			setValue(
				"usufruttore_comune_nascita",
				produttore.comune_nascita ? produttore.comune_nascita : undefined,
				{
					shouldDirty: true,
				}
			);
			setValue("usufruttore_data_nascita", produttore.data_nascita, {
				shouldDirty: true,
			});
			setValue("usufruttore_indirizzo", produttore.indirizzo, {
				shouldDirty: true,
			});
			setValue("usufruttore_numero_civico", produttore.numero_civico, {
				shouldDirty: true,
			});
			setValue("usufruttore_cap", produttore.cap, { shouldDirty: true });
			setValue(
				"usufruttore_comune",
				produttore.comune ? produttore.comune : undefined,
				{ shouldDirty: true }
			);
		} else {
			setValue("usufruttore_nome", iterConnessione["usufruttore_nome"], {
				shouldDirty: true,
			});
			setValue("usufruttore_cognome", iterConnessione["usufruttore_cognome"], {
				shouldDirty: true,
			});
			setValue(
				"usufruttore_codice_fiscale",
				iterConnessione["usufruttore_codice_fiscale"],
				{
					shouldDirty: true,
				}
			);
			setValue(
				"usufruttore_comune_nascita",
				iterConnessione["usufruttore_comune_nascita"]
					? iterConnessione["usufruttore_comune_nascita"]
					: undefined,
				{
					shouldDirty: true,
				}
			);
			setValue(
				"usufruttore_data_nascita",
				iterConnessione["usufruttore_data_nascita"],
				{
					shouldDirty: true,
				}
			);
			setValue(
				"usufruttore_indirizzo",
				iterConnessione["usufruttore_indirizzo"],
				{
					shouldDirty: true,
				}
			);
			setValue(
				"usufruttore_numero_civico",
				iterConnessione["usufruttore_numero_civico"],
				{
					shouldDirty: true,
				}
			);
			setValue("usufruttore_cap", iterConnessione["usufruttore_cap"], {
				shouldDirty: true,
			});
			setValue(
				"usufruttore_comune",
				iterConnessione["usufruttore_comune"]
					? iterConnessione["usufruttore_comune"]
					: undefined,
				{ shouldDirty: true }
			);
		}
	}, [editUsufruttore]);

	const handlerSaveIterConnessione = (iterConnessione: IterConnessione) => {
		consentiModifica;
		dispatch(
			saveIterConnessione({
				iterConnessioneToSave: iterConnessione,
				azione: forzaCambioGestorePod ? "forzaCambioGestorePod" : "",
			})
		);
	};

	const onSubmit: SubmitHandler<IterConnessione> = (iterConnessione) => {
		handlerSaveIterConnessione(iterConnessione);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							codice POD:
						</Typography>
						{!editing ? (
							<IconButton
								key={iterConnessione.codice_pod}
								aria-label="vai a commessa"
								size="small"
								component={Link}
								to={{
									pathname: Routes_path.POD + iterConnessione.pod_id,
								}}
								// state={{
								// 	lavorazine_id: params.value,
								// }}
							>
								<Typography
									variant="h5"
									component="div"
									color="text.secondary"
									gutterBottom
								>
									{iterConnessione.codice_pod || "inserisci codice_pod"}
								</Typography>
							</IconButton>
						) : (
							<TextField
								// label="codice_pod"
								disabled={!!iterConnessione.codice_pod}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								required
								{...register("codice_pod")}
								error={!!errors?.codice_pod}
								helperText={errors?.codice_pod?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
							/>
						)}
						{!!iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors && (
							<FormControlLabel
								label={
									<Typography fontSize="0.8rem" marginLeft="0.1rem">
										{editSedecodice_pod
											? "Forza il cambio gestore sul POD"
											: "attiva per forzare il cambio gestore sul POD"}
									</Typography>
								}
								control={
									<Switch
										id="forza_codice_pod"
										checked={forzaCambioGestorePod || false}
										onChange={() =>
											setForzaCambioGestorePod(!forzaCambioGestorePod)
										}
										color="warning"
										size="small"
									/>
								}
							/>
						)}
					</Grid>
					{!!iterConnessione.codice_pod && iterConnessione.iter_semplificato ? (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								iban addebito oneri di connessione*:
							</Typography>
							{!editing ? (
								<Typography
									variant="h6"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: iterConnessione.iban_iter_connessione
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{iterConnessione.iban_iter_connessione ||
										"inserisci iban iter connessione"}
								</Typography>
							) : (
								<TextField
									// label="iban_iter_connessione"
									disabled={!iterConnessione.codice_pod}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("iban_iter_connessione")}
									error={!!errors?.iban_iter_connessione}
									helperText={errors?.iban_iter_connessione?.message}
									autoComplete={"off"}
									sx={{ width: "100%" }}
									fullWidth
								/>
							)}
						</Grid>
					) : (
						<Grid item xs={12} sm={6} md={6} lg={4}></Grid>
					)}
					{!!iterConnessione.codice_pod && (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								iban accrediti provenienti da GSE
								{iterConnessione.iter_semplificato ? "*" : ""}:
							</Typography>
							{!editing ? (
								<Typography
									variant="h6"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: iterConnessione.iban_accredito_gse
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{iterConnessione.iban_accredito_gse ||
										"inserisci iban accredito GSE"}
								</Typography>
							) : (
								<TextField
									// label="iban_accredito_gse"
									disabled={!iterConnessione.codice_pod}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("iban_accredito_gse")}
									error={!!errors?.iban_accredito_gse}
									helperText={errors?.iban_accredito_gse?.message}
									autoComplete={"off"}
									sx={{ width: "100%" }}
									fullWidth
								/>
							)}
						</Grid>
					)}
				</Grid>
				{!!iterConnessione.codice_pod && (
					<Divider
						textAlign="left"
						sx={{ mb: 2, color: theme.palette.primary.main }}
					>
						Indirizzo del codice_pod
					</Divider>
				)}
				{!!iterConnessione.codice_pod && (
					<Grid container spacing={2} sx={{ mb: 2 }} key={keycodice_pod}>
						<Grid item xs={9} sm={9} md={9} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								indirizzo*:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{iterConnessione.indirizzo}
								</Typography>
							) : (
								<TextField
									// label="indirizzo"
									disabled={!iterConnessione.codice_pod || !editSedecodice_pod}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("indirizzo")}
									error={!!errors?.indirizzo}
									helperText={errors?.indirizzo?.message}
									autoComplete={"off"}
									sx={{ width: "100%" }}
									fullWidth
									onClick={() => setEditing(true)}
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						</Grid>
						<Grid item xs={3} sm={3} md={3} lg={1}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								n. civico*:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{iterConnessione.numero_civico}
								</Typography>
							) : (
								<TextField
									// label="numero civico"
									disabled={!iterConnessione.codice_pod || !editSedecodice_pod}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("numero_civico")}
									error={!!errors?.numero_civico}
									helperText={errors?.numero_civico?.message}
									autoComplete={"off"}
									sx={{ width: "100%" }}
									fullWidth
									onClick={() => setEditing(true)}
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						</Grid>
						<Grid item xs={4} sm={4} md={4} lg={1}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								cap*:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									color="text.secondary"
									gutterBottom
									// onClick={() => setEditing(true)}
								>
									{iterConnessione.cap}
								</Typography>
							) : (
								<TextField
									// label="cap"
									disabled={!iterConnessione.codice_pod || !editSedecodice_pod}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("cap")}
									error={!!errors?.cap}
									helperText={errors?.cap?.message}
									autoComplete={"off"}
									sx={{ width: "100%" }}
									fullWidth
									onClick={() => setEditing(true)}
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						</Grid>
						<Grid item xs={7} sm={7} md={7} lg={3}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								comune*:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{comuneCorrente &&
										`${comuneCorrente?.nome} (${comuneCorrente?.provincia})`}
								</Typography>
							) : (
								<Autocomplete
									key={comuneCorrente?.id}
									disabled={!iterConnessione.codice_pod || !editSedecodice_pod}
									value={comuneCorrente}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									onClick={() => setEditing(true)}
									onChange={(event: any, newValue: any) => {
										setValue("comune", newValue ? newValue.id : null, {
											shouldDirty: true,
										});
									}}
									id="comune"
									options={comuni}
									getOptionLabel={(option) => option.nome}
									renderOption={(props, option) => (
										<Box component="li" {...props}>
											{option.nome}
										</Box>
									)}
									renderInput={(params: any) => (
										<TextField
											variant="outlined"
											{...params}
											// label="Comune"
											error={!!errors?.comune}
											helperText={errors?.comune?.message}
											onClick={() => setEditing(true)}
										/>
									)}
								/>
							)}
						</Grid>
						<Grid item xs={1} sm={1} md={1} lg={1}>
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
							>
								{/* {comuneCorrente?.provincia} */}
								{getValues("provincia")}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={2}>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{editSedecodice_pod
										? "Diverso da residenza"
										: "Uguale a residenza"}
								</Typography>
							) : (
								<>
									<Typography
										variant="caption"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										Diverso da residenza:
									</Typography>
									<FormControlLabel
										label={
											<Typography fontSize="0.8rem" marginLeft="0.1rem">
												{editSedecodice_pod ? "SI" : "NO"}
											</Typography>
										}
										control={
											<Switch
												id="edit_sede_codice_pod"
												disabled={!iterConnessione.codice_pod}
												checked={editSedecodice_pod || false}
												onChange={() =>
													setEditSedecodice_pod(!editSedecodice_pod)
												}
												color="info"
												size="small"
											/>
										}
									/>
								</>
							)}
						</Grid>

						<Grid item xs={6} sm={4} md={3} lg={3}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								superficie calpestabile:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: iterConnessione.superficie_calpestabile
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{iterConnessione.superficie_calpestabile
										? Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 2,
										  })
												.format(iterConnessione.superficie_calpestabile || 0)
												.toString() + " mq"
										: // iterConnessione.superficie_calpestabile.toString() + " W"
										  "inserisci superficie"}
								</Typography>
							) : (
								<Controller
									name="superficie_calpestabile"
									control={control}
									render={({ field }) => (
										<NumericFormat
											id="superficie_calpestabile"
											customInput={TextField}
											variant="outlined"
											disabled={!iterConnessione.codice_pod}
											// label="potenza unitaria"
											InputLabelProps={{
												shrink: true,
											}}
											error={!!errors?.superficie_calpestabile}
											helperText={errors?.superficie_calpestabile?.message}
											// {...register("potenza_unitaria")}
											value={field.value}
											autoComplete="off"
											prefix="mq "
											thousandSeparator="."
											decimalSeparator=","
											valueIsNumericString={true}
											onValueChange={(v) => {
												setValue("superficie_calpestabile", Number(v.value), {
													shouldDirty: true,
												});
											}}
											sx={{ width: "100%" }}
										/>
									)}
								/>
							)}
						</Grid>

						<Grid item xs={6} sm={4} md={3} lg={3}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								numero unità immobiliari:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: iterConnessione.numero_unita_immobiliari
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{iterConnessione.numero_unita_immobiliari
										? Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 2,
										  })
												.format(iterConnessione.numero_unita_immobiliari || 0)
												.toString()
										: // iterConnessione.numero_unita_immobiliari.toString() + " W"
										  "inserisci unità"}
								</Typography>
							) : (
								<Controller
									name="numero_unita_immobiliari"
									control={control}
									render={({ field }) => (
										<NumericFormat
											id="numero_unita_immobiliari"
											customInput={TextField}
											variant="outlined"
											// label="potenza unitaria"
											disabled={!iterConnessione.codice_pod}
											InputLabelProps={{
												shrink: true,
											}}
											error={!!errors?.numero_unita_immobiliari}
											helperText={errors?.numero_unita_immobiliari?.message}
											// {...register("potenza_unitaria")}
											value={field.value}
											autoComplete="off"
											thousandSeparator="."
											decimalSeparator=","
											valueIsNumericString={true}
											onValueChange={(v) => {
												setValue("numero_unita_immobiliari", Number(v.value), {
													shouldDirty: true,
												});
											}}
											sx={{ width: "100%" }}
										/>
									)}
								/>
							)}
						</Grid>

						<Grid item xs={6} sm={4} md={3} lg={3}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								anno costruz(approssimato):
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: iterConnessione.anno_costruzione
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{iterConnessione.anno_costruzione
										? iterConnessione.anno_costruzione
										: // iterConnessione.anno_costruzione.toString() + " W"
										  "inserisci anno"}
								</Typography>
							) : (
								<Controller
									name="anno_costruzione"
									control={control}
									render={({ field }) => (
										<NumericFormat
											id="anno_costruzione"
											customInput={TextField}
											variant="outlined"
											// label="potenza unitaria"
											disabled={!iterConnessione.codice_pod}
											InputLabelProps={{
												shrink: true,
											}}
											error={!!errors?.anno_costruzione}
											helperText={errors?.anno_costruzione?.message}
											// {...register("potenza_unitaria")}
											value={field.value}
											autoComplete="off"
											// thousandSeparator="."
											// decimalSeparator=","
											valueIsNumericString={true}
											onValueChange={(v) => {
												setValue("anno_costruzione", Number(v.value), {
													shouldDirty: true,
												});
											}}
											sx={{ width: "100%" }}
										/>
									)}
								/>
							)}
						</Grid>

						<Grid item xs={1} sm={1} md={1} lg={1}>
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
							>
								{/* {comuneCorrente?.provincia} */}
								{getValues("provincia")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{iterConnessione.destinazione_residenziale
										? "Residenziale"
										: "Non  residenziale"}
								</Typography>
							) : (
								<>
									<Typography
										variant="caption"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										Destinazione d'uso:
									</Typography>
									<FormControlLabel
										label={
											<Typography fontSize="0.8rem">
												{getValues("destinazione_residenziale")
													? "residenziale"
													: "non residenziale"}
											</Typography>
										}
										control={
											<Switch
												id="destinazione_residenziale"
												checked={watch("destinazione_residenziale") || false}
												disabled={!iterConnessione.codice_pod}
												onChange={(
													event: React.ChangeEvent<HTMLInputElement>,
													newValue: any
												) => {
													setValue("destinazione_residenziale", newValue, {
														shouldDirty: true,
													});
												}}
												color="info"
												size="small"
											/>
										}
									/>
								</>
							)}
						</Grid>

						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								titolo possesso:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: iterConnessione.titolo_possesso
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{
										Object.entries(TitoloPossessoTypes).find(
											([key, value]) => key == iterConnessione.titolo_possesso
										)?.[1]
									}
								</Typography>
							) : (
								<ReactHookFormSelect
									name="titolo_possesso"
									// label="tipo_pratica"
									disabled={!iterConnessione.codice_pod}
									register={register}
									control={control}
									watch={watch}
									variant="outlined"
									error={errors?.titolo_possesso?.message}
									sx={{ width: "100%" }}
								>
									<MenuItem value="">
										<em>Nessuno</em>
									</MenuItem>

									{Object.entries(TitoloPossessoTypes).map(([key, value]) => {
										return (
											<MenuItem key={key} value={key}>
												{value}
											</MenuItem>
										);
									})}
								</ReactHookFormSelect>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								tipologia edilizia:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: iterConnessione.tipologia_edilizia
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{
										Object.entries(TipologiaEdiliziaTypes).find(
											([key, value]) =>
												key == iterConnessione.tipologia_edilizia
										)?.[1]
									}
								</Typography>
							) : (
								<ReactHookFormSelect
									name="tipologia_edilizia"
									// label="tipo_pratica"
									disabled={!iterConnessione.codice_pod}
									register={register}
									control={control}
									watch={watch}
									variant="outlined"
									error={errors?.tipologia_edilizia?.message}
									sx={{ width: "100%" }}
								>
									<MenuItem value="">
										<em>Nessuno</em>
									</MenuItem>

									{Object.entries(TipologiaEdiliziaTypes).map(
										([key, value]) => {
											return (
												<MenuItem key={key} value={key}>
													{value}
												</MenuItem>
											);
										}
									)}
								</ReactHookFormSelect>
							)}
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								note:
							</Typography>
							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									{watch("tipologia_edilizia_note")}
								</Typography>
							) : (
								<TextField
									// label="tipologia_edilizia_note"
									disabled={!iterConnessione.codice_pod}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									multiline={true}
									rows={3}
									{...register("tipologia_edilizia_note")}
									error={!!errors?.tipologia_edilizia_note}
									helperText={errors?.tipologia_edilizia_note?.message}
									autoComplete={"off"}
									sx={{ width: "100%" }}
									fullWidth
									onClick={() => setEditing(true)}
								/>
							)}
						</Grid>
					</Grid>
				)}
				{!!iterConnessione.codice_pod && produttore.tipo_persona == "PF" && (
					<>
						<Divider
							textAlign="left"
							sx={{ mb: 2, color: theme.palette.primary.main }}
						>
							Soggetto che usufruisce della detrazione
						</Divider>
						<Grid container spacing={2} sx={{ mb: 2 }} key="usufruttore">
							<Grid item xs={9} sm={9} md={9} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									nome:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{/* {iterConnessione.indirizzo} */}
										{getValues("usufruttore_nome")}
									</Typography>
								) : (
									<TextField
										// label="indirizzo"
										disabled={!iterConnessione.codice_pod}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("usufruttore_nome")}
										error={!!errors?.usufruttore_nome}
										helperText={errors?.usufruttore_nome?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onClick={() => setEditing(true)}
										// onBlur={handlerDetailChaingSubmit}
									/>
								)}
							</Grid>
							<Grid item xs={9} sm={9} md={9} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									cognome:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{/* {iterConnessione.indirizzo} */}
										{getValues("usufruttore_cognome")}
									</Typography>
								) : (
									<TextField
										// label="indirizzo"
										disabled={!iterConnessione.codice_pod}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("usufruttore_cognome")}
										error={!!errors?.usufruttore_cognome}
										helperText={errors?.usufruttore_cognome?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onClick={() => setEditing(true)}
										// onBlur={handlerDetailChaingSubmit}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={4}></Grid>
							<Grid item xs={12} sm={12} md={12} lg={2}>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{editUsufruttore
											? "Diverso da produttore"
											: "Uguale a produttore"}
									</Typography>
								) : (
									<>
										<Typography
											variant="caption"
											component="div"
											color="text.secondary"
											gutterBottom
											onClick={() => setEditing(true)}
										>
											Diverso da produttore:
										</Typography>
										<FormControlLabel
											label={
												<Typography fontSize="0.8rem" marginLeft="0.1rem">
													{editUsufruttore ? "SI" : "NO"}
												</Typography>
											}
											control={
												<Switch
													disabled={!iterConnessione.codice_pod}
													id="edit_usufrottore"
													checked={editUsufruttore || false}
													onChange={() => setEditUsufruttore(!editUsufruttore)}
													color="info"
													size="small"
												/>
											}
										/>
									</>
								)}
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									codice fiscale:
								</Typography>

								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{/* {iterConnessione.indirizzo} */}
										{getValues("usufruttore_codice_fiscale")}
									</Typography>
								) : (
									<TextField
										// label="indirizzo"
										disabled={!iterConnessione.codice_pod}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("usufruttore_codice_fiscale")}
										error={!!errors?.usufruttore_codice_fiscale}
										helperText={errors?.usufruttore_codice_fiscale?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onClick={() => setEditing(true)}
										// onBlur={handlerDetailChaingSubmit}
									/>
								)}
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									comune nascita:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{comuneUsufruttoreNascitaCorrente &&
											`${comuneUsufruttoreNascitaCorrente?.nome} (${comuneUsufruttoreNascitaCorrente?.provincia})`}
									</Typography>
								) : (
									<Autocomplete
										key={comuneUsufruttoreNascitaCorrente?.id}
										value={comuneUsufruttoreNascitaCorrente}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										disabled={!iterConnessione.codice_pod}
										onClick={() => setEditing(true)}
										onChange={(event: any, newValue: any) => {
											setValue(
												"usufruttore_comune_nascita",
												newValue ? newValue.id : null,
												{
													shouldDirty: true,
												}
											);
										}}
										id="usufruttore_comune_nascita"
										options={comuni}
										getOptionLabel={(option) => option.nome}
										renderOption={(props, option) => (
											<Box component="li" {...props}>
												{option.nome}
											</Box>
										)}
										renderInput={(params: any) => (
											<TextField
												variant="outlined"
												{...params}
												error={!!errors?.usufruttore_comune_nascita}
												helperText={errors?.usufruttore_comune_nascita?.message}
												onClick={() => setEditing(true)}
											/>
										)}
									/>
								)}
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									data di nascita:
								</Typography>
								{!editing ? (
									<Typography
										variant="h6"
										component="div"
										// color="text.secondary"

										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: iterConnessione.usufruttore_data_nascita
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{iterConnessione.usufruttore_data_nascita
											? moment(iterConnessione.usufruttore_data_nascita).format(
													"LL"
											  )
											: "inserisci data nascita"}
									</Typography>
								) : (
									<DesktopDatePicker
										// components={{
										// 	OpenPickerIcon: ClockIcon,
										// }}
										// inputFormat="DD/MM/YY HH:mm"
										// mask={"__/__/__ __:__"}
										// label="Data Progetto"
										// {...register("data_ora_carico_pianificata")}
										disabled={!iterConnessione.codice_pod}
										value={
											watch("usufruttore_data_nascita") ||
											iterConnessione.usufruttore_data_nascita
										}
										renderInput={(params: any) => (
											<TextField
												{...params}
												error={!!errors?.usufruttore_data_nascita}
												helperText={errors?.usufruttore_data_nascita?.message}
												sx={{ width: "100%" }}
												autoComplete={"off"}
												// {...register("data_ora_fine_turno")}
											/>
										)}
										onChange={(newValue: any) => {
											setEditing(true);
											setValue(
												"usufruttore_data_nascita",
												newValue ? new Date(newValue.toDate()) : "",
												{
													shouldDirty: true,
												}
											);
											clearErrors("usufruttore_data_nascita");
										}}
									/>
								)}
							</Grid>

							<Grid item xs={9} sm={9} md={9} lg={4}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									indirizzo residenza:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{watch("usufruttore_indirizzo")}
									</Typography>
								) : (
									<TextField
										// label="indirizzo"
										disabled={!iterConnessione.codice_pod}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("usufruttore_indirizzo")}
										error={!!errors?.usufruttore_indirizzo}
										helperText={errors?.usufruttore_indirizzo?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onClick={() => setEditing(true)}
										// onBlur={handlerDetailChaingSubmit}
									/>
								)}
							</Grid>

							<Grid item xs={3} sm={3} md={3} lg={1}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									n. civico*:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{watch("usufruttore_numero_civico")}
									</Typography>
								) : (
									<TextField
										// label="numero civico"
										disabled={!iterConnessione.codice_pod}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("usufruttore_numero_civico")}
										error={!!errors?.usufruttore_numero_civico}
										helperText={errors?.usufruttore_numero_civico?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onClick={() => setEditing(true)}
										// onBlur={handlerDetailChaingSubmit}
									/>
								)}
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={1}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									cap*:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										// onClick={() => setEditing(true)}
									>
										{watch("usufruttore_cap")}
									</Typography>
								) : (
									<TextField
										disabled={!iterConnessione.codice_pod}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("usufruttore_cap")}
										error={!!errors?.usufruttore_cap}
										helperText={errors?.usufruttore_cap?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onClick={() => setEditing(true)}
										// onBlur={handlerDetailChaingSubmit}
									/>
								)}
							</Grid>
							<Grid item xs={7} sm={7} md={7} lg={3}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									comune*:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
									>
										{comuneUsufruttoreCorrente &&
											`${comuneUsufruttoreCorrente?.nome} (${comuneUsufruttoreCorrente?.provincia})`}
									</Typography>
								) : (
									<Autocomplete
										disabled={!iterConnessione.codice_pod}
										key={comuneUsufruttoreCorrente?.id}
										value={comuneUsufruttoreCorrente}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										onClick={() => setEditing(true)}
										onChange={(event: any, newValue: any) => {
											setValue(
												"usufruttore_comune",
												newValue ? newValue.id : null,
												{
													shouldDirty: true,
												}
											);
										}}
										id="usufruttore_comune"
										options={comuni}
										getOptionLabel={(option) => option.nome}
										renderOption={(props, option) => (
											<Box component="li" {...props}>
												{option.nome}
											</Box>
										)}
										renderInput={(params: any) => (
											<TextField
												variant="outlined"
												{...params}
												error={!!errors?.usufruttore_comune}
												helperText={errors?.usufruttore_comune?.message}
												onClick={() => setEditing(true)}
											/>
										)}
									/>
								)}
							</Grid>
							<Grid item xs={1} sm={1} md={1} lg={1}>
								<Typography
									variant="body1"
									component="div"
									color="text.secondary"
									gutterBottom
								>
									{/* {comuneCorrente?.provincia} */}
									{getValues("provincia")}
								</Typography>
							</Grid>
						</Grid>{" "}
					</>
				)}

				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						setEditing(false);
						// reset();
					}}
					sx={{ ...(!editing && { display: "none" }) }}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ...(!editing && { display: "none" }), ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default IterConnessioneDatiGeneraliForm;
