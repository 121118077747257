import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import {
	IterConnessione,
	TipoTensioneTypes,
	iterConnessioniSlice,
	saveIterConnessione,
} from "../../iterConnessioneSlice";

import {
	RichiestaConnessione,
	saveRichiestaConnessione,
} from "./richiesteConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import moment from "moment";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

import { Button, Divider } from "@mui/material";

interface IterConnessioneRichiestaConnessioneFormProps {
	richiestaConnessione: RichiestaConnessione;
	consentiModifica: boolean;
	iterSemplificato: boolean;
	// saveRichiestaConnessione: (
	// 	richiestaConnessione: RichiestaConnessione
	// ) => void;
}

const IterConnessioneRichiestaConnessioneForm = ({
	richiestaConnessione,
	consentiModifica = false,
	iterSemplificato,
}: // saveRichiestaConnessione,
IterConnessioneRichiestaConnessioneFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<RichiestaConnessione>();

	const listaCampi = Object.keys(richiestaConnessione) as Array<
		keyof RichiestaConnessione
	>;

	const dispatch = useAppDispatch();

	// const [editing, setEditing] = useState(!iterConnessione.cliente);
	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, richiestaConnessione[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			richiestaConnessione?.errorsStack?.fieldsErrors &&
				richiestaConnessione.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message:
						richiestaConnessione.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		richiestaConnessione?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		richiestaConnessione?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [
		richiestaConnessione,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const onSubmit: SubmitHandler<RichiestaConnessione> = (
		richiestaConnessione
	) => {
		dispatch(
			saveRichiestaConnessione({
				richiestaConnessioneToSave: richiestaConnessione,
			})
		);
	};

	return (
		<Box sx={{ my: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{richiestaConnessione?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{richiestaConnessione?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							Data Invio*:
						</Typography>

						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: richiestaConnessione.data_invio
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{richiestaConnessione.data_invio
									? moment(richiestaConnessione.data_invio).format("DD/MM/YYYY")
									: "inserisci data invio"}
							</Typography>
						) : (
							<DesktopDatePicker
								value={watch("data_invio") || richiestaConnessione.data_invio}
								renderInput={(params: any) => (
									<TextField
										{...params}
										required
										error={!!errors?.data_invio}
										helperText={errors?.data_invio?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										disabled={!!richiestaConnessione.stato}
										// {...register("data_ora_fine_turno")}
									/>
								)}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_invio",
										newValue ? new Date(newValue.toDate()) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_invio");
								}}
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							Codice Richiesta*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: richiestaConnessione.codice
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{richiestaConnessione.codice
									? richiestaConnessione.codice.toString()
									: "inserisci codice richiesta"}
							</Typography>
						) : (
							<TextField
								// label="indirizzo"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								// type="number"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("codice")}
								required
								// disabled={!!richiestaConnessione.stato}
								error={!!errors?.codice}
								helperText={errors?.codice?.message?.toString()}
								autoComplete={"off"}
								// InputProps={{
								// 	inputMode: "numeric",
								// 	// pattern: "[0-9]*",
								// 	endAdornment: (
								// 		<InputAdornment position="end">kW</InputAdornment>
								// 	),
								// }}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					{iterSemplificato && (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								Data Conferma:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: richiestaConnessione.data_conferma
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{richiestaConnessione.data_conferma
										? moment(richiestaConnessione.data_conferma).format(
												"DD/MM/YYYY"
										  )
										: "inserisci data di conferma"}
								</Typography>
							) : (
								<DesktopDatePicker
									value={
										watch("data_conferma") || richiestaConnessione.data_conferma
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.data_conferma}
											helperText={errors?.data_conferma?.message}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										console.log("ddd== ", newValue);
										setValue(
											"data_conferma",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("data_conferma");
									}}
								/>
							)}
						</Grid>
					)}
				</Grid>
				{!iterSemplificato && richiestaConnessione.stato != "Z" && (
					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								Data Ricezione Preventivo:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: richiestaConnessione.data_ricezione_preventivo
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{richiestaConnessione.data_ricezione_preventivo
										? moment(
												richiestaConnessione.data_ricezione_preventivo
										  ).format("DD/MM/YYYY")
										: "inserisci data ricezione preventivo"}
								</Typography>
							) : (
								<DesktopDatePicker
									value={
										watch("data_ricezione_preventivo") ||
										richiestaConnessione.data_ricezione_preventivo
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.data_ricezione_preventivo}
											helperText={errors?.data_ricezione_preventivo?.message}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"data_ricezione_preventivo",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("data_ricezione_preventivo");
									}}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								Importo Preventivo da pagare:
							</Typography>

							{!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color: richiestaConnessione.importo_preventivo
											? theme.palette.text.secondary
											: theme.palette.divider,
									}}
								>
									{richiestaConnessione.importo_preventivo
										? // ? "€ " + richiestaConnessione.importo_preventivo.toString()
										  "€ " +
										  Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 2,
										  }).format(richiestaConnessione.importo_preventivo || 0)
										: "inserisci importo preventivo"}
								</Typography>
							) : (
								<Controller
									name="importo_preventivo"
									control={control}
									render={({ field }) => (
										<NumericFormat
											id="importo_preventivo"
											customInput={TextField}
											variant="outlined"
											// label="importo_preventivo"
											InputLabelProps={{
												shrink: true,
											}}
											error={!!errors?.importo_preventivo}
											helperText={errors?.importo_preventivo?.message}
											// {...register("potenza_unitaria")}
											value={field.value}
											autoComplete="off"
											prefix="€ "
											thousandSeparator="."
											decimalSeparator=","
											valueIsNumericString={true}
											onValueChange={(v) => {
												setValue("importo_preventivo", Number(v.value), {
													shouldDirty: true,
												});
											}}
											sx={{ width: "100%" }}
										/>
									)}
								/>
							)}
						</Grid>

						<Grid item xs={12} sm={6} md={6} lg={4}></Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								Data Inoltro a I43 pagamento Preventivo:
							</Typography>
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								{richiestaConnessione.data_invio_accettazione_preventivo
									? moment(
											richiestaConnessione.data_invio_accettazione_preventivo
									  ).format("DD/MM/YYYY")
									: "-"}
							</Typography>
							{/* {!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color:
											richiestaConnessione.data_invio_accettazione_preventivo
												? theme.palette.text.secondary
												: theme.palette.divider,
									}}
								>
									{richiestaConnessione.data_invio_accettazione_preventivo
										? moment(
												richiestaConnessione.data_invio_accettazione_preventivo
										  ).format("DD/MM/YYYY")
										: "inserisci data accettazione"}
								</Typography>
							) : (
								<DesktopDatePicker
									value={
										watch("data_invio_accettazione_preventivo") ||
										richiestaConnessione.data_invio_accettazione_preventivo
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.data_invio_accettazione_preventivo}
											helperText={
												errors?.data_invio_accettazione_preventivo?.message
											}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"data_invio_accettazione_preventivo",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("data_invio_accettazione_preventivo");
									}}
								/>
							)} */}
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								Data Inoltro Accettazione Preventivo:
							</Typography>
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								{richiestaConnessione.data_inoltro_accettazione_preventivo
									? moment(
											richiestaConnessione.data_inoltro_accettazione_preventivo
									  ).format("DD/MM/YYYY")
									: "-"}
							</Typography>
							{/* {!editing ? (
								<Typography
									variant="body1"
									component="div"
									// color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
									sx={{
										color:
											richiestaConnessione.data_invio_accettazione_preventivo
												? theme.palette.text.secondary
												: theme.palette.divider,
									}}
								>
									{richiestaConnessione.data_invio_accettazione_preventivo
										? moment(
												richiestaConnessione.data_invio_accettazione_preventivo
										  ).format("DD/MM/YYYY")
										: "inserisci data accettazione"}
								</Typography>
							) : (
								<DesktopDatePicker
									value={
										watch("data_invio_accettazione_preventivo") ||
										richiestaConnessione.data_invio_accettazione_preventivo
									}
									renderInput={(params: any) => (
										<TextField
											{...params}
											error={!!errors?.data_invio_accettazione_preventivo}
											helperText={
												errors?.data_invio_accettazione_preventivo?.message
											}
											sx={{ width: "100%" }}
											autoComplete={"off"}
											// {...register("data_ora_fine_turno")}
										/>
									)}
									onChange={(newValue: any) => {
										setEditing(true);
										setValue(
											"data_invio_accettazione_preventivo",
											newValue ? new Date(newValue.toDate()) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("data_invio_accettazione_preventivo");
									}}
								/>
							)} */}
						</Grid>
					</Grid>
				)}
				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						setEditing(false);
						// reset();
					}}
					sx={{ ...(!editing && { display: "none" }) }}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ...(!editing && { display: "none" }), ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default IterConnessioneRichiestaConnessioneForm;
