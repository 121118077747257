import { Routes_path } from "../../../routerApp";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { useAppDispatch, RootState } from "../../../../redux/store";

import {
	Prodotto,
	TipoProdottoTypes,
	IterConnessione,
} from "../iterConnessioneSlice";
import { StatoIterTypes, StatiIter } from "../../../anagrafiche/kpi/kpiSlice";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
interface IterConnessioneTimeLineProps {
	iterConnessione: IterConnessione;
}

const IterConnessioneTimeLine = ({
	iterConnessione,
}: IterConnessioneTimeLineProps) => {
	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);
	return (
		<>
			<Timeline position="left">
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_richiesta_presa_carico &&
							moment(iterConnessione.data_richiesta_presa_carico).format(
								"dddd LL"
							)}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={iterConnessione.stato > "B" ? "success" : "secondary"}
						>
							{iterConnessione.stato == "B" && <PlayCircleOutlineIcon />}
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{
							Object.entries(StatoIterTypes).find(
								([key, value]) => key == "C"
							)?.[1]
						}
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_presa_carico &&
							moment(iterConnessione.data_presa_carico).format("dddd LL")}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={
								iterConnessione.stato > "C"
									? "success"
									: iterConnessione.stato == "C"
									? "secondary"
									: "grey"
							}
						>
							{iterConnessione.stato == "C" && <PlayCircleOutlineIcon />}
						</TimelineDot>

						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{
							Object.entries(StatoIterTypes).find(
								([key, value]) => key == "D"
							)?.[1]
						}
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_assegnazione &&
							moment(iterConnessione.data_assegnazione).format("dddd LL")}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={
								iterConnessione.stato > "D"
									? "success"
									: iterConnessione.stato == "D"
									? "secondary"
									: "grey"
							}
						>
							{iterConnessione.stato == "D" && <PlayCircleOutlineIcon />}
						</TimelineDot>

						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{
							Object.entries(StatoIterTypes).find(
								([key, value]) => key == "E"
							)?.[1]
						}
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_avvio_pratica &&
							moment(iterConnessione.data_avvio_pratica).format("dddd LL")}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={
								iterConnessione.stato > "E"
									? "success"
									: iterConnessione.stato == "E"
									? "secondary"
									: "grey"
							}
						>
							{iterConnessione.stato == "E" && <PlayCircleOutlineIcon />}
						</TimelineDot>

						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{
							Object.entries(StatoIterTypes).find(
								([key, value]) => key == "F"
							)?.[1]
						}
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_progetto &&
							moment(iterConnessione.data_progetto).format("dddd LL")}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={
								iterConnessione.stato > "F"
									? "success"
									: iterConnessione.stato == "F"
									? "secondary"
									: "grey"
							}
						>
							{iterConnessione.stato == "F" && <PlayCircleOutlineIcon />}
						</TimelineDot>

						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{
							Object.entries(StatoIterTypes).find(
								([key, value]) => key == "G"
							)?.[1]
						}
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_conferma_richiesta_connessione &&
							moment(
								iterConnessione.data_conferma_richiesta_connessione
							).format("dddd LL")}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={
								iterConnessione.stato > "H"
									? "success"
									: iterConnessione.stato == "H"
									? "secondary"
									: "grey"
							}
						>
							{iterConnessione.stato == "H" && <PlayCircleOutlineIcon />}
						</TimelineDot>

						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{
							Object.entries(StatoIterTypes).find(
								([key, value]) => key == "H"
							)?.[1]
						}
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_conclusione_fine_lavori &&
							moment(iterConnessione.data_conclusione_fine_lavori).format(
								"dddd LL"
							)}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={
								iterConnessione.stato > "I"
									? "success"
									: iterConnessione.stato == "I"
									? "secondary"
									: "grey"
							}
						>
							{iterConnessione.stato == "I" && <PlayCircleOutlineIcon />}
						</TimelineDot>

						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{
							Object.entries(StatoIterTypes).find(
								([key, value]) => key == "I"
							)?.[1]
						}
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_conclusione_allaccio &&
							moment(iterConnessione.data_conclusione_allaccio).format(
								"dddd LL"
							)}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={
								iterConnessione.stato > "L"
									? "success"
									: iterConnessione.stato == "L"
									? "secondary"
									: "grey"
							}
						>
							{iterConnessione.stato == "L" && <PlayCircleOutlineIcon />}
						</TimelineDot>

						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{
							Object.entries(StatoIterTypes).find(
								([key, value]) => key == "L"
							)?.[1]
						}
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent color="text.secondary">
						{iterConnessione.data_consegna &&
							moment(iterConnessione.data_consegna).format("dddd LL")}
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							color={
								iterConnessione.stato == "N"
									? "success"
									: iterConnessione.stato == "M"
									? "secondary"
									: "grey"
							}
						>
							{iterConnessione.stato == "M" && <PlayCircleOutlineIcon />}
						</TimelineDot>

						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						{iterConnessione.stato == "M"
							? Object.entries(StatoIterTypes).find(
									([key, value]) => key == "M"
							  )?.[1]
							: Object.entries(StatoIterTypes).find(
									([key, value]) => key == "N"
							  )?.[1]}
					</TimelineContent>
				</TimelineItem>
			</Timeline>
		</>
	);
};

export default IterConnessioneTimeLine;
