import React from "react";
import { styled, useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import Avatar from "@mui/material/Avatar";
import AccountCircle from "@mui/icons-material/AccountCircle";

import { Link } from "react-router-dom";

interface ProfileMenuProps {
	// TODO gestire il tipo utente
	utente: any;
	resetUtente: () => void;
	activeStyle: React.CSSProperties;
}
const ProfileMenu = ({
	utente,
	resetUtente,
	activeStyle,
	...props
}: ProfileMenuProps) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const theme = useTheme();

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = () => {
		resetUtente();
		setAnchorEl(null);
		navigate("/");
	};

	return (
		<>
			<Button
				sx={{ color: theme.palette.secondary.main }}
				aria-controls="simple-menu"
				onClick={handleClick}
				size="small"
				color="inherit"
				// activeStyle={activeStyle}
			>
				{utente.username}
				<AccountCircle sx={{ ml: 1 }} />

				{/* <Avatar src={ElonAvatar} className={classes.small} sx={{ ml: 1 }} /> */}
			</Button>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleClose} component={Link} to="/me">
						Profile
					</MenuItem>
					<MenuItem
						onClick={handleClose}
						component={Link}
						to="/me/changepassword/"
					>
						Cambia Password
					</MenuItem>
					<MenuItem onClick={handleLogOut}>Logout</MenuItem>
				</Menu>
			</div>
		</>
	);
};

export default ProfileMenu;
