import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import { Clienti, Cliente, fetchClienti } from "../clienti/clientiSlice";

import moment from "moment";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import { TipoTensione } from "../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { RegimeCommerciale } from "../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { Pod } from "../../main/pods/podsSlice";
import IconButton from "@mui/material/IconButton";
import { theme } from "../../theme";

interface PodsProduttoreGridProps {
	pods: Pod[];
}

const PodsProduttoreGrid = ({ pods }: PodsProduttoreGridProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const location = useLocation();

	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	return (
		<Paper elevation={3} sx={{ p: 3 }}>
			<Typography gutterBottom variant="h5">
				POD intestate al produttore
			</Typography>
			<Grid container spacing={0.5}>
				<Grid item xs={12} sm={12} md={12} lg={2}>
					<Typography
						variant="subtitle1"
						// color="text.secondary"
						gutterBottom
						// onClick={() => setEditing(true)}
						sx={{
							color: theme.palette.text.secondary,
						}}
					>
						Cod. POD
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={1}>
					<Typography
						variant="subtitle1"
						// color="text.secondary"
						gutterBottom
						// onClick={() => setEditing(true)}
						sx={{
							color: theme.palette.text.secondary,
						}}
					>
						Pot. disponibile
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={1}>
					<Typography
						variant="subtitle1"
						// color="text.secondary"
						gutterBottom
						// onClick={() => setEditing(true)}
						sx={{
							color: theme.palette.text.secondary,
						}}
					>
						Pot. nominale
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={2}>
					<Typography
						variant="subtitle1"
						// color="text.secondary"
						gutterBottom
						// onClick={() => setEditing(true)}
						sx={{
							color: theme.palette.text.secondary,
						}}
					>
						Tipo tensione
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={3}>
					<Typography
						variant="subtitle1"
						// color="text.secondary"
						gutterBottom
						// onClick={() => setEditing(true)}
						sx={{
							color: theme.palette.text.secondary,
						}}
					>
						Regime commerciale
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={3}>
					<Typography
						variant="subtitle1"
						// color="text.secondary"
						gutterBottom
						// onClick={() => setEditing(true)}
						sx={{
							color: theme.palette.text.secondary,
						}}
					>
						Indirizzo
					</Typography>
				</Grid>
			</Grid>
			{pods.map((pod: Pod, index) => (
				<Grid
					container
					spacing={0.5}
					key={index}
					sx={{ backgroundColor: index % 2 === 0 ? "#eeeeee" : "inherit" }}
				>
					<Grid item xs={12} sm={12} md={12} lg={2}>
						<IconButton
							key={pod.codice}
							aria-label="vai a commessa"
							size="small"
							component={Link}
							to={{
								pathname: Routes_path.POD + pod.id,
							}}
							// state={{
							// 	lavorazine_id: params.value,
							// }}
						>
							<Typography
								variant="body1"

								// color="text.secondary"
								// onClick={() => setEditing(true)}
							>
								{pod.codice}
							</Typography>
						</IconButton>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={1}>
						<Typography
							variant="body1"

							// color="text.secondary"
							// onClick={() => setEditing(true)}
						>
							{Intl.NumberFormat("it-IT", {
								maximumFractionDigits: 2,
							})
								.format(pod.potenza_disponibile || 0)
								.toString() + " kW"}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={1}>
						<Typography
							variant="body1"
							// color="text.secondary"
							// onClick={() => setEditing(true)}
						>
							{Intl.NumberFormat("it-IT", {
								maximumFractionDigits: 2,
							})
								.format(pod.potenza_nominale || 0)
								.toString() + " kW"}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={2}>
						<Typography
							variant="body1"
							// color="text.secondary"
							// onClick={() => setEditing(true)}
						>
							{tipiTensione.find(
								(tipoTensione) => tipoTensione.id == pod.tipo_tensione
							)?.descrizione || ""}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={3}>
						<Typography
							variant="body1"
							// color="text.secondary"
							// onClick={() => setEditing(true)}
						>
							{regimiCommerciali.find((reg) => reg.id == pod.regime_commerciale)
								?.descrizione || "seleziona regime commerciale"}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={3}>
						<Typography
							variant="body1"
							sx={{ fontSize: "0.8rem" }}
							// color="text.secondary"
							// onClick={() => setEditing(true)}
						>
							{pod.indirizzo}
						</Typography>
					</Grid>
				</Grid>
			))}
		</Paper>
	);
};

export default PodsProduttoreGrid;
