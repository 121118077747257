import { ServizioTypes } from "../kpi/kpiSlice";

export const testoBottoneProseguimento = (tipoPraticaId?: ServizioTypes) => {
	switch (tipoPraticaId) {
		case ServizioTypes.SOP_FV:
			return "Richiesta Sopralluogo";
			break;
		case ServizioTypes.ITERS_FV:
			return "Iter di connessione per impianto nuovo";
			break;
		case ServizioTypes.ITERO_FV:
			return "Iter di connessione per ampliamento di impianto esistente";
			break;
		case ServizioTypes.ENEA_FV:
			return "Richiesta per portale Enea";
			break;
		case ServizioTypes.OFF_FV:
			return "Richiesta per Officina Elettrica (ADM)";
			break;
		case ServizioTypes.ADEM_FV:
			return "Richiesta Gestione Scadenze Mantenimento Esercizio";
			break;
		case ServizioTypes.FES_11:
			return "Full Engineering Service per impianto sino a 11,08 KWP";
			break;
		case ServizioTypes.FES_20:
			return "Full Engineering Service per impianto sino a 20 KWP";
			break;
		case ServizioTypes.FES_150:
			return "Full Engineering Service per impianto sino a 150 KWP";
			break;

		default:
			return "Caso non previsto";
	}
};

export const isAmpliamento = (tipoPraticaId?: ServizioTypes) => {
	switch (tipoPraticaId) {
		case ServizioTypes.ITERS_FV:
			return false;
			break;
		case ServizioTypes.ITERO_FV:
			return true;
			break;
		case ServizioTypes.ENEA_FV:
			return false;
			break;
		case ServizioTypes.OFF_FV:
			return false;
			break;
		case ServizioTypes.ADEM_FV:
			return false;
			break;
		case ServizioTypes.FES_11:
			return undefined;
			break;
		case ServizioTypes.FES_20:
			return undefined;
			break;

		case ServizioTypes.FES_150:
			return undefined;
			break;

		default:
			return false;
	}
};

export const passiInizioPratica: { indice: string; descrizione: string }[] = [
	{
		indice: "Identif. Produttore",
		descrizione: "",
		// descrizione: "Inserire estremi identificativi primari del produttore",
	},
	{
		indice: "Anagrafica Produttore",
		descrizione: "",
		// descrizione: "Completare i dati anagrafici del produtore",
	},
	{
		indice: "Anagrafica POD",
		descrizione: "",

		// descrizione: "Completare i dati anagrafici del POD",
	},
	{
		indice: "In Lavorazione",
		descrizione: "",
		// descrizione: "Pratica in corso di esecuzione",
	},
	{
		indice: "Completata",
		descrizione: "",
		// descrizione: "Pratica completata e consegnata",
	},
];
