import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./authentication/PrivateRoute";
import ScrollToTop from "./common/ScrollToTop";

import HomePage from "./main/home/HomePage";
import ProfilePage from "./authentication/profile/ProfilePage";
import UnderConstruction from "./common/UnderConstruction";
import NotFoundPage from "./common/NotFoundPage";
import NotPermittedPage from "./common/NotPermittedPage";

import { PermissionTypes } from "../components/authentication/permissionsGroups";

import SignIn from "./authentication/SignIn";
import ChangePassword from "./authentication/ChangePassword";
import ForgotPassword from "./authentication/ForgotPassword";
import ResetPassword from "./authentication/ResetPassword";
import WellComePage from "./authentication/WellComePage";

import DashboardPage from "./main/dashboard/DashboardPage";
import IterConnessioniPage from "./main/iterConnessioni/IterConnessioniPage";
import IterConnessionePage from "./main/iterConnessioni/iterConnessione/IterConnessionePage";
import OfficinaElettricaNuovaIstanzaPage from "./main/iterConnessioni/iterConnessione/officinaElettrica/OfficinaElettricaNuovaIstanzaPage";
import AnagrafichePage from "./anagrafiche/AnagrafichePage";
import ProduttorePage from "./anagrafiche/produttori/ProduttorePage";
import ProduttoriPage from "./anagrafiche/produttori/ProduttoriPage";
import PraticheFatturabiliPage from "./main/amministrazione/fatturabili/PraticheFatturabiliPage";
import ClientePage from "./anagrafiche/clienti/ClientePage";
import SopralluoghiPage from "./main/sopralluoghi/SopralluoghiPage";
import SopralluogoPage from "./main/sopralluoghi/sopralluogo/SopralluogoPage";
import ScadenzePage from "./main/scadenze/ScadenzePage";
import RichiesteConnessionePage from "./main/richiesteConnessione/RichiesteConnessionePage";
import PRATICHE_COMUNALI from "./main/autorizzazioniComunali/AutorizzazioniComunaliPage";
import PodsPage from "./main/pods/podsPage";
import PodPage from "./main/pods/podPage";
import AdempimentiPage from "./main/adempimenti/AdempimentiPage";
import AdempimentoPage from "./main/adempimenti/adempimento/AdempimentoPage";
import ServiziServiziAdempimentiPage from "./main/adempimenti/serviziAdempimenti/ServiziAdempimentiPage";
import ServizioAdempimentiPage from "./main/adempimenti/serviziAdempimenti/servizioAdempimento/ServizioAdempimentiPage";

export enum Routes_path {
	HOME = "/",
	SIGNIN = "/signin/",
	WELL_COME = "/well_come/:uidb64/:token/",
	FORGOT_PASSWORD = "/forgotPassword/",
	RESET_PASSWORD = "/resetPassword/:uidb64/:token/",
	UNDER_COSTRUCTION = "/UnderConstruction/",

	DASHBOARD = "/dashboard/",
	PODS = "/pods/",
	POD = "/pods/pod/",
	ITER_CONNESSIONI = "/iter_connessioni/",
	ITER_CONNESSIONE = "/iter_connessioni/iter/",
	SCADENZE = "/scadenze/",
	SOPRALLUOGHI = "/sopralluoghi/",
	SOPRALLUOGO = "/sopralluoghi/sopralluogo/",
	RICHIESTE_CONNESSIONE = "/richieste_connessione/",
	PRATICHE_COMUNALI = "/pratiche_comunali/",
	PRATICA_COMUNALE = "/pratica_comunale/",
	OFFICINA_ELETTRICA_NUOVA_ISTANZA = "/iter_connessioni/officinaElettrica/nuova_istanza/",
	ANAGRAFICHE = "/anagrafiche/",
	PRODUTTORI = "/produttori/",
	PRODUTTORE = "/anagrafiche/produttori/",
	CLIENTE = "/anagrafiche/clienti/",
	GESTORE = "/anagrafiche/gestori/",
	FATTURABILI = "/amministrazione/fatturabili/",
	ADEMPIMENTI = "/adempimenti/",
	ADEMPIMENTO = "/adempimenti/adempimento/",
	SERVIZI_ADEMPIMENTI = "/servizi_adempimenti/",
	SERVIZIO_ADEMPIMENTI = "/servizio_adempimenti/",
}

const RouterApp = () => {
	return (
		<ScrollToTop>
			<Routes>
				<Route path={Routes_path.HOME} element={<HomePage />} />
				<Route path={Routes_path.SIGNIN} element={<SignIn />} />
				{/* <Route  path={"/register/"} element={Register} /> */}
				<Route path={Routes_path.WELL_COME} element={<WellComePage />} />
				<Route
					path={Routes_path.FORGOT_PASSWORD}
					element={<ForgotPassword />}
				/>
				<Route path={Routes_path.RESET_PASSWORD} element={<ResetPassword />} />
				{/* <Route
					path={Routes_path.UNDER_COSTRUCTION}
					element={<UnderConstruction />}
				/> */}
				<Route
					path={Routes_path.UNDER_COSTRUCTION}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.ALL]}
							element={<UnderConstruction />}
						/>
					}
				/>
				<Route
					path={Routes_path.DASHBOARD}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.PROGETTISTI,
							]}
							element={<DashboardPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.ITER_CONNESSIONI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<IterConnessioniPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.ITER_CONNESSIONE + ":iter_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<IterConnessionePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PODS}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.PROGETTISTI,
							]}
							element={<PodsPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.POD + ":pod_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.PROGETTISTI,
							]}
							element={<PodPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.ITER_CONNESSIONE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<IterConnessioniPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SOPRALLUOGO + ":sopralluogo_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<SopralluogoPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SOPRALLUOGO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<SopralluogoPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SOPRALLUOGHI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<SopralluoghiPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SCADENZE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								// PermissionTypes.OSPITI,
							]}
							element={<ScadenzePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PRATICA_COMUNALE + ":praticaComunale_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<UnderConstruction />}
						/>
					}
				/>
				<Route
					path={Routes_path.PRATICA_COMUNALE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<UnderConstruction />}
						/>
					}
				/>
				<Route
					path={Routes_path.RICHIESTE_CONNESSIONE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
							]}
							element={<RichiesteConnessionePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PRATICHE_COMUNALI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.ALL,
							]}
							element={<PRATICHE_COMUNALI />}
						/>
					}
				/>
				<Route
					path={
						Routes_path.OFFICINA_ELETTRICA_NUOVA_ISTANZA + ":iterConnessione_id"
					}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<OfficinaElettricaNuovaIstanzaPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.ANAGRAFICHE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.GESTORI_ITER]}
							element={<AnagrafichePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PRODUTTORI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.PROGETTISTI,
							]}
							element={<ProduttoriPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PRODUTTORE + ":produttore_id/:tipoPratica_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<ProduttorePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PRODUTTORE + ":produttore_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<ProduttorePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PRODUTTORE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<ProduttorePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.FATTURABILI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<PraticheFatturabiliPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.ADEMPIMENTI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.CLIENTI,
								// PermissionTypes.OSPITI,
							]}
							element={<AdempimentiPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.ADEMPIMENTO + ":adempimento_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<AdempimentoPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SERVIZI_ADEMPIMENTI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.CLIENTI,
								// PermissionTypes.OSPITI,
							]}
							element={<ServiziServiziAdempimentiPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SERVIZIO_ADEMPIMENTI + ":servizioAdempimenti_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.GESTORI_ITER,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<ServizioAdempimentiPage />}
						/>
					}
				/>
				////////////////////////////////////////////////////
				<Route
					path={Routes_path.CLIENTE + ":cliente_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<ClientePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.CLIENTE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
								// PermissionTypes.OSPITI,
							]}
							element={<ClientePage />}
						/>
					}
				/>
				<Route
					path="/me"
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.ALL]}
							element={<ProfilePage />}
						/>
					}
				/>
				<Route
					path="/me/changepassword"
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.ALL]}
							element={<ChangePassword />}
						/>
					}
				/>
				<Route path="/notPermitted" element={<NotPermittedPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</ScrollToTop>
	);
};

export default RouterApp;
