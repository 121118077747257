import React, { useState, useEffect } from "react";
import { RootState, useAppDispatch } from "../../../../../redux/store";

// import { Parametri } from "../../anagrafiche/anagraficheSlice";
import { Progetto, saveProgetto } from "./progettiSlice";
import { IterConnessione } from "../../iterConnessioneSlice";

import { StatoConnessioneTypes } from "../../../../anagrafiche/kpi/kpiSlice";

import moment from "moment";
import { theme } from "../../../../theme";
// import AlleggatiPage from "../../allegati/AlleggatiPage";
import { styled } from "@mui/system";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleIcon from "@mui/icons-material/People";
import TimerIcon from "@mui/icons-material/Timer";
import Chip from "@mui/material/Chip";
// import Chip from "@material-ui/core/Chip"; ************************ da cancellare se non ci sono malfunzionamenti
import Grid from "@mui/material/Grid";
// import { Link } from "react-router-dom";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Typography } from "@mui/material";
import IterConnessioneProgettiForm from "./IterConnessioneProgettiForm";
import IterConnessioneDatiInterniForm from "../../iterConnessione/datiPratica/IterConnessioneDatiInterniForm";

import SezioniCard from "./sezioni/SezioniCard";
import AllegatiPage from "../../../../allegati/AllegatiPage";

const NumberChip = styled(Chip)({
	"&&": {
		height: "1.5rem",
		backgroundColor: "#1b75b1",
		color: "#e1e1e1",
		fontSize: "1.2rem",
		fontWeight: 500,
		paddingLeft: 0,
		paddingRight: 0,
		borderRadius: 5,
	},
	"& .MuiChip-label": {
		paddingLeft: "5px",
		paddingRight: "5px",
	},
});
interface IterConnessioneProgettoCardProps {
	progetto: Progetto;
	iterConnessione: IterConnessione;
	// parametri: Parametri;
	// expanded: string | false;
	consentiModifica: boolean;
	iterSemplificato: boolean;
	// handleChangeProgettoPanel: (
	// 	progetto: Progetto
	// ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}
const IterConnessioneProgettoCard = ({
	progetto,
	iterConnessione,
	// parametri,
	// expanded,
	consentiModifica,
	iterSemplificato,
}: // handleChangeProgettoPanel,
IterConnessioneProgettoCardProps) => {
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 400,
		width: "95%",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		pb: 1,
		textAlign: "center",
	};

	const dispatch = useAppDispatch();

	const handlerSaveProgetto = (progetto: Progetto) => {
		consentiModifica && dispatch(saveProgetto({ progettoToSave: progetto }));
	};

	const [expanded, setExpanded] = useState<boolean>(
		!progetto.progetto_rigettato
	);

	return (
		<Accordion
			key={"progetto_panel" + progetto.id}
			sx={{
				backgroundColor: progetto.progetto_rigettato ? "#19191920" : "inherit",
			}}
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="richiesta_connessione_accordion"
				id="richiesta_connessione_accordion_header"
			>
				<Grid container spacing={2}>
					<Grid item lg={4}>
						<Stack direction="row" spacing={2} justifyContent="flex-start">
							<Typography>Progetto n.</Typography>
							<NumberChip
								aria-label="richiesta_connessione"
								label={progetto.id}
							/>
							{progetto.data_progetto && (
								<Typography>
									del {moment(progetto.data_progetto).format("DD/MM/YYYY")}
								</Typography>
							)}
						</Stack>
					</Grid>
					<Grid item lg={8}>
						<Stack direction="row" spacing={2} justifyContent="flex-start">
							{progetto.progetto_rigettato && (
								<Typography>
									data rigetto:{" "}
									{moment(progetto.data_rigetto).format("DD/MM/YYYY")}
								</Typography>
							)}
							{progetto.progetto_rigettato && (
								<Typography>{progetto.note_rilavorazione}</Typography>
							)}
						</Stack>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					py: 0,
					"&:last-child": { pb: 1 },
				}}
			>
				<Stack spacing={2}>
					<SezioniCard
						progetto={progetto}
						consentiModifica={consentiModifica}
					/>
					<Divider
						textAlign="left"
						sx={{ mt: 4, mb: 1, color: theme.palette.primary.main }}
					>
						Valori Totali
					</Divider>
					{progetto && (
						<IterConnessioneProgettiForm
							progetto={progetto}
							consentiModifica={consentiModifica}
						/>
					)}
				</Stack>
			</AccordionDetails>
			<AccordionDetails
				sx={{
					mb: 2,
				}}
			>
				<Divider sx={{ mb: 2 }} />
				{iterConnessione && iterConnessione.data_assegnazione && (
					<IterConnessioneDatiInterniForm
						iterConnessione={iterConnessione}
						consentiModifica={consentiModifica}
					/>
				)}
				<Divider
					textAlign="left"
					sx={{ mt: 4, mb: 1, color: theme.palette.primary.main }}
				>
					Allegati
				</Divider>
				{progetto.listaDocumentiPerTipo && (
					<AllegatiPage
						listaDocumentiPerTipo={progetto.listaDocumentiPerTipo}
						iterConnessioneId={progetto.iter_connessione || 0}
						progettoId={progetto.id || 0}
						consentiModifica={consentiModifica}
					/>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default IterConnessioneProgettoCard;
