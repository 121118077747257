import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Utente } from "../../authentication/authenticationSlice";

import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../../components/authentication/permissionsGroups";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ElementoMenuControllato from "./ElementoMenuControllato";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";

import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
// import TableChartIcon from "@mui/icons-material/TableChart";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ConstructionIcon from "@mui/icons-material/Construction";
import EuroIcon from "@mui/icons-material/Euro";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import FlashAuto from "@mui/icons-material/FlashAuto";

const PREFIX = "SideMenu";

const classes = {
	iconSelected: `${PREFIX}-iconSelected`,
};

const styleListItem = { justifyContent: "center" };

interface SideMenuProps {
	open: boolean;
	tooggleDrawer: () => void;
}
const SideMenu = ({ open, tooggleDrawer }: SideMenuProps) => {
	const location = useLocation();

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	// const matches = useMediaQuery("min-width:900px)");
	const mobiletooggleDrawer = () => {
		matches && tooggleDrawer();
	};

	const StyledList = styled(List)(() => ({
		[`& .${classes.iconSelected}`]: {
			color: theme.palette.info.main,
		},
	}));
	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	return (
		<StyledList>
			<ListItem
				component={Link}
				to={Routes_path.HOME}
				key={"Home"}
				sx={styleListItem}
			>
				<ListItemIcon sx={{ minWidth: "auto" }}>
					<HomeIcon
						className={
							location.pathname == Routes_path.HOME ? classes.iconSelected : ""
						}
						onClick={mobiletooggleDrawer}
					/>
				</ListItemIcon>
				{open && (
					<ListItemText
						style={{ marginLeft: 12 }}
						primary={"Home"}
						className={location.pathname == "/" ? classes.iconSelected : ""}
						onClick={mobiletooggleDrawer}
					/>
				)}
			</ListItem>

			{/* <ElementoMenuControllato
				utente={utente}
				path={Routes_path.DASHBOARD}
				label="Dashboard"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.PROGETTISTI,
					PermissionTypes.OPERATORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.DASHBOARD}
				tooggleDrawer={tooggleDrawer}
				Icon={DashboardIcon}
			/> */}

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PRODUTTORI}
				label="Produttori"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.PROGETTISTI,
					PermissionTypes.OPERATORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.PRODUTTORI}
				tooggleDrawer={tooggleDrawer}
				Icon={ContactPageIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PODS}
				label="POD"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.PROGETTISTI,
					PermissionTypes.OPERATORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.PODS}
				tooggleDrawer={tooggleDrawer}
				Icon={ElectricMeterIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.ITER_CONNESSIONI}
				label="Iter Connessione"
				gruppiAutorizzati={[
					PermissionTypes.ALL,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.ITER_CONNESSIONI}
				tooggleDrawer={tooggleDrawer}
				Icon={ElectricalServicesIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.ANAGRAFICHE}
				label="Anagrafiche"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.ANAGRAFICHE}
				tooggleDrawer={tooggleDrawer}
				Icon={FolderCopyIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.SOPRALLUOGHI}
				label="Sopralluoghi"
				gruppiAutorizzati={[PermissionTypes.ALL]}
				open={open}
				selected={location.pathname == Routes_path.SOPRALLUOGHI}
				tooggleDrawer={tooggleDrawer}
				Icon={EditRoadIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.RICHIESTE_CONNESSIONE}
				label="Richieste Connessione"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.OPERATORI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.RICHIESTE_CONNESSIONE}
				tooggleDrawer={tooggleDrawer}
				Icon={AddShoppingCartIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PRATICHE_COMUNALI}
				label="Pratiche Comunali"
				gruppiAutorizzati={[
					PermissionTypes.ALL,
					PermissionTypes.OPERATORI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.PRATICHE_COMUNALI}
				tooggleDrawer={tooggleDrawer}
				Icon={AccountBalanceIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.SCADENZE}
				label="Scadenziario Tarature"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.SCADENZE}
				tooggleDrawer={tooggleDrawer}
				Icon={ManageHistoryIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.FATTURABILI}
				label="Pratiche Fatturabili"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.FATTURABILI}
				tooggleDrawer={tooggleDrawer}
				Icon={EuroIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.ADEMPIMENTI}
				label="Adempimenti"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.OPERATORI,
					PermissionTypes.CLIENTI,
				]}
				open={open}
				selected={location.pathname == Routes_path.ADEMPIMENTI}
				tooggleDrawer={tooggleDrawer}
				Icon={FlashAutoIcon}
			/>

			<Divider />

			{(utente.is_staff || utente.is_superuser) && (
				<ListItem
					component="a"
					target="_blank"
					rel="noopener"
					href={process.env.API_URL + "/admin"}
					button
					key="Admin_be"
					sx={styleListItem}
				>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<ManageAccountsIcon
							className={
								location.pathname == process.env.API_URL + "/admin"
									? classes.iconSelected
									: ""
							}
							onClick={mobiletooggleDrawer}
						/>
					</ListItemIcon>
					{open && (
						<ListItemText
							style={{ marginLeft: 12, fontStyle: "italic" }}
							primary={"Admin"}
							className={
								location.pathname == process.env.API_URL + "/admin"
									? classes.iconSelected
									: ""
							}
							onClick={mobiletooggleDrawer}
						/>
					)}
				</ListItem>
			)}
		</StyledList>
	);
};

export default SideMenu;
