import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import moment from "moment";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";
import { IterConnessione } from "../../iterConnessioneSlice";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";
import { Enea, getEneaFromIter } from "./eneaSlice";
import IterConnessioneSpalla from "../IterConnessioneSpalla";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import IterConnessioneEneaForm from "./IterConnessioneEneaForm";
import IterConnessioneEneaDatiCard from "./IterConnessioneEneaDatiCard";
import AzioniEneaCard from "./AzioniEneaCard";
import AzioniGestoreCard from "../datiGenerali/AzioniGestoreCard";

import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";
import IterConnessioniPage from "../../IterConnessioniPage";

interface IterConnessioneEneaTabProps {
	iterConnessione: IterConnessione;
	utente: UtenteLogged;
	consentiModifica: boolean;
}

const IterConnessioneEneaTab = ({
	iterConnessione,
	utente,
	consentiModifica = false,
}: IterConnessioneEneaTabProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	useEffect(() => {
		iterConnessione.id && dispatch(getEneaFromIter(iterConnessione.id));
	}, []);

	const enea: Enea | undefined = useSelector<RootState, Enea | undefined>(
		(state) =>
			state.eneaState.enea.results.find(
				(enea) => enea.iter_connessione == iterConnessione.id || 0
			)
		// state.eneaState.enea.results.slice().sort((a, b) => {
		// 	return b.id - a.id;
		// })
	);
	const enea_status: ErrorsStack | undefined = useSelector<
		RootState,
		ErrorsStack | undefined
	>(
		(state) => state.eneaState.enea.errorsStack
		// state.eneaState.enea.results.slice().sort((a, b) => {
		// 	return b.id - a.id;
		// })
	);

	const produttore: Produttore | undefined = useSelector<
		RootState,
		Produttore | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore?.id == iterConnessione?.produttore
		)
	);

	return (
		<Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={enea_status?.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9}>
					<Stack spacing={2}>
						{enea && (
							<>
								{produttore && (
									<IterConnessioneEneaDatiCard
										iterConnessione={iterConnessione}
										produttore={produttore}
									/>
								)}
								<IterConnessioneEneaForm
									iterConnessione={iterConnessione}
									enea={enea}
									consentiModifica={consentiModifica}
								/>

								<Divider
									textAlign="left"
									sx={{ mt: 4, mb: 1, color: theme.palette.primary.main }}
								>
									Allegati
								</Divider>
								{enea.listaDocumentiPerTipo && (
									<AllegatiPage
										listaDocumentiPerTipo={enea.listaDocumentiPerTipo}
										iterConnessioneId={enea.iter_connessione || 0}
										eneaId={enea.id || 0}
										consentiModifica={consentiModifica}
									/>
								)}
							</>
						)}
					</Stack>
				</Grid>
				<Grid item xs={6} sm={6} md={4} lg={3}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />

					{utenteAutorizzato(utente, [
						PermissionTypes.OPERATORI,
						PermissionTypes.GESTORI_ITER,
					]) && <AzioniEneaCard iterConnessione={iterConnessione} />}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneEneaTab;
