import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	Adempimento,
	StatoAdempimentoTypes,
	getAdempimento,
	saveAdempimento,
	TipoAdempimento,
	deleteAdempimento,
} from "../adempimentiSlice";
import PodsProduttoreGrid from "../../../anagrafiche/produttori/PodsProduttoreGrid";

import { useAppDispatch, RootState } from "../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";

import { theme } from "../../../theme";
import Checkbox from "@mui/material/Checkbox";

import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";

import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";

import ConfermaAnnulla from "../../../common/ConfermaAnnulla";

import {
	UtenteLogged,
	UtenteApplicativo,
} from "../../../authentication/authenticationSlice";
import { Cliente } from "../../../anagrafiche/clienti/clientiSlice";
import { Comune } from "../../../anagrafiche/comuni/comuniSlice";
import {
	Produttore,
	getProduttore,
} from "../../../anagrafiche/produttori/produttoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";

import { Button, ButtonGroup, Divider } from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface AdempimentoFormProps {
	adempimento: Adempimento;
	// produttore?: Produttore;
	consentiModifica: boolean;
}

const AdempimentoForm = ({
	adempimento,
	// produttore,
	consentiModifica = false,
}: AdempimentoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Adempimento>();

	const listaCampi = Object.keys(adempimento) as Array<keyof Adempimento>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(!adempimento.id);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const tipi_adempimenti = useSelector<RootState, TipoAdempimento[]>(
		(state) => state.adempimentiState.tipiAdempimento.results
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
		(state) => state.authentication.utentiApplicativo.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	const produttoreCorrente: Produttore | undefined = useSelector<
		RootState,
		Produttore | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore?.id == adempimento?.produttore
		)
	);

	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	const [keyPod, setKeyPod] = useState<string>(makeid(5));
	const [annulla, setAnnulla] = useState<boolean>(false);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, adempimento[field]);
		});
		// setKeyPod(makeid(5));

		setAnnulla(false);
	}, [
		adempimento.id,
		annulla,
		listaCampi.forEach((field) => {
			adempimento[field];
		}),
	]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			adempimento?.errorsStack?.fieldsErrors &&
				adempimento.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: adempimento.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		adempimento?.errorsStack?.fieldsErrors,
		// sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		adempimento?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(!adempimento.id);
	}, [
		adempimento,
		// sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		if (adempimento) {
			dispatch(getProduttore(adempimento.produttore || 0));
		}
	}, []);

	useEffect(() => {
		setValue("pods", adempimento["pods"]);
	}, [JSON.stringify(adempimento.pods)]);

	const handlerSaveAdempimento = (adempimento: Adempimento) => {
		consentiModifica &&
			dispatch(
				saveAdempimento({
					adempimentoToSave: getValues(),
				})
			);
		// adempimento.id && dispatch(getAdempimento(adempimento.id));
	};

	const handleClickAvanzaStato = () => {
		if (adempimento.stato === "P" && watch("data_pianificata")) {
			setValue("stato", "A");
			dispatch(saveAdempimento({ adempimentoToSave: getValues() }));
		} else if (adempimento.stato === "P" && !watch("data_pianificata")) {
			setError("data_pianificata", {
				type: "",
				message: "inserire una data pianificata per mettere in stato Assegnato",
			});
		}
		if (adempimento.stato === "A" && watch("data_completamento")) {
			setValue("stato", "C");
			dispatch(saveAdempimento({ adempimentoToSave: getValues() }));
		} else if (adempimento.stato === "A" && !watch("data_completamento")) {
			setError("data_completamento", {
				type: "",
				message:
					"inserire una data di completamento per mettere in stato Completato",
			});
		}
	};

	const onSubmit: SubmitHandler<Adempimento> = (adempimento) => {
		handlerSaveAdempimento(adempimento);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)} key={keyPod}>
				{adempimento?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{adempimento?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}

				<Grid container spacing={2} sx={{ my: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<ReactHookFormSelect
							name="cliente"
							label="Cliente"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.cliente?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="cliente_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{clienti.map((cliente) => {
								return (
									<MenuItem key={cliente.id} value={cliente.id}>
										{cliente.ragione_sociale}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={4}>
						<ReactHookFormSelect
							name="tipo_adempimento"
							label="tipo adempimento"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.tipo_adempimento?.message}
							sx={{ width: "100%" }}
						>
							{tipi_adempimenti.map((tipo) => {
								return (
									<MenuItem key={tipo.id} value={tipo.id}>
										{tipo.nome}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<ReactHookFormSelect
							name="operatore"
							label="operatore"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.operatore?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="operatore_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{utentiApplicativo
								.filter((utente) => utente.user.is_staff == true)
								.map((utente) => {
									return (
										<MenuItem key={utente.id} value={utente.user.id}>
											{utente.user.full_name}
										</MenuItem>
									);
								})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={9}>
						<ReactHookFormSelect
							name="pods"
							label="pods"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							multiple={true}
							error={errors?.pods?.message}
							sx={{ width: "100%" }}
						>
							{produttoreCorrente?.pods?.map((pod) => {
								return (
									<MenuItem key={pod.id} value={pod.id || 0}>
										{pod.codice}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}></Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<DesktopDatePicker
							label="data pianificata"
							// components={{
							// 	OpenPickerIcon: ClockIcon,
							// }}
							// inputFormat="DD/MM/YY HH:mm"
							// mask={"__/__/__ __:__"}
							// label="Data Progetto"
							// {...register("data_ora_carico_pianificata")}
							value={watch("data_pianificata") || adempimento.data_pianificata}
							disabled={!consentiModifica}
							renderInput={(params: any) => (
								<TextField
									{...params}
									error={!!errors?.data_pianificata}
									helperText={errors?.data_pianificata?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									// {...register("data_ora_fine_turno")}
								/>
							)}
							onChange={(newValue: any) => {
								setEditing(true);
								setValue(
									"data_pianificata",
									newValue ? new Date(newValue.toDate()) : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_pianificata");
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<DesktopDatePicker
							label="data scadenza"
							// components={{
							// 	OpenPickerIcon: ClockIcon,
							// }}
							// inputFormat="DD/MM/YY HH:mm"
							// mask={"__/__/__ __:__"}
							// label="Data Progetto"
							// {...register("data_ora_carico_pianificata")}
							value={watch("data_scadenza") || adempimento.data_scadenza}
							disabled={!consentiModifica}
							renderInput={(params: any) => (
								<TextField
									{...params}
									error={!!errors?.data_scadenza}
									helperText={errors?.data_scadenza?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									// {...register("data_ora_fine_turno")}
								/>
							)}
							onChange={(newValue: any) => {
								setEditing(true);
								setValue(
									"data_scadenza",
									newValue ? new Date(newValue.toDate()) : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_scadenza");
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<DesktopDatePicker
							label="data completamento"
							// components={{
							// 	OpenPickerIcon: ClockIcon,
							// }}
							// inputFormat="DD/MM/YY HH:mm"
							// mask={"__/__/__ __:__"}
							// label="Data Progetto"
							// {...register("data_ora_carico_pianificata")}
							value={
								watch("data_completamento") || adempimento.data_completamento
							}
							disabled={!consentiModifica}
							renderInput={(params: any) => (
								<TextField
									{...params}
									error={!!errors?.data_completamento}
									helperText={errors?.data_completamento?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									// {...register("data_ora_fine_turno")}
								/>
							)}
							onChange={(newValue: any) => {
								setEditing(true);
								setValue(
									"data_completamento",
									newValue ? new Date(newValue.toDate()) : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_completamento");
							}}
						/>
					</Grid>
				</Grid>
				{consentiModifica && (
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						sx={{ mb: 1 }}
					>
						<Button
							disabled={!consentiModifica}
							// variant="outlined"
							onClick={() => {
								setAnnulla(true);
							}}
						>
							Annulla
						</Button>
						<Button
							disabled={!consentiModifica}
							//  variant="outlined"
							type="submit"
						>
							Salva
						</Button>
						{adempimento.stato !== "C" && (
							<Button
								disabled={!consentiModifica}
								// variant="contained"
								type="submit"
								onClick={handleClickAvanzaStato}
							>
								{adempimento.stato == "P"
									? "Assegna Adempimento"
									: adempimento.stato == "A"
									? "Completa Adempimento"
									: ""}
							</Button>
						)}
						<Button
							disabled={!consentiModifica || !adempimento.id}
							color="warning"
							onClick={() => setOpenConferma(true)}
						>
							Elimina Adempimento
						</Button>
					</ButtonGroup>
				)}
			</form>

			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={() => {
					dispatch(deleteAdempimento(adempimento));
					handleConfermaClose();
				}}
				domanda={"Sicuro di voler eliminare  L'adempimento"}
				open={openConferma}
			/>
		</Box>
	);
};

export default AdempimentoForm;
