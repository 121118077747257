import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
import * as officinaElettricaApi from "../../../../../api/iterConnessioni/officinaElettricaApi";
import moment from "moment";
import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../../../allegati/allegatiSlice";
import { getIterConnessione } from "../../iterConnessioneSlice";
import { fetchRichiesteConnessioneFromIter } from "../richiesteConnessione/richiesteConnessioneSlice";

// import { useDispatch } from "react-redux";

moment.locale("it");

export enum StatoOfficinaElettricaTypes {
	A = "in corso",
	B = "conclusa",
}
export interface OfficinaElettrica {
	id: number;
	iter_connessione?: number;
	data_consegna?: Date | string;
	codice_ricevuta?: string;
	data_rilascio?: Date | string;
	rigettata?: boolean;
	completo?: boolean;
	stato: string;
	listaDocumentiPerTipo?: DocumentiPerTipo[];
	errorsStack?: ErrorsStack;
}

export interface OfficinaElettricaState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: OfficinaElettrica[];
	errorsStack: ErrorsStack;
}
export interface OfficinaElettricaStrutturaState {
	officinaElettrica: OfficinaElettricaState;
}

const initialState: OfficinaElettricaStrutturaState = {
	officinaElettrica: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchOfficinaElettrica = createAsyncThunk(
	"officinaElettrica/fetchOfficinaElettrica",
	async () => {
		return await officinaElettricaApi.fetchOfficinaElettrica();
	}
);

export const getOfficinaElettrica = createAsyncThunk(
	"officinaElettrica/getOfficinaElettrica",
	async (officinaElettricaId: number) => {
		return await officinaElettricaApi.getOfficinaElettrica(officinaElettricaId);
	}
);

export const getOfficinaElettricaFromIter = createAsyncThunk(
	"officinaElettrica/getOfficinaElettricaFromIter",
	async (iterId: number) => {
		return await officinaElettricaApi.getOfficinaElettricaFromIter(iterId);
	}
);

export const saveOfficinaElettrica = createAsyncThunk(
	"officinaElettrica/saveOfficinaElettrica",
	async (
		parametri: {
			officinaElettricaToSave: OfficinaElettrica;
			azione?: string;
		},
		thunkApi
	) => {
		return await officinaElettricaApi
			.saveOfficinaElettrica(
				parametri.officinaElettricaToSave,
				parametri.azione
			)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						getOfficinaElettricaFromIter(
							parametri.officinaElettricaToSave.iter_connessione || 0
						)
					);

				return response;
			});
	}
);

export const deleteOfficinaElettrica = createAsyncThunk(
	"officinaElettrica/deleteOfficinaElettrica",
	async (officinaElettricaToDelete: OfficinaElettrica, thunkApi) => {
		return await officinaElettricaApi.deleteOfficinaElettrica(
			officinaElettricaToDelete
		);
	}
);

export const officinaElettricaSlice = createSlice({
	name: "officinaElettricaState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch OfficinaElettrica
		builder.addCase(fetchOfficinaElettrica.pending, (state, action) => {
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchOfficinaElettrica.fulfilled, (state, action) => {
			state.officinaElettrica = action.payload;
			state.officinaElettrica.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchOfficinaElettrica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get OfficinaElettrica
		builder.addCase(getOfficinaElettrica.pending, (state, action) => {
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getOfficinaElettrica.fulfilled, (state, action) => {
			state.officinaElettrica.results = state.officinaElettrica.results.filter(
				(officinaElettrica) => officinaElettrica.id != action.payload.id
			);
			state.officinaElettrica.results.push(action.payload);

			state.officinaElettrica.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getOfficinaElettrica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get OfficinaElettrica from Iter
		builder.addCase(getOfficinaElettricaFromIter.pending, (state, action) => {
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getOfficinaElettricaFromIter.fulfilled, (state, action) => {
			state.officinaElettrica.results = state.officinaElettrica.results.filter(
				(officinaElettrica) => officinaElettrica.id != action.payload.id
			);
			state.officinaElettrica.results.push(action.payload);

			state.officinaElettrica.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getOfficinaElettricaFromIter.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save OfficinaElettrica
		builder.addCase(saveOfficinaElettrica.pending, (state, action) => {
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveOfficinaElettrica.fulfilled, (state, action) => {
			state.officinaElettrica.results = state.officinaElettrica.results.filter(
				(officinaElettrica) => officinaElettrica.id != action.payload.id
			);
			state.officinaElettrica.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Pratica OfficinaElettrica salvata.");
		});
		builder.addCase(saveOfficinaElettrica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.officinaElettrica.results = state.officinaElettrica.results.map(
				(officinaElettrica) => {
					if (
						officinaElettrica.id == action.meta.arg.officinaElettricaToSave.id
					) {
						return {
							...officinaElettrica,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return officinaElettrica;
					}
				}
			);
			state.officinaElettrica.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = officinaElettricaSlice.actions;

export const officinaElettricaSliceReducer = officinaElettricaSlice.reducer;
