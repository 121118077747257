import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import { Routes_path } from "../../routerApp";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { Utente } from "../../authentication/authenticationSlice";
import { StatiIter, ConteggioStati } from "../../anagrafiche/kpi/kpiSlice";
import { resetProduttoreCorrente } from "../../anagrafiche/produttori/produttoriSlice";
import ClienteServiziPieChart from "./ClienteServiziPieChart";
import ClienteProgressoPieChart from "./ClienteProgressoPieChart";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { CircularProgress, Typography } from "@mui/material";
import { theme } from "../../theme";

const HomeMenu = () => {
	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);

	const conteggioStatiSopralluoghi = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioStatiSopralluoghi
	);

	const conteggioStatiProgetti = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioStatiProgettii
	);

	const conteggioStatiIter = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioStatiIter
	);
	const conteggioDispacciamentoGse = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioDispacciamentoGse
	);
	const conteggioPraticheEnea = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioPraticheEnea
	);
	const conteggioPraticheOfficinaElettrica = useSelector<
		RootState,
		ConteggioStati
	>((state) => state.kpiState.conteggioPraticheOfficinaElettrica);

	const conteggioAutorizzazioniComunali = useSelector<
		RootState,
		ConteggioStati
	>((state) => state.kpiState.conteggioAutorizzazioniComunali);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handlerCreateIterConnessione = () => {
		dispatch(resetProduttoreCorrente());

		navigate(Routes_path.PRODUTTORE);
	};

	return (
		<Box>
			{/* <Grid container>
				<Grid item>
					<Typography variant="h3">Cliente:</Typography>
				</Grid>
			</Grid> */}
			<Grid
				container
				direction="row"
				justifyContent="left"
				alignItems="center"
				// sx={{ flexGrow: 1, mb: 1 }}
				// rowSpacing={0.1}
				rowSpacing={1}
				// columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				sx={{ my: 1 }}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={6}
					// key={`kpi_${statoIter.stato}`}
				>
					<ListItemText
						key={"titoli"}
						// sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
							<Grid item xs={8} sm={8} md={8} lg={10}>
								<Typography variant="h5" gutterBottom>
									Servizi attivi
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography
									variant="h5"
									sx={{
										textOrientation: "sideways",
										writingMode: "vertical-lr",
									}}
									gutterBottom
								>
									in lavorazione
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography
									variant="h5"
									sx={{
										textOrientation: "sideways",
										writingMode: "vertical-lr",
									}}
									gutterBottom
								>
									disponibile
								</Typography>
							</Grid>
						</Grid>
					</ListItemText>
					<ListItemButton
						key="Sopralluogo_link"
						component={Link}
						to={Routes_path.SOPRALLUOGHI}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={8} sm={8} md={8} lg={10}>
								<Typography variant="h4" fontWeight={600}>
									Sopralluogo
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioStatiSopralluoghi.in_lavorazione != undefined ? (
										conteggioStatiSopralluoghi.in_lavorazione
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioStatiSopralluoghi.disponibile != undefined ? (
										conteggioStatiSopralluoghi.disponibile
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>
					<ListItemButton component={Link} to={Routes_path.ITER_CONNESSIONI}>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={8} sm={8} md={8} lg={10}>
								<Typography variant="h4" fontWeight={600}>
									Progetto Esecutivo Impianto FV
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioStatiProgetti.in_lavorazione != undefined ? (
										conteggioStatiProgetti.in_lavorazione
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioStatiProgetti.disponibile != undefined ? (
										conteggioStatiProgetti.disponibile
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>
					<ListItemButton component={Link} to={Routes_path.PRATICHE_COMUNALI}>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={8} sm={8} md={8} lg={10}>
								<Typography variant="h4" fontWeight={600}>
									Autorizzazione Comunale
								</Typography>
							</Grid>

							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioAutorizzazioniComunali.in_lavorazione !=
									undefined ? (
										conteggioAutorizzazioniComunali.in_lavorazione
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioAutorizzazioniComunali.disponibile != undefined ? (
										conteggioAutorizzazioniComunali.disponibile
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>
					<ListItemButton component={Link} to={Routes_path.ITER_CONNESSIONI}>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={8} sm={8} md={8} lg={10}>
								<Typography variant="h4" fontWeight={600}>
									Iter Connessione (Gestore di Rete)
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioStatiIter.in_lavorazione != undefined ? (
										conteggioStatiIter.in_lavorazione
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioStatiIter.disponibile != undefined ? (
										conteggioStatiIter.disponibile
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>

					<ListItemButton component={Link} to={Routes_path.ITER_CONNESSIONI}>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={8} sm={8} md={8} lg={10}>
								<Typography variant="h4" fontWeight={600}>
									Officina Elettrica (ADM)
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioPraticheOfficinaElettrica.in_lavorazione !=
									undefined ? (
										conteggioPraticheOfficinaElettrica.in_lavorazione
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioPraticheOfficinaElettrica.disponibile !=
									undefined ? (
										conteggioPraticheOfficinaElettrica.disponibile
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>
					<ListItemButton component={Link} to={Routes_path.ITER_CONNESSIONI}>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={8} sm={8} md={8} lg={10}>
								<Typography variant="h4" fontWeight={600}>
									Convenzione Operatore Dispacciamento (GSE)
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioDispacciamentoGse.in_lavorazione != undefined ? (
										conteggioDispacciamentoGse.in_lavorazione
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioDispacciamentoGse.disponibile != undefined ? (
										conteggioDispacciamentoGse.disponibile
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>
					<ListItemButton component={Link} to={Routes_path.ITER_CONNESSIONI}>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={8} sm={8} md={8} lg={10}>
								<Typography variant="h4" fontWeight={600}>
									Comunicazione ENEA
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioPraticheEnea.in_lavorazione != undefined ? (
										conteggioPraticheEnea.in_lavorazione
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={1}>
								<Typography variant="h4" fontWeight={600}>
									{conteggioPraticheEnea.disponibile != undefined ? (
										conteggioPraticheEnea.disponibile
									) : (
										<CircularProgress size={20} />
									)}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>

					{/* <KpiList
							descrizione={statoIter.stato_desc}
							valore={statoIter.count}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							to={Routes_path.ITER_CONNESSIONI}
							state={{
								statoIterId: statoIter.stato,
							}}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							Icon={MoveToInboxIcon}
						/> */}
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={6}
					sx={{
						display: { xs: "none", sm: "block", md: "block", lg: "block" },
					}}
					// key={`kpi_${statoIter.stato}`}
				>
					<Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1, px: 3 }}>
						<ClienteServiziPieChart />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						// key={`kpi_${statoIter.stato}`}
						sx={{ my: 1, px: 3 }}
					>
						<ClienteProgressoPieChart />
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default HomeMenu;
