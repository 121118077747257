import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import { UtenteLogged } from "../../authentication/authenticationSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import SignIn from "../../authentication/SignIn";
import ClienteServiziButtonSection from "./ClienteServiziButtonSection";

import CircularProgress from "@mui/material/CircularProgress";

import home_img from "../../../../static/images/common/mondo.png";
import hero_image from "../../../../static/images/common/heroImage.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Parallax from "../../common/Parallax/Parallax";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import HomeMenu from "./HomeMenu";
import { theme } from "components/theme";

const containerFluid = {
	paddingRight: "15px",
	paddingLeft: "15px",
	marginRight: "auto",
	// marginLeft: "auto",
	width: "100%",
};
const container = {
	...containerFluid,
	"@media (min-width: 576px)": {
		// maxWidth: "540px",
		maxWidth: "750px",
	},
	"@media (min-width: 768px)": {
		// maxWidth: "720px",
		maxWidth: "980px",
	},
	"@media (min-width: 992px)": {
		// maxWidth: "960px",
		maxWidth: "1190px",
	},
	"@media (min-width: 1200px)": {
		// maxWidth: "1140px",
		maxWidth: "1900px",
	},
};

const parallaxContainer = {
	zIndex: "12",
	color: "#FFFFFF",
	pb: 6,
	...container,
};

const HomePage = () => {
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	return (
		<Box>
			<Parallax filter image={hero_image}>
				<Box sx={[{ ...parallaxContainer }]}>
					<Grid container>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							{utente.errorsStack.status == ErrorStatusTypes.PENDING ? (
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
								>
									<Grid item>
										<CircularProgress size={100} color="info" />
									</Grid>
								</Grid>
							) : utente.isLogged ? (
								<HomeMenu />
							) : (
								<Fade in timeout={3000}>
									<div>
										<Box>
											<Slide in direction="up" timeout={1500}>
												<Typography variant="h1" color="#fff" sx={{ mt: 1 }}>
													Sinergie43
												</Typography>
											</Slide>
											<Slide in direction="up" timeout={2500}>
												<Box>
													<Typography
														display="inline"
														variant="h3"
														color={theme.palette.primary.main}
														sx={{ mt: 1 }}
													>
														S
													</Typography>
													<Typography
														variant="h3"
														color="#fff"
														sx={{ mt: 1 }}
														display="inline"
													>
														ervizi{" "}
													</Typography>
													<Typography
														display="inline"
														variant="h3"
														color={theme.palette.primary.main}
														sx={{ mt: 1 }}
													>
														IN
													</Typography>
													<Typography
														variant="h3"
														color="#fff"
														sx={{ mt: 1 }}
														display="inline"
													>
														gegneria{" "}
													</Typography>
													<Typography
														display="inline"
														variant="h3"
														color={theme.palette.primary.main}
														sx={{ mt: 1 }}
													>
														E
													</Typography>
													<Typography
														variant="h3"
														color="#fff"
														sx={{ mt: 1 }}
														display="inline"
													>
														nergie{" "}
													</Typography>
													<Typography
														display="inline"
														variant="h3"
														color={theme.palette.primary.main}
														sx={{ mt: 1 }}
													>
														R
													</Typography>
													<Typography
														variant="h3"
														color="#fff"
														sx={{ mt: 1 }}
														display="inline"
													>
														innovabili{" "}
													</Typography>
													<Typography
														display="inline"
														variant="h3"
														color={theme.palette.primary.main}
														sx={{ mt: 1 }}
													>
														G
													</Typography>
													<Typography
														variant="h3"
														color="#fff"
														sx={{ mt: 1 }}
														display="inline"
													>
														ruppo{" "}
													</Typography>
													<Typography
														display="inline"
														variant="h3"
														color={theme.palette.primary.main}
														sx={{ mt: 1 }}
													>
														E43
													</Typography>
												</Box>
											</Slide>
										</Box>

										<Stack
											direction="row"
											justifyContent="center"
											// sx={{
											// 	// py: 10,
											// 	// textAlign: "center",
											// 	// px: 30,
											// 	width: "100%",
											// }}
										>
											<Slide in direction="left" timeout={4000}>
												<Card
													raised
													sx={{
														// mx: 8,
														mt: 4,
														// mb: 1,
														maxWidth: "40vmax",
													}}
												>
													<CardContent>
														<Typography
															gutterBottom
															variant="h5"
															component="div"
															alignContent={"center"}
														>
															Sei interessato?
														</Typography>
														<Typography variant="h6" color="text.secondary">
															Per maggiori informazioni sui servizi offerti da
															Engineering I43 scrivere a:{" "}
															<Link
																href="mailto:info@i43.it"
																target="_blank"
																rel="noopener"
															>
																info@i43.it
															</Link>
														</Typography>
													</CardContent>
												</Card>
											</Slide>
										</Stack>
										<SignIn />
									</div>
								</Fade>
							)}
						</Grid>
					</Grid>
				</Box>
			</Parallax>
			{utente.isLogged && <ClienteServiziButtonSection />}
		</Box>
	);
};

export default HomePage;
