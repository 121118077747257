import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import * as utenteApi from "../../api/utenteApi";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../api/apiUtils";
import { toast } from "react-toastify";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../common/errorsDeclarations";
import { PermissionTypes } from "./permissionsGroups";

export interface CredenzialiUser {
	username: string;
	password: string;
}
export interface Token {
	errorsStack: ErrorsStack;
}

export interface Utente {
	id?: string;
	username?: string;
	first_name?: string;
	last_name?: string;
	full_name?: string;
	email?: string;
	gruppi?: PermissionTypes[];
	isLogged?: boolean;
	is_staff: boolean;
	is_superuser: boolean;
	// drawer_aperto: boolean;
	// dark_mode: boolean;
}

export interface UtenteLogged extends Utente {
	errorsStack: ErrorsStack;
}

export interface UtenteApplicativo {
	id?: number;
	user: Utente;
	interno: boolean;
	cliente?: number;
	solo_lettura: boolean;
	errorsStack: ErrorsStack;
}

export interface UtentiApplicativo {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: UtenteApplicativo[];
	errorsStack: ErrorsStack;
}
export interface AuthenticationState {
	minutiRefreshToken: number;
	minutiRefreshAnagrafiche: number;
	token: Token;
	utenteLogged: UtenteLogged;
	utentiApplicativo: UtentiApplicativo;
}

export interface ChangePassword {
	old_password: string;
	new_password: string;
	new_password2: string;
}

export interface ResetPassword {
	uidb64: string;
	token: string;
	new_password: string;
	new_password2: string;
}

const initialState: AuthenticationState = {
	minutiRefreshToken: 6,
	minutiRefreshAnagrafiche: 10,
	token: {
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	utenteLogged: {
		id: undefined,
		username: "anonimo",
		first_name: undefined,
		last_name: undefined,
		email: undefined,
		gruppi: undefined,
		isLogged: false,
		is_staff: false,
		is_superuser: false,
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	utentiApplicativo: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export function utenteTokenObtain(user: CredenzialiUser) {
	return function (dispatch: any) {
		return utenteApi
			.tokenObtain(user)
			.then((token) => {
				const timeRefresh = { timeRefresh: Date.now() };
				localStorage.setItem(ACCESS_TOKEN, token.access);
				localStorage.setItem(REFRESH_TOKEN, token.refresh);
				localStorage.setItem("timeRefresh", timeRefresh.toString());
				dispatch(getUtente());
				toast.success("Successful Sign-in.");
			})
			.catch((error) => {
				localStorage.removeItem(ACCESS_TOKEN);
				localStorage.removeItem(REFRESH_TOKEN);
				localStorage.removeItem("timeRefresh");
				const message = error.toString();
				toast.error(message || "Login Error", { autoClose: 7000 });
				// dispatch(resetUtente());
				throw error;
			});
	};
}

export const utenteTokenRefresh = createAsyncThunk(
	"utente/utenteTokenRefresh",
	async (_, thunkApi) => {
		return await utenteApi
			.tokenRefresh()
			.then((response) => {
				const timeRefresh = { timeRefresh: Date.now() };

				localStorage.setItem(ACCESS_TOKEN, response.access);
				localStorage.setItem(REFRESH_TOKEN, response.refresh);
				localStorage.setItem("timeRefresh", timeRefresh.toString());
				thunkApi.dispatch(getUtente());
				return response;
			})
			.catch((error) => {
				thunkApi.dispatch(resetUtente());
				const message = error.toString();
				toast.error(message || "Login Error", { autoClose: 7000 });
				throw error;
			});
		//   return user;
	}
);

export const getUtente = createAsyncThunk("utente/getUtente", async () => {
	return await utenteApi.getUtente();
	//   return user;
});

export const saveUtente = createAsyncThunk(
	"utente/saveUtente",
	async (utente: Utente) => {
		return await utenteApi.saveUser(utente);
		//   return user;
	}
);

export const changePassword = createAsyncThunk(
	"utente/changePassword",
	async (passwordStack: ChangePassword) => {
		return await utenteApi.changePassword(passwordStack);
		//   return user;
	}
);

export const requestPasswordResetEmail = createAsyncThunk(
	"utente/requestPasswordResetEmail",
	async (email: string) => {
		return await utenteApi.requestPasswordResetEmail(email);
		//   return user;
	}
);

export const passwordResetCheck = createAsyncThunk(
	"utente/passwordResetCheck",
	async (resetPassword: ResetPassword) => {
		return await utenteApi.passwordResetCheck(resetPassword);
		//   return user;
	}
);
export const passwordReset = createAsyncThunk(
	"utente/passwordReset",
	async (resetPassword: ResetPassword) => {
		return await utenteApi.passwordReset(resetPassword);
		//   return user;
	}
);

export const fetchUtentiApplicativo = createAsyncThunk(
	"utente/fetchUtentiApplicativo",
	async (parametri: {
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await utenteApi.fetchUtentiApplicativo(
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const authenticationSlice = createSlice({
	name: "authentication",
	initialState,
	reducers: {
		resetUtente: (state) => {
			localStorage.removeItem(ACCESS_TOKEN);
			localStorage.removeItem(REFRESH_TOKEN);
			localStorage.removeItem("timeRefresh");
			state.utenteLogged = initialState.utenteLogged;

			toast.warning("Successful Sign-out.");
		},
		resetErrorStatus: (state) => {
			state.utenteLogged = initialState.utenteLogged;
			// state.utenteLogged.errorsStack.status = ErrorStatusTypes.OK;
		},
	},
	extraReducers: (builder) => {
		// get Token
		builder.addCase(utenteTokenRefresh.pending, (state, action) => {
			state.token.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(utenteTokenRefresh.fulfilled, (state, action) => {
			state.token.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(utenteTokenRefresh.rejected, (state, action) => {
			// state = initialState;

			state.token.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
			// toast.error(
			// 	"Errore....." + action?.error?.message || "Problemi di autenticazione"
			// );
		});

		/////////////////////////////////////////
		// get utente

		builder.addCase(getUtente.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getUtente.fulfilled, (state, action) => {
			state.utenteLogged = { ...action.payload, isLogged: true };
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getUtente.rejected, (state, action) => {
			state.utenteLogged = initialState.utenteLogged;

			state.utenteLogged.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
			toast.error("Errore....." + action.error);
		});

		/////////////////////////////////////////
		// save utente
		builder.addCase(saveUtente.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveUtente.fulfilled, (state, action) => {
			state.utenteLogged = {
				...action.payload,
				isLogged: state.utenteLogged.isLogged,
			};
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Profilo aggiornato.");
		});
		builder.addCase(saveUtente.rejected, (state, action) => {
			state.utenteLogged.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
			toast.error("Errore: " + JSON.stringify(action?.error?.message || ""));
		});
		/////////////////////////////////////////
		// change password utente
		builder.addCase(changePassword.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(changePassword.fulfilled, (state, action) => {
			// state.utenteLogged = {
			//   ...action.payload,
			//   isLogged: state.utenteLogged.isLogged,
			// };
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Password aggiornata.");
		});
		builder.addCase(changePassword.rejected, (state, action) => {
			state.utenteLogged.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
			toast.error("Errore: " + JSON.stringify(action?.error?.message || ""));
		});
		/////////////////////////////////////////
		// change password utente
		builder.addCase(requestPasswordResetEmail.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(requestPasswordResetEmail.fulfilled, (state, action) => {
			// state.utenteLogged = {
			// 	...action.payload,
			// 	//   isLogged: state.utenteLogged.isLogged,
			// };
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Richiesta inviata.");
		});
		builder.addCase(requestPasswordResetEmail.rejected, (state, action) => {
			state.utenteLogged.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
			toast.error("Errore: " + JSON.stringify(action?.error?.message || ""));
		});
		/////////////////////////////////////////
		// check token x reset password utente
		builder.addCase(passwordResetCheck.pending, (state, action) => {
			// state.token.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(passwordResetCheck.fulfilled, (state, action) => {
			state.utenteLogged = {
				...action.payload,
				//   isLogged: state.utenteLogged.isLogged,
			};
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.OK };
			state.token.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(passwordResetCheck.rejected, (state, action) => {
			state.token.errorsStack = { status: ErrorStatusTypes.ERROR };
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.ERROR };
			state.utenteLogged.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: { token: ["Token non valido"] },
			};
			state.token.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: { token: ["Token non valido"] },
			};
			// toast.error("Errore: " + JSON.stringify(action?.error?.message || ""));
		});
		/////////////////////////////////////////
		// change password utente
		builder.addCase(passwordReset.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(passwordReset.fulfilled, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Pasword modificata");
		});
		builder.addCase(passwordReset.rejected, (state, action) => {
			state.utenteLogged.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(JSON.stringify(action?.error?.message || "")),
			};
			toast.error("Errore: " + action?.error?.message || "");
		});

		/////////////////////////////////////////////////////////////

		// fetch IterConnessioni
		builder.addCase(fetchUtentiApplicativo.pending, (state, action) => {
			state.utentiApplicativo.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchUtentiApplicativo.fulfilled, (state, action) => {
			state.utentiApplicativo = action.payload;
			state.utentiApplicativo.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchUtentiApplicativo.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.utentiApplicativo.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetUtente, resetErrorStatus } = authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;
