import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as podsApi from "../../../api/pods/podsApi";
import moment from "moment";
import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../allegati/allegatiSlice";
import { OrdinamentoLista } from "components/userPreference/userPreferenceSlice";
// import { useDispatch } from "react-redux";

moment.locale("it");

export enum TipoTensioneTypes {
	BTM = "bassa tensione 230V",
	BTT = "bassa tensione 400V",
	MT = "media tensione 20000V",
}
export enum TipoSchemaTypes {
	HYB = "Lato produzione DC",
	PRD = "Lato produzione AC",
	POS = "Lato post produzione AC",
}
export enum TitoloPossessoTypes {
	A = "proprietario o comproprietario",
	B = "detentore o codetentore (locatore, comodatario, ecc.)",
	C = "familiare convivente con il possessore o il detentore",
}
export enum TipologiaEdiliziaTypes {
	A = "casa singola",
	B = "bifamiliare",
	C = "trifamiliare",
	D = "edificio a schiera e condominio fino a tre piani",
	E = "edificio in linea e condominio oltre i tre piani fuori terra",
	F = "altro specificare nelle note",
}

export interface Elemento {
	id: number | null | undefined;
	iterConnessione: number;
	codice: string;
	descrizione: string;
	errorsStack?: ErrorsStack;
}

export enum TipoProdottoTypes {
	MF = "Moduli Fotovoltaici",
	IN = "Inverter",
	SA = "Sistema Accumulo",
}

export interface Prodotto {
	id: number | null | undefined;
	pod: number;
	tipo_prodotto: "MF" | "IN" | "SA";
	marca?: string;
	modello?: string;
	quantita?: number;
	potenza_unitaria?: number;
	capacita_singola_batteria?: number;
	capacita_totale?: number;
}

export interface SezioneImpianto {
	id?: number | null | undefined;
	pod: number;
	potenza_generatore?: number;
	potenza_inverter?: number;
	contrib_ICC?: number;
	potenza_cc_inverter?: number;
	potenza_nominale_sda?: number;
	potenza_cc_sda?: number;
	capacita?: number;
	tensione_nominale_sda?: number;
	tipo_schema?: "HYB" | "PRD" | "POS";
	errorsStack?: ErrorsStack;
}

export interface IterConnessioneLight {
	id?: number | null | undefined;
	commessa?: string;
	iter_semplificato: boolean;
	tipo_pratica?: string;
	cliente?: number;
	installatore?: number;
	indirizzo?: string;
	numero_civico?: string;
	cap?: string;
	comune?: number;
	stato: string;
	data_ultimo_stato?: string | Date;
	stato_richiesta_connessione?: string;
	errorsStack?: ErrorsStack;
}

export interface Pod {
	id?: number | null | undefined;
	codice?: string;
	potenza_disponibile?: number;
	potenza_nominale?: number;
	potenza_immessa?: number;
	tipo_tensione?: TipoTensioneTypes;
	gestore_rete?: number;
	regime_commerciale?: number;
	produttore: number;
	produttore_descrizione: string;
	iban_accredito_gse?: string;
	indirizzo?: string;
	numero_civico?: string;
	cap?: string;
	comune?: number;
	provincia?: string;
	foglio_catastale?: string;
	particella_catastale?: string;
	sub_particella_catastale?: string;
	prodotti?: Prodotto[];
	sezioni: SezioneImpianto[];
	numero_iter_connessioni_pod?: number;
	iter_connessioni?: IterConnessioneLight[];
	errorsStack?: ErrorsStack;
}

export interface PodsState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Pod[];
	errorsStack: ErrorsStack;
}
export interface PodsStrutturaState {
	pods: PodsState;
	nuovoPodId?: number;
}

const initialState: PodsStrutturaState = {
	pods: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchPods = createAsyncThunk(
	"pod/fetchPods",
	async (parametri: {
		// statoIter?: string;
		// clienteIter?: number;
		// iterSemplificato?: string;
		// operatore?: number;
		// progettista?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
		ordinamento?: OrdinamentoLista | undefined;
	}) => {
		return await podsApi.fetchPods(
			// parametri.statoIter,
			// parametri.clienteIter,
			// parametri.iterSemplificato,
			// parametri.operatore,
			// parametri.progettista,
			parametri.numeroRecord,
			parametri.page,
			parametri.search,
			parametri.ordinamento
		);
	}
);

export const getPod = createAsyncThunk("pod/getPod", async (podId: number) => {
	return await podsApi.getPod(podId);
});

export const savePod = createAsyncThunk(
	"pod/savePod",
	async (
		parametri: {
			podToSave: Pod;
			// richiedeIntegrazioni?: boolean;
			// azione?: string;
		},
		thunkApi
	) => {
		return await podsApi.savePod(
			parametri.podToSave
			// parametri.richiedeIntegrazioni,
			// parametri.azione
		);
	}
);

export const deletePod = createAsyncThunk(
	"pod/deletePod",
	async (podToDelete: Pod, thunkApi) => {
		return await podsApi.deletePod(podToDelete);
	}
);

// export const richiestaPresaInCarico = createAsyncThunk(
// 	"iterConnessione/richiestaPresaInCarico",
// 	async (iterConnessioneToSave: IterConnessione, thunkApi) => {
// 		return await iterConnessioniApi.richiestaPresaInCarico(
// 			iterConnessioneToSave
// 		);
// 	}
// );

// export const presaInCarico = createAsyncThunk(
// 	"iterConnessione/presaInCarico",
// 	async (iterConnessioneToSave: IterConnessione, thunkApi) => {
// 		return await iterConnessioniApi.presaInCarico(iterConnessioneToSave);
// 	}
// );
// export const assegnaPratica = createAsyncThunk(
// 	"iterConnessione/assegnaPratica",
// 	async (iterConnessioneToSave: IterConnessione, thunkApi) => {
// 		return await iterConnessioniApi.assegnaPratica(iterConnessioneToSave);
// 	}
// );

// export const avviaProgetto = createAsyncThunk(
// 	"iterConnessione/avviaProgetto",
// 	async (iterConnessioneToSave: IterConnessione, thunkApi) => {
// 		return await iterConnessioniApi.avviaProgetto(iterConnessioneToSave);
// 	}
// );

// export const saveProdotto = createAsyncThunk(
// 	"iterConnessione/saveProdotto",
// 	async (
// 		parametri: { prodotto: Prodotto; iterconnessioneId: number },
// 		thunkApi
// 	) => {
// 		return await iterConnessioniApi.saveProdotto(
// 			parametri.prodotto,
// 			parametri.iterconnessioneId
// 		);
// 	}
// );

// export const deleteProdotto = createAsyncThunk(
// 	"iterConnessione/deleteProdotto",
// 	async (
// 		parametri: { prodotto: Prodotto; iterconnessioneId: number },
// 		thunkApi
// 	) => {
// 		return await iterConnessioniApi.deleteProdotto(parametri.prodotto);
// 	}
// );

export const podsSlice = createSlice({
	name: "podsState",
	initialState,
	reducers: {
		resetNuovoPodId: (state) => {
			state.nuovoPodId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch Pods
		builder.addCase(fetchPods.pending, (state, action) => {
			state.pods.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchPods.fulfilled, (state, action) => {
			state.pods = action.payload;
			state.pods.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchPods.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.pods.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Pod
		builder.addCase(getPod.pending, (state, action) => {
			state.pods.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getPod.fulfilled, (state, action) => {
			state.pods.results = state.pods.results.filter(
				(pod) => pod.id != action.payload.id
			);
			state.pods.results.push(action.payload);
			// state.nuovoIterConnessioneId = action.payload.id;

			state.pods.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getPod.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.pods.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Pod
		builder.addCase(savePod.pending, (state, action) => {
			state.pods.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(savePod.fulfilled, (state, action) => {
			state.pods.results = state.pods.results.filter(
				(pod) => pod.id != action.payload.id
			);
			state.pods.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			// Solo se è nuova creazione ********************
			if (!action.meta.arg.podToSave.id) {
				state.nuovoPodId = action.payload.id;
			}
			// ***********************************************
			state.pods.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Pod salvato.");
		});
		builder.addCase(savePod.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.pods.results = state.pods.results.map((pod) => {
				if (pod.id == action.meta.arg.podToSave.id) {
					return {
						...pod,
						errorsStack: {
							status: ErrorStatusTypes.ERROR,
							fieldsErrors: JSON.parse(action?.error?.message || ""),
						},
					};
				} else {
					return pod;
				}
			});
			state.pods.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});

		// cancella Pod
		builder.addCase(deletePod.pending, (state, action) => {
			state.pods.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deletePod.fulfilled, (state, action) => {
			state.pods.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.pods.results = state.pods.results.filter(
				(pod) => pod.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Pod cancellato.");
		});
		builder.addCase(deletePod.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.pods.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// // richiesta di presa in carico IterConnessione
		// builder.addCase(richiestaPresaInCarico.pending, (state, action) => {
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		// });
		// builder.addCase(richiestaPresaInCarico.fulfilled, (state, action) => {
		// 	state.iterConnessioni.results = state.iterConnessioni.results.filter(
		// 		(iterConnessione) => iterConnessione.id != action.payload.id
		// 	);
		// 	state.iterConnessioni.results.push({
		// 		...action.payload,
		// 		errorsStack: { status: ErrorStatusTypes.SUCCESS },
		// 	});
		// 	// state.nuovoIterConnessioneId = action.payload.id;
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
		// 	toast.success("Inviata richiesta di Presa in Carico.");
		// });
		// builder.addCase(richiestaPresaInCarico.rejected, (state, action) => {
		// 	toast.error("Errore:" + action?.error?.message || "");

		// 	state.iterConnessioni.errorsStack = {
		// 		status: ErrorStatusTypes.ERROR,
		// 	};
		// });

		// //  presa in carico IterConnessione
		// builder.addCase(presaInCarico.pending, (state, action) => {
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		// });
		// builder.addCase(presaInCarico.fulfilled, (state, action) => {
		// 	state.iterConnessioni.results = state.iterConnessioni.results.filter(
		// 		(iterConnessione) => iterConnessione.id != action.payload.id
		// 	);
		// 	state.iterConnessioni.results.push({
		// 		...action.payload,
		// 		errorsStack: { status: ErrorStatusTypes.SUCCESS },
		// 	});
		// 	// state.nuovoIterConnessioneId = action.payload.id;
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
		// 	toast.success("Inviata richiesta di Presa in Carico.");
		// });
		// builder.addCase(presaInCarico.rejected, (state, action) => {
		// 	toast.error("Errore:" + action?.error?.message || "");

		// 	state.iterConnessioni.errorsStack = {
		// 		status: ErrorStatusTypes.ERROR,
		// 	};
		// });

		// //  presa avvio Pratica IterConnessione
		// builder.addCase(assegnaPratica.pending, (state, action) => {
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		// });
		// builder.addCase(assegnaPratica.fulfilled, (state, action) => {
		// 	state.iterConnessioni.results = state.iterConnessioni.results.filter(
		// 		(iterConnessione) => iterConnessione.id != action.payload.id
		// 	);
		// 	state.iterConnessioni.results.push({
		// 		...action.payload,
		// 		errorsStack: { status: ErrorStatusTypes.SUCCESS },
		// 	});
		// 	// state.nuovoIterConnessioneId = action.payload.id;
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
		// 	toast.success("Inviata richiesta di Presa in Carico.");
		// });
		// builder.addCase(assegnaPratica.rejected, (state, action) => {
		// 	toast.error("Errore:" + action?.error?.message || "");

		// 	state.iterConnessioni.errorsStack = {
		// 		status: ErrorStatusTypes.ERROR,
		// 	};
		// });

		// //  presa avvio Pratica IterConnessione
		// builder.addCase(avviaProgetto.pending, (state, action) => {
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		// });
		// builder.addCase(avviaProgetto.fulfilled, (state, action) => {
		// 	state.iterConnessioni.results = state.iterConnessioni.results.filter(
		// 		(iterConnessione) => iterConnessione.id != action.payload.id
		// 	);
		// 	state.iterConnessioni.results.push({
		// 		...action.payload,
		// 		errorsStack: { status: ErrorStatusTypes.SUCCESS },
		// 	});
		// 	// state.nuovoIterConnessioneId = action.payload.id;
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
		// 	toast.success("Progetto avviato.");
		// });
		// builder.addCase(avviaProgetto.rejected, (state, action) => {
		// 	toast.error("Errore:" + action?.error?.message || "");

		// 	state.iterConnessioni.errorsStack = {
		// 		status: ErrorStatusTypes.ERROR,
		// 	};
		// });

		// // save Prodotto
		// builder.addCase(saveProdotto.pending, (state, action) => {
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		// });
		// builder.addCase(saveProdotto.fulfilled, (state, action) => {
		// 	state.iterConnessioni.results = state.iterConnessioni.results.filter(
		// 		(iterConnessione) => iterConnessione.id != action.payload.id
		// 	);
		// 	state.iterConnessioni.results.push({
		// 		...action.payload,
		// 		errorsStack: { status: ErrorStatusTypes.SUCCESS },
		// 	});
		// 	// state.nuovoIterConnessioneId = action.payload.id;
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
		// 	toast.success("IterConnessione salvata.");
		// });
		// builder.addCase(saveProdotto.rejected, (state, action) => {
		// 	toast.error("Errore:" + action?.error?.message || "");

		// 	state.iterConnessioni.results = state.iterConnessioni.results.map(
		// 		(iterConnessione) => {
		// 			if (iterConnessione.id == action.meta.arg.iterconnessioneId) {
		// 				return {
		// 					...iterConnessione,
		// 					errorsStack: {
		// 						status: ErrorStatusTypes.ERROR,
		// 						fieldsErrors: JSON.parse(action?.error?.message || ""),
		// 					},
		// 				};
		// 			} else {
		// 				return iterConnessione;
		// 			}
		// 		}
		// 	);
		// 	state.iterConnessioni.errorsStack = {
		// 		status: ErrorStatusTypes.ERROR,
		// 	};
		// });

		// // cancella Prodotto
		// builder.addCase(deleteProdotto.pending, (state, action) => {
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		// });
		// builder.addCase(deleteProdotto.fulfilled, (state, action) => {
		// 	state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
		// 	state.iterConnessioni.results = state.iterConnessioni.results.map(
		// 		(iter) => {
		// 			const prodottifiltrati = iter.prodotti?.filter(
		// 				(p) => p.id != action.meta.arg.prodotto.id
		// 			);
		// 			return { ...iter, prodotti: prodottifiltrati };
		// 		}
		// 	);
		// 	toast.success(action.payload.message || "IterConnessione cancellata.");
		// });
		// builder.addCase(deleteProdotto.rejected, (state, action) => {
		// 	toast.error("Errore:" + action?.error?.message || "");
		// 	state.iterConnessioni.errorsStack = {
		// 		status: ErrorStatusTypes.ERROR,
		// 		fieldsErrors: JSON.parse(action?.error?.message || ""),
		// 	};
		// });
	},
});

// Action creators are generated for each case reducer function
export const { resetNuovoPodId } = podsSlice.actions;

export const podsSliceReducer = podsSlice.reducer;
