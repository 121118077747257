import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	Sopralluogo,
	// TipoTensioneTypes,
	// TipologiaEdiliziaTypes,
	// TitoloPossessoTypes,
	saveSopralluogo,
	StatoSopralluogoTypes,
	AccessoTettoTypes,
	TipoTettoTypes,
	TipoCoperturaTypes,
	StatoCoperturaTypes,
	TipoStaffaTypes,
} from "../../sopralluoghiSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";
import Checkbox from "@mui/material/Checkbox";

import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
	IterConnessione,
	TipoTensioneTypes,
	TipologiaEdiliziaTypes,
	TitoloPossessoTypes,
	saveIterConnessione,
} from "../../../iterConnessioni/iterConnessioneSlice";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";

import {
	UtenteLogged,
	UtenteApplicativo,
} from "../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";

import { Button, Divider } from "@mui/material";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface SopralluogoEdificioFormProps {
	sopralluogo: Sopralluogo;
	// produttore: Produttore;
	consentiModifica: boolean;
}

const SopralluogoEdificioForm = ({
	sopralluogo,
	// produttore,
	consentiModifica = false,
}: SopralluogoEdificioFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Sopralluogo>();

	const listaCampi = Object.keys(sopralluogo) as Array<keyof Sopralluogo>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(!sopralluogo.id);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
		(state) => state.authentication.utentiApplicativo.results
	);

	const installatori = useSelector<RootState, Installatore[]>(
		(state) => state.installatoriState.installatori.results
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);
	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);
	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const gestori = useSelector<RootState, Gestore[]>(
		(state) => state.gestoriState.gestori.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune"), sopralluogo]);

	const [keyPod, setKeyPod] = useState<string>(makeid(5));
	const [annulla, setAnnulla] = useState<boolean>(false);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, sopralluogo[field]);
		});
		setKeyPod(makeid(5));

		setAnnulla(false);
	}, [sopralluogo.id, annulla]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			sopralluogo?.errorsStack?.fieldsErrors &&
				sopralluogo.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: sopralluogo.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		sopralluogo?.errorsStack?.fieldsErrors,
		// sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		sopralluogo?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(!sopralluogo.id);
	}, [
		sopralluogo,
		// sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveSopralluogo = (sopralluogo: Sopralluogo) => {
		consentiModifica &&
			dispatch(
				saveSopralluogo({
					sopralluogoToSave: sopralluogo,
				})
			);
	};

	const onSubmit: SubmitHandler<Sopralluogo> = (sopralluogo) => {
		handlerSaveSopralluogo(sopralluogo);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)} key={keyPod}>
				{sopralluogo?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{sopralluogo?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<ReactHookFormSelect
							name="cliente"
							label="Cliente"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.cliente?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="cliente_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{clienti.map((cliente) => {
								return (
									<MenuItem key={cliente.id} value={cliente.id}>
										{cliente.ragione_sociale}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<DesktopDatePicker
							label="data sopralluogo"
							// components={{
							// 	OpenPickerIcon: ClockIcon,
							// }}
							// inputFormat="DD/MM/YY HH:mm"
							// mask={"__/__/__ __:__"}
							// label="Data Progetto"
							// {...register("data_ora_carico_pianificata")}
							value={watch("data_sopralluogo") || sopralluogo.data_sopralluogo}
							disabled={!consentiModifica}
							renderInput={(params: any) => (
								<TextField
									{...params}
									error={!!errors?.data_sopralluogo}
									helperText={errors?.data_sopralluogo?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									// {...register("data_ora_fine_turno")}
								/>
							)}
							onChange={(newValue: any) => {
								setEditing(true);
								setValue(
									"data_sopralluogo",
									newValue ? new Date(newValue.toDate()) : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_sopralluogo");
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<ReactHookFormSelect
							name="operatore"
							label="operatore"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.operatore?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="operatore_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{utentiApplicativo
								.filter((utente) => utente.user.is_staff == true)
								.map((utente) => {
									return (
										<MenuItem key={utente.id} value={utente.user.id}>
											{utente.user.full_name}
										</MenuItem>
									);
								})}
						</ReactHookFormSelect>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={9} sm={9} md={9} lg={4}>
						{sopralluogo.produttore_descrizione}
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={9} sm={9} md={9} lg={4}>
						<TextField
							label="indirizzo"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("indirizzo")}
							error={!!errors?.indirizzo}
							helperText={errors?.indirizzo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={1}>
						<TextField
							variant="outlined"
							label="N# civico"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("numero_civico")}
							error={!!errors?.numero_civico}
							helperText={errors?.numero_civico?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={1}>
						<TextField
							label="cap"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("cap")}
							error={!!errors?.cap}
							helperText={errors?.cap?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={8} sm={8} md={8} lg={4}>
						<Autocomplete
							key={comuneCorrente?.id}
							value={comuneCorrente}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							onClick={() => setEditing(true)}
							onChange={(event: any, newValue: any) => {
								setValue("comune", newValue ? newValue.id : null, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							id="comune"
							options={comuni}
							getOptionLabel={(option) => option.nome}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									{option.nome}
								</Box>
							)}
							renderInput={(params: any) => (
								<TextField
									variant="outlined"
									{...params}
									label="Comune"
									error={!!errors?.comune}
									helperText={errors?.comune?.message}
									onClick={() => setEditing(true)}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} md={4}>
						<TextField
							id="cellulare"
							label="Cellulare"
							disabled={!consentiModifica}
							{...register("cellulare")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="number"
							autoComplete={"off"}
							error={!!errors?.cellulare}
							helperText={errors?.cellulare?.message}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<TextField
							label="TIPO E TAGLIA MODULI FV"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("tipo_moduli")}
							error={!!errors?.tipo_moduli}
							helperText={errors?.tipo_moduli?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<TextField
							label="TIPO E TAGLIA BATTERIA"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("tipo_batteria")}
							error={!!errors?.tipo_batteria}
							helperText={errors?.tipo_batteria?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={6} sm={4} md={4} lg={2}>
						<FormControlLabel
							value={
								watch("presenza_ev_charger") || sopralluogo?.presenza_ev_charger
							}
							checked={watch("presenza_ev_charger") || false}
							control={<Checkbox />}
							label="EV Charger"
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("presenza_ev_charger", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.presenza_ev_charger && (
							<FormHelperText error>
								{errors?.presenza_ev_charger.toString()}
							</FormHelperText>
						)}
					</Grid>

					<Grid item xs={12} sm={8} md={8} lg={10}>
						{!!watch("presenza_ev_charger") && (
							<TextField
								label="MARCA E MODELLO"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								disabled={!consentiModifica}
								{...register("ev_charger_marca")}
								error={!!errors?.ev_charger_marca}
								helperText={errors?.ev_charger_marca?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								onClick={() => setEditing(true)}
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={6} sm={4} md={4} lg={2}>
						<FormControlLabel
							value={
								watch("presenza_ottimizzatori") ||
								sopralluogo?.presenza_ottimizzatori
							}
							checked={watch("presenza_ottimizzatori") || false}
							control={<Checkbox />}
							label="Ottimizzatori"
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("presenza_ottimizzatori", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.presenza_ottimizzatori && (
							<FormHelperText error>
								{errors?.presenza_ottimizzatori.toString()}
							</FormHelperText>
						)}
					</Grid>

					<Grid item xs={6} sm={4} md={4} lg={8}>
						{!!watch("presenza_ottimizzatori") && (
							<TextField
								label="MODELLO"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								disabled={!consentiModifica}
								{...register("ottimizzatori_modello")}
								error={!!errors?.ottimizzatori_modello}
								helperText={errors?.ottimizzatori_modello?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								onClick={() => setEditing(true)}
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={2}>
						{!!watch("presenza_ottimizzatori") && (
							<Controller
								name="ottimizzatori_numero"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="ottimizzatori_numero"
										customInput={TextField}
										variant="outlined"
										label="Numero"
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!consentiModifica}
										error={!!errors?.ottimizzatori_numero}
										helperText={errors?.ottimizzatori_numero?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("ottimizzatori_numero", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
				</Grid>
				<Divider sx={{ mb: 2 }}>
					TIPOLOGIA EDIFICIO, COPERTURA E SOTTOCOPERTURA
				</Divider>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} md={4}>
						<ReactHookFormSelect
							name="tipologia_edilizia"
							label="tipologia edilizia"
							register={register}
							disabled={!consentiModifica}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.tipologia_edilizia?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{Object.entries(TipologiaEdiliziaTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} md={8}>
						<TextField
							variant="outlined"
							label="specifiche per altro tipo edilizia"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("tipologia_edilizia_altro_desc")}
							error={!!errors?.tipologia_edilizia_altro_desc}
							helperText={errors?.tipologia_edilizia_altro_desc?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} md={4}>
						<ReactHookFormSelect
							name="accesso_tetto"
							label="accesso al tetto"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.accesso_tetto?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{Object.entries(AccessoTettoTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>

					<Grid item xs={12} md={8}>
						<TextField
							variant="outlined"
							label="
								specifiche per alto tipo accesso al tetto
							
							"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("accesso_tetto_altro_desc")}
							error={!!errors?.accesso_tetto_altro_desc}
							helperText={errors?.accesso_tetto_altro_desc?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Controller
							name="altezza_gronda"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="altezza_gronda"
									customInput={TextField}
									variant="outlined"
									label="Altezza del livello di gronda per scarico a tetto"
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!consentiModifica}
									error={!!errors?.altezza_gronda}
									helperText={errors?.altezza_gronda?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="m "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("altezza_gronda", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3} lg={4}>
						<Controller
							name="altezza_abitazione"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="altezza_abitazione"
									customInput={TextField}
									variant="outlined"
									label="Altezza abitazione:"
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!consentiModifica}
									error={!!errors?.altezza_abitazione}
									helperText={errors?.altezza_abitazione?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="m "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("altezza_abitazione", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3} lg={4}>
						<Controller
							name="numero_piani"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="numero_piani"
									customInput={TextField}
									variant="outlined"
									label="Numero piani:"
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!consentiModifica}
									error={!!errors?.numero_piani}
									helperText={errors?.numero_piani?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="piani "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("numero_piani", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} md={5} lg={4}>
						<ReactHookFormSelect
							name="tipo_tetto"
							label="Tipo di tetto"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.tipo_tetto?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{Object.entries(TipoTettoTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>

					<Grid item xs={12} md={7} lg={8}>
						<TextField
							variant="outlined"
							label="specifiche per alto tipo di tetto"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("tipo_tetto_altro_desc")}
							error={!!errors?.tipo_tetto_altro_desc}
							helperText={errors?.tipo_tetto_altro_desc?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} md={5} lg={4}>
						<ReactHookFormSelect
							name="tipo_copertura"
							label="Tipo di copertura"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.tipo_copertura?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{Object.entries(TipoCoperturaTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>

					<Grid item xs={12} md={7} lg={8}>
						<TextField
							variant="outlined"
							label="specifiche per alto tipo di copertura"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("tipo_copertura_altro_desc")}
							error={!!errors?.tipo_copertura_altro_desc}
							helperText={errors?.tipo_copertura_altro_desc?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} md={5} lg={4}>
						<ReactHookFormSelect
							name="stato_copertura"
							label="Stato della copertura"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.stato_copertura?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{Object.entries(StatoCoperturaTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} md={7} lg={4}>
						<ReactHookFormSelect
							name="tipo_staffa"
							label="tipo staffa sugerito"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.tipo_staffa?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{Object.entries(TipoStaffaTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
				</Grid>
				<Button
					disabled={!consentiModifica}
					variant="outlined"
					onClick={() => {
						setAnnulla(true);
					}}
				>
					Annulla
				</Button>
				<Button
					disabled={!consentiModifica}
					variant="outlined"
					type="submit"
					sx={{ ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default SopralluogoEdificioForm;
