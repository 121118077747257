import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as comuniApi from "../../../api/anagrafiche/comuniApi";
import { toast } from "react-toastify";

export interface Comune {
	id: number;
	codice_istat: number;
	codice_catastale: string;
	nome: string;
	provincia: string;
	errorsStack?: ErrorsStack;
}

export interface Comuni {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	ultimo_aggiornamento: string;
	results: Comune[];
	errorsStack: ErrorsStack;
}

export interface ComuniStrutturaState {
	comuni: Comuni;
}

const initialState: ComuniStrutturaState = {
	comuni: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		ultimo_aggiornamento: "",
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchComuni = createAsyncThunk(
	"comuni/fetchComuni",
	async (
		parametri: { ultimoAggiornamento?: string; nextUrl?: string },
		thunkApi
	) => {
		return await comuniApi
			.fetchComuni(parametri?.ultimoAggiornamento, parametri?.nextUrl)
			.then((response) => {
				if (response.next) {
					thunkApi.dispatch(fetchComuni({ nextUrl: response.next }));
				}
				return response;
			});
	}
);
export const comuniSlice = createSlice({
	name: "comuni",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Regimi Commerciali
		builder.addCase(fetchComuni.pending, (state, action) => {
			state.comuni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchComuni.fulfilled, (state, action) => {
			state.comuni.ultimo_aggiornamento = action.payload.ultimo_aggiornamento;
			state.comuni.results = [
				...action.payload.results,
				// qui sotto vengono aggiunti i comuni nello store che non arrivano dalla fetch
				// per compensare lo scaricamento giorno per giorno

				// ...state.comuni.results,
				...state.comuni.results.filter(
					(comune) =>
						action.payload.results.find(
							(comuneNew: Comune) => comuneNew.id == comune.id
						) == undefined
				),
			];
			// e poi li riordino per nome comune
			state.comuni.results = state.comuni.results.sort((a, b) =>
				a.nome.localeCompare(b.nome)
			);
			state.comuni.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchComuni.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.comuni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = comuniSlice.actions;

export const comuniSliceReducer = comuniSlice.reducer;
