import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
import * as autorizzazioneComunaleApi from "../../../../../api/iterConnessioni/autorizzazioneComunaleApi";
import moment from "moment";
import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../../../allegati/allegatiSlice";
import { getIterConnessione } from "../../iterConnessioneSlice";
import { fetchRichiesteConnessioneFromIter } from "../richiesteConnessione/richiesteConnessioneSlice";

// import { useDispatch } from "react-redux";

moment.locale("it");

export enum StatoAutorizzazioneComunaleTypes {
	A = "in corso",
	B = "conclusa",
}
export interface AutorizzazioneComunale {
	id: number;
	iter_connessione?: number;
	produttore?: string;
	operatore?: string;
	data_avvio?: Date | string;
	data_avvio_costruzione?: Date | string;
	data_completamento_impianto?: Date | string;
	data_conclusione?: Date | string;
	rigettata?: boolean;
	codice_ricevuta_protocollo?: string;
	richiesta_titolo_abilitativo?: string;
	pratica_paesaggistica_necessaria: boolean;
	note?: string;
	latitudine?: number;
	longitudine?: number;
	listaDocumentiPerTipo?: DocumentiPerTipo[];
	link_maps?: string;

	completo?: boolean;
	stato: string;
	errorsStack?: ErrorsStack;
}

export interface AutorizzazioniComunaliState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: AutorizzazioneComunale[];
	errorsStack: ErrorsStack;
}
export interface AutorizzazioneComunaleStrutturaState {
	autorizzazioniComunali: AutorizzazioniComunaliState;
}

const initialState: AutorizzazioneComunaleStrutturaState = {
	autorizzazioniComunali: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchAutorizzazioneComunale = createAsyncThunk(
	"autorizzazioniComunali/fetchAutorizzazioneComunale",
	async (parametri: {
		statoAutorizzazioneComunale?: string;
		clienteIter?: number;
		operatore?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await autorizzazioneComunaleApi.fetchAutorizzazioneComunale(
			parametri.statoAutorizzazioneComunale,
			parametri.clienteIter,
			parametri.operatore,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getAutorizzazioneComunale = createAsyncThunk(
	"autorizzazioniComunali/getAutorizzazioneComunale",
	async (autorizzazioneComunaleId: number) => {
		return await autorizzazioneComunaleApi.getAutorizzazioneComunale(
			autorizzazioneComunaleId
		);
	}
);

export const getAutorizzazioneComunaleFromIter = createAsyncThunk(
	"autorizzazioniComunali/getAutorizzazioneComunaleFromIter",
	async (iterId: number) => {
		return await autorizzazioneComunaleApi.getAutorizzazioneComunaleFromIter(
			iterId
		);
	}
);

export const saveAutorizzazioneComunale = createAsyncThunk(
	"autorizzazioniComunali/saveAutorizzazioneComunale",
	async (
		parametri: {
			autorizzazioneComunaleToSave: AutorizzazioneComunale;
			azione?: string;
		},
		thunkApi
	) => {
		return await autorizzazioneComunaleApi
			.saveAutorizzazioneComunale(
				parametri.autorizzazioneComunaleToSave,
				parametri.azione
			)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						getAutorizzazioneComunaleFromIter(
							parametri.autorizzazioneComunaleToSave.iter_connessione || 0
						)
					);

				return response;
			});
	}
);

export const deleteAutorizzazioneComunale = createAsyncThunk(
	"autorizzazioniComunali/deleteAutorizzazioneComunale",
	async (autorizzazioneComunaleToDelete: AutorizzazioneComunale, thunkApi) => {
		return await autorizzazioneComunaleApi.deleteAutorizzazioneComunale(
			autorizzazioneComunaleToDelete
		);
	}
);

export const autorizzazioneComunaleSlice = createSlice({
	name: "autorizzazioneComunaleState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch AutorizzazioneComunale
		builder.addCase(fetchAutorizzazioneComunale.pending, (state, action) => {
			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchAutorizzazioneComunale.fulfilled, (state, action) => {
			state.autorizzazioniComunali = action.payload;
			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchAutorizzazioneComunale.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get AutorizzazioneComunale
		builder.addCase(getAutorizzazioneComunale.pending, (state, action) => {
			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getAutorizzazioneComunale.fulfilled, (state, action) => {
			state.autorizzazioniComunali.results =
				state.autorizzazioniComunali.results.filter(
					(autorizzazioniComunali) =>
						autorizzazioniComunali.id != action.payload.id
				);
			state.autorizzazioniComunali.results.push(action.payload);

			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(getAutorizzazioneComunale.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get AutorizzazioneComunale from Iter
		builder.addCase(
			getAutorizzazioneComunaleFromIter.pending,
			(state, action) => {
				state.autorizzazioniComunali.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			getAutorizzazioneComunaleFromIter.fulfilled,
			(state, action) => {
				state.autorizzazioniComunali.results =
					state.autorizzazioniComunali.results.filter(
						(autorizzazioniComunali) =>
							autorizzazioniComunali.id != action.payload.id
					);
				state.autorizzazioniComunali.results.push(action.payload);

				state.autorizzazioniComunali.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			getAutorizzazioneComunaleFromIter.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");
				state.autorizzazioniComunali.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);

		// save AutorizzazioneComunale
		builder.addCase(saveAutorizzazioneComunale.pending, (state, action) => {
			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveAutorizzazioneComunale.fulfilled, (state, action) => {
			state.autorizzazioniComunali.results =
				state.autorizzazioniComunali.results.filter(
					(autorizzazioniComunali) =>
						autorizzazioniComunali.id != action.payload.id
				);
			state.autorizzazioniComunali.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Pratica AutorizzazioneComunale salvata.");
		});
		builder.addCase(saveAutorizzazioneComunale.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.autorizzazioniComunali.results =
				state.autorizzazioniComunali.results.map((autorizzazioniComunali) => {
					if (
						autorizzazioniComunali.id ==
						action.meta.arg.autorizzazioneComunaleToSave.id
					) {
						return {
							...autorizzazioniComunali,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return autorizzazioniComunali;
					}
				});
			state.autorizzazioniComunali.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = autorizzazioneComunaleSlice.actions;

export const autorizzazioneComunaleSliceReducer =
	autorizzazioneComunaleSlice.reducer;
