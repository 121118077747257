import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import {
	Progetto,
	SezioneProgetto,
} from "../../components/main/iterConnessioni/iterConnessione/progetti/progettiSlice";
import { RichiestaConnessione } from "components/main/iterConnessioni/iterConnessione/richiesteConnessione/richiesteConnessioneSlice";

const baseUrl = (richiestaConnessione_id?: number | null, azione?: string) => {
	if (richiestaConnessione_id) {
		let url = `${process.env.API_URL}/api/richieste_connessioni/${richiestaConnessione_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/richieste_connessioni/";
	}
};

export function fetchRichiesteConnessioni(
	statoRichiesta?: string,
	clienteIter?: number,
	operatore?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();

	if (
		statoRichiesta != undefined ||
		clienteIter != undefined ||
		operatore != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (statoRichiesta != undefined) {
		url += `stato_richiesta=${statoRichiesta}&`;
	}
	if (clienteIter != undefined) {
		url += `cliente=${clienteIter}&`;
	}
	if (operatore != undefined) {
		url += `operatore=${operatore}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getRichiestaConnessione(richiestaConnessioneId: number) {
	let url = baseUrl(richiestaConnessioneId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveRichiestaConnessione(
	richiestaConnessioneToSave: RichiestaConnessione,
	azione?: string
) {
	const data_invio = richiestaConnessioneToSave.data_invio
		? moment(richiestaConnessioneToSave.data_invio).format("YYYY-MM-DD")
		: null;
	const data_rigetto = richiestaConnessioneToSave.data_rigetto
		? moment(richiestaConnessioneToSave.data_rigetto).format("YYYY-MM-DD")
		: null;
	const data_conferma = richiestaConnessioneToSave.data_conferma
		? moment(richiestaConnessioneToSave.data_conferma).format("YYYY-MM-DD")
		: null;
	const data_ricezione_preventivo =
		richiestaConnessioneToSave.data_ricezione_preventivo
			? moment(richiestaConnessioneToSave.data_ricezione_preventivo).format(
					"YYYY-MM-DD"
			  )
			: null;
	const data_invio_accettazione_preventivo =
		richiestaConnessioneToSave.data_invio_accettazione_preventivo
			? moment(
					richiestaConnessioneToSave.data_invio_accettazione_preventivo
			  ).format("YYYY-MM-DD")
			: null;
	const richiestaConnessione = {
		...richiestaConnessioneToSave,
		data_invio: data_invio,
		data_conferma: data_conferma,
		data_ricezione_preventivo: data_ricezione_preventivo,
		data_invio_accettazione_preventivo: data_invio_accettazione_preventivo,
		data_rigetto: data_rigetto,
	};
	return fetch(baseUrl(richiestaConnessioneToSave.id, azione), {
		method: richiestaConnessioneToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...richiestaConnessione,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteRichiestaConnessione(
	richiestaConnessioneToDelete: RichiestaConnessione
) {
	return fetch(baseUrl(richiestaConnessioneToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////

export function fetchRichiesteConnessioneFromIter(iterId: number) {
	let url =
		process.env.API_URL +
		"/api/richieste_connessioni/richieste_connessione_from_iter/" +
		iterId +
		"/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
