import React, { useState, useEffect } from "react";
import { RootState, useAppDispatch } from "../../redux/store";

import { useForm, SubmitHandler } from "react-hook-form";
import { Allegato, TipoDocumento, uploadAllegato } from "./allegatiSlice";
import ReactHookFormSelect from "../common/ReactHookFormSelect";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

const limiteDimensioneFileSuperato = (size: number) => {
	if (size > 9000000) {
		return true;
	}
	return false;
};

interface AllegatiUploadFormProps {
	allegato: Allegato;
	tipiDocumento: TipoDocumento[];
	uploadAllegato: (allegato: Allegato, selectedFile: File | null) => void;
	uploading: boolean;
}

const AllegatiUploadForm = ({
	allegato,
	tipiDocumento,
	uploadAllegato,
	uploading,
}: AllegatiUploadFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Allegato>();

	const [scegliTipo, setScegliTipo] = useState<boolean>(
		!allegato.tipo_documento
	);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const listaCampi = Object.keys(allegato) as Array<keyof Allegato>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, allegato[field]);
		});
	}, [
		allegato.id,
		listaCampi.forEach((field) => {
			allegato[field];
		}),
		allegato.iter_connessione,
	]);

	const onSubmit: SubmitHandler<Allegato> = (allegato) => {
		selectedFile && uploadAllegato(allegato, selectedFile);
	};

	useEffect(() => {
		selectedFile && uploading == false && setSelectedFile(null);
		selectedFile && uploading == false && setValue("descrizione", "");
	}, [uploading]);

	const handleClose = () => setSelectedFile(null);

	return (
		<Box>
			{selectedFile ? (
				<Modal
					open={!!selectedFile}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Box
							sx={{
								position: "absolute" as "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								width: "70%",
								bgcolor: "background.paper",
								border: "2px solid #000",
								boxShadow: 24,
								pt: 2,
								px: 4,
								pb: 3,
							}}
						>
							<Grid
								container
								sx={{ flexGrow: 1, mb: 1 }}
								rowSpacing={1}
								columnSpacing={1}
								justifyContent="center"
								alignItems="flex-start"
							>
								<Grid item xs={6} sm={4} md={12}>
									<Typography variant="h5">
										{
											tipiDocumento.find((t) => t.id == allegato.tipo_documento)
												?.nome
										}
									</Typography>
									<Typography variant="caption">
										{
											tipiDocumento.find((t) => t.id == allegato.tipo_documento)
												?.descrizione
										}
									</Typography>
									{scegliTipo && (
										<ReactHookFormSelect
											name="tipo_documento"
											label="tipo documento"
											register={register}
											control={control}
											watch={watch}
											variant="outlined"
											required={true}
											error={errors?.tipo_documento?.message}
											sx={{ width: "100%" }}
										>
											{tipiDocumento.map((option) => {
												return (
													<MenuItem key={option.id} value={option.id}>
														{option.nome}
													</MenuItem>
												);
											})}
										</ReactHookFormSelect>
									)}
								</Grid>
								<Grid item xs={6} sm={4} md={8}>
									<TextField
										label="note"
										// disabled={schedaCarico.numero_capi_pianificati}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("descrizione")}
										error={!!errors?.descrizione}
										helperText={errors?.descrizione?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
									/>
								</Grid>
								<Grid item xs={6} sm={4} md={4}>
									{limiteDimensioneFileSuperato(selectedFile.size) && (
										<Alert severity="error">
											Attenzione File troppo grande!
										</Alert>
									)}
									<ButtonGroup
										orientation="vertical"
										aria-label="vertical outlined button group"
									>
										<Button
											variant="outlined"
											// component="label"
											// onClick={submitForm}
											type="submit"
											size="small"
											disabled={
												uploading ||
												limiteDimensioneFileSuperato(selectedFile.size)
											}
										>
											{uploading ? (
												<>
													in caricamento
													<CircularProgress size={12} />
												</>
											) : (
												<>{"Avvia Upload " + selectedFile.name}</>
											)}
										</Button>
										<Button
											variant="outlined"
											// component="label"
											onClick={() => {
												setSelectedFile(null);
											}}
											size="small"
											disabled={uploading}
										>
											Annulla
										</Button>
									</ButtonGroup>
								</Grid>
							</Grid>
						</Box>
					</form>
				</Modal>
			) : (
				<Button variant="outlined" component="label" size="small">
					Carica File
					<input
						name="file"
						style={{ padding: "10px" }}
						type="file"
						onChange={(e) =>
							setSelectedFile(e.target.files && e.target.files[0])
						}
						hidden
					/>
				</Button>
			)}
		</Box>
	);
};

export default AllegatiUploadForm;
