import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import { OfficinaElettrica } from "../../components/main/iterConnessioni/iterConnessione/officinaElettrica/officinaElettricaSlice";

const baseUrl = (officinaElettrica_id?: number | null, azione?: string) => {
	if (officinaElettrica_id) {
		let url = `${process.env.API_URL}/api/officina_elettrica/${officinaElettrica_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/officina_elettrica/";
	}
};

export function fetchOfficinaElettrica() {
	let url = baseUrl();
	// moment(data_da).format("YYYY-MM-DD");
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getOfficinaElettrica(officinaElettricaId: number) {
	let url = baseUrl(officinaElettricaId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveOfficinaElettrica(
	officinaElettricaToSave: OfficinaElettrica,
	azione?: string
) {
	const data_consegna = officinaElettricaToSave.data_consegna
		? moment(officinaElettricaToSave.data_consegna).format("YYYY-MM-DD")
		: null;
	const data_rilascio = officinaElettricaToSave.data_rilascio
		? moment(officinaElettricaToSave.data_rilascio).format("YYYY-MM-DD")
		: null;
	const officinaElettrica = {
		...officinaElettricaToSave,
		data_consegna: data_consegna,
		data_rilascio: data_rilascio,
	};
	return fetch(baseUrl(officinaElettricaToSave.id, azione), {
		method: officinaElettricaToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...officinaElettrica,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(allacci.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteOfficinaElettrica(
	officinaElettricaToDelete: OfficinaElettrica
) {
	return fetch(baseUrl(officinaElettricaToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////

export function getOfficinaElettricaFromIter(iterId: number) {
	let url =
		process.env.API_URL +
		"/api/officina_elettrica/officina_elettrica_from_iter/" +
		iterId +
		"/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////
