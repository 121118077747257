import { useAppDispatch, RootState } from "../../../../../../redux/store";

import {
	SezioneProgetto,
	TipoSchemaTypes,
	deleteSezione,
} from "../progettiSlice";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import { Typography } from "@mui/material";

interface SezioneCardProps {
	sezione: SezioneProgetto;
	consentiModifica: boolean;
}

const SezioneCard = ({
	sezione,
	consentiModifica = false,
}: SezioneCardProps) => {
	const dispatch = useAppDispatch();

	return (
		<Card sx={{ mb: 2 }}>
			<CardContent>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					spacing={2}
				>
					<Typography variant="h5" gutterBottom>
						{Object.entries(TipoSchemaTypes).find(
							([key, value]) => key == sezione.tipo_schema
						)?.[1] || "inserisci tipo schema"}
					</Typography>

					{consentiModifica && (
						<IconButton
							aria-label="delete"
							disabled={!consentiModifica}
							size="small"
							onClick={() => {
								dispatch(deleteSezione(sezione));
							}}
						>
							<DeleteIcon />
						</IconButton>
					)}
				</Stack>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					spacing={2}
				>
					<Grid container spacing={2}>
						{sezione.tipo_schema != "POS" && (
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Typography variant="h6" gutterBottom>
									Moduli Fotovoltaici
								</Typography>
								{sezione.potenza_generatore && (
									<>
										<Typography variant="caption" color="text.secondary">
											Potenza Generatore*:
										</Typography>
										<Typography variant="body1" gutterBottom>
											{Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 2,
											}).format(sezione.potenza_generatore || 0)}{" "}
											kWp
										</Typography>
									</>
								)}
							</Grid>
						)}
						{sezione.tipo_schema != "POS" && (
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Typography variant="h6" gutterBottom>
									Inverter
								</Typography>
								{sezione.potenza_inverter && (
									<>
										<Typography variant="caption" color="text.secondary">
											Potenza Inverter*:
										</Typography>
										<Typography variant="body1" gutterBottom>
											{Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 2,
											}).format(sezione.potenza_inverter || 0)}{" "}
											kW
										</Typography>
									</>
								)}
								{sezione.contrib_ICC && (
									<>
										<Typography variant="caption" color="text.secondary">
											Contributo ICC*:
										</Typography>
										<Typography variant="body1" gutterBottom>
											{Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 2,
											}).format(sezione.contrib_ICC || 0)}{" "}
											A
										</Typography>
									</>
								)}
								{sezione.potenza_cc_inverter && (
									<>
										<Typography variant="caption" color="text.secondary">
											Potenza CC:
										</Typography>
										<Typography variant="body1" gutterBottom>
											{Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 2,
											}).format(sezione.potenza_cc_inverter || 0)}{" "}
											kW
										</Typography>
									</>
								)}
							</Grid>
						)}
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography variant="h6" gutterBottom>
								Accumulo
							</Typography>
							{sezione.potenza_nominale_sda && (
								<>
									<Typography variant="caption" color="text.secondary">
										Potenza Nominale SDA*:
									</Typography>
									<Typography variant="body1" gutterBottom>
										{Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
										}).format(sezione.potenza_nominale_sda || 0)}{" "}
										kW
									</Typography>
								</>
							)}
							{sezione.potenza_cc_sda && (
								<>
									<Typography variant="caption" color="text.secondary">
										Potenza CC SDA*:
									</Typography>
									<Typography variant="body1" gutterBottom>
										{Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
										}).format(sezione.potenza_cc_sda || 0)}{" "}
										kW
									</Typography>
								</>
							)}
							{sezione.capacita && (
								<>
									<Typography variant="caption" color="text.secondary">
										Capacità*:
									</Typography>
									<Typography variant="body1" gutterBottom>
										{Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
										}).format(sezione.capacita || 0)}{" "}
										kWh
									</Typography>
								</>
							)}
							{sezione.tensione_nominale_sda && (
								<>
									<Typography variant="caption" color="text.secondary">
										Tensione Nominale SDA*:
									</Typography>
									<Typography variant="body1" gutterBottom>
										{Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
										}).format(sezione.tensione_nominale_sda || 0)}{" "}
										V
									</Typography>
								</>
							)}
						</Grid>
					</Grid>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default SezioneCard;
