import { handleResponse, handleError, headerset } from "../apiUtils";
import { AllegatoAdempimenti } from "../../components/main/adempimenti/allegatiAdempimenti/allegatiAdempimentiSlice";

// const baseUrl = (
// 	adempimento_id?: number | null,
// 	tipo_documento_id?: number | null
// ) => {
// 	let baseUrl = process.env.API_URL + "/api/adempimenti/allegati_list/";
// 	if (adempimento_id) {
// 		baseUrl += "?adempimento_id=" + adempimento_id;
// 		if (tipo_documento_id) {
// 			baseUrl += "&tipo_documento_id=" + tipo_documento_id;
// 		}
// 	} else {
// 		if (tipo_documento_id) {
// 			baseUrl += "?tipo_documento_id=" + tipo_documento_id;
// 		}
// 	}
// 	return baseUrl;
// };

export function fetchAllegati(
	adempimento_id?: number | null,
	tipo_documento_id?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = process.env.API_URL + "/api/adempimenti/allegati_list/";
	if (
		adempimento_id != undefined ||
		tipo_documento_id != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (tipo_documento_id != undefined) {
		url += `tipo_documento=${tipo_documento_id}&`;
	}
	if (adempimento_id != undefined) {
		url += `iter_connessione=${adempimento_id}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteAllegatoAdempimenti(
	allegatoToDelete: AllegatoAdempimenti
) {
	const url =
		process.env.API_URL +
		"/api/adempimenti/allegato/" +
		allegatoToDelete.id +
		"/";
	return fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

const uploadBaseUrl = (
	nomeFile: string,
	descrizione?: string,
	tipo_documento?: number | null,
	adempimento_id?: number | null
) => {
	let uploadBaseUrl = process.env.API_URL + "/api/adempimenti/allegati_list/";
	if (adempimento_id) {
		uploadBaseUrl = `${process.env.API_URL}/api/adempimenti/upload/adempimento/${adempimento_id}/${tipo_documento}/${nomeFile}`;
		if (descrizione) {
			uploadBaseUrl += "?descrizione=" + descrizione;
		}
	} else {
		return process.env.API_URL + "/api/adempimenti/allegati_list/";
	}
	return uploadBaseUrl;
};

export function uploadAllegatoAdempimenti(
	allegato: AllegatoAdempimenti,

	file: File
) {
	console.log("allegato=", allegato);
	return fetch(
		uploadBaseUrl(
			file.name,
			allegato.descrizione,
			allegato.tipo_documento,
			allegato.adempimento
		),
		{
			method: "PUT", // PUT to upload.
			headers: {
				"content-type": "application/json",
				Authorization: headerset().Authorization,
			},
			body: file,
		}
	)
		.then(handleResponse)
		.catch(handleError);
}

// #######################################################################

const tipiDocumentoUrl =
	process.env.API_URL + "/api/adempimenti/tipo_documento_list/";

export function fetchTipiDocumento() {
	return fetch(tipiDocumentoUrl, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
