import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../common/errorsDeclarations";

import * as allegatiApi from "../../api/allegati/allegatiApi";
import moment from "moment";
import { toast } from "react-toastify";
import { getIterConnessione } from "../main/iterConnessioni/iterConnessioneSlice";
import { getProgettoFromIter } from "../main/iterConnessioni/iterConnessione/progetti/progettiSlice";
import { getRichiestaConnessione } from "../main/iterConnessioni/iterConnessione/richiesteConnessione/richiesteConnessioneSlice";
import { getFineLavoro } from "../main/iterConnessioni/iterConnessione/richiesteConnessione/fineLavori/fineLavoriSlice";
import { getAllaccio } from "../main/iterConnessioni/iterConnessione/richiesteConnessione/allaccio/allaccioSlice";
import { getEnea } from "../main/iterConnessioni/iterConnessione/enea/eneaSlice";
import { getOfficinaElettrica } from "../main/iterConnessioni/iterConnessione/officinaElettrica/officinaElettricaSlice";
import { getAutorizzazioneComunale } from "../main/iterConnessioni/iterConnessione/autorizzazioneComunale/autorizzazioneComunaleSlice";
import {
	getSopralluogo,
	getSopralluogoElettrico,
} from "../main/sopralluoghi/sopralluoghiSlice";

moment.locale("it");

export interface TipoDocumento {
	id: number;
	nome: string;
	descrizione?: string;
}
export interface TipiDocumento {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoDocumento[];
}

export interface Allegato {
	id: number | null;
	tipo_documento: number | null;
	tipo_documento_des?: TipoDocumento;
	descrizione: string;
	iter_connessione?: number | null;
	progetto?: number | null;
	richiesta_connessione?: number | null;
	fine_lavori?: number | null;
	allaccio?: number | null;
	enea?: number | null;
	officina_elettrica?: number | null;
	autorizzazione_comunale?: number | null;
	sopralluogo?: number | null;
	sopralluogo_elettrico?: number | null;
	autore: string;
	file: string;
	file_name: string;
	data_documento?: Date | string;
}

export interface Allegati {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Allegato[];
	errorsStack: ErrorsStack;
}

export enum TipoObbligoTypes {
	OI = "Obbligatorio per iniziare",
	OC = "Obbligatorio per completare",
}

export interface DocumentiPerTipo {
	tipo_id: number | null;
	tipo: string;
	descrizione?: string;
	modificabile: boolean;
	obbligo_tipo: boolean;
	tipo_obbligo: "OI" | "OC";
	casi_obbligo: "PF" | "PG";
	allegati_presenti: Allegato[];
}

export interface AllegatiState {
	allegato: Allegato;
	errorsStack: ErrorsStack;
}

export interface AllegatiStrutturaState {
	tipiDocumento: TipiDocumento;
	allegati: Allegati;
	allegatoCorrente: AllegatiState;
}

const initialState: AllegatiStrutturaState = {
	tipiDocumento: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	allegati: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	allegatoCorrente: {
		allegato: {
			id: null,
			tipo_documento: null,
			descrizione: "",
			iter_connessione: null,
			autore: "",
			file: "",
			file_name: "",
		},
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchTipiDocumento = createAsyncThunk(
	"allegato/fetchTipiDocumento",
	async () => {
		return await allegatiApi.fetchTipiDocumento();
		//   return user;
	}
);
export const fetchAllegati = createAsyncThunk(
	"allegato/fetchAllegati",
	async (filtriAllegati: {
		iterConnessione_id?: number | null;
		tipo_documento_id?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await allegatiApi.fetchAllegati(
			filtriAllegati.iterConnessione_id,
			filtriAllegati.tipo_documento_id,
			filtriAllegati.numeroRecord,
			filtriAllegati.page,
			filtriAllegati.search
		);
		//   return user;
	}
);

export const uploadAllegato = createAsyncThunk(
	"allegato/uploadAllegato",
	async (
		parametriAllegato: {
			allegato: Allegato;
			file: File;
		},
		thunkApi
	) => {
		const iterConnessione_id = parametriAllegato.allegato.iter_connessione;
		const richiestaConnessione_id =
			parametriAllegato.allegato.richiesta_connessione;
		const fine_lavori_id = parametriAllegato.allegato.fine_lavori;
		const allaccio_id = parametriAllegato.allegato.allaccio;
		const enea_id = parametriAllegato.allegato.enea;
		const officina_elettrica_id = parametriAllegato.allegato.officina_elettrica;
		const autorizzazione_comunale_id =
			parametriAllegato.allegato.autorizzazione_comunale;
		const sopralluogo_id = parametriAllegato.allegato.sopralluogo;
		const sopralluogoElettrico_id =
			parametriAllegato.allegato.sopralluogo_elettrico;

		return await allegatiApi
			.uploadAllegato(parametriAllegato.allegato, parametriAllegato.file)
			.then((response) => {
				iterConnessione_id &&
					thunkApi.dispatch(getIterConnessione(iterConnessione_id));
				iterConnessione_id &&
					thunkApi.dispatch(getProgettoFromIter(iterConnessione_id));
				richiestaConnessione_id &&
					thunkApi.dispatch(getRichiestaConnessione(richiestaConnessione_id));
				fine_lavori_id && thunkApi.dispatch(getFineLavoro(fine_lavori_id));
				allaccio_id && thunkApi.dispatch(getAllaccio(allaccio_id));
				enea_id && thunkApi.dispatch(getEnea(enea_id));
				officina_elettrica_id &&
					thunkApi.dispatch(getOfficinaElettrica(officina_elettrica_id));
				autorizzazione_comunale_id &&
					thunkApi.dispatch(
						getAutorizzazioneComunale(autorizzazione_comunale_id)
					);
				sopralluogo_id && thunkApi.dispatch(getSopralluogo(sopralluogo_id));
				sopralluogoElettrico_id &&
					thunkApi.dispatch(getSopralluogoElettrico(sopralluogoElettrico_id));
				return response;
			});
	}
);

export const deleteAllegato = createAsyncThunk(
	"schedaCarico/deleteAllegato",
	async (allegatoToDelete: Allegato, thunkApi) => {
		const iterConnessione_id = allegatoToDelete.iter_connessione;

		const richiestaConnessione_id = allegatoToDelete.richiesta_connessione;
		const fine_lavori_id = allegatoToDelete.fine_lavori;
		const allaccio_id = allegatoToDelete.allaccio;
		const enea_id = allegatoToDelete.enea;
		const officina_elettrica_id = allegatoToDelete.officina_elettrica;
		const autorizzazione_comunale_id = allegatoToDelete.autorizzazione_comunale;
		const sopralluogo_id = allegatoToDelete.sopralluogo;
		const sopralluogoElettrico_id = allegatoToDelete.sopralluogo_elettrico;

		return await allegatiApi
			.deleteAllegato(allegatoToDelete)
			.then((response) => {
				iterConnessione_id &&
					thunkApi.dispatch(getIterConnessione(iterConnessione_id));

				iterConnessione_id &&
					thunkApi.dispatch(getProgettoFromIter(iterConnessione_id));
				richiestaConnessione_id &&
					thunkApi.dispatch(getRichiestaConnessione(richiestaConnessione_id));
				fine_lavori_id && thunkApi.dispatch(getFineLavoro(fine_lavori_id));
				allaccio_id && thunkApi.dispatch(getAllaccio(allaccio_id));
				enea_id && thunkApi.dispatch(getEnea(enea_id));

				officina_elettrica_id &&
					thunkApi.dispatch(getOfficinaElettrica(officina_elettrica_id));

				autorizzazione_comunale_id &&
					thunkApi.dispatch(
						getAutorizzazioneComunale(autorizzazione_comunale_id)
					);

				sopralluogo_id && thunkApi.dispatch(getSopralluogo(sopralluogo_id));
				sopralluogoElettrico_id &&
					thunkApi.dispatch(getSopralluogoElettrico(sopralluogoElettrico_id));

				return response;
			});
	}
);

export const allegatiSlice = createSlice({
	name: "allegatiSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch tipiDocumento
		builder.addCase(fetchTipiDocumento.pending, (state, action) => {
			// state.tipiDocumento.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchTipiDocumento.fulfilled, (state, action) => {
			state.tipiDocumento = action.payload;
			// state.tipiDocumento.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchTipiDocumento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			// state.tipiDocumento.errorsStack = {
			//   status: ErrorStatusTypes.ERROR,
			//   fieldsErrors: JSON.parse(action?.error?.message || ""),
			// };
		});
		// fetch Allegati
		builder.addCase(fetchAllegati.pending, (state, action) => {
			state.allegati.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchAllegati.fulfilled, (state, action) => {
			// state.allegati.results = [
			// 	...state.allegati.results.filter(
			// 		(allegato) =>
			// 			allegato.iter_connessione != action.meta.arg.iterConnessione_id
			// 	),
			// 	...action.payload.results,
			// ];
			state.allegati = action.payload;

			state.allegati.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchAllegati.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegati.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch Allegati
		builder.addCase(deleteAllegato.pending, (state, action) => {
			state.allegati.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteAllegato.fulfilled, (state, action) => {
			state.allegati.results = state.allegati.results.filter(
				(allegato) => allegato.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Allegato cancellato.");
			state.allegati.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(deleteAllegato.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegati.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// carica Documento
		builder.addCase(uploadAllegato.pending, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(uploadAllegato.fulfilled, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Allegato inserito.");
		});
		builder.addCase(uploadAllegato.rejected, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.ERROR };
			toast.error("Errore:" + action?.error?.message || "");
			try {
				const errorMessage = JSON.parse(
					(action?.error?.message || "").replace(/^Error: /, "")
				);

				state.allegatoCorrente.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: errorMessage,
				};
			} catch {
				console.log("json errato = ", action?.error?.message);
			}
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = allegatiSlice.actions;

export const allegatiSliceReducer = allegatiSlice.reducer;
