import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
	IterConnessione,
	presaInCarico,
	avviaProgetto,
	assegnaPratica,
} from "components/main/iterConnessioni/iterConnessioneSlice";

import moment from "moment";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import {
	OfficinaElettrica,
	saveOfficinaElettrica,
	StatoOfficinaElettricaTypes,
} from "./officinaElettricaSlice";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Alert, Typography } from "@mui/material";

interface AzioniOfficinaElettricaCardProps {
	iterConnessione: IterConnessione;
	officinaElettrica: OfficinaElettrica;
}

const AzioniOfficinaElettricaCard = ({
	iterConnessione,
	officinaElettrica,
}: AzioniOfficinaElettricaCardProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const [formRigetto, setFormRigetto] = useState<boolean>(false);

	return (
		<Card sx={{ mt: 2, backgroundColor: "#ddd" }}>
			<CardContent>
				<Stack sx={{ width: "100%" }} spacing={2}>
					<Alert variant="filled" severity="info">
						Pratica{" "}
						{
							Object.entries(StatoOfficinaElettricaTypes).find(
								([key, value]) => key == officinaElettrica.stato
							)?.[1]
						}
					</Alert>
					{officinaElettrica.stato == "A" && (
						<Button
							variant="contained"
							size="large"
							disabled={!officinaElettrica?.completo}
							sx={{ width: "100%", mt: 1 }}
							onClick={() => {
								dispatch(
									saveOfficinaElettrica({
										officinaElettricaToSave: officinaElettrica,
										azione: "concludi_officina_elettrica",
									})
								);
							}}
						>
							Segna come conclusa la fase Officina Elettrica
						</Button>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default AzioniOfficinaElettricaCard;
