import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
	IterConnessione,
	presaInCarico,
	avviaProgetto,
	assegnaPratica,
} from "components/main/iterConnessioni/iterConnessioneSlice";

import moment from "moment";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface AzioniEneaCardProps {
	iterConnessione: IterConnessione;
}

const AzioniEneaCard = ({ iterConnessione }: AzioniEneaCardProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const [formRigetto, setFormRigetto] = useState<boolean>(false);

	return (
		<Card sx={{ mt: 2, backgroundColor: "#ddd" }}>
			<CardContent>
				{["F"].includes(iterConnessione.stato) && (
					<Stack sx={{ width: "100%" }} spacing={2}>
						<Button
							variant="contained"
							size="large"
							// disabled={!progetto?.progetto_completo}
							sx={{ width: "100%", mt: 1 }}
							// onClick={() => {
							// 	progetto?.id && dispatch(chiudiProgetto(progetto));
							// }}
						>
							Segna come conclusa la fase "enea"
						</Button>
					</Stack>
				)}
			</CardContent>
		</Card>
	);
};

export default AzioniEneaCard;
