import { useEffect, useState } from "react";
import { IterConnessione, SegnalazioneRitardo } from "../iterConnessioneSlice";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import { PermissionTypes } from "../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import {
	utenteTokenRefresh,
	UtenteLogged,
} from "../../../authentication/authenticationSlice";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

interface RitardoChipProps {
	ritardo: SegnalazioneRitardo;
}

const RitardoChip = (ritardo?: SegnalazioneRitardo) => {
	if (ritardo && ritardo.allarme) {
		return (
			<Tooltip title={ritardo.descrizione}>
				<Chip
					label={`${ritardo.ritardo} gg`}
					color={ritardo.ritardo > 0 ? "error" : "warning"}
					variant="filled"
					icon={<ReportGmailerrorredIcon />}
				/>
			</Tooltip>
		);
	}
};

interface IterConnessioneAlerChipProps {
	iterConnessione?: IterConnessione;
}

const IterConnessioneAlerChip = ({
	iterConnessione,
}: IterConnessioneAlerChipProps) => {
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	utenteAutorizzato(utente, [
		PermissionTypes.GESTORI_ITER,
		PermissionTypes.OPERATORI,
		PermissionTypes.PROGETTISTI,
	]);
	return (
		<Box>
			{RitardoChip(iterConnessione?.scadenza_presa_carico)}
			{RitardoChip(iterConnessione?.scadenza_progetto)}
			{RitardoChip(iterConnessione?.scadenza_invio_richiesta)}
			{RitardoChip(iterConnessione?.scadenza_ricezione_preventivo)}
			{RitardoChip(iterConnessione?.scadenza_accettazione_preventivo)}
			{RitardoChip(iterConnessione?.scadenza_inoltro_accettazione_preventivo)}
			{RitardoChip(iterConnessione?.scadenza_inoltro_regolamento)}
			{RitardoChip(iterConnessione?.scadenza_conferma_regolamento)}
			{RitardoChip(iterConnessione?.scadenza_allaccio)}
			{RitardoChip(iterConnessione?.scadenza_richiesta_gse)}
			{RitardoChip(iterConnessione?.scadenza_attivazione_gse)}
		</Box>
	);
};

export default IterConnessioneAlerChip;
