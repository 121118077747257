import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
} from "../../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../../common/errorsDeclarations";

import { theme } from "../../../../../theme";

import { utenteAutorizzato } from "../../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../../../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ProdottoCard from "./ProdottoCard";
import ProdottoForm from "./ProdottoForm";
import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";

import { Button, Divider } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";

interface IterConnessioneDistintaProdottiBoxProps {
	iterConnessione: IterConnessione;
	produttore: Produttore;
	consentiModifica: boolean;
	preEsistente: boolean;
}

const IterConnessioneDistintaProdottiBox = ({
	iterConnessione,
	produttore,
	consentiModifica = false,
	preEsistente,
}: IterConnessioneDistintaProdottiBoxProps) => {
	const dispatch = useAppDispatch();

	const [openInputProdotto, setOpenInputProdotto] = useState(false);
	const [tipoProdotto, setTipoProdotto] = useState<
		"MF" | "IN" | "SA" | undefined
	>(undefined);
	const handleClosInputProdotto = () => {
		setTipoProdotto(undefined);
		setOpenInputProdotto(false);
	};
	return (
		<Box sx={{ mb: 1 }}>
			{tipoProdotto && (
				<Modal
					open={openInputProdotto}
					onClose={handleClosInputProdotto}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box
						sx={{
							position: "absolute" as "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "70%",
							bgcolor: "background.paper",
							border: "2px solid #000",
							boxShadow: 24,
							pt: 2,
							px: 4,
							pb: 3,
						}}
					>
						<ProdottoForm
							iterConnessione={iterConnessione}
							tipoProdotto={tipoProdotto}
							handleClosInputProdotto={handleClosInputProdotto}
							preEsistente={preEsistente}
						/>
					</Box>
				</Modal>
			)}
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={6} md={6} lg={4}>
					<Typography variant="h6" gutterBottom>
						Moduli Fotovoltaici
						{consentiModifica && (
							<IconButton
								aria-label="fingerprint"
								color="primary"
								onClick={() => {
									setTipoProdotto("MF");
									setOpenInputProdotto(true);
								}}
								sx={{ m: 0, ml: 1, p: 0 }}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						)}
					</Typography>
					<Stack>
						{iterConnessione.prodotti
							?.filter(
								(prodotto) =>
									prodotto.tipo_prodotto == "MF" &&
									prodotto.pre_esistente == preEsistente
							)
							.map((prodotto) => (
								<ProdottoCard
									key={"prodotto_" + prodotto.id}
									prodotto={prodotto}
									consentiModifica={consentiModifica}
								/>
							))}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4}>
					<Typography variant="h6" gutterBottom>
						Inverter
						{consentiModifica && (
							<IconButton
								aria-label="fingerprint"
								color="primary"
								onClick={() => {
									setTipoProdotto("IN");
									setOpenInputProdotto(true);
								}}
								sx={{ m: 0, ml: 1, p: 0 }}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						)}
					</Typography>
					<Stack>
						{iterConnessione.prodotti
							?.filter(
								(prodotto) =>
									prodotto.tipo_prodotto == "IN" &&
									prodotto.pre_esistente == preEsistente
							)
							.map((prodotto) => (
								<ProdottoCard
									key={"prodotto_" + prodotto.id}
									prodotto={prodotto}
									consentiModifica={consentiModifica}
								/>
							))}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={4}>
					<Typography variant="h6" gutterBottom>
						Sistemi di accumulo
						{consentiModifica && (
							<IconButton
								aria-label="fingerprint"
								color="primary"
								onClick={() => {
									setTipoProdotto("SA");
									setOpenInputProdotto(true);
								}}
								sx={{ m: 0, ml: 1, p: 0 }}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						)}
					</Typography>
					<Stack>
						{iterConnessione.prodotti
							?.filter(
								(prodotto) =>
									prodotto.tipo_prodotto == "SA" &&
									prodotto.pre_esistente == preEsistente
							)
							.map((prodotto) => (
								<ProdottoCard
									key={"prodotto_" + prodotto.id}
									prodotto={prodotto}
									consentiModifica={consentiModifica}
								/>
							))}
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneDistintaProdottiBox;
