import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import {
	IterConnessione,
	Prodotto,
} from "../../components/main/iterConnessioni/iterConnessioneSlice";
import { OrdinamentoLista } from "../../components/userPreference/userPreferenceSlice";

const baseUrl = (iterConnessione_id?: number | null) => {
	if (iterConnessione_id) {
		return (
			process.env.API_URL +
			"/api/iter_connessioni/iter/" +
			iterConnessione_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/iter_connessioni/iter/";
	}
};

export function fetchIterConnessioni(
	statoIter?: string,
	clienteIter?: number,
	iterSemplificato?: string,
	operatore?: number,
	progettista?: number,
	numeroRecord?: number,
	page?: number,
	search?: string,
	ordinamento?: OrdinamentoLista
) {
	let url = baseUrl();
	// moment(data_da).format("YYYY-MM-DD");

	if (
		statoIter != undefined ||
		clienteIter != undefined ||
		iterSemplificato != undefined ||
		operatore != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined ||
		ordinamento != undefined
	) {
		url += "?";
	}
	if (statoIter != undefined) {
		url += `stato_iter=${statoIter}&`;
	}
	if (clienteIter != undefined) {
		url += `cliente=${clienteIter}&`;
	}
	if (iterSemplificato != undefined) {
		url += `iter_semplificato=${iterSemplificato}&`;
	}
	if (operatore != undefined) {
		url += `operatore=${operatore}&`;
	}
	if (progettista != undefined) {
		url += `progettista=${progettista}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	if (ordinamento != undefined) {
		if (ordinamento.asc) {
			url += `ordina_asc=${ordinamento.campo}`;
		} else {
			url += `ordina_desc=${ordinamento.campo}`;
		}
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getIterConnessione(iterConnessioneId: number) {
	let url = baseUrl(iterConnessioneId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveIterConnessione(
	iterConnessioneToSave: IterConnessione,
	richiedeIntegrazioni?: boolean,
	azione?: string
) {
	console.log("iterConnessioneToSave", iterConnessioneToSave);
	console.log("azione==", azione);
	let url = baseUrl(iterConnessioneToSave.id);
	if (!!richiedeIntegrazioni) {
		url = url + `?richiede_integrazioni=true`;
	} else if (!!azione) {
		url = url + `?azione=${azione}`;
	}
	const usufruttore_data_nascita =
		iterConnessioneToSave.usufruttore_data_nascita
			? moment(iterConnessioneToSave.usufruttore_data_nascita).format(
					"YYYY-MM-DD"
			  )
			: null;
	const iterConnessione = {
		...iterConnessioneToSave,
		usufruttore_data_nascita: usufruttore_data_nascita,
	};
	return fetch(url, {
		method: iterConnessioneToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...iterConnessione,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteIterConnessione(
	iterConnessioneToDelete: IterConnessione
) {
	return fetch(baseUrl(iterConnessioneToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveProdotto(
	prodottoToSave: Prodotto,
	iterconnessioneId: number
) {
	const url =
		process.env.API_URL +
		"/api/iter_connessioni/iter/" +
		iterconnessioneId +
		"/" +
		"aggiungi_prodotto/";
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...prodottoToSave,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteProdotto(prodottoToDelete: Prodotto) {
	const url =
		process.env.API_URL +
		"/api/iter_connessioni/prodotto/" +
		prodottoToDelete.id +
		"/";
	return fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function richiestaPresaInCarico(iterConnessioneToOwn: IterConnessione) {
	const url = `${process.env.API_URL}/api/iter_connessioni/iter/${iterConnessioneToOwn.id}/richiesta_presa_in_carico/`;
	return fetch(url, {
		method: "PUT", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function presaInCarico(iterConnessioneToOwn: IterConnessione) {
	const url = `${process.env.API_URL}/api/iter_connessioni/iter/${iterConnessioneToOwn.id}/presa_in_carico/`;
	return fetch(url, {
		method: "PUT", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function assegnaPratica(iterConnessioneToOwn: IterConnessione) {
	const url = `${process.env.API_URL}/api/iter_connessioni/iter/${iterConnessioneToOwn.id}/assegna_pratica/`;
	return fetch(url, {
		method: "PUT", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function avviaProgetto(iterConnessioneToOwn: IterConnessione) {
	const url = `${process.env.API_URL}/api/iter_connessioni/iter/${iterConnessioneToOwn.id}/avvio_progetto/`;
	return fetch(url, {
		method: "PUT", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
