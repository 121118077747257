import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as iterConnessioniApi from "../../../api/iterConnessioni/iterConnessioniApi";
import moment from "moment";
import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../allegati/allegatiSlice";
import { OrdinamentoLista } from "components/userPreference/userPreferenceSlice";
// import { useDispatch } from "react-redux";

moment.locale("it");

export enum TipoTensioneTypes {
	BTM = "bassa tensione 230V",
	BTT = "bassa tensione 400V",
	MT = "media tensione 20000V",
}
export enum TitoloPossessoTypes {
	A = "proprietario o comproprietario",
	B = "detentore o codetentore (locatore, comodatario, ecc.)",
	C = "familiare convivente con il possessore o il detentore",
}
export enum TipologiaEdiliziaTypes {
	A = "casa singola",
	B = "bifamiliare",
	C = "trifamiliare",
	D = "edificio a schiera e condominio fino a tre piani",
	E = "edificio in linea e condominio oltre i tre piani fuori terra",
	F = "altro specificare nelle note",
}

export interface Elemento {
	id: number | null | undefined;
	iterConnessione: number;
	codice: string;
	descrizione: string;
	errorsStack?: ErrorsStack;
}

export enum TipoProdottoTypes {
	MF = "Moduli Fotovoltaici",
	IN = "Inverter",
	SA = "Sistema Accumulo",
}

export interface Prodotto {
	id: number | null | undefined;
	iterConnessione: number;
	tipo_prodotto: "MF" | "IN" | "SA";
	pre_esistente: boolean;
	marca?: string;
	modello?: string;
	quantita?: number;
	potenza_unitaria?: number;
	capacita_singola_batteria?: number;
	capacita_totale?: number;
}

export interface SegnalazioneRitardo {
	giorni_lt: number;
	allarme: boolean;
	ritardo: number;
	descrizione: string;
}
export interface IterConnessione {
	id?: number | null | undefined;
	iter_semplificato: boolean;

	tipo_servizio?: number;
	tipo_pratica?: string;
	super_bonus: boolean;
	ampliamento?: boolean;
	altro_pod?: boolean;
	commessa?: string;
	cliente?: number;
	installatore?: number;
	produttore: number;
	produttore_descrizione: string;
	stato: string;
	stato_richiesta_connessione?: string;
	iban_iter_connessione?: string;
	iban_accredito_gse?: string;
	indirizzo?: string;
	numero_civico?: string;
	cap?: string;
	comune?: number;
	provincia?: string;

	usufruttore_nome?: string;
	usufruttore_cognome?: string;
	usufruttore_codice_fiscale?: string;
	usufruttore_comune_nascita?: number;
	usufruttore_data_nascita?: string | Date;
	usufruttore_indirizzo?: string;
	usufruttore_numero_civico?: string;
	usufruttore_cap?: string;
	usufruttore_comune?: number;

	note_esterne?: string;
	operatore?: number;
	progettista?: number;
	note_non_ricevibile?: string;
	note_interne?: string;
	dati_generali_completo?: {
		dati_generali_completo: boolean;
		dati_mancanti: string;
	};
	assegnabile?: {
		assegnabile: boolean;
		dati_mancanti: string;
	};
	progetto_avviabilie?: {
		progetto_avviabilie: boolean;
		dati_mancanti: string;
	};
	data_richiesta_presa_carico?: string | Date;
	data_presa_carico?: string | Date;
	data_assegnazione?: string | Date;
	data_avvio_pratica?: string | Date;
	data_progetto?: string | Date;
	data_conferma_richiesta_connessione?: string | Date;
	data_conclusione_fine_lavori?: string | Date;
	data_conclusione_allaccio?: string | Date;
	data_consegna?: string | Date;
	data_ultimo_stato?: string | Date;

	scadenza_presa_carico?: SegnalazioneRitardo;
	scadenza_progetto?: SegnalazioneRitardo;

	scadenza_invio_richiesta?: SegnalazioneRitardo;

	scadenza_ricezione_preventivo?: SegnalazioneRitardo;

	scadenza_accettazione_preventivo?: SegnalazioneRitardo;

	scadenza_inoltro_accettazione_preventivo?: SegnalazioneRitardo;
	scadenza_inoltro_regolamento?: SegnalazioneRitardo;
	scadenza_conferma_regolamento?: SegnalazioneRitardo;
	scadenza_allaccio?: SegnalazioneRitardo;
	scadenza_richiesta_gse?: SegnalazioneRitardo;
	scadenza_attivazione_gse?: SegnalazioneRitardo;
	ritardo?: number;
	potenza_disponibile?: number;
	tipo_tensione?: TipoTensioneTypes;
	gestore_rete?: number;
	codice_pod?: string;
	pod_id?: number;
	regime_commerciale?: number;
	foglio_catastale?: string;
	particella_catastale?: string;
	sub_particella_catastale?: string;

	superficie_calpestabile?: number;
	numero_unita_immobiliari?: number;
	anno_costruzione?: number;
	destinazione_residenziale?: boolean;
	titolo_possesso?: TitoloPossessoTypes;
	tipologia_edilizia?: TipologiaEdiliziaTypes;
	tipologia_edilizia_note?: string;

	prodotti?: Prodotto[];
	listaDocumentiPerTipo?: DocumentiPerTipo[];
	errorsStack?: ErrorsStack;
}
export interface IterConnessioniState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: IterConnessione[];
	errorsStack: ErrorsStack;
}
export interface IterConnessioniStrutturaState {
	iterConnessioni: IterConnessioniState;
	nuovoIterConnessioneId?: number;
}

const initialState: IterConnessioniStrutturaState = {
	iterConnessioni: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchIterConnessioni = createAsyncThunk(
	"iterConnessione/fetchIterConnessioni",
	async (parametri: {
		statoIter?: string;
		clienteIter?: number;
		iterSemplificato?: string;
		operatore?: number;
		progettista?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
		ordinamento?: OrdinamentoLista | undefined;
	}) => {
		return await iterConnessioniApi.fetchIterConnessioni(
			parametri.statoIter,
			parametri.clienteIter,
			parametri.iterSemplificato,
			parametri.operatore,
			parametri.progettista,
			parametri.numeroRecord,
			parametri.page,
			parametri.search,
			parametri.ordinamento
		);
	}
);

export const getIterConnessione = createAsyncThunk(
	"iterConnessione/getIterConnessione",
	async (iterConnessioneId: number) => {
		return await iterConnessioniApi.getIterConnessione(iterConnessioneId);
	}
);

export const saveIterConnessione = createAsyncThunk(
	"iterConnessione/saveIterConnessione",
	async (
		parametri: {
			iterConnessioneToSave: IterConnessione;
			richiedeIntegrazioni?: boolean;
			azione?: string;
		},
		thunkApi
	) => {
		// il codice_pod deve essere maiuscolo

		const iterConnessioneToSave = {
			...parametri.iterConnessioneToSave,
			codice_pod: parametri.iterConnessioneToSave.codice_pod?.toUpperCase(),
		};

		return await iterConnessioniApi.saveIterConnessione(
			parametri.iterConnessioneToSave,
			parametri.richiedeIntegrazioni,
			parametri.azione
		);
	}
);

export const deleteIterConnessione = createAsyncThunk(
	"iterConnessione/deleteIterConnessione",
	async (iterConnessioneToDelete: IterConnessione, thunkApi) => {
		return await iterConnessioniApi.deleteIterConnessione(
			iterConnessioneToDelete
		);
	}
);

export const richiestaPresaInCarico = createAsyncThunk(
	"iterConnessione/richiestaPresaInCarico",
	async (iterConnessioneToSave: IterConnessione, thunkApi) => {
		return await iterConnessioniApi.richiestaPresaInCarico(
			iterConnessioneToSave
		);
	}
);

export const presaInCarico = createAsyncThunk(
	"iterConnessione/presaInCarico",
	async (iterConnessioneToSave: IterConnessione, thunkApi) => {
		return await iterConnessioniApi.presaInCarico(iterConnessioneToSave);
	}
);
export const assegnaPratica = createAsyncThunk(
	"iterConnessione/assegnaPratica",
	async (iterConnessioneToSave: IterConnessione, thunkApi) => {
		return await iterConnessioniApi.assegnaPratica(iterConnessioneToSave);
	}
);

export const avviaProgetto = createAsyncThunk(
	"iterConnessione/avviaProgetto",
	async (iterConnessioneToSave: IterConnessione, thunkApi) => {
		return await iterConnessioniApi.avviaProgetto(iterConnessioneToSave);
	}
);

export const saveProdotto = createAsyncThunk(
	"iterConnessione/saveProdotto",
	async (
		parametri: { prodotto: Prodotto; iterconnessioneId: number },
		thunkApi
	) => {
		return await iterConnessioniApi.saveProdotto(
			parametri.prodotto,
			parametri.iterconnessioneId
		);
	}
);

export const deleteProdotto = createAsyncThunk(
	"iterConnessione/deleteProdotto",
	async (
		parametri: { prodotto: Prodotto; iterconnessioneId: number },
		thunkApi
	) => {
		return await iterConnessioniApi.deleteProdotto(parametri.prodotto);
	}
);

export const iterConnessioniSlice = createSlice({
	name: "iterConnessioniState",
	initialState,
	reducers: {
		resetNuovoIterConnessioneId: (state) => {
			state.nuovoIterConnessioneId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch IterConnessioni
		builder.addCase(fetchIterConnessioni.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchIterConnessioni.fulfilled, (state, action) => {
			state.iterConnessioni = action.payload;
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchIterConnessioni.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get IterConnessione
		builder.addCase(getIterConnessione.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getIterConnessione.fulfilled, (state, action) => {
			state.iterConnessioni.results = state.iterConnessioni.results.filter(
				(iterConnessione) => iterConnessione.id != action.payload.id
			);
			state.iterConnessioni.results.push(action.payload);
			// state.nuovoIterConnessioneId = action.payload.id;

			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getIterConnessione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save save IterConnessione
		builder.addCase(saveIterConnessione.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveIterConnessione.fulfilled, (state, action) => {
			state.iterConnessioni.results = state.iterConnessioni.results.filter(
				(iterConnessione) => iterConnessione.id != action.payload.id
			);
			state.iterConnessioni.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			// Solo se è nuova creazione ********************
			if (!action.meta.arg.iterConnessioneToSave.id) {
				state.nuovoIterConnessioneId = action.payload.id;
			}
			// ***********************************************
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("IterConnessione salvata.");
		});
		builder.addCase(saveIterConnessione.rejected, (state, action) => {
			state.iterConnessioni.results = state.iterConnessioni.results.map(
				(iterConnessione) => {
					if (iterConnessione.id == action.meta.arg.iterConnessioneToSave.id) {
						return {
							...iterConnessione,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return iterConnessione;
					}
				}
			);
			state.iterConnessioni.errorsStack = parseErrorMessage(action.error);
		});

		// cancella IterConnessione
		builder.addCase(deleteIterConnessione.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteIterConnessione.fulfilled, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.iterConnessioni.results = state.iterConnessioni.results.filter(
				(iterConnessione) => iterConnessione.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "IterConnessione cancellata.");
		});
		builder.addCase(deleteIterConnessione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// richiesta di presa in carico IterConnessione
		builder.addCase(richiestaPresaInCarico.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(richiestaPresaInCarico.fulfilled, (state, action) => {
			state.iterConnessioni.results = state.iterConnessioni.results.filter(
				(iterConnessione) => iterConnessione.id != action.payload.id
			);
			state.iterConnessioni.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			// state.nuovoIterConnessioneId = action.payload.id;
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Inviata richiesta di Presa in Carico.");
		});
		builder.addCase(richiestaPresaInCarico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});

		//  presa in carico IterConnessione
		builder.addCase(presaInCarico.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(presaInCarico.fulfilled, (state, action) => {
			state.iterConnessioni.results = state.iterConnessioni.results.filter(
				(iterConnessione) => iterConnessione.id != action.payload.id
			);
			state.iterConnessioni.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			// state.nuovoIterConnessioneId = action.payload.id;
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Inviata richiesta di Presa in Carico.");
		});
		builder.addCase(presaInCarico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});

		//  presa avvio Pratica IterConnessione
		builder.addCase(assegnaPratica.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(assegnaPratica.fulfilled, (state, action) => {
			state.iterConnessioni.results = state.iterConnessioni.results.filter(
				(iterConnessione) => iterConnessione.id != action.payload.id
			);
			state.iterConnessioni.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			// state.nuovoIterConnessioneId = action.payload.id;
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Inviata richiesta di Presa in Carico.");
		});
		builder.addCase(assegnaPratica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});

		//  presa avvio Pratica IterConnessione
		builder.addCase(avviaProgetto.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(avviaProgetto.fulfilled, (state, action) => {
			state.iterConnessioni.results = state.iterConnessioni.results.filter(
				(iterConnessione) => iterConnessione.id != action.payload.id
			);
			state.iterConnessioni.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			// state.nuovoIterConnessioneId = action.payload.id;
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Progetto avviato.");
		});
		builder.addCase(avviaProgetto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});

		// save Prodotto
		builder.addCase(saveProdotto.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveProdotto.fulfilled, (state, action) => {
			state.iterConnessioni.results = state.iterConnessioni.results.filter(
				(iterConnessione) => iterConnessione.id != action.payload.id
			);
			state.iterConnessioni.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			// state.nuovoIterConnessioneId = action.payload.id;
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("IterConnessione salvata.");
		});
		builder.addCase(saveProdotto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.iterConnessioni.results = state.iterConnessioni.results.map(
				(iterConnessione) => {
					if (iterConnessione.id == action.meta.arg.iterconnessioneId) {
						return {
							...iterConnessione,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return iterConnessione;
					}
				}
			);
			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});

		// cancella Prodotto
		builder.addCase(deleteProdotto.pending, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteProdotto.fulfilled, (state, action) => {
			state.iterConnessioni.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.iterConnessioni.results = state.iterConnessioni.results.map(
				(iter) => {
					const prodottifiltrati = iter.prodotti?.filter(
						(p) => p.id != action.meta.arg.prodotto.id
					);
					return { ...iter, prodotti: prodottifiltrati };
				}
			);
			toast.success(action.payload.message || "IterConnessione cancellata.");
		});
		builder.addCase(deleteProdotto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.iterConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetNuovoIterConnessioneId } = iterConnessioniSlice.actions;

export const iterConnessioniSliceReducer = iterConnessioniSlice.reducer;
