import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as tipiServizioApi from "../../../api/anagrafiche/tipiServizioApi";
import { toast } from "react-toastify";

export interface TipoServizio {
	id?: number;
	codice?: string;
	descrizione?: string;
	deleted?: boolean;
	errorsStack?: ErrorsStack;
}

export interface TipiServizio {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoServizio[];
	errorsStack: ErrorsStack;
}

export interface TipiServizioStrutturaState {
	tipiServizio: TipiServizio;
}

const initialState: TipiServizioStrutturaState = {
	tipiServizio: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchTipiServizio = createAsyncThunk(
	"tipiServizio/fetchTipiServizio",
	async () => {
		return await tipiServizioApi.fetchTipiServizio();
	}
);

export const getTipoServizio = createAsyncThunk(
	"tipiServizio/getTipoServizio",
	async (tipoServizioId: number) => {
		return await tipiServizioApi.getTipoServizio(tipoServizioId);
	}
);

export const saveTipoServizio = createAsyncThunk(
	"tipiServizio/saveTipoServizio",
	async (tipoServizioToSave: TipoServizio, thunkApi) => {
		return await tipiServizioApi
			.saveTipoServizio(tipoServizioToSave)
			.then((response) => {
				thunkApi.dispatch(fetchTipiServizio());
				return response;
			});
	}
);

export const deleteTipoServizio = createAsyncThunk(
	"tipiServizio/deleteTipoServizio",
	async (tipoServizioToDelete: TipoServizio, thunkApi) => {
		return await tipiServizioApi.deleteTipoServizio(tipoServizioToDelete);
	}
);

export const tipiServizioSlice = createSlice({
	name: "tipiServizio",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Tipi Servizio
		builder.addCase(fetchTipiServizio.pending, (state, action) => {
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchTipiServizio.fulfilled, (state, action) => {
			state.tipiServizio = action.payload;

			state.tipiServizio.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchTipiServizio.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Tipo Servizio
		builder.addCase(getTipoServizio.pending, (state, action) => {
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getTipoServizio.fulfilled, (state, action) => {
			state.tipiServizio.results = state.tipiServizio.results.map(
				(tipoServizio) => {
					if (tipoServizio.id == action.payload.id) {
						return action.payload;
					} else {
						return tipoServizio;
					}
				}
			);
			state.tipiServizio.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getTipoServizio.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Tipo Servizio
		builder.addCase(saveTipoServizio.pending, (state, action) => {
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveTipoServizio.fulfilled, (state, action) => {
			state.tipiServizio.results = state.tipiServizio.results.map(
				(tipoServizio) => {
					if (tipoServizio.id == action.payload.id) {
						return {
							...action.payload,
							errorsStack: {
								status: ErrorStatusTypes.OK,
							},
						};
					} else {
						return tipoServizio;
					}
				}
			);
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Tipo Servizio salvato.");
		});
		builder.addCase(saveTipoServizio.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.tipiServizio.results = state.tipiServizio.results.map(
				(tipoServizio) => {
					if (tipoServizio.id == action.meta.arg.id) {
						return {
							...tipoServizio,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return tipoServizio;
					}
				}
			);
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// cancella Tipo Servizio
		builder.addCase(deleteTipoServizio.pending, (state, action) => {
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteTipoServizio.fulfilled, (state, action) => {
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.tipiServizio.results = state.tipiServizio.results.filter(
				(tipoServizio) => tipoServizio.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Tipo Servizio cancellato.");
		});
		builder.addCase(deleteTipoServizio.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiServizio.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = tipiServizioSlice.actions;

export const tipiServizioSliceReducer = tipiServizioSlice.reducer;
