import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// nodejs library to set properties for components
import Box from "@mui/material/Box";
// @material-ui/core components

// core components
import styles from "./parallaxStyle";

interface ParallaxProps {
	filter?: boolean;
	image?: string;
	xsmall?: boolean;
	small?: boolean;
	medium?: boolean;
	style?: string;
	className?: string;
	children?: React.ReactNode;
}

export default function Parallax({
	className,
	filter,
	children,
	style,
	image,
	xsmall,
	small,
	medium,
}: ParallaxProps) {
	let windowScrollTop;
	if (window.innerWidth >= 768) {
		windowScrollTop = window.pageYOffset / 3;
	} else {
		windowScrollTop = 0;
	}
	const [transform, setTransform] = React.useState(
		"translate3d(0," + windowScrollTop + "px,0)"
	);
	React.useEffect(() => {
		if (window.innerWidth >= 768) {
			window.addEventListener("scroll", resetTransform);
		}
		return function cleanup() {
			if (window.innerWidth >= 768) {
				window.removeEventListener("scroll", resetTransform);
			}
		};
	});
	const resetTransform = () => {
		var windowScrollTop = window.pageYOffset / 3;
		setTransform("translate3d(0," + windowScrollTop + "px,0)");
	};

	// const parallaxClasses = classNames({
	// 	[classes.parallax]: true,
	// 	[classes.filter]: filter,
	// 	[classes.small]: small,
	// 	[classes.xsmall]: xsmall,
	// 	[classes.medium]: medium,
	// 	[className]: className !== undefined,
	// });
	return (
		<Box
			sx={{
				// height: xsmall ? "30vh" : small ? "40vh" : medium ? "70vh" : "90vh",
				// height: "41vmax",
				// maxHeight: "150vmax",
				overflow: "hidden",
				position: "relative",
				backgroundPosition: "center center",
				// backgroundPosition: "top",
				backgroundSize: "cover",
				// backgroundRepeat: "no-repeat",
				margin: "0",
				padding: "0",
				border: "0",
				display: "flex",
				alignItems: "center",
				backgroundImage: "url(" + image + ")",
				transform: transform,
			}}
		>
			{children}
		</Box>
	);
}
