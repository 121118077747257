import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";

import moment from "moment";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import { PermissionTypes } from "../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import { IterConnessione, getIterConnessione } from "../iterConnessioneSlice";
import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	ServizioTypes,
} from "../../../anagrafiche/kpi/kpiSlice";
import { TipoServizio } from "../../../anagrafiche/tipiServizio/tipiServizioSlice";

import { Cliente } from "../../../anagrafiche/clienti/clientiSlice";
import { Produttore } from "../../../anagrafiche/produttori/produttoriSlice";
import {
	Progetto,
	getProgettoFromIter,
} from "../iterConnessione/progetti/progettiSlice";

import IterConnessioneAlerChip from "./IterConnessioneAlerChip";
import IterConnessioneDatiGeneraliTab from "./datiGenerali/IterConnessioneDatiGeneraliTab";
import IterConnessioneDatiPraticaTab from "./datiPratica/IterConnessioneDatiPraticaTab";
import IterConnessioneProgettiTab from "./progetti/IterConnessioneProgettiTab";
import IterConnessioneRichiesteConnessioneTab from "./richiesteConnessione/IterConnessioneRichiesteConnessioneTab";
import IterConnessioneAutorizzazioneComunaleTab from "./autorizzazioneComunale/IterConnessioneAutorizzazioneComunaleTab";
import IterConnessioneDocumentiTab from "./documenti/IterConnessioneDocumentiTab";
import IterConnessioneOfficinaElettricaTab from "./officinaElettrica/IterConnessioneOfficinaElettricaTab";
import IterConnessioneEneaTab from "./enea/IterConnessioneEneaTab";
import IterConnessioneConsegnaTab from "./consegna/IterConnessioneConsegnaTab";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

import ArrowStepper from "../../../common/ArrowStepper";
import {
	testoBottoneProseguimento,
	passiInizioPratica,
} from "../../../anagrafiche/produttori/testoBottoneProseguimento";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { getProduttore } from "../../../anagrafiche/produttori/produttoriSlice";
import { Grid, Paper } from "@mui/material";

import { EsponiStatoSintetico } from "components/common/EsponiStatoSintetico";

const IterConnessionePage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const { iter_id } = useParams();

	const [iterId, setIterId] = useState<number>(parseInt(iter_id || ""));

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const iterConnessioniErrorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.iterConnessioniState.iterConnessioni.errorsStack
	);

	const tipiServizio = useSelector<RootState, TipoServizio[]>(
		(state) => state.tipiServizioState.tipiServizio.results
	);

	const servizioEscludeEnea = () => {
		const codiceServizio = tipiServizio.find(
			(ts) => ts.id == iterConnessione?.tipo_servizio
		)?.codice;

		const servizioEscludeEnea =
			codiceServizio == ServizioTypes.ITERO_FV ||
			codiceServizio == ServizioTypes.ITERS_FV;
		return servizioEscludeEnea;
	};

	const servizioEscludeOfficinaElettrica = () => {
		const codiceServizio = tipiServizio.find(
			(ts) => ts.id == iterConnessione?.tipo_servizio
		)?.codice;

		const servizioEscludeOfficinaElettrica =
			codiceServizio == ServizioTypes.ITERO_FV ||
			codiceServizio == ServizioTypes.ITERS_FV;
		return servizioEscludeOfficinaElettrica;
	};

	const nuovoIterConnessioneId = useSelector<RootState, number | undefined>(
		(state) => state.iterConnessioniState.nuovoIterConnessioneId
	);

	useEffect(() => {
		if (iterId) {
			dispatch(getIterConnessione(iterId));
		}
	}, []);

	useEffect(() => {
		nuovoIterConnessioneId && setIterId(nuovoIterConnessioneId);
	}, [nuovoIterConnessioneId]);

	const iterConnessione: IterConnessione | undefined = useSelector<
		RootState,
		IterConnessione | undefined
	>((state) =>
		state.iterConnessioniState.iterConnessioni.results.find(
			(iterConnessione) => iterConnessione?.id == iterId
		)
	);

	useEffect(() => {
		iterConnessione && dispatch(getProgettoFromIter(iterId));

		if (iterConnessione && iterConnessione.produttore) {
			dispatch(getProduttore(iterConnessione.produttore));
		}
	}, [iterConnessione]);

	const cliente: Cliente | undefined = useSelector<
		RootState,
		Cliente | undefined
	>((state) =>
		state.clientiState.clienti.results.find(
			(cliente) => cliente?.id == iterConnessione?.cliente
		)
	);
	const produttore: Produttore | undefined = useSelector<
		RootState,
		Produttore | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore?.id == iterConnessione?.produttore
		)
	);

	// useEffect(() => {
	// 	if (iterConnessione) {
	// 		dispatch(getIterConnessione(iterId));
	// 		dispatch(getProgettoFromIter(iterId));
	// 	}
	// }, [produttore?.email_alias]);

	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);

	const [currentTab, setCurrentTab] = useState("1");
	const handleTabChange = (event: any, newValue: string) => {
		setCurrentTab(newValue);
	};

	useEffect(() => {
		iterConnessione &&
			["A", "B", "C"].includes(iterConnessione.stato) &&
			setCurrentTab("1");
		iterConnessione &&
			["D", "E"].includes(iterConnessione.stato) &&
			setCurrentTab("2");
		iterConnessione &&
			["F"].includes(iterConnessione.stato) &&
			setCurrentTab("3");
		iterConnessione &&
			["G", "H", "I", "L"].includes(iterConnessione.stato) &&
			setCurrentTab("4b");
		iterConnessione &&
			["M", "N"].includes(iterConnessione.stato) &&
			setCurrentTab("7");
	}, [iterConnessione?.stato]);
	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={iterConnessioniErrorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{iterConnessione && iterConnessione?.tipo_servizio && (
				<ArrowStepper
					titolo={testoBottoneProseguimento(
						Object.entries(ServizioTypes).find(
							([key, value]) =>
								key ==
								tipiServizio.find(
									(ts) => ts.id == iterConnessione?.tipo_servizio
								)?.codice
						)?.[1]
					)}
					passi={passiInizioPratica}
					colore={
						["A", "B", "C"].includes(iterConnessione.stato)
							? "#c7b52a"
							: ["N"].includes(iterConnessione.stato)
							? "#658822"
							: "#03b0e2"
					}
					indicePassoCorrente={
						["A", "B", "C"].includes(iterConnessione.stato)
							? 2
							: ["N"].includes(iterConnessione.stato)
							? 4
							: 3
					}
				/>
			)}
			{iterConnessione ? (
				<Box>
					<Grid container spacing={1}>
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<Chip
								color="primary"
								variant="outlined"
								label={iterConnessione?.commessa}
							/>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={2}>
							<Typography variant="h6" color="text.secondary" gutterBottom>
								{
									tipiServizio.find(
										(ts) => ts.id == iterConnessione?.tipo_servizio
									)?.codice
								}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={2}>
							<Typography variant="h6" color="text.secondary" gutterBottom>
								{iterConnessione?.iter_semplificato
									? "iter semplificato"
									: "iter ordinario"}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={5} lg={3}>
							<Typography variant="h5">{cliente?.ragione_sociale}</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<Typography variant="h5">
								{/* {
							statiIter.results.find(
								(statoIter) => statoIter.stato == iterConnessione?.stato
							)?.stato_desc
						} */}
								{EsponiStatoSintetico(iterConnessione?.stato)}
							</Typography>
							<Typography variant="h5">
								{utenteAutorizzato(utente, [
									PermissionTypes.GESTORI_ITER,
									PermissionTypes.OPERATORI,
									PermissionTypes.PROGETTISTI,
								]) &&
									iterConnessione?.stato == "H" &&
									` (${
										Object.entries(StatoConnessioneTypes).find(
											([key, value]) =>
												key == iterConnessione.stato_richiesta_connessione
										)?.[1]
									})`}
							</Typography>
						</Grid>
						{utenteAutorizzato(utente, [
							PermissionTypes.GESTORI_ITER,
							PermissionTypes.OPERATORI,
							PermissionTypes.PROGETTISTI,
						]) && (
							<Grid item xs={6} sm={6} md={4} lg={1}>
								<IterConnessioneAlerChip iterConnessione={iterConnessione} />
							</Grid>
						)}
					</Grid>

					<TabContext value={currentTab}>
						<Box
							sx={{
								borderBottom: 1,
								borderColor: "divider",
							}}
						>
							<TabList
								onChange={handleTabChange}
								aria-label="lab API tabs example"
								variant="scrollable"
								scrollButtons="auto"
							>
								<Tab label="1.DATI GENERALI" value="1" />
								<Tab
									label="2.DATI PRATICA"
									value="2"
									disabled={
										!["D", "E", "F", "G", "H", "I", "L", "M", "N"].includes(
											iterConnessione?.stato || ""
										)
									}
								/>
								<Tab
									label="3.PROGETTO"
									value="3"
									disabled={
										!["F", "G", "H", "I", "L", "M", "N"].includes(
											iterConnessione?.stato || ""
										)
									}
								/>
								<Tab
									label="4a.AUTORIZZAZIONI COMUNALI"
									value="4a"
									disabled={
										!["G", "H", "I", "L", "M", "N"].includes(
											iterConnessione?.stato || ""
										)
									}
								/>

								<Tab
									label="4b.RICHIESTA CONNESSIONE"
									value="4b"
									disabled={
										!["G", "H", "I", "L", "M", "N"].includes(
											iterConnessione?.stato || ""
										)
									}
								/>
								{produttore?.tipo_persona == "PF" && !servizioEscludeEnea() && (
									<Tab
										label="ENEA"
										value="5"
										disabled={
											!["G", "H", "I", "L", "M", "N"].includes(
												iterConnessione?.stato || ""
											) || servizioEscludeEnea()
										}
									/>
								)}
								{utenteAutorizzato(utente, [
									PermissionTypes.GESTORI_ITER,
									PermissionTypes.OPERATORI,
									PermissionTypes.PROGETTISTI,
								]) &&
									produttore?.tipo_persona == "PG" &&
									!servizioEscludeOfficinaElettrica() && (
										<Tab
											label="OFFICINA ELETTRICA"
											value="6"
											disabled={
												!["G", "H", "I", "L", "M", "N"].includes(
													iterConnessione?.stato || ""
												) || servizioEscludeOfficinaElettrica()
											}
										/>
									)}

								<Tab
									label="CONSEGNA"
									value="7"
									disabled={
										!(
											(utenteAutorizzato(utente, [
												PermissionTypes.GESTORI_ITER,
												PermissionTypes.OPERATORI,
												PermissionTypes.PROGETTISTI,
											]) &&
												["M", "N"].includes(iterConnessione?.stato || "")) ||
											iterConnessione?.stato == "N"
										)
									}
								/>
								{utenteAutorizzato(utente, [
									PermissionTypes.GESTORI_ITER,
									PermissionTypes.OPERATORI,
									PermissionTypes.PROGETTISTI,
								]) && <Tab label="RACCOLTA DOCUMENTI" value="8" />}
							</TabList>
						</Box>
						<TabPanel value="1">
							{iterConnessione && produttore ? (
								<IterConnessioneDatiGeneraliTab
									iterConnessione={iterConnessione}
									utente={utente}
									produttore={produttore}
									consentiModifica={
										(["A", "B", "C"].includes(iterConnessione?.stato || "") &&
											utenteAutorizzato(utente, [
												PermissionTypes.GESTORI_ITER,
												PermissionTypes.CLIENTI,
												PermissionTypes.OPERATORI,
												// PermissionTypes.PROGETTISTI,
											])) ||
										utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
									}
								/>
							) : (
								<CircularProgress color="inherit" />
							)}
						</TabPanel>
						<TabPanel value="2">
							{iterConnessione && produttore ? (
								<IterConnessioneDatiPraticaTab
									iterConnessione={iterConnessione}
									produttore={produttore}
									consentiModifica={utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.OPERATORI,
									])}
								/>
							) : (
								<CircularProgress color="inherit" />
							)}
						</TabPanel>
						<TabPanel value="3">
							{iterConnessione ? (
								<IterConnessioneProgettiTab
									iterConnessione={iterConnessione}
									utente={utente}
									consentiModifica={utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.PROGETTISTI,
									])}
								/>
							) : (
								<CircularProgress color="inherit" />
							)}
						</TabPanel>
						<TabPanel value="4a">
							{iterConnessione ? (
								<IterConnessioneAutorizzazioneComunaleTab
									iterConnessione={iterConnessione}
									utente={utente}
									consentiModifica={utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.OPERATORI,
									])}
								/>
							) : (
								<CircularProgress color="inherit" />
							)}
						</TabPanel>
						<TabPanel value="4b">
							{iterConnessione ? (
								<IterConnessioneRichiesteConnessioneTab
									iterConnessione={iterConnessione}
									utente={utente}
									consentiModifica={utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.OPERATORI,
									])}
								/>
							) : (
								<CircularProgress color="inherit" />
							)}
						</TabPanel>
						<TabPanel value="5">
							{iterConnessione ? (
								<IterConnessioneEneaTab
									iterConnessione={iterConnessione}
									utente={utente}
									consentiModifica={utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.OPERATORI,
									])}
								/>
							) : (
								<CircularProgress color="inherit" />
							)}
						</TabPanel>
						<TabPanel value="6">
							{iterConnessione && produttore?.tipo_persona == "PG" && (
								<IterConnessioneOfficinaElettricaTab
									iterConnessione={iterConnessione}
									utente={utente}
									consentiModifica={utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.OPERATORI,
									])}
								/>
							)}
						</TabPanel>
						<TabPanel value="7">
							{((utenteAutorizzato(utente, [
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.PROGETTISTI,
							]) &&
								["M", "N"].includes(iterConnessione?.stato || "")) ||
								iterConnessione?.stato == "N") &&
								iterConnessione &&
								produttore && (
									<IterConnessioneConsegnaTab
										iterConnessione={iterConnessione}
										produttore={produttore}
										utente={utente}
										consentiModifica={utenteAutorizzato(utente, [
											PermissionTypes.GESTORI_ITER,
											PermissionTypes.OPERATORI,
										])}
									/>
								)}
						</TabPanel>
						<TabPanel value="8">
							{iterConnessione?.id && (
								<IterConnessioneDocumentiTab
									iterConnessione_id={iterConnessione.id}
									utente={utente}
									consentiModifica={utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.OPERATORI,
									])}
								/>
							)}
						</TabPanel>
					</TabContext>
				</Box>
			) : (
				<Paper sx={{ p: 2, backgroundColor: "lightgoldenrodyellow", mb: 2 }}>
					<Typography variant="h6" color="text.secondary" gutterBottom>
						<ReportGmailerrorredIcon />
						Errore caricamento iter connessione
					</Typography>
				</Paper>
			)}
		</Box>
	);
};

export default IterConnessionePage;
