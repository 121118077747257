import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import {
	Progetto,
	SezioneProgetto,
} from "../../components/main/iterConnessioni/iterConnessione/progetti/progettiSlice";
import {
	Sopralluogo,
	SopralluogoElettrico,
} from "components/main/sopralluoghi/sopralluoghiSlice";

const baseUrl = (sopralluogo_id?: number | null, azione?: string) => {
	if (sopralluogo_id) {
		let url = `${process.env.API_URL}/api/sopralluoghi/${sopralluogo_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/sopralluoghi/";
	}
};

export function fetchSopralluoghi(
	statoSopralluogo?: string,
	cliente?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (
		statoSopralluogo != undefined ||
		cliente != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (statoSopralluogo != undefined) {
		url += `stato_sopralluogo=${statoSopralluogo}&`;
	}
	if (cliente != undefined) {
		url += `cliente=${cliente}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getSopralluogo(sopralluogoId: number) {
	let url = baseUrl(sopralluogoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveSopralluogo(
	sopralluogoToSave: Sopralluogo,
	azione?: string
) {
	const data_sopralluogo = sopralluogoToSave.data_sopralluogo
		? moment(sopralluogoToSave.data_sopralluogo).format("YYYY-MM-DD")
		: null;
	const sopralluogo = {
		...sopralluogoToSave,
		data_sopralluogo: data_sopralluogo,
	};
	return fetch(baseUrl(sopralluogoToSave.id, azione), {
		method: sopralluogoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...sopralluogo,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteSopralluogo(sopralluogoToDelete: Sopralluogo) {
	return fetch(baseUrl(sopralluogoToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////

export function fetchRichiesteConnessioneFromIter(iterId: number) {
	let url =
		process.env.API_URL +
		"/api/sopralluoghi/richieste_connessione_from_iter/" +
		iterId +
		"/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ///////////////////////////////////////////////////////////////////

const baseUrlElettrico = (
	sopralluogo_elettrico_id?: number | null,
	azione?: string
) => {
	if (sopralluogo_elettrico_id) {
		let url = `${process.env.API_URL}/api/sopralluoghi/sopralluoghi_elettrici/${sopralluogo_elettrico_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/sopralluoghi/sopralluoghi_elettrici/";
	}
};

export function fetchSopralluoghiElettrici() {
	let url = baseUrlElettrico();
	// moment(data_da).format("YYYY-MM-DD");
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getSopralluogoElettrico(sopralluogoElettricoId: number) {
	let url = baseUrlElettrico(sopralluogoElettricoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveSopralluogoElettrico(
	sopralluogoElettricoToSave: SopralluogoElettrico,
	azione?: string
) {
	return fetch(baseUrlElettrico(sopralluogoElettricoToSave.id, azione), {
		method: sopralluogoElettricoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...sopralluogoElettricoToSave,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteSopralluogoElettrico(
	sopralluogoElettricoToDelete: Sopralluogo
) {
	return fetch(baseUrlElettrico(sopralluogoElettricoToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
////////////////////////////////////////

////////////////////////////////////////
export function getSopralluogoElettricoFromSopralluogo(
	sopralluogoElettricoId: number
) {
	let url =
		process.env.API_URL +
		"/api/sopralluoghi/sopralluogo_elettrico_from_sopralluogo/" +
		sopralluogoElettricoId +
		"/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
