import { Routes_path } from "../../../routerApp";
import { useEffect, useState } from "react";
import { useAppDispatch, RootState } from "../../../../redux/store";

import { Prodotto, TipoProdottoTypes } from "../podsSlice";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

// import ConfermaAnnulla from "../../../../../common/ConfermaAnnulla";
import { Typography } from "@mui/material";

interface PodProdottoCardProps {
	prodotto: Prodotto;
	consentiModifica: boolean;
}

const PodProdottoCard = ({
	prodotto,
	consentiModifica = false,
}: PodProdottoCardProps) => {
	const dispatch = useAppDispatch();

	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);
	return (
		<Card sx={{ mb: 2 }}>
			<CardContent>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					spacing={2}
				>
					<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
						{
							Object.entries(TipoProdottoTypes).find(
								([key, value]) => key == prodotto.tipo_prodotto
							)?.[1]
						}
					</Typography>
					{consentiModifica && (
						<IconButton
							aria-label="delete"
							size="small"
							onClick={() => setOpenConferma(true)}
						>
							{/* <DeleteIcon /> */}
						</IconButton>
					)}
				</Stack>

				{/* <ConfermaAnnulla
					handleAnnulla={handleConfermaClose}
					handleConferma={() => {
						dispatch(
							deleteProdotto({
								prodotto: prodotto,
								iterconnessioneId: prodotto.iterConnessione,
							})
						);
					}}
					domanda={"Sicuro di voler cancellare il prodotto"}
					open={openConferma}
				/> */}
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					spacing={2}
				>
					<Box>
						<Typography variant="h5" component="div">
							{prodotto.marca}
						</Typography>
						<Typography sx={{ mb: 1.5 }} color="text.secondary">
							{prodotto.modello}
						</Typography>
					</Box>

					<Box>
						<Typography variant="caption">qtà:</Typography>

						<Typography variant="body2">{prodotto.quantita}</Typography>
					</Box>

					{prodotto.tipo_prodotto == "SA" ? (
						<Box>
							<Typography variant="caption">Capacità batteria</Typography>
							<Typography variant="body2">
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 2,
								}).format(prodotto.capacita_singola_batteria || 0)}{" "}
								kWh
							</Typography>
							<Typography variant="caption">Capacità totale</Typography>
							<Typography variant="body2">
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 2,
								}).format(prodotto.capacita_totale || 0)}{" "}
								kWh
							</Typography>
						</Box>
					) : (
						(prodotto.tipo_prodotto == "MF" || "IN") && (
							<Box>
								<Typography variant="caption">Pot.Unit.</Typography>

								<Typography variant="body2">
									{Intl.NumberFormat("it-IT", {
										maximumFractionDigits: 2,
									}).format(prodotto.potenza_unitaria || 0)}
									{prodotto.tipo_prodotto == "MF" ? " Wp" : " kW"}
								</Typography>
							</Box>
						)
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default PodProdottoCard;
