import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../../redux/store";

import moment from "moment";

import IterConnessioneSpalla from "../../IterConnessioneSpalla";
import { PermissionTypes } from "../../../../../authentication/permissionsGroups";
import AzioniFineLavoriCard from "./AzioniAllaccioCard";

import { utenteAutorizzato } from "../../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../../authentication/authenticationSlice";
import { Allaccio, getAllaccioFromIter } from "./allaccioSlice";
import { IterConnessione } from "../../../iterConnessioneSlice";
import AllegatiPage from "../../../../../allegati/AllegatiPage";
import IterConnessioneAllaccioCard from "./IterConnessioneAllaccioCard";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";

import Stack from "@mui/material/Stack";
import { theme } from "../../../../../theme";

interface IterConnessioneAllaccioBoxProps {
	iterConnessione: IterConnessione;
	allaccio: Allaccio;
	utente: UtenteLogged;

	consentiModifica: boolean;
}

const IterConnessioneAllaccioBox = ({
	iterConnessione,
	allaccio,
	utente,
	consentiModifica = false,
}: IterConnessioneAllaccioBoxProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	return (
		<Box>
			{allaccio && (
				<IterConnessioneAllaccioCard
					key={allaccio.id}
					allaccio={allaccio}
					consentiModifica={consentiModifica}
					iterSemplificato={iterConnessione.iter_semplificato}
				/>
			)}
		</Box>
	);
};

export default IterConnessioneAllaccioBox;
