import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Produttore, saveProduttore } from "./produttoriSlice";
import { useAppDispatch, RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
	IterConnessione,
	saveIterConnessione,
	resetNuovoIterConnessioneId,
} from "../../main/iterConnessioni/iterConnessioneSlice";
import {
	Sopralluogo,
	saveSopralluogo,
	resetSopralluogoId,
} from "../../main/sopralluoghi/sopralluoghiSlice";

import {
	saveServizioAdempimenti,
	ServizioAdempimenti,
	resetNuovoServizioAdempimentiId,
} from "../../main/adempimenti/adempimentiSlice";
import { ServizioTypes } from "../kpi/kpiSlice";
import { TipoServizio } from "../../anagrafiche/tipiServizio/tipiServizioSlice";
import {
	testoBottoneProseguimento,
	isAmpliamento,
} from "./testoBottoneProseguimento";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Modal from "@mui/material/Modal";

import TextField from "@mui/material/TextField";

import Alert from "../../common/Alert";

import { Button, Divider } from "@mui/material";
import { theme } from "../../theme";
import { toast } from "react-toastify";

interface AvvioIterConnessioneFormProps {
	produttore: Produttore;
	tipoPraticaId?: ServizioTypes;
	setTipoPraticaId: (tipoPraticaId: ServizioTypes | undefined) => void;
}

const AvvioIterConnessioneForm = ({
	produttore,
	tipoPraticaId,
	setTipoPraticaId,
}: AvvioIterConnessioneFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Produttore>();

	const listaCampi = Object.keys(produttore) as Array<keyof Produttore>;

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const nuovoIterConnessioneId = useSelector<RootState, number | undefined>(
		(state) => state.iterConnessioniState.nuovoIterConnessioneId
	);

	const nuovoSopralluogoId = useSelector<RootState, number | undefined>(
		(state) => state.sopralluoghiState.nuovoSopralluogoId
	);

	const nuovoServizioAdempimentiId = useSelector<RootState, number | undefined>(
		(state) => state.adempimentiState.nuovoServizioAdempimentiId
	);

	const tipiServizio = useSelector<RootState, TipoServizio[]>(
		(state) => state.tipiServizioState.tipiServizio.results
	);

	const [tipoServizio, setTipoServizio] = useState<TipoServizio | undefined>(
		undefined
	);

	const [ampliamento, setAmpliamento] = useState<boolean | undefined>(
		undefined
	);
	const tipoPraticaPreimpostaApliamento =
		isAmpliamento(tipoPraticaId) != undefined;
	const [altroPod, setAltroPod] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		nuovoIterConnessioneId &&
			navigate(Routes_path.ITER_CONNESSIONE + nuovoIterConnessioneId);
		dispatch(resetNuovoIterConnessioneId());
	}, [nuovoIterConnessioneId]);

	useEffect(() => {
		nuovoSopralluogoId &&
			navigate(Routes_path.SOPRALLUOGO + nuovoSopralluogoId);
		dispatch(resetSopralluogoId());
	}, [nuovoSopralluogoId]);

	useEffect(() => {
		nuovoServizioAdempimentiId && setTipoPraticaId(undefined);
		// nuovoServizioAdempimentiId &&
		// 	navigate(Routes_path.PRODUTTORE + produttore.id);
		dispatch(resetNuovoServizioAdempimentiId());
	}, [nuovoServizioAdempimentiId]);

	useEffect(
		() => {
			reset();
			listaCampi.map((field) => {
				setValue(field, produttore[field]);
			});
		},
		[
			// pesonaFisica,
			// listaCampi.forEach((field) => {
			// 	produttore[field];
			// }),
			// produttore.id,
		]
	);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			produttore?.errorsStack?.fieldsErrors &&
				produttore.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: produttore.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		produttore?.errorsStack?.fieldsErrors,
		// produttore.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveProduttore = (produttore: Produttore) => {
		dispatch(saveProduttore(produttore));
	};

	const handlerCreateIterConnessione = (semplificato: boolean) => {
		const iterConnessione: IterConnessione = {
			super_bonus: false,
			tipo_servizio: tipoServizio?.id,
			iter_semplificato: semplificato,
			produttore: produttore.id || 0,
			produttore_descrizione: "",
			indirizzo: produttore.indirizzo,
			numero_civico: produttore.numero_civico,
			cap: produttore.cap,
			comune: produttore.comune || undefined,
			stato: "A",
			ampliamento: ampliamento,
			altro_pod: altroPod,
		};
		// handleSubmit(onSubmit);
		dispatch(saveProduttore({ ...getValues() }));

		dispatch(
			saveIterConnessione({
				iterConnessioneToSave: iterConnessione,
			})
		);
	};

	const handlerCreateSopralluogo = () => {
		const sopralluogo: Sopralluogo = {
			iter_connessione: null,
			// cliente: null,
			produttore: produttore.id || 0,

			indirizzo: produttore.indirizzo,
			numero_civico: produttore.numero_civico,
			cap: produttore.cap,
			comune: produttore.comune || undefined,
			cellulare: produttore.cellulare,
			// data_sopralluogo: new Date(),
			// operatore: 0,
			stato: "A",
		};
		// handleSubmit(onSubmit);
		// dispatch(saveProduttore({ ...getValues() }));

		dispatch(
			saveSopralluogo({
				sopralluogoToSave: sopralluogo,
			})
		);
	};

	const handlerCreateServizioAdempimenti = () => {
		const servizioAdempimenti: ServizioAdempimenti = {
			cliente: undefined,
			produttore: produttore.id || 0,
			data_validita_da: new Date(),
			// data di validita_a termina al 31/12 dell'anno corrente
			data_validita_a: new Date(new Date().getFullYear(), 11, 31),

			errorsStack: undefined,
		};
		// handleSubmit(onSubmit);
		// dispatch(saveProduttore({ ...getValues() }));

		dispatch(
			saveServizioAdempimenti({
				servizioAdempimentiToSave: servizioAdempimenti,
			})
		);
	};

	const onSubmit: SubmitHandler<Produttore> = (produttore) => {
		handlerSaveProduttore(produttore);
	};
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);

	const style = {
		position: "absolute" as "absolute",
		top: "30%",
		left: "10%",
		transform: "translate(-5%, -10%)",
		width: "90%",
		bgcolor: "background.paper",
		border: "2px solid #666",
		boxShadow: 24,
		p: 4,
	};
	return (
		<Box sx={{ my: 1, pt: 2 }}>
			{tipoPraticaId && (
				<Button
					variant="contained"
					sx={{ mr: 2 }}
					onClick={() => {
						if (tipoPraticaId == ServizioTypes.SOP_FV) {
							handlerCreateSopralluogo();
						} else if (tipoPraticaId == ServizioTypes.ADEM_FV) {
							handlerCreateServizioAdempimenti();
						} else {
							setTipoServizio(
								tipiServizio.find((ts) => ts.codice == tipoPraticaId)
							);
							setOpen(true);
							setAmpliamento(isAmpliamento(tipoPraticaId));
						}
					}}
					disabled={!produttore.anagrafica_completa}
				>
					{testoBottoneProseguimento(tipoPraticaId)}
				</Button>
			)}

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<form onSubmit={handleSubmit(onSubmit)}>
						{tipoPraticaPreimpostaApliamento && (
							<Alert severity="info">
								{ampliamento
									? "nuovo Iter di connessione per ampliamento di impianto esistente"
									: "nuovo Iter di connessione per impianto nuovo"}
							</Alert>
						)}
						{produttore?.errorsStack?.fieldsErrors?.non_field_errors && (
							<Alert severity="error">
								{produttore?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
							</Alert>
						)}

						<Grid container spacing={2} sx={{ mb: 2 }}>
							{!tipoPraticaPreimpostaApliamento && (
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<FormControl>
										<FormLabel id="radio-buttons-group-label">
											Si tratta di apliamento di impianto esistente?
										</FormLabel>
										<RadioGroup
											aria-labelledby="altri-impiantil"
											name="radio-buttons-group"
											value={ampliamento}
											onChange={(
												event: React.ChangeEvent<HTMLInputElement>
											) => {
												setAmpliamento(event.target.value == "SI");
											}}
										>
											<FormControlLabel
												value={"SI"}
												control={<Radio />}
												label="SI"
											/>
											<FormControlLabel
												value={"NO"}
												control={<Radio />}
												label="NO"
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
							)}
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<FormControl>
									<FormLabel id="radio-buttons-group-label">
										Il produttore ha altri impianti di produzione su altro POD*?
									</FormLabel>
									<RadioGroup
										aria-labelledby="altri-impiantil"
										name="radio-buttons-group"
										value={altroPod}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											setAltroPod(event.target.value == "SI");
										}}
									>
										<FormControlLabel
											value={"SI"}
											control={<Radio />}
											label="SI"
										/>
										<FormControlLabel
											value={"NO"}
											control={<Radio />}
											label="NO"
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
						</Grid>
						{(ampliamento ||
							altroPod ||
							produttore.gse_id ||
							produttore.gaudi_id ||
							tipoPraticaId == ServizioTypes.ENEA_FV ||
							tipoPraticaId == ServizioTypes.OFF_FV) && (
							<Grid container spacing={2} sx={{ mb: 2 }}>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Divider
										textAlign="left"
										sx={{ mb: 2, color: theme.palette.primary.main }}
									>
										Credenziali portale GSE{" "}
									</Divider>
									<Grid container spacing={2} sx={{ mb: 2 }}>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<TextField
												label="gse id"
												// disabled={schedaCarico.numero_capi_pianificati}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												{...register("gse_id")}
												required
												error={!!errors?.gse_id}
												helperText={errors?.gse_id?.message}
												autoComplete={"off"}
												sx={{ width: "100%" }}
												fullWidth
												// onBlur={handlerDetailChaingSubmit}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<TextField
												label="gse psw"
												// disabled={schedaCarico.numero_capi_pianificati}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												{...register("gse_psw")}
												required
												error={!!errors?.gse_psw}
												helperText={errors?.gse_psw?.message}
												autoComplete={"off"}
												sx={{ width: "100%" }}
												fullWidth
												// onBlur={handlerDetailChaingSubmit}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Divider
										textAlign="left"
										sx={{ mb: 2, color: theme.palette.primary.main }}
									>
										Credenziali portale Gaudì{" "}
									</Divider>
									<Grid container spacing={2} sx={{ mb: 2 }}>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<TextField
												label="gaudi id"
												// disabled={schedaCarico.numero_capi_pianificati}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												{...register("gaudi_id")}
												error={!!errors?.gaudi_id}
												helperText={errors?.gaudi_id?.message}
												autoComplete={"off"}
												sx={{ width: "100%" }}
												fullWidth
												// onBlur={handlerDetailChaingSubmit}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<TextField
												label="gaudi psw"
												// disabled={schedaCarico.numero_capi_pianificati}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												{...register("gaudi_psw")}
												error={!!errors?.gaudi_psw}
												helperText={errors?.gaudi_psw?.message}
												autoComplete={"off"}
												sx={{ width: "100%" }}
												fullWidth
												// onBlur={handlerDetailChaingSubmit}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Stack direction="row" spacing={2}>
							<Button
								disabled={
									!produttore.anagrafica_completa ||
									ampliamento == undefined ||
									altroPod == undefined ||
									(!!ampliamento && !watch("gse_id")) ||
									(!!ampliamento && !watch("gse_psw")) ||
									(!!ampliamento && !watch("gaudi_id")) ||
									(!!ampliamento && !watch("gaudi_psw")) ||
									(!!altroPod && !watch("gse_id")) ||
									(!!altroPod && !watch("gse_psw")) ||
									(!!altroPod && !watch("gaudi_id")) ||
									(!!altroPod && !watch("gaudi_psw")) ||
									(!!(tipoPraticaId == ServizioTypes.ENEA_FV) &&
										!watch("gaudi_psw")) ||
									(!!(tipoPraticaId == ServizioTypes.ENEA_FV) &&
										!watch("gaudi_id")) ||
									(!!(tipoPraticaId == ServizioTypes.OFF_FV) &&
										!watch("gaudi_psw")) ||
									(!!(tipoPraticaId == ServizioTypes.OFF_FV) &&
										!watch("gaudi_id"))
								}
								variant="outlined"
								type="submit"
								sx={{ mt: 2 }}
								onClick={(event) => {
									event.preventDefault();

									handlerCreateIterConnessione(!ampliamento);
								}}
							>
								{testoBottoneProseguimento(tipoPraticaId)}
							</Button>
						</Stack>
					</form>
				</Box>
			</Modal>
		</Box>
	);
};

export default AvvioIterConnessioneForm;
