import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";

import moment from "moment";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RouterApp, { Routes_path } from "../../../routerApp";

import { PermissionTypes } from "../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import {
	Adempimento,
	StatoAdempimentoTypes,
	getAdempimento,
	fetchTipiAdempimento,
	saveAdempimento,
	TipoAdempimento,
} from "../adempimentiSlice";
import AdempimentoForm from "./AdempimentoForm";

import { Cliente } from "../../../anagrafiche/clienti/clientiSlice";
import { Pod, fetchPods } from "../../../main/pods/podsSlice";
import {
	Produttore,
	getProduttore,
} from "../../../anagrafiche/produttori/produttoriSlice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AdempimentiDocumentiList from "../allegatiAdempimenti/AdempimentiDocumentiList";
import AdempimentoPageBreadcrumbs from "./AdempimentoPageBreadcrumbs";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Button, Grid, ButtonGroup } from "@mui/material";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
const AdempimentoPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const { adempimento_id } = useParams();

	const [adempimentoId, setAdempimentoId] = useState<number>(
		parseInt(adempimento_id || "")
	);

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const tipi_adempimenti = useSelector<RootState, TipoAdempimento[]>(
		(state) => state.adempimentiState.tipiAdempimento.results
	);

	useEffect(() => {
		adempimentoId && dispatch(getAdempimento(adempimentoId));
	}, []);

	const adempimento: Adempimento | undefined = useSelector<
		RootState,
		Adempimento | undefined
	>((state) =>
		state.adempimentiState.adempimenti.results.find(
			(adempimento) => adempimento?.id == adempimentoId
		)
	);

	useEffect(() => {
		if (adempimentoId) {
			dispatch(getAdempimento(adempimentoId));
		}
		dispatch(fetchTipiAdempimento());
	}, [adempimentoId]);

	const adempimentoStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.adempimentiState.adempimenti.errorsStack.status
	);

	const cliente: Cliente | undefined = useSelector<
		RootState,
		Cliente | undefined
	>((state) =>
		state.clientiState.clienti.results.find(
			(cliente) => cliente?.id == adempimento?.cliente
		)
	);

	const [currentTab, setCurrentTab] = useState("1");
	const handleTabChange = (event: any, newValue: string) => {
		setCurrentTab(newValue);
	};

	const [openRicercaIter, setOpenRicercaIter] = useState(false);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 1,
				pr: 4,
			}}
		>
			<AdempimentoPageBreadcrumbs
				identificativoPosizione={`${adempimento?.id}`}
			/>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={adempimentoStatus == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* <Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={4}
				sx={{ mb: 1 }}
			> */}

			<Grid container spacing={1}>
				<Grid item xs={6} sm={6} md={6} lg={3}>
					<Typography variant="h4" color="text.secondary" gutterBottom>
						Adempimento
						<Chip
							color="primary"
							variant="outlined"
							label={adempimento?.id}
							sx={{ ml: 1 }}
						/>
					</Typography>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={3}>
					<Typography
						variant="h5"
						component={Link}
						to={Routes_path.PRODUTTORE + adempimento?.produttore}
					>
						{adempimento?.produttore_descrizione}
					</Typography>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={3}>
					<Typography variant="h5">
						{
							Object.entries(StatoAdempimentoTypes).find(([key, value]) => {
								return key == adempimento?.stato;
							})?.[1]
						}
					</Typography>
				</Grid>
			</Grid>

			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9}>
					<Stack spacing={2}>
						{adempimento && (
							<AdempimentoForm
								adempimento={adempimento}
								consentiModifica={utenteAutorizzato(utente, [
									PermissionTypes.GESTORI_ITER,
									PermissionTypes.OPERATORI,
								])}
							/>
						)}
					</Stack>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={3}>
					<Card
						elevation={2}
						sx={{
							minHeight: "80%",
							// backgroundColor: "#ddd" ,
						}}
					>
						<Box sx={{ backgroundColor: "#1b75b155", px: 2, py: 1 }}>
							<Typography variant="h5" color="text.secondary" sx={{ mb: 1 }}>
								POD associati all'adempimento
							</Typography>
						</Box>

						{adempimento?.pods_pack?.map((adempimento_pod) => (
							<CardContent sx={{ py: 1 }} key={adempimento_pod.id}>
								<Typography
									component={Link}
									to={Routes_path.POD + adempimento_pod.id}
								>
									{adempimento_pod.codice}
								</Typography>
							</CardContent>
						))}
					</Card>
				</Grid>
			</Grid>
			{adempimento && (
				<Paper
					elevation={2}
					sx={{
						borderBottom: 1,
						borderColor: "divider",
					}}
				>
					<AdempimentiDocumentiList
						adempimento={adempimento}
						consentiModifica={utenteAutorizzato(utente, [
							PermissionTypes.GESTORI_ITER,
							PermissionTypes.OPERATORI,
						])}
					/>
				</Paper>
			)}
		</Box>
	);
};

export default AdempimentoPage;
