import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Routes_path } from "../../../../routerApp";
import {
	Progetto,
	chiudiProgetto,
	creaSopralluogoProgetto,
} from "./progettiSlice";
import {
	IterConnessione,
	presaInCarico,
	avviaProgetto,
	assegnaPratica,
} from "components/main/iterConnessioni/iterConnessioneSlice";

import { StatoSopralluogoTypes } from "../../../sopralluoghi/sopralluoghiSlice";
import moment from "moment";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";

import RigettaProgettoForm from "./RigettaProgettoForm";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
interface AzioniProgettistaCardProps {
	iterConnessione: IterConnessione;
	progetto?: Progetto;
}

const AzioniProgettistaCard = ({
	iterConnessione,
	progetto,
}: AzioniProgettistaCardProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const [formRigetto, setFormRigetto] = useState<boolean>(false);

	return (
		<Card sx={{ mt: 2, backgroundColor: "#ddd" }}>
			<CardContent>
				{progetto?.sopralluoghi.map((sopralluogo) => (
					<Link to={Routes_path.SOPRALLUOGO + sopralluogo.id}>
						<Alert severity={sopralluogo.stato == "C" ? "success" : "warning"}>
							sopralluogo {sopralluogo.data_sopralluogo}{" "}
							{
								Object.entries(StatoSopralluogoTypes).find(
									([key, value]) => key == sopralluogo.stato
								)?.[1]
							}
						</Alert>
					</Link>
				))}
				{utenteAutorizzato(utente, [
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.PROGETTISTI,
				]) &&
					!progetto?.progetto_completo.progetto_completo && (
						<Button
							variant="contained"
							size="large"
							disabled={progetto?.progetto_completo.progetto_completo}
							sx={{ width: "100%", mt: 1 }}
							onClick={() => {
								progetto?.id && dispatch(creaSopralluogoProgetto(progetto));
							}}
						>
							Crea Sopralluogo
						</Button>
					)}
			</CardContent>
			<CardContent>
				{["F"].includes(iterConnessione.stato) && (
					<Stack sx={{ width: "100%" }} spacing={2}>
						{!progetto?.progetto_completo.progetto_completo && (
							<Alert severity="error" sx={{ mt: 1 }}>
								<AlertTitle>Attenzione manca:</AlertTitle>
								<Typography
									variant="body2"
									// component="div"
									// color="text.secondary"
									// sx={{
									// 	color: theme?.palette?.error.main,
									// }}
									sx={{ whiteSpace: "pre-line" }}
								>
									{progetto?.progetto_completo.dati_mancanti}
								</Typography>
							</Alert>
						)}
						<Button
							variant="contained"
							size="large"
							disabled={!progetto?.progetto_completo.progetto_completo}
							sx={{ width: "100%", mt: 1 }}
							onClick={() => {
								progetto?.id && dispatch(chiudiProgetto(progetto));
							}}
						>
							Concludi Progetto
						</Button>
					</Stack>
				)}

				{!formRigetto &&
					utenteAutorizzato(utente, [
						PermissionTypes.GESTORI_ITER,
						PermissionTypes.OPERATORI,
						PermissionTypes.PROGETTISTI,
					]) && (
						<Button
							variant="contained"
							color="secondary"
							size="large"
							disabled={!progetto}
							sx={{ width: "100%", mt: 1 }}
							onClick={() => {
								setFormRigetto(!formRigetto);
							}}
						>
							rigetta
						</Button>
					)}
				{progetto && formRigetto && (
					<RigettaProgettoForm
						consentiModifica={true}
						progetto={progetto}
						annullaRigetto={() => setFormRigetto(false)}
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default AzioniProgettistaCard;
