import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
} from "../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import {
	OfficinaElettrica,
	saveOfficinaElettrica,
} from "./officinaElettricaSlice";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";

import { Button, Divider } from "@mui/material";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

interface IterConnessioneOfficinaElettricaFormProps {
	iterConnessione: IterConnessione;
	officinaElettrica: OfficinaElettrica;
	consentiModifica: boolean;
}

const IterConnessioneOfficinaElettricaForm = ({
	iterConnessione,
	officinaElettrica,
	consentiModifica = false,
}: IterConnessioneOfficinaElettricaFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<OfficinaElettrica>();

	const listaCampi = Object.keys(officinaElettrica) as Array<
		keyof OfficinaElettrica
	>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, officinaElettrica[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			officinaElettrica?.errorsStack?.fieldsErrors &&
				officinaElettrica.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: officinaElettrica.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		officinaElettrica?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		officinaElettrica?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [
		officinaElettrica,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveOfficinaElettrica = (
		officinaElettrica: OfficinaElettrica
	) => {
		consentiModifica &&
			dispatch(
				saveOfficinaElettrica({
					officinaElettricaToSave: officinaElettrica,
				})
			);
	};

	const onSubmit: SubmitHandler<OfficinaElettrica> = (officinaElettrica) => {
		handlerSaveOfficinaElettrica(officinaElettrica);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{officinaElettrica?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{officinaElettrica?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							data invio pratica OfficinaElettrica:
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: officinaElettrica.data_consegna
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{officinaElettrica.data_consegna
									? moment(officinaElettrica.data_consegna).format("dddd LL")
									: "inserisci data invio pratica OfficinaElettrica"}
							</Typography>
						) : (
							<DesktopDatePicker
								// components={{
								// 	OpenPickerIcon: ClockIcon,
								// }}
								// inputFormat="DD/MM/YY HH:mm"
								// mask={"__/__/__ __:__"}
								// label="Data Progetto"
								// {...register("data_ora_carico_pianificata")}
								value={
									watch("data_consegna") || officinaElettrica.data_consegna
								}
								renderInput={(params: any) => (
									<TextField
										{...params}
										error={!!errors?.data_consegna}
										helperText={errors?.data_consegna?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										// {...register("data_ora_fine_turno")}
									/>
								)}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_consegna",
										newValue ? new Date(newValue.toDate()) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_consegna");
								}}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							protocollo dogane:
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: officinaElettrica.codice_ricevuta
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{officinaElettrica.codice_ricevuta
									? officinaElettrica.codice_ricevuta?.toString()
									: "inserisci protocollo dogane"}
							</Typography>
						) : (
							<TextField
								// label="pod"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("codice_ricevuta")}
								error={!!errors?.codice_ricevuta}
								helperText={errors?.codice_ricevuta?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							data rilascio OfficinaElettrica:
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: officinaElettrica.data_rilascio
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{officinaElettrica.data_rilascio
									? moment(officinaElettrica.data_rilascio).format("dddd LL")
									: "inserisci data rilascio OfficinaElettrica:"}
							</Typography>
						) : (
							<DesktopDatePicker
								// components={{
								// 	OpenPickerIcon: ClockIcon,
								// }}
								// inputFormat="DD/MM/YY HH:mm"
								// mask={"__/__/__ __:__"}
								// label="Data Progetto"
								// {...register("data_ora_carico_pianificata")}
								value={
									watch("data_rilascio") || officinaElettrica.data_rilascio
								}
								renderInput={(params: any) => (
									<TextField
										{...params}
										error={!!errors?.data_rilascio}
										helperText={errors?.data_rilascio?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										// {...register("data_ora_fine_turno")}
									/>
								)}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_rilascio",
										newValue ? new Date(newValue.toDate()) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_rilascio");
								}}
							/>
						)}
					</Grid>
				</Grid>
				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						setEditing(false);
						// reset();
					}}
					sx={{ ...(!editing && { display: "none" }) }}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ...(!editing && { display: "none" }), ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default IterConnessioneOfficinaElettricaForm;
