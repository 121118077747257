import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Produttore, saveProduttore } from "./produttoriSlice";
import { useAppDispatch, RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Comune } from "../comuni/comuniSlice";
import {
	IterConnessione,
	saveIterConnessione,
	resetNuovoIterConnessioneId,
} from "../../main/iterConnessioni/iterConnessioneSlice";
import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	StatoIter,
} from "../../anagrafiche/kpi/kpiSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import MenuItem from "@mui/material/MenuItem";
import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";

import { Button, Divider } from "@mui/material";
import { theme } from "../../theme";

interface ProduttoreFormProps {
	produttore: Produttore;
}

const ProduttoreForm = ({ produttore }: ProduttoreFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Produttore>();

	const listaCampi = Object.keys(produttore) as Array<keyof Produttore>;

	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const [pesonaFisica, setPesonaFisica] = useState<boolean>(true);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	const [comuneNascitaCorrente, setComuneNascitaCorrente] = useState<
		Comune | undefined
	>(undefined);

	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);

	const nuovoIterConnessioneId = useSelector<RootState, number | undefined>(
		(state) => state.iterConnessioniState.nuovoIterConnessioneId
	);
	const [ampliamento, setAmpliamento] = useState<boolean | undefined>(
		undefined
	);
	const [altroPod, setAltroPod] = useState<boolean | undefined>(undefined);
	useEffect(() => {
		nuovoIterConnessioneId &&
			navigate(Routes_path.ITER_CONNESSIONE + nuovoIterConnessioneId);
		dispatch(resetNuovoIterConnessioneId());
	}, [nuovoIterConnessioneId]);

	useEffect(
		() => {
			reset();
			listaCampi.map((field) => {
				setValue(field, produttore[field]);
			});
		},
		[
			// pesonaFisica,
			// listaCampi.forEach((field) => {
			// 	produttore[field];
			// }),
			// produttore.id,
		]
	);

	useEffect(() => {
		setPesonaFisica(getValues("tipo_persona") == "PF");
	}, [getValues("tipo_persona")]);

	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune"), pesonaFisica]);

	useEffect(() => {
		setComuneNascitaCorrente(
			comuni.find(
				(comuneScelto) => comuneScelto.id == getValues("comune_nascita")
			)
		);
	}, [comuni, watch("comune_nascita"), pesonaFisica]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			produttore?.errorsStack?.fieldsErrors &&
				produttore.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: produttore.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		produttore?.errorsStack?.fieldsErrors,
		// produttore.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveProduttore = (produttore: Produttore) => {
		dispatch(saveProduttore(produttore));
	};

	const handlerCreateIterConnessione = (semplificato: boolean) => {
		const iterConnessione: IterConnessione = {
			super_bonus: false,
			iter_semplificato: semplificato,
			produttore: produttore.id || 0,
			produttore_descrizione: "",
			indirizzo: produttore.indirizzo,
			numero_civico: produttore.numero_civico,
			cap: produttore.cap,
			comune: produttore.comune || undefined,
			stato: "A",
			ampliamento: ampliamento,
			altro_pod: altroPod,
		};
		// handleSubmit(onSubmit);
		dispatch(saveProduttore({ ...getValues() }));

		dispatch(
			saveIterConnessione({
				iterConnessioneToSave: iterConnessione,
			})
		);
	};

	const onSubmit: SubmitHandler<Produttore> = (produttore) => {
		handlerSaveProduttore(produttore);
	};

	return (
		<Box sx={{ my: 1, pt: 2 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{produttore?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{produttore?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				{produttore.tipo_persona == "PF" ? (
					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<Typography variant="caption">nome:</Typography>
							<Typography>{produttore.nome}</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<Typography variant="caption">cognome:</Typography>

							<Typography>{produttore.cognome}</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<Typography variant="caption">codice fiscale:</Typography>

							<Typography>{produttore.codice_fiscale}</Typography>
						</Grid>

						<Grid item xs={12} sm={6} md={6} lg={2}>
							<Typography variant="caption">comune nascita:</Typography>

							<Typography>{comuneNascitaCorrente?.nome}</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<Typography variant="caption">nazione nascita:</Typography>
							<Typography>{produttore.nazione_nascita}</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<Typography variant="caption">data nascita:</Typography>

							<Typography>
								{moment(produttore.data_nascita).format("LL")}
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={6} md={6} lg={3}>
							<Typography variant="caption">ragione sociale:</Typography>
							<Typography>{produttore.ragione_sociale}</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={3}>
							<Typography variant="caption">Partita Iva:</Typography>

							<Typography>{produttore.piva}</Typography>
						</Grid>
					</Grid>
				)}
				<Divider
					textAlign="left"
					sx={{ mb: 2, color: theme.palette.primary.main }}
				>
					Indirizzo di residenza
				</Divider>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="indirizzo*"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("indirizzo")}
							error={!!errors?.indirizzo}
							helperText={errors?.indirizzo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="numero civico*"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("numero_civico")}
							error={!!errors?.numero_civico}
							helperText={errors?.numero_civico?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="cap*"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("cap")}
							error={!!errors?.cap}
							helperText={errors?.cap?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<Autocomplete
							key={comuneCorrente?.id}
							// disabled={getValues("confermato")}
							value={comuneCorrente}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							onChange={(event: any, newValue: any) => {
								setValue("comune", newValue ? newValue.id : null, {
									shouldDirty: true,
								});
							}}
							id="comune"
							options={comuni}
							getOptionLabel={(option) => option.nome}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									{option.nome}
								</Box>
							)}
							renderInput={(params: any) => (
								<TextField
									variant="outlined"
									{...params}
									label="Comune*"
									error={!!errors?.comune}
									helperText={errors?.comune?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<Typography
							variant="body1"
							component="div"
							color="text.secondary"
							gutterBottom
						>
							{comuneCorrente?.provincia}
						</Typography>
					</Grid>
				</Grid>
				<Divider
					textAlign="left"
					sx={{ mb: 2, color: theme.palette.primary.main }}
				>
					Contatti
				</Divider>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="cellulare*"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("cellulare")}
							error={!!errors?.cellulare}
							helperText={errors?.cellulare?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					{/* <Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="telefono fisso*"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("telefono_fisso")}
							error={!!errors?.telefono_fisso}
							helperText={errors?.telefono_fisso?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid> */}
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="email*"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email")}
							error={!!errors?.email}
							helperText={errors?.email?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<Stack
							direction="row"
							spacing={2}
							justifyContent="center"
							alignItems="center"
						>
							<Button
								disabled={!isDirty}
								variant="outlined"
								type="submit"
								// sx={{ display: "none" }}
							>
								Salva
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default ProduttoreForm;
