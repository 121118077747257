import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import moment from "moment";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IterConnessioniProduttoreGrid from "./IterConnessioniProduttoreGrid";
import AdempimentiProduttoreGrid from "./AdempimentiProduttoreGrid";
import PodsProduttoreGrid from "./PodsProduttoreGrid";

import ArrowStepper from "../../common/ArrowStepper";
import {
	testoBottoneProseguimento,
	passiInizioPratica,
} from "./testoBottoneProseguimento";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../authentication/permissionsGroups";

import { UtenteLogged } from "../../authentication/authenticationSlice";
import { Produttore, getProduttore, TipoPersoneTypes } from "./produttoriSlice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProduttoreForm from "./ProduttoreForm";
import AvvioIterConnessioneForm from "./AvvioIterConnessioneForm";
import NuovoProduttoreForm from "./NuovoProduttoreForm";
// import ProduttoreBreadcrumbs from "./ProduttoreBreadcrumbs";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button, Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import { theme } from "../../theme";
import Paper from "@mui/material/Paper";

import {
	DataGrid,
	GridToolbar,
	GridRowsProp,
	GridRowModel,
	GridColDef,
	GridCellEditStopParams,
	GridCellEditStopReasons,
	MuiEvent,
	GridRenderCellParams,
	GridValueFormatterParams,
} from "@mui/x-data-grid";
import { ServizioTypes } from "../kpi/kpiSlice";

const ProduttorePage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const { produttore_id, tipoPratica_id } = useParams();
	// const produttoreId: number = parseInt(produttore_id || "");

	const initialTipoPraticaId = (() => {
		for (const key in ServizioTypes) {
			if (key === tipoPratica_id) {
				return key as ServizioTypes;
			}
		}
		return undefined;
	})();

	const [tipoPraticaId, setTipoPraticaId] = useState<ServizioTypes | undefined>(
		initialTipoPraticaId
	);

	// useEffect(() => {
	// 	const tipoPraticaId = (() => {
	// 		for (const key in ServizioTypes) {
	// 			if (key === tipoPratica_id) {
	// 				return key as ServizioTypes;
	// 			}
	// 		}
	// 		return undefined;
	// 	})();
	// 	setTipoPraticaId(tipoPraticaId);
	// }, [tipoPratica_id]);

	const [produttoreId, setProduttoreId] = useState<number>(
		parseInt(produttore_id || "")
	);

	const location = useLocation();
	const navigate = useNavigate();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const produttoreCorrenteId = useSelector<RootState, number | undefined>(
		(state) => state.produttoriState.produttoreCorrenteId
	);

	useEffect(() => {
		produttoreId && dispatch(getProduttore(produttoreId));
	}, []);

	useEffect(() => {
		produttoreCorrenteId && setProduttoreId(produttoreCorrenteId);
		produttoreCorrenteId && tipoPraticaId
			? navigate(
					`${Routes_path.PRODUTTORE}${produttoreCorrenteId}/${tipoPraticaId}`,
					{
						replace: true,
					}
			  )
			: navigate(`${Routes_path.PRODUTTORE}${produttoreCorrenteId}`, {
					replace: true,
			  });
	}, [produttoreCorrenteId]);

	const produttore: Produttore | undefined = useSelector<
		RootState,
		Produttore | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore?.id == produttoreId
		)
	);
	const errorsStack: ErrorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.produttoriState.produttori.errorsStack
	);

	return (
		<>
			{/* <ProduttoreBreadcrumbs produttore={produttore} /> */}
			<Box
				sx={{
					backgroundColor: "#fff",
					elevation: 20,
					p: 2,
				}}
			>
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={errorsStack.status == ErrorStatusTypes.PENDING}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{tipoPraticaId && (
					<ArrowStepper
						titolo={testoBottoneProseguimento(tipoPraticaId)}
						passi={passiInizioPratica}
						colore="#658822"
						indicePassoCorrente={produttoreId ? 1 : 0}
					/>
				)}

				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={4}
					sx={{ mb: 1 }}
				>
					<Typography variant="h3" component="div" color="text.secondary">
						{produttore?.id}
					</Typography>
					<Typography variant="h3" component="div" color="text.secondary">
						{produttore?.tipo_persona == "PF"
							? `${produttore?.nome} ${produttore?.cognome}`
							: produttore?.ragione_sociale}
					</Typography>
				</Stack>

				<Divider sx={{ mb: 1 }} />

				<Box sx={{ mb: 2 }}>
					{produttoreId ? (
						produttore ? (
							<ProduttoreForm produttore={produttore} />
						) : (
							<Box>non trovato</Box>
						)
					) : (
						<NuovoProduttoreForm tipoPraticaId={tipoPraticaId} />
					)}
				</Box>

				{!!produttoreId && produttore && tipoPraticaId && (
					<Paper sx={{ p: 2, backgroundColor: "lightgoldenrodyellow", mb: 2 }}>
						<AvvioIterConnessioneForm
							key={`avvio_${produttore?.updated_at}_${tipoPraticaId}`}
							produttore={produttore}
							tipoPraticaId={tipoPraticaId}
							setTipoPraticaId={setTipoPraticaId}
						/>
					</Paper>
				)}

				{!!produttore?.numero_pods &&
					produttore.pods &&
					utenteAutorizzato(utente, [
						PermissionTypes.GESTORI_ITER,
						PermissionTypes.OPERATORI,
						PermissionTypes.PROGETTISTI,
					]) && (
						<Box sx={{ mb: 2 }}>
							<PodsProduttoreGrid pods={produttore.pods} />
						</Box>
					)}
				<Box>
					{!!produttore?.numero_iter_connessioni &&
						produttore.iter_connessioni &&
						utenteAutorizzato(utente, [
							PermissionTypes.GESTORI_ITER,
							PermissionTypes.OPERATORI,
							PermissionTypes.PROGETTISTI,
						]) && (
							<Box sx={{ mb: 2 }}>
								<IterConnessioniProduttoreGrid
									iterConnessioni={produttore.iter_connessioni}
								/>
							</Box>
						)}
				</Box>
				<Box>
					{produttore && (
						<Box sx={{ mb: 2 }}>
							<AdempimentiProduttoreGrid
								produttore={produttore}
								consentiModifica={utenteAutorizzato(utente, [
									PermissionTypes.GESTORI_ITER,
									PermissionTypes.OPERATORI,
									PermissionTypes.PROGETTISTI,
								])}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};

export default ProduttorePage;
