import React, { useEffect, useState } from "react";

import { useForm, SubmitHandler } from "react-hook-form";

import { Installatore } from "./installatoriSlice";

import { ErrorsStack, ErrorStatusTypes } from "../../common/errorsDeclarations";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { Box } from "@mui/system";
import { Button } from "@mui/material";

import Stack from "@mui/material/Stack";
import { theme } from "../../theme";

interface InstallatoriFormProps {
	installatoreNuovo: Installatore;
	saveInstallatore: (installatore: Installatore) => void;
	annulla: () => void;
}

const InstallatoriForm = ({
	installatoreNuovo,
	// regimiCommerciali,
	saveInstallatore,
	annulla,
}: InstallatoriFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Installatore>();

	const listaCampi = Object.keys(installatoreNuovo) as Array<
		keyof Installatore
	>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, installatoreNuovo[field]);
		});
	}, []);

	const onSubmit: SubmitHandler<Installatore> = (installatore) => {
		saveInstallatore(installatore);
	};

	useEffect(() => {
		listaCampi.forEach((field) => {
			installatoreNuovo.errorsStack?.fieldsErrors &&
				installatoreNuovo.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: installatoreNuovo.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [installatoreNuovo?.errorsStack?.fieldsErrors]);

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					// rowSpacing={1}
					columnSpacing={1}
					// justifyContent="space-between"
					// alignItems="flex-start"
				>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="ragione_sociale"
							label="Ragione Sociale"
							// disabled={getValues("confermato")}
							{...register("ragione_sociale")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							required={true}
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.ragione_sociale}
							helperText={errors?.ragione_sociale?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="nome"
							label="Nome Referente"
							// disabled={getValues("confermato")}
							{...register("nome")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							// required={true}
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.nome}
							helperText={errors?.nome?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="cognome"
							label="Cognome Referente"
							// disabled={getValues("confermato")}
							{...register("cognome")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.cognome}
							helperText={errors?.cognome?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="cellulare"
							label="Cellulare"
							// disabled={getValues("confermato")}
							{...register("cellulare")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="number"
							autoComplete={"off"}
							error={!!errors?.cellulare}
							helperText={errors?.cellulare?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="telefono_fisso"
							label="Telefono fisso"
							// disabled={getValues("confermato")}
							{...register("telefono_fisso")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="number"
							autoComplete={"off"}
							error={!!errors?.telefono_fisso}
							helperText={errors?.telefono_fisso?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="email"
							label="Email"
							// disabled={getValues("confermato")}
							{...register("email")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.email}
							helperText={errors?.email?.message}
						/>
					</Grid>
					<Grid item xs={12} md={4} sx={{ mb: 1 }}>
						<Stack direction="row" justifyContent="flex-start" spacing={1}>
							<Button disabled={!isDirty} variant="contained" type="submit">
								Salva
							</Button>
							<Button variant="outlined" onClick={annulla}>
								Annulla
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default InstallatoriForm;
