export const ACCESS_TOKEN = "access_token_" + process.env.APP_NAME;
export const REFRESH_TOKEN = "refresh_token_" + process.env.APP_NAME;
export const DATA_CORRENTE = "dataCorrente_" + process.env.APP_NAME;
export const DATA_CORRENTE_DATA = "dataCorrente_data_" + process.env.APP_NAME;

export async function handleResponse(response: any) {
	if (response.ok) return response.json();
	console.log("response.status== ", response.status);
	if (response.status >= 400 && response.status < 500) {
		if (response.status == 401) {
			const messaggioErrore =
				"API call server error: status:" +
				response.status +
				" " +
				response.statusText +
				" at: " +
				response.url;

			// alert(messaggioErrore);

			localStorage.removeItem(ACCESS_TOKEN);
			localStorage.removeItem(REFRESH_TOKEN);
			localStorage.removeItem("timeRefresh");
		}
		if (response.status == 403) {
			// accesso negato
			const messaggioErrore =
				"API call server error: status:" +
				response.status +
				" " +
				response.statusText +
				" at: " +
				response.url;
			console.log(messaggioErrore);

			// alert(messaggioErrore);
		}
		if (response.status == 413) {
			// accesso negato
			const messaggioErrore =
				"API call server error 413: status:" +
				response.status +
				" " +
				response.statusText +
				" at: " +
				response.url;
			console.log(messaggioErrore);

			// alert(messaggioErrore);
		}
		// So, a server-side validation error occurred.
		// Server side validation returns a string error message, so parse as text instead of json.
		// const error = await response.text();
		const error = await response.text();
		throw new Error(error);
		// throw error;
	}

	const messaggioErrore =
		"API call server error: status:" +
		response.status +
		" " +
		response.statusText +
		" at: " +
		response.url;

	// alert(messaggioErrore);

	throw new Error(messaggioErrore);
}

// In a real app, would likely call an error logging service.
export function handleError(error: any) {
	// eslint-disable-next-line no-console
	console.error("Errore Server: ", error);

	const messaggioErrore =
		"API call server error: status:" +
		error.status +
		" " +
		error.statusText +
		" at: " +
		error.url;
	console.log(messaggioErrore);

	// alert(error);
	// throw new Error(error);
	throw error;
}

// In a real app, would likely call an error logging service.
export function headerset() {
	const access_token = localStorage.getItem(ACCESS_TOKEN);
	const refresh_token = localStorage.getItem(REFRESH_TOKEN);

	!access_token && window.location.replace("/signin");
	const Authorization = "Bearer " + access_token;
	const headers = {
		access_token: access_token,
		refresh_token: refresh_token,
		Authorization: Authorization,
	};
	return headers;
}
// export async function handleResponse(response) {
//   if (response.ok) return response.json();
//   if (response.status === 400) {
//     // So, a server-side validation error occurred.
//     // Server side validation returns a string error message, so parse as text instead of json.
//     const error = await response.text();
//     throw new Error(error);
//   }
//   throw new Error("Network response was not ok.");
// }

// // In a real app, would likely call an error logging service.
// export function handleError(error) {
//   // eslint-disable-next-line no-console
//   console.error("API call failed. " + error);
//   throw error;
// }
