import React, { useState, useEffect } from "react";
import { RootState, useAppDispatch } from "../../../../../../redux/store";

// import { Parametri } from "../../anagrafiche/anagraficheSlice";
import { FineLavoro, saveFineLavoro } from "./fineLavoriSlice";

import { StatoConnessioneTypes } from "../../../../../anagrafiche/kpi/kpiSlice";

import moment from "moment";
import { theme } from "../../../../../theme";
// import AlleggatiPage from "../../allegati/AlleggatiPage";
import { styled } from "@mui/system";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleIcon from "@mui/icons-material/People";
import TimerIcon from "@mui/icons-material/Timer";
import Chip from "@mui/material/Chip";
// import Chip from "@material-ui/core/Chip"; ************************ da cancellare se non ci sono malfunzionamenti
import Grid from "@mui/material/Grid";
// import { Link } from "react-router-dom";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Typography } from "@mui/material";
import IterConnessioneFineLavoroForm from "./IterConnessioneFineLavoroForm";
import AllegatiPage from "../../../../../allegati/AllegatiPage";

const NumberChip = styled(Chip)({
	"&&": {
		height: "1.5rem",
		backgroundColor: "#1b75b1",
		color: "#e1e1e1",
		fontSize: "1.2rem",
		fontWeight: 500,
		paddingLeft: 0,
		paddingRight: 0,
		borderRadius: 5,
	},
	"& .MuiChip-label": {
		paddingLeft: "5px",
		paddingRight: "5px",
	},
});
interface IterConnessioneFineLavoroCardProps {
	fineLavoro: FineLavoro;
	// parametri: Parametri;
	// expanded: string | false;
	consentiModifica: boolean;
	iterSemplificato: boolean;
	// handleChangeFineLavoroPanel: (
	// 	fineLavoro: FineLavoro
	// ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}
const IterConnessioneFineLavoroCard = ({
	fineLavoro,
	// parametri,
	// expanded,
	consentiModifica,
	iterSemplificato,
}: // handleChangeFineLavoroPanel,
IterConnessioneFineLavoroCardProps) => {
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 400,
		width: "95%",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		pb: 1,
		textAlign: "center",
	};

	const dispatch = useAppDispatch();

	const handlerSaveFineLavoro = (fineLavoro: FineLavoro) => {
		consentiModifica &&
			dispatch(saveFineLavoro({ fineLavoroToSave: fineLavoro }));
	};

	return (
		<Grid container spacing={2}>
			<Grid item lg={12}>
				<Stack spacing={2}>
					{fineLavoro && (
						<IterConnessioneFineLavoroForm
							fineLavoro={fineLavoro}
							consentiModifica={consentiModifica}
							iterSemplificato={iterSemplificato}
						/>
					)}
					<Divider
						textAlign="left"
						sx={{ mt: 4, mb: 1, color: theme.palette.primary.main }}
					>
						Allegati
					</Divider>
					{fineLavoro.listaDocumentiPerTipo && (
						<AllegatiPage
							listaDocumentiPerTipo={fineLavoro.listaDocumentiPerTipo}
							iterConnessioneId={fineLavoro.iter_connessione || 0}
							fineLavoriId={fineLavoro.id || 0}
							consentiModifica={consentiModifica}
						/>
					)}
				</Stack>
			</Grid>
		</Grid>
	);
};

export default IterConnessioneFineLavoroCard;
