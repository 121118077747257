import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";

import AllegatiUploadForm from "./AllegatiUploadForm";
import Tooltip from "@mui/material/Tooltip";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../common/errorsDeclarations";

import {
	Allegato,
	TipoDocumento,
	DocumentiPerTipo,
	AllegatiState,
	uploadAllegato,
	fetchAllegati,
	fetchTipiDocumento,
	deleteAllegato,
} from "./allegatiSlice";

import ConfermaAnnulla from "../common/ConfermaAnnulla";

import { IterConnessione } from "../main/iterConnessioni/iterConnessioneSlice";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Typography, Link, IconButton } from "@mui/material";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";

interface AllegatiPageProps {
	listaDocumentiPerTipo: DocumentiPerTipo[];
	iterConnessioneId?: number;
	progettoId?: number;
	richiestaConnessioneId?: number;
	fineLavoriId?: number;
	allaccioId?: number;
	eneaId?: number;
	officinaElettricaId?: number;
	autorizzazioneComunaleId?: number;
	sopralluogoId?: number;
	sopralluogoElettricoId?: number;
	consentiModifica: boolean;
}

const AllegatiPage = ({
	listaDocumentiPerTipo,
	iterConnessioneId,
	progettoId,
	richiestaConnessioneId,
	fineLavoriId,
	allaccioId,
	eneaId,
	officinaElettricaId,
	autorizzazioneComunaleId,
	sopralluogoId,
	sopralluogoElettricoId,
	consentiModifica = false,
	...props
}: AllegatiPageProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchTipiDocumento());
	}, []);

	const tipiDocumento: TipoDocumento[] = useSelector<
		RootState,
		TipoDocumento[]
	>((state) => state.allegati.tipiDocumento.results);

	const allegatoCorrente: AllegatiState | undefined = useSelector<
		RootState,
		AllegatiState | undefined
	>((state) => state.allegati.allegatoCorrente);

	// const [poiChiudi, setPoiChiudi] = useState(false);

	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const handleUploadAllegato = (
		allegato: Allegato,
		selectedFile: File | null
	) => {
		selectedFile &&
			dispatch(
				uploadAllegato({
					allegato: allegato,
					file: selectedFile,
				})
			);
	};

	const [allegatoSelezionato, setAllegatoSelezionato] = useState<Allegato>();

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	const handleDelete = (allegato: Allegato) => {
		dispatch(deleteAllegato(allegato));
		handleConfermaClose();
	};

	const handleModalopenConfermaOpen = (allegato: Allegato) => {
		setAllegatoSelezionato(allegato);
		setOpenConferma(true);
	};

	return (
		<Grid container spacing={2}>
			{listaDocumentiPerTipo.map((tipoDocumento) => (
				<Grid
					key={"tipoDoc_" + tipoDocumento.tipo_id}
					item
					xs={12}
					sm={6}
					md={4}
					lg={3}
				>
					<Card
						sx={{
							mt: 2,
							minHeight: "100%",
							backgroundColor:
								tipoDocumento.allegati_presenti.length == 0 &&
								tipoDocumento.obbligo_tipo
									? "#FF222222"
									: "inherit",
						}}
					>
						<CardContent>
							<Typography
								variant="h5"
								// sx={{ fontSize: 14 }}
								color="text.secondary"
								gutterBottom
							>
								{tipoDocumento.tipo}
							</Typography>
							<Box sx={{ ml: 2 }}>
								{tipoDocumento.allegati_presenti.map((allegato) => (
									<Stack
										key={`allegato_${allegato.id}`}
										direction="row"
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={1}
									>
										{" "}
										<Tooltip title={allegato.descrizione}>
											<Link
												variant="body2"
												href={allegato.file}
												target="_blank"
												key={"allegato_" + allegato.id}
											>
												file
												{/* {allegato.file_name} */}
											</Link>
										</Tooltip>
										{consentiModifica && tipoDocumento.modificabile && (
											<IconButton
												aria-label="delete"
												size="small"
												// onClick={() => handleDelete(allegato)}
												onClick={() => handleModalopenConfermaOpen(allegato)}
											>
												<DeleteIcon />
											</IconButton>
										)}
									</Stack>
								))}
								{/* {tipoDocumento.allegati_presenti.length == 0 &&
									(tipoDocumento.obbligo_tipo ? (
										<Typography color="red">Obbligatorio</Typography>
									) : (
										<Typography>Facoltativo</Typography>
									))} */}
							</Box>
							<Box>
								<Typography variant="caption" color="grey">
									{tipoDocumento.descrizione}
								</Typography>
							</Box>
						</CardContent>
						{consentiModifica && tipoDocumento.modificabile && (
							<CardActions>
								<AllegatiUploadForm
									allegato={{
										id: null,
										tipo_documento: tipoDocumento.tipo_id,
										descrizione: "",
										iter_connessione: iterConnessioneId,
										progetto: progettoId,
										richiesta_connessione: richiestaConnessioneId,
										fine_lavori: fineLavoriId,
										allaccio: allaccioId,
										enea: eneaId,
										officina_elettrica: officinaElettricaId,
										autorizzazione_comunale: autorizzazioneComunaleId,
										sopralluogo: sopralluogoId,
										sopralluogo_elettrico: sopralluogoElettricoId,
										autore: "",
										file: "",
										file_name: "",
									}}
									tipiDocumento={tipiDocumento}
									uploadAllegato={handleUploadAllegato}
									uploading={
										allegatoCorrente?.errorsStack.status ==
										ErrorStatusTypes.PENDING
									}
								/>
							</CardActions>
						)}
					</Card>

					<Box sx={{ mt: 0 }}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="flex-start"
							spacing={2}
							sx={{ mt: 0 }}
						>
							<Box></Box>
							<Box></Box>
						</Stack>
					</Box>
				</Grid>
			))}
			{allegatoSelezionato && (
				<ConfermaAnnulla
					handleAnnulla={handleConfermaClose}
					handleConferma={() => handleDelete(allegatoSelezionato)}
					domanda={
						"Sicuro di voler cancellare il file " +
						allegatoSelezionato?.file_name
					}
					open={openConferma}
				/>
			)}
		</Grid>
	);
};

export default AllegatiPage;
