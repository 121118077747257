import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
} from "../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../anagrafiche/tipiTensione/tipiTensioneSlice";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

import { Button, Divider } from "@mui/material";

interface IterConnessioneDatiPraticaFormProps {
	iterConnessione: IterConnessione;
	consentiModifica: boolean;
}

const IterConnessioneDatiPraticaForm = ({
	iterConnessione,
	consentiModifica = false,
}: IterConnessioneDatiPraticaFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<IterConnessione>();

	const listaCampi = Object.keys(iterConnessione) as Array<
		keyof IterConnessione
	>;

	const dispatch = useAppDispatch();

	// const [editing, setEditing] = useState(!iterConnessione.cliente);
	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const installatori = useSelector<RootState, Installatore[]>(
		(state) => state.installatoriState.installatori.results
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);
	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);
	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const gestori = useSelector<RootState, Gestore[]>(
		(state) => state.gestoriState.gestori.results
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, iterConnessione[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			iterConnessione?.errorsStack?.fieldsErrors &&
				iterConnessione.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: iterConnessione.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		iterConnessione?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		iterConnessione?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [
		iterConnessione,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveIterConnessione = (iterConnessione: IterConnessione) => {
		consentiModifica &&
			dispatch(
				saveIterConnessione({
					iterConnessioneToSave: iterConnessione,
				})
			);
	};

	const onSubmit: SubmitHandler<IterConnessione> = (iterConnessione) => {
		handlerSaveIterConnessione(iterConnessione);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							potenza disponibile*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: iterConnessione.potenza_disponibile
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{iterConnessione.potenza_disponibile
									? Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
									  })
											.format(iterConnessione.potenza_disponibile || 0)
											.toString() + " kW"
									: // iterConnessione.potenza_disponibile.toString() + " W"
									  "inserisci potenza"}
							</Typography>
						) : (
							<Controller
								name="potenza_disponibile"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_disponibile"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_disponibile}
										helperText={errors?.potenza_disponibile?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kW "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_disponibile", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							tipo di tensione*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: iterConnessione.tipo_tensione
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{tipiTensione.find(
									(tipoTensione) =>
										tipoTensione.id == iterConnessione.tipo_tensione
								)?.descrizione || "seleziona tipo tensione"}
							</Typography>
						) : (
							<ReactHookFormSelect
								name="tipo_tensione"
								// label="tipo_pratica"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.tipo_tensione?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem value="">
									<em>Nessuno</em>
								</MenuItem>

								{tipiTensione.map((tipoTensione) => {
									return (
										<MenuItem key={tipoTensione.id} value={tipoTensione.id}>
											{tipoTensione.nome} {tipoTensione.descrizione}
										</MenuItem>
									);
								})}
								{/* 
								{Object.entries(TipoTensioneTypes).map(([key, value]) => {
									return (
										<MenuItem key={key} value={key}>
											{value}
										</MenuItem>
									);
								})} */}
							</ReactHookFormSelect>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							gestore rete*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: iterConnessione.gestore_rete
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{gestori.find((reg) => reg.id == iterConnessione.gestore_rete)
									?.ragione_sociale || "seleziona gestore"}
							</Typography>
						) : (
							<ReactHookFormSelect
								name="gestore_rete"
								// label="regime_commerciale"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.gestore_rete?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem value="" key="gestore_vuoto">
									<em>Nessuno</em>
								</MenuItem>
								{gestori.map((gestore_rete) => {
									return (
										<MenuItem key={gestore_rete.id} value={gestore_rete.id}>
											{gestore_rete.ragione_sociale}
										</MenuItem>
									);
								})}
							</ReactHookFormSelect>
						)}
					</Grid>
				</Grid>
				<Divider
					textAlign="left"
					sx={{ my: 2, color: theme.palette.primary.main }}
				>
					Dati catastali del POD
				</Divider>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							foglio catastale*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: iterConnessione.foglio_catastale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{iterConnessione.foglio_catastale ||
									"inserisci foglio catastale"}
							</Typography>
						) : (
							<TextField
								// label="foglio_catastale"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("foglio_catastale")}
								error={!!errors?.foglio_catastale}
								helperText={errors?.foglio_catastale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							particella catastale*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: iterConnessione.particella_catastale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{iterConnessione.particella_catastale ||
									"inserisci particella catastale"}
							</Typography>
						) : (
							<TextField
								// label="particella_catastale"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("particella_catastale")}
								error={!!errors?.particella_catastale}
								helperText={errors?.particella_catastale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							sub particella catastale:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: iterConnessione.sub_particella_catastale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{iterConnessione.sub_particella_catastale ||
									"inserisci sub particella"}
							</Typography>
						) : (
							<TextField
								// label="sub_particella_catastale"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("sub_particella_catastale")}
								error={!!errors?.sub_particella_catastale}
								helperText={errors?.sub_particella_catastale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
				</Grid>

				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						setEditing(false);
						// reset();
					}}
					sx={{ ...(!editing && { display: "none" }) }}
				>
					Annulla
				</Button>

				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ...(!editing && { display: "none" }), ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default IterConnessioneDatiPraticaForm;
