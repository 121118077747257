import { useEffect, useState } from "react";

import moment from "moment";

import SezioneForm from "./SezioneForm";

import { Progetto, TipoSchemaTypes } from "../progettiSlice";
import SezioneCard from "./SezioneCard";
import { Box } from "@mui/system";
import { Button, ButtonGroup } from "@mui/material";

import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";

interface SezioniCardProps {
	progetto: Progetto;
	consentiModifica: boolean;
}

const SezioniCard = ({
	progetto,
	consentiModifica = false,
}: SezioniCardProps) => {
	moment.locale("it");

	const [openInputSezione, setOpenInputSezione] = useState(false);
	const [tipoSchema, setSetTipoSchema] = useState<"HYB" | "PRD" | "POS">("HYB");

	const handleClosInputSezioneProgetto = () => {
		setOpenInputSezione(false);
	};
	return (
		<Stack>
			<ButtonGroup
				variant="outlined"
				disabled={!consentiModifica}
				aria-label="schelta schema"
				sx={{ mb: 1 }}
			>
				<Button
					onClick={() => {
						setSetTipoSchema("HYB");
						setOpenInputSezione(true);
					}}
					sx={{ fontSize: { xs: 10, sm: 14 } }}
				>
					Aggiungi sezione Lato produzione DC
				</Button>
				<Button
					onClick={() => {
						setSetTipoSchema("PRD");
						setOpenInputSezione(true);
					}}
					sx={{ fontSize: { xs: 10, sm: 14 } }}
				>
					Aggiungi sezione Lato produzione AC
				</Button>
				<Button
					onClick={() => {
						setSetTipoSchema("POS");
						setOpenInputSezione(true);
					}}
					sx={{ fontSize: { xs: 10, sm: 14 } }}
				>
					Aggiungi accumulo post produzione AC
				</Button>
			</ButtonGroup>
			<Modal
				open={openInputSezione}
				onClose={() => {
					setOpenInputSezione(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						position: "absolute" as "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "70%",
						bgcolor: "background.paper",
						border: "2px solid #000",
						boxShadow: 24,
						pt: 2,
						px: 4,
						pb: 3,
					}}
				>
					<SezioneForm
						progetto={progetto}
						tipoSchema={tipoSchema}
						handleClosInputSezioneProgetto={handleClosInputSezioneProgetto}
					/>
				</Box>
			</Modal>

			{progetto?.sezioni?.map((sezione) => (
				<SezioneCard
					key={sezione.id}
					sezione={sezione}
					consentiModifica={consentiModifica}
				/>
			))}
		</Stack>
	);
};

export default SezioniCard;
