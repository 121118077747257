import { useSelector } from "react-redux";

import { RootState } from "../../../../redux/store";
import moment from "moment";

import {
	Prodotto,
	TipoProdottoTypes,
	IterConnessione,
} from "../iterConnessioneSlice";

import { Produttore } from "../../../anagrafiche/produttori/produttoriSlice";
import { StatoIterTypes, StatiIter } from "../../../anagrafiche/kpi/kpiSlice";

import IterConnessioneTimeLine from "./IterConnessioneTimeLine";
import DatiProduttoreCard from "./DatiProduttoreCard";

import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IterConnessioneSpallaProps {
	iterConnessione: IterConnessione;
}

const IterConnessioneSpalla = ({
	iterConnessione,
}: IterConnessioneSpallaProps) => {
	const produttore: Produttore | undefined = useSelector<
		RootState,
		Produttore | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore?.id == iterConnessione?.produttore
		)
	);

	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);
	return (
		<>
			{produttore && (
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="flex-end"
							spacing={2}
						>
							<Typography variant="caption">produttore:</Typography>
							<Typography variant="h5">
								{produttore.tipo_persona == "PF"
									? `${produttore.nome} ${produttore.cognome}`
									: produttore.ragione_sociale}
							</Typography>
						</Stack>
					</AccordionSummary>
					<AccordionDetails>
						<DatiProduttoreCard produttore={produttore} />
					</AccordionDetails>
				</Accordion>
			)}

			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel2a-content"
					id="panel2a-header"
				>
					<Stack
						direction="column"
						justifyContent="space-between"
						alignItems="flex-end"
						spacing={2}
					>
						<Typography variant="h5">
							Fase:{" "}
							{
								statiIter.results.find(
									(statoIter) => statoIter.stato == iterConnessione?.stato
								)?.stato_desc
							}
						</Typography>
						<Typography variant="subtitle1">
							ultimo agg.:
							{moment(iterConnessione.data_ultimo_stato).format("ddd LL")}
						</Typography>
					</Stack>
				</AccordionSummary>
				<AccordionDetails>
					<IterConnessioneTimeLine iterConnessione={iterConnessione} />
				</AccordionDetails>
			</Accordion>
		</>
	);
};

export default IterConnessioneSpalla;
