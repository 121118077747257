import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Pod, savePod } from "./podsSlice";
import { useAppDispatch, RootState } from "../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { theme } from "../../theme";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import { TipoTensione } from "../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../anagrafiche/gestori/gestoriSlice";
import { RegimeCommerciale } from "../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";

import { Button, Divider } from "@mui/material";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface PodDatiGeneraliFormProps {
	pod: Pod;
	consentiModifica: boolean;
}

const PodDatiGeneraliForm = ({
	pod,
	consentiModifica = false,
}: PodDatiGeneraliFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Pod>();

	const listaCampi = Object.keys(pod) as Array<keyof Pod>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);
	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const gestori = useSelector<RootState, Gestore[]>(
		(state) => state.gestoriState.gestori.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune"), pod]);

	const [keycodice_pod, setKeycodice_pod] = useState<string>(makeid(5));

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, pod[field]);
		});
		// setKeycodice_pod(makeid(5));
	}, [editing, pod, pod.id]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			pod?.errorsStack?.fieldsErrors &&
				pod.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: pod.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		pod?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSavePod = (pod: Pod) => {
		consentiModifica &&
			dispatch(
				savePod({
					podToSave: pod,
				})
			);
	};

	const onSubmit: SubmitHandler<Pod> = (pod) => {
		handlerSavePod(pod);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{pod?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{pod?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ my: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							iban accrediti provenienti da GSE
						</Typography>
						{!editing ? (
							<Typography
								variant="h6"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.iban_accredito_gse
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{pod.iban_accredito_gse}
							</Typography>
						) : (
							<TextField
								// label="iban_accredito_gse"
								disabled={!pod.codice}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("iban_accredito_gse")}
								error={!!errors?.iban_accredito_gse}
								helperText={errors?.iban_accredito_gse?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
				<Divider
					textAlign="left"
					sx={{ mb: 2, color: theme.palette.primary.main }}
				>
					Indirizzo del pod
				</Divider>
				<Grid container spacing={2} sx={{ mb: 2 }} key={keycodice_pod}>
					<Grid item xs={9} sm={9} md={9} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							indirizzo*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								{pod.indirizzo}
							</Typography>
						) : (
							<TextField
								// label="indirizzo"
								// disabled={!editSedecodice_pod}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("indirizzo")}
								error={!!errors?.indirizzo}
								helperText={errors?.indirizzo?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								onClick={() => setEditing(true)}
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={1}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							n. civico*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								{pod.numero_civico}
							</Typography>
						) : (
							<TextField
								// label="numero civico"
								// disabled={!editSedecodice_pod}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("numero_civico")}
								error={!!errors?.numero_civico}
								helperText={errors?.numero_civico?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								onClick={() => setEditing(true)}
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={1}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							cap*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
							>
								{pod.cap}
							</Typography>
						) : (
							<TextField
								// label="cap"
								// disabled={!editSedecodice_pod}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("cap")}
								error={!!errors?.cap}
								helperText={errors?.cap?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								onClick={() => setEditing(true)}
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={3}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							comune*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								{comuneCorrente &&
									`${comuneCorrente?.nome} (${comuneCorrente?.provincia})`}
							</Typography>
						) : (
							<Autocomplete
								key={comuneCorrente?.id}
								// disabled={!editSedecodice_pod}
								value={comuneCorrente}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								onClick={() => setEditing(true)}
								onChange={(event: any, newValue: any) => {
									setValue("comune", newValue ? newValue.id : null, {
										shouldDirty: true,
									});
								}}
								id="comune"
								options={comuni}
								getOptionLabel={(option) => option.nome}
								renderOption={(props, option) => (
									<Box component="li" {...props}>
										{option.nome}
									</Box>
								)}
								renderInput={(params: any) => (
									<TextField
										variant="outlined"
										{...params}
										// label="Comune"
										error={!!errors?.comune}
										helperText={errors?.comune?.message}
										onClick={() => setEditing(true)}
									/>
								)}
							/>
						)}
					</Grid>
				</Grid>
				<Divider
					textAlign="left"
					sx={{ my: 2, color: theme.palette.primary.main }}
				>
					Dati catastali del POD
				</Divider>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							foglio catastale*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.foglio_catastale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{pod.foglio_catastale || "inserisci foglio catastale"}
							</Typography>
						) : (
							<TextField
								// label="foglio_catastale"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("foglio_catastale")}
								error={!!errors?.foglio_catastale}
								helperText={errors?.foglio_catastale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							particella catastale*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.particella_catastale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{pod.particella_catastale || "inserisci particella catastale"}
							</Typography>
						) : (
							<TextField
								// label="particella_catastale"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("particella_catastale")}
								error={!!errors?.particella_catastale}
								helperText={errors?.particella_catastale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							sub particella catastale:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.sub_particella_catastale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{pod.sub_particella_catastale || "inserisci sub particella"}
							</Typography>
						) : (
							<TextField
								// label="sub_particella_catastale"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("sub_particella_catastale")}
								error={!!errors?.sub_particella_catastale}
								helperText={errors?.sub_particella_catastale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
				</Grid>

				<Divider
					textAlign="left"
					sx={{ my: 2, color: theme.palette.primary.main }}
				>
					Valori Totali
				</Divider>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							regime commerciale:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.regime_commerciale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{regimiCommerciali.find(
									(reg) => reg.id == pod.regime_commerciale
								)?.descrizione || "seleziona regime commerciale"}
							</Typography>
						) : (
							<ReactHookFormSelect
								name="regime_commerciale"
								// label="regime_commerciale"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.regime_commerciale?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem value="" key="regime_vuoto">
									<em>Nessuno</em>
								</MenuItem>
								{regimiCommerciali.map((regime_commerciale) => {
									return (
										<MenuItem
											key={regime_commerciale.id}
											value={regime_commerciale.id}
										>
											{regime_commerciale.nome} -{" "}
											{regime_commerciale.descrizione}
										</MenuItem>
									);
								})}
							</ReactHookFormSelect>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							gestore rete*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.gestore_rete
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{gestori.find((reg) => reg.id == pod.gestore_rete)
									?.ragione_sociale || "seleziona gestore"}
							</Typography>
						) : (
							<ReactHookFormSelect
								name="gestore_rete"
								// label="regime_commerciale"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.gestore_rete?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem value="" key="gestore_vuoto">
									<em>Nessuno</em>
								</MenuItem>
								{gestori.map((gestore_rete) => {
									return (
										<MenuItem key={gestore_rete.id} value={gestore_rete.id}>
											{gestore_rete.ragione_sociale}
										</MenuItem>
									);
								})}
							</ReactHookFormSelect>
						)}
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							Potenza Immessa*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.potenza_immessa
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{pod.potenza_immessa
									? Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
									  })
											.format(pod.potenza_immessa || 0)
											.toString() + " kW"
									: "inserisci potenza immagazzinata"}
							</Typography>
						) : (
							<Controller
								name="potenza_immessa"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_immessa"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_immessa}
										helperText={errors?.potenza_immessa?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kW "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_immessa", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							Potenza Nominale*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.potenza_nominale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{pod.potenza_nominale
									? Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
									  })
											.format(pod.potenza_nominale || 0)
											.toString() + " kW"
									: "inserisci potenza nominale"}
							</Typography>
						) : (
							<Controller
								name="potenza_nominale"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_nominale"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_nominale}
										helperText={errors?.potenza_nominale?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kW "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_nominale", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							potenza disponibile*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.potenza_disponibile
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{pod.potenza_disponibile
									? Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
									  })
											.format(pod.potenza_disponibile || 0)
											.toString() + " kW"
									: // iterConnessione.potenza_disponibile.toString() + " W"
									  "inserisci potenza"}
							</Typography>
						) : (
							<Controller
								name="potenza_disponibile"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_disponibile"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_disponibile}
										helperText={errors?.potenza_disponibile?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kW "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_disponibile", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							tipo di tensione*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: pod.tipo_tensione
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{tipiTensione.find(
									(tipoTensione) => tipoTensione.id == pod.tipo_tensione
								)?.descrizione || "seleziona tipo tensione"}
							</Typography>
						) : (
							<ReactHookFormSelect
								name="tipo_tensione"
								// label="tipo_pratica"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.tipo_tensione?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem value="">
									<em>Nessuno</em>
								</MenuItem>

								{tipiTensione.map((tipoTensione) => {
									return (
										<MenuItem key={tipoTensione.id} value={tipoTensione.id}>
											{tipoTensione.nome} {tipoTensione.descrizione}
										</MenuItem>
									);
								})}
								{/* 
								{Object.entries(TipoTensioneTypes).map(([key, value]) => {
									return (
										<MenuItem key={key} value={key}>
											{value}
										</MenuItem>
									);
								})} */}
							</ReactHookFormSelect>
						)}
					</Grid>
				</Grid>

				{/* <Grid item xs={12} sm={12} md={12} lg={2}>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
							>
								{editSedecodice_pod
									? "Diverso da residenza"
									: "Uguale a residenza"}
							</Typography>
						) : (
							<>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									Diverso da residenza:
								</Typography>
								<FormControlLabel
									label={
										<Typography fontSize="0.8rem" marginLeft="0.1rem">
											{editSedecodice_pod ? "SI" : "NO"}
										</Typography>
									}
									control={
										<Switch
											id="edit_sede_codice_pod"
											checked={editSedecodice_pod || false}
											onChange={() =>
												setEditSedecodice_pod(!editSedecodice_pod)
											}
											color="info"
											size="small"
										/>
									}
								/>
							</>
						)}
					</Grid> */}
				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						setEditing(false);
						// reset();
					}}
					sx={{ ...(!editing && { display: "none" }) }}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ...(!editing && { display: "none" }), ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default PodDatiGeneraliForm;
