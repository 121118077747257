import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
	RichiestaConnessione,
	saveRichiestaConnessione,
} from "./richiesteConnessioneSlice";
import { FineLavoro, saveFineLavoro } from "./fineLavori/fineLavoriSlice";
import { Allaccio, saveAllaccio } from "./allaccio/allaccioSlice";
import {
	IterConnessione,
	presaInCarico,
	avviaProgetto,
	assegnaPratica,
} from "components/main/iterConnessioni/iterConnessioneSlice";
import { Produttore } from "../../../../anagrafiche/produttori/produttoriSlice";

import moment from "moment";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { TipoPratica } from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";

import RigettaRichiestaConnessioneForm from "./RigettaRichiestaConnessioneForm";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
interface AzioniClienteConnessioneCardProps {
	iterConnessione: IterConnessione;
	richiestaConnessione?: RichiestaConnessione;
	fineLavoro?: FineLavoro;
	allaccio?: Allaccio;
}

const AzioniClienteConnessioneCard = ({
	iterConnessione,
	richiestaConnessione,
	fineLavoro,
	allaccio,
}: AzioniClienteConnessioneCardProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const [formRigetto, setFormRigetto] = useState<boolean>(false);

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);
	return richiestaConnessione && iterConnessione.stato == "H" ? (
		<>
			{!iterConnessione?.iter_semplificato &&
				["D"].includes(richiestaConnessione.stato) &&
				utenteAutorizzato(utente, [
					// PermissionTypes.GESTORI_ITER,
					// PermissionTypes.OPERATORI,
					PermissionTypes.CLIENTI,
					// PermissionTypes.GESTORI_ITER,
				]) && (
					<Stack sx={{ width: "100%" }} spacing={2}>
						{!richiestaConnessione.pagamento_effettuato
							.pagamento_effettuato && (
							<Alert severity="error" sx={{ mt: 1 }}>
								<AlertTitle>Attenzione manca:</AlertTitle>
								<Typography
									variant="body2"
									// component="div"
									// color="text.secondary"
									// sx={{
									// 	color: theme?.palette?.error.main,
									// }}
									sx={{ whiteSpace: "pre-line" }}
								>
									{richiestaConnessione.pagamento_effettuato.dati_mancanti}
								</Typography>
							</Alert>
						)}
						<Button
							variant="contained"
							size="large"
							sx={{ width: "100%", mt: 1 }}
							disabled={
								!richiestaConnessione.pagamento_effettuato.pagamento_effettuato
							}
							onClick={() => {
								richiestaConnessione &&
									dispatch(
										saveRichiestaConnessione({
											richiestaConnessioneToSave: richiestaConnessione,
											azione: "invia_accettazione",
										})
									);
							}}
						>
							invia attestazione di pagamento ad I43
						</Button>
					</Stack>
				)}
		</>
	) : (
		<></>
	);
};

export default AzioniClienteConnessioneCard;
