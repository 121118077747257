import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../../redux/store";

import moment from "moment";

import IterConnessioneSpalla from "../../IterConnessioneSpalla";
import { PermissionTypes } from "../../../../../authentication/permissionsGroups";
import AzioniFineLavoriCard from "./AzioniFineLavoriCard";

import { utenteAutorizzato } from "../../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../../authentication/authenticationSlice";
import { FineLavoro, getFineLavoroFromIter } from "./fineLavoriSlice";
import { IterConnessione } from "../../../iterConnessioneSlice";
import AllegatiPage from "../../../../../allegati/AllegatiPage";
import IterConnessioneFineLavoriCard from "./IterConnessioneFineLavoriCard";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";

import Stack from "@mui/material/Stack";
import { theme } from "../../../../../theme";

interface IterConnessioneFineLavoriBoxProps {
	iterConnessione: IterConnessione;
	fineLavoro: FineLavoro;
	utente: UtenteLogged;

	consentiModifica: boolean;
}

const IterConnessioneFineLavoriBox = ({
	iterConnessione,
	fineLavoro,
	utente,
	consentiModifica = false,
}: IterConnessioneFineLavoriBoxProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	return (
		<Box>
			{fineLavoro && (
				<IterConnessioneFineLavoriCard
					key={fineLavoro.id}
					fineLavoro={fineLavoro}
					consentiModifica={consentiModifica}
					iterSemplificato={iterConnessione.iter_semplificato}
				/>
			)}
		</Box>
	);
};

export default IterConnessioneFineLavoriBox;
