import { useSelector } from "react-redux";
import { UtenteLogged } from "./authenticationSlice";
import { PermissionTypes } from "./permissionsGroups";
import { RootState } from "../../redux/store";
import { ACCESS_TOKEN } from "../../api/apiUtils";

import { Route, Navigate, RouteProps, useLocation } from "react-router-dom";
import { utenteAutorizzato } from "../authentication/UtenteAutorizzato";

import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

interface PrivateRouteProps {
	gruppiAutorizzati: PermissionTypes[];
	element: JSX.Element;
}

const PrivateRoute = ({ gruppiAutorizzati, element }: PrivateRouteProps) => {
	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const access_token = localStorage.getItem(ACCESS_TOKEN);
	if (access_token !== null && !utente.isLogged) {
		return (
			<Backdrop sx={{ color: "#fff", zIndex: 1 }} open>
				<Box sx={{ width: "100%" }}>
					<Typography variant="h3" component="h2" align="center">
						Verifica autenticazione in corso
					</Typography>
					;
					<LinearProgress />
				</Box>
			</Backdrop>
		);
	} else if (utente.isLogged) {
		if (utenteAutorizzato(utente, gruppiAutorizzati)) {
			return element;
		} else {
			return (
				<Navigate
					replace
					to={{
						pathname: "/notPermitted",
						// state: { from: routeProps.location },
					}}
				/>
				// <Redirect
				//   to={{ pathname: "/notPermitted", state: { from: routeProps.location } }}
				// />
			);
		}
	} else {
		return (
			<Navigate
				to="/signin"
				state={{ from: { pathname: location.pathname } }}
			/>
		);
	}
};

export default PrivateRoute;
