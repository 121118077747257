import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import { NavLink, Link } from "react-router-dom";

import ButtonGroup from "@mui/material/ButtonGroup";
import { styled, useTheme } from "@mui/material/styles";
import ProfileMenu from "./ProfileMenu";
import { userIsLogged } from "../UserIsLogged";
import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../../components/authentication/permissionsGroups";
import {
	StatiIter,
	ConteggioStati,
	StatiConnessioni,
} from "../../anagrafiche/kpi/kpiSlice";
import HeaderMenuControllato from "./HeaderMenuControllato";
import Tooltip from "@mui/material/Tooltip";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";

import IconButton from "@mui/material/IconButton";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import CabinIcon from "@mui/icons-material/Cabin";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

const MenuItemStyle = {
	display: { sm: "inline-flex", xs: "flex" },
	padding: 0,
	ml: 1,
};

export enum Orientation {
	Horizontal = "horizontal",
	Vertical = "vertical",
}

interface HeaderMenuProps {
	// TODO gestire il tipo utente
	utente: any;
	resetUtente: () => void;
	orientation: Orientation;
	activeStyle: React.CSSProperties;
}

const HeaderMenu = ({
	utente,
	resetUtente,
	orientation,
	activeStyle,
}: HeaderMenuProps) => {
	const theme = useTheme();

	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);

	const statiConnessioni = useSelector<RootState, StatiConnessioni>(
		(state) => state.kpiState.statiConnessioni
	);

	const conteggioAutorizzazioniComunali = useSelector<
		RootState,
		ConteggioStati
	>((state) => state.kpiState.conteggioAutorizzazioniComunali);
	return (
		<>
			<HeaderMenuControllato
				utente={utente}
				path={Routes_path.HOME}
				label="HOME"
				gruppiAutorizzati={[PermissionTypes.ALL]}
				selected={location.pathname == Routes_path.HOME}
				Icon={HomeIcon}
			/>
			<HeaderMenuControllato
				utente={utente}
				path={Routes_path.ITER_CONNESSIONI}
				label="Iter Connessioni"
				gruppiAutorizzati={[
					PermissionTypes.ALL,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				selected={location.pathname == Routes_path.ITER_CONNESSIONI}
				Icon={ElectricalServicesIcon}
			/>
			{utenteAutorizzato(utente, [
				PermissionTypes.GESTORI_ITER,
				PermissionTypes.OPERATORI,
			]) && (
				<>
					<Tooltip title="DDC da inviare" arrow>
						<IconButton
							aria-controls="simple-menu"
							component={NavLink}
							size="large"
							to={{
								pathname: Routes_path.RICHIESTE_CONNESSIONE,
							}}
							state={{
								statoRichiestaId: "Z",
							}}
							sx={{ ml: 2, color: "#ddd" }}
						>
							<Badge
								badgeContent={
									statiConnessioni.results.find(
										(conteggioStato) => conteggioStato.stato == "Z"
									)?.count
								}
								color="secondary"
							>
								<MoveToInboxIcon />
							</Badge>
						</IconButton>
					</Tooltip>

					<Tooltip title="In attesa di preventivo" arrow>
						<IconButton
							aria-controls="simple-menu"
							component={NavLink}
							size="large"
							// component={Link}
							to={{
								pathname: Routes_path.RICHIESTE_CONNESSIONE,
							}}
							state={{
								statoRichiestaId: "B",
							}}
							// activeStyle={activeStyle}
							// exact

							// to={Routes_path.ITER_CONNESSIONI}
							sx={{ ml: 2, color: "#ddd" }}
							color="secondary"
						>
							<Badge
								badgeContent={
									statiConnessioni.results.find(
										(conteggioStato) => conteggioStato.stato == "B"
									)?.count
								}
								color="secondary"
							>
								<ProductionQuantityLimitsIcon />
							</Badge>
						</IconButton>
					</Tooltip>

					<Tooltip title="preventivi da accettare" arrow>
						<IconButton
							aria-controls="simple-menu"
							component={NavLink}
							size="large"
							// component={Link}
							to={{
								pathname: Routes_path.RICHIESTE_CONNESSIONE,
							}}
							state={{
								statoRichiestaId: "E",
							}}
							// activeStyle={activeStyle}
							// exact

							// to={Routes_path.ITER_CONNESSIONI}
							color="secondary"
						>
							<Badge
								badgeContent={
									statiConnessioni.results.find(
										(conteggioStato) => conteggioStato.stato == "E"
									)?.count
								}
								color="secondary"
							>
								<ShoppingCartCheckoutIcon sx={{ color: "#ddd" }} />
							</Badge>
						</IconButton>
					</Tooltip>
					<Tooltip title="Iter Autorizzativi da eseguire" arrow>
						<IconButton
							aria-controls="simple-menu"
							component={NavLink}
							size="large"
							// component={Link}
							to={{
								pathname: Routes_path.PRATICHE_COMUNALI,
							}}
							state={{
								statoAutorizzazioneComunaleId: "A",
							}}
							// activeStyle={activeStyle}
							// exact

							// to={Routes_path.ITER_CONNESSIONI}
							color="secondary"
						>
							<Badge
								badgeContent={conteggioAutorizzazioniComunali.in_lavorazione}
								color="secondary"
							>
								<AccountBalanceIcon sx={{ color: "#ddd" }} />
							</Badge>
						</IconButton>
					</Tooltip>

					<Tooltip title="Fine lavori da inviare" arrow>
						<IconButton
							aria-controls="simple-menu"
							component={NavLink}
							size="large"
							// component={Link}
							to={{
								pathname: Routes_path.ITER_CONNESSIONI,
							}}
							state={{
								statoIterId: "I",
							}}
							// activeStyle={activeStyle}
							// exact

							// to={Routes_path.ITER_CONNESSIONI}
							sx={{ ml: 2, color: "#ddd" }}
						>
							<Badge
								badgeContent={
									statiIter.results.find(
										(conteggioStato) => conteggioStato.stato == "I"
									)?.count
								}
								color="secondary"
							>
								<WarehouseIcon />
							</Badge>
						</IconButton>
					</Tooltip>
					<Tooltip title="Allacci da completare" arrow>
						<IconButton
							aria-controls="simple-menu"
							component={NavLink}
							size="large"
							// component={Link}
							to={{
								pathname: Routes_path.ITER_CONNESSIONI,
							}}
							state={{
								statoIterId: "L",
							}}
							// activeStyle={activeStyle}
							// exact

							// to={Routes_path.ITER_CONNESSIONI}
							sx={{ ml: 2, color: "#ddd" }}
						>
							<Badge
								badgeContent={
									statiIter.results.find(
										(conteggioStato) => conteggioStato.stato == "L"
									)?.count
								}
								color="secondary"
							>
								<ElectricBoltIcon />
							</Badge>
						</IconButton>
					</Tooltip>
				</>
			)}

			{userIsLogged(utente) ? (
				<Box sx={MenuItemStyle}>
					<ProfileMenu
						utente={utente}
						resetUtente={resetUtente}
						activeStyle={activeStyle}
					/>
				</Box>
			) : (
				<Button
					aria-controls="simple-menu"
					component={NavLink}
					size="small"
					// activeStyle={activeStyle}
					// exact
					color="inherit"
					to={Routes_path.SIGNIN}
				>
					accedi
					<IconButton
						aria-label="account of current user"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						color="inherit"
						size="large"
					>
						<LoginIcon />
					</IconButton>
				</Button>
			)}
		</>
	);
};

export default HeaderMenu;
