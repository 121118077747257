import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
import * as richiesteConnessioniApi from "../../../../../api/iterConnessioni/richiesteConnessioneApi";
import moment from "moment";
import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../../../allegati/allegatiSlice";
import { getIterConnessione } from "../../iterConnessioneSlice";

// import { useDispatch } from "react-redux";

moment.locale("it");

export interface RichiestaConnessione {
	id: number;
	iter_connessione?: number;
	produttore?: string;
	operatore?: number;
	stato: string;
	data_invio?: Date | string;
	codice?: string;
	data_conferma?: Date | string;
	data_ricezione_preventivo?: Date | string;
	data_invio_accettazione_preventivo?: Date | string;
	data_inoltro_accettazione_preventivo?: Date | string;
	importo_preventivo?: number;
	richiesta_rigettata?: boolean;
	data_rigetto?: Date | string;
	note_rilavorazione?: string;
	pagamento_richiedibile: {
		pagamento_richiedibile: boolean;
		dati_mancanti: string;
	};
	pagamento_effettuato: {
		pagamento_effettuato: boolean;
		dati_mancanti: string;
	};
	preventivo_richiedibile: {
		preventivo_richiedibile: boolean;
		dati_mancanti: string;
	};
	accettazione_inoltrabile: {
		accettazione_inoltrabile: boolean;
		dati_mancanti: string;
	};
	connessione_confermabile: {
		connessione_confermabile: boolean;
		dati_mancanti: string;
	};
	listaDocumentiPerTipo?: DocumentiPerTipo[];

	errorsStack?: ErrorsStack;
}
export interface RichiesteConnessioniState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: RichiestaConnessione[];
	errorsStack: ErrorsStack;
}
export interface RichiesteConnessioniStrutturaState {
	richiesteConnessioni: RichiesteConnessioniState;
}

const initialState: RichiesteConnessioniStrutturaState = {
	richiesteConnessioni: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchRichiesteConnessioni = createAsyncThunk(
	"richieste_connessioni/fetchRichiesteConnessioni",
	async (parametri: {
		statoRichiesta?: string;
		clienteIter?: number;
		operatore?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await richiesteConnessioniApi.fetchRichiesteConnessioni(
			parametri.statoRichiesta,
			parametri.clienteIter,
			parametri.operatore,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getRichiestaConnessione = createAsyncThunk(
	"richieste_connessioni/getRichiestaConnessione",
	async (richiestaConnessioneId: number) => {
		return await richiesteConnessioniApi.getRichiestaConnessione(
			richiestaConnessioneId
		);
	}
);

export const fetchRichiesteConnessioneFromIter = createAsyncThunk(
	"richieste_connessioni/fetchRichiesteConnessioneFromIter",
	async (iterId: number, thunkApi) => {
		return await richiesteConnessioniApi
			.fetchRichiesteConnessioneFromIter(iterId)
			.then((response) => {
				iterId && thunkApi.dispatch(getIterConnessione(iterId));
				return response;
			});
	}
);

export const saveRichiestaConnessione = createAsyncThunk(
	"richieste_connessioni/saveRichiestaConnessione",
	async (
		parametri: {
			richiestaConnessioneToSave: RichiestaConnessione;
			azione?: string;
		},
		thunkApi
	) => {
		return await richiesteConnessioniApi
			.saveRichiestaConnessione(
				parametri.richiestaConnessioneToSave,
				parametri.azione
			)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						fetchRichiesteConnessioneFromIter(
							parametri.richiestaConnessioneToSave.iter_connessione || 0
						)
					);
				thunkApi.dispatch(
					getIterConnessione(
						parametri.richiestaConnessioneToSave.iter_connessione || 0
					)
				);
				return response;
			});
	}
);

export const deleteRichiestaConnessione = createAsyncThunk(
	"richieste_connessioni/deleteRichiestaConnessione",
	async (richiestaConnessioneToDelete: RichiestaConnessione, thunkApi) => {
		return await richiesteConnessioniApi.deleteRichiestaConnessione(
			richiestaConnessioneToDelete
		);
	}
);

export const richiesteConnessioneSlice = createSlice({
	name: "richiesteConnessioniState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Richieste Connessioni
		builder.addCase(fetchRichiesteConnessioni.pending, (state, action) => {
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchRichiesteConnessioni.fulfilled, (state, action) => {
			state.richiesteConnessioni = action.payload;
			state.richiesteConnessioni.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchRichiesteConnessioni.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Richiesta Connessione
		builder.addCase(getRichiestaConnessione.pending, (state, action) => {
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getRichiestaConnessione.fulfilled, (state, action) => {
			state.richiesteConnessioni.results =
				state.richiesteConnessioni.results.filter(
					(richiesta) => richiesta.id != action.payload.id
				);
			state.richiesteConnessioni.results.push(action.payload);

			state.richiesteConnessioni.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getRichiestaConnessione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Richiesta Connessione from Iter
		builder.addCase(
			fetchRichiesteConnessioneFromIter.pending,
			(state, action) => {
				state.richiesteConnessioni.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchRichiesteConnessioneFromIter.fulfilled,
			(state, action) => {
				state.richiesteConnessioni = action.payload;
				state.richiesteConnessioni.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			fetchRichiesteConnessioneFromIter.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");
				state.richiesteConnessioni.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);

		// save Richiesta Connessione
		builder.addCase(saveRichiestaConnessione.pending, (state, action) => {
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveRichiestaConnessione.fulfilled, (state, action) => {
			state.richiesteConnessioni.results =
				state.richiesteConnessioni.results.filter(
					(richiesta) => richiesta.id != action.payload.id
				);
			state.richiesteConnessioni.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Richiesta Connessione salvata.");
		});
		builder.addCase(saveRichiestaConnessione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.richiesteConnessioni.results =
				state.richiesteConnessioni.results.map((richiesta) => {
					if (richiesta.id == action.meta.arg.richiestaConnessioneToSave.id) {
						return {
							...richiesta,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return richiesta;
					}
				});
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
		// cancella Richiesta Connessione
		builder.addCase(deleteRichiestaConnessione.pending, (state, action) => {
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteRichiestaConnessione.fulfilled, (state, action) => {
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.richiesteConnessioni.results =
				state.richiesteConnessioni.results.filter(
					(richiesta) => richiesta.id != action.meta.arg.id
				);
			toast.success(
				action.payload.message || "Richiesta Connessione cancellata."
			);
		});
		builder.addCase(deleteRichiestaConnessione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.richiesteConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = richiesteConnessioneSlice.actions;

export const richiesteConnessioneSliceReducer =
	richiesteConnessioneSlice.reducer;
