import { Routes_path } from "../../../routerApp";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

import { Link } from "react-router-dom";

import {
	Produttore,
	TipoPersoneTypes,
} from "components/anagrafiche/produttori/produttoriSlice";
import { Comune } from "../../../anagrafiche/comuni/comuniSlice";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import ProduttoreCredenzialiGSEForm from "./consegna/ProduttoreCredenzialiGSEForm";

import { Typography } from "@mui/material";

interface DatiProduttoreCardProps {
	produttore: Produttore;
}

const DatiProduttoreCard = ({ produttore }: DatiProduttoreCardProps) => {
	const comune: Comune | undefined = useSelector<RootState, Comune | undefined>(
		(state) =>
			state.comuniState.comuni.results.find(
				(comune) => comune?.id == produttore?.comune
			)
	);

	return (
		<Card>
			<CardContent>
				<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
					{
						Object.entries(TipoPersoneTypes).find(
							([key, value]) => key == produttore.tipo_persona
						)?.[1]
					}
				</Typography>
				<Typography variant="h5" component="div">
					{produttore.tipo_persona == "PF"
						? `${produttore.nome} ${produttore.cognome}`
						: produttore.ragione_sociale}
				</Typography>
				<Typography sx={{ mb: 1.5 }} color="text.secondary">
					{produttore.tipo_persona == "PF"
						? produttore.codice_fiscale
						: produttore.piva}
				</Typography>
				<Typography variant="body2">
					{produttore.indirizzo} {produttore.numero_civico}
				</Typography>
				<Typography variant="body2" gutterBottom>
					{comune?.nome + ` (${comune?.provincia})`}
				</Typography>
				<Typography variant="body2" sx={{ fontStyle: "italic" }}>
					{"cell: "}
					{produttore.cellulare}
				</Typography>
				<Typography variant="body2" sx={{ fontStyle: "italic" }}>
					{"email: "}
					{produttore.email}
				</Typography>
			</CardContent>
			<CardContent>
				<ProduttoreCredenzialiGSEForm produttore={produttore} />
			</CardContent>
			<CardActions>
				{/* <Button size="small"> */}
				<IconButton
					aria-label="modifica elemento"
					size="small"
					component={Link}
					to={{
						pathname: Routes_path.PRODUTTORE + produttore.id,
					}}
				>
					modifica
				</IconButton>
				{/* </Button> */}
			</CardActions>
		</Card>
	);
};

export default DatiProduttoreCard;
