import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import { FineLavoro } from "../../components/main/iterConnessioni/iterConnessione/richiesteConnessione/fineLavori/fineLavoriSlice";

const baseUrl = (fineLavori_id?: number | null, azione?: string) => {
	if (fineLavori_id) {
		let url = `${process.env.API_URL}/api/fine_lavori/${fineLavori_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/fine_lavori/";
	}
};

export function fetchFineLavori() {
	let url = baseUrl();
	// moment(data_da).format("YYYY-MM-DD");
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getFineLavoro(fineLavoriId: number) {
	let url = baseUrl(fineLavoriId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveFineLavoro(fineLavoroToSave: FineLavoro, azione?: string) {
	const gaudi_data_registrazione_impianto =
		fineLavoroToSave.gaudi_data_registrazione_impianto
			? moment(fineLavoroToSave.gaudi_data_registrazione_impianto).format(
					"YYYY-MM-DD"
			  )
			: null;
	const gaudi_data_registrazione_UP =
		fineLavoroToSave.gaudi_data_registrazione_UP
			? moment(fineLavoroToSave.gaudi_data_registrazione_UP).format(
					"YYYY-MM-DD"
			  )
			: null;
	const iter_autorizzativo_data_avvio =
		fineLavoroToSave.iter_autorizzativo_data_avvio
			? moment(fineLavoroToSave.iter_autorizzativo_data_avvio).format(
					"YYYY-MM-DD"
			  )
			: null;
	const iter_autorizzativo_data_conclusione =
		fineLavoroToSave.iter_autorizzativo_data_conclusione
			? moment(fineLavoroToSave.iter_autorizzativo_data_conclusione).format(
					"YYYY-MM-DD"
			  )
			: null;
	const iter_autorizzativo_data_avvio_costrizione =
		fineLavoroToSave.iter_autorizzativo_data_avvio_costrizione
			? moment(
					fineLavoroToSave.iter_autorizzativo_data_avvio_costrizione
			  ).format("YYYY-MM-DD")
			: null;
	const iter_autorizzativo_data_completamento_costruzione =
		fineLavoroToSave.iter_autorizzativo_data_completamento_costruzione
			? moment(
					fineLavoroToSave.iter_autorizzativo_data_completamento_costruzione
			  ).format("YYYY-MM-DD")
			: null;
	const regolamento_esercizio_data_disponibilita =
		fineLavoroToSave.regolamento_esercizio_data_disponibilita
			? moment(
					fineLavoroToSave.regolamento_esercizio_data_disponibilita
			  ).format("YYYY-MM-DD")
			: null;
	const regolamento_esercizio_data_inoltro =
		fineLavoroToSave.regolamento_esercizio_data_inoltro
			? moment(fineLavoroToSave.regolamento_esercizio_data_inoltro).format(
					"YYYY-MM-DD"
			  )
			: null;
	const regolamento_esercizio_data_convalida =
		fineLavoroToSave.regolamento_esercizio_data_convalida
			? moment(fineLavoroToSave.regolamento_esercizio_data_convalida).format(
					"YYYY-MM-DD"
			  )
			: null;
	const fineLavori = {
		...fineLavoroToSave,
		gaudi_data_registrazione_impianto: gaudi_data_registrazione_impianto,
		gaudi_data_registrazione_UP: gaudi_data_registrazione_UP,
		iter_autorizzativo_data_avvio: iter_autorizzativo_data_avvio,
		iter_autorizzativo_data_conclusione: iter_autorizzativo_data_conclusione,
		iter_autorizzativo_data_avvio_costrizione:
			iter_autorizzativo_data_avvio_costrizione,
		iter_autorizzativo_data_completamento_costruzione:
			iter_autorizzativo_data_completamento_costruzione,
		regolamento_esercizio_data_disponibilita:
			regolamento_esercizio_data_disponibilita,
		regolamento_esercizio_data_inoltro: regolamento_esercizio_data_inoltro,
		regolamento_esercizio_data_convalida: regolamento_esercizio_data_convalida,
	};
	return fetch(baseUrl(fineLavoroToSave.id, azione), {
		method: fineLavoroToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...fineLavori,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(fineLavori.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteFineLavoro(fineLavoriToDelete: FineLavoro) {
	return fetch(baseUrl(fineLavoriToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////

export function getFineLavoroFromIter(iterId: number) {
	let url =
		process.env.API_URL +
		"/api/fine_lavori/fine_lavori_from_iter/" +
		iterId +
		"/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////
