import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import moment from "moment";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";
import {
	IterConnessione,
	richiestaPresaInCarico,
	presaInCarico,
} from "../../iterConnessioneSlice";
import IterConnessioneSpalla from "../IterConnessioneSpalla";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import IterConnessioneDatiGeneraliForm from "./IterConnessioneDatiGeneraliForm";
import IterConnessioneDistintaProdottiBox from "./prodotti/IterConnessioneDistintaProdottiBox";
import AzioniClienteCard from "./AzioniClienteCard";
import AzioniGestoreCard from "./AzioniGestoreCard";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";
import { Produttore } from "components/anagrafiche/produttori/produttoriSlice";

interface IterConnessioneDatiGeneraliTabProps {
	iterConnessione: IterConnessione;
	utente: UtenteLogged;
	produttore: Produttore;
	consentiModifica: boolean;
}

const IterConnessioneDatiGeneraliTab = ({
	iterConnessione,
	utente,
	produttore,
	consentiModifica = false,
}: IterConnessioneDatiGeneraliTabProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const [preEsistente, setPreEsistente] = useState<boolean>(
		iterConnessione.iter_semplificato ? false : true
	);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9} order={{ xs: 2, md: 1 }}>
					<Stack spacing={2}>
						{iterConnessione && (
							<IterConnessioneDatiGeneraliForm
								iterConnessione={iterConnessione}
								produttore={produttore}
								consentiModifica={consentiModifica}
							/>
						)}
						{!!iterConnessione.codice_pod && preEsistente && (
							<>
								<Divider
									textAlign="left"
									sx={{ mt: 63, mb: 1, color: theme.palette.primary.main }}
								>
									Distinta Prodotti Pre-esistenti
								</Divider>
								<IterConnessioneDistintaProdottiBox
									iterConnessione={iterConnessione}
									produttore={produttore}
									consentiModifica={consentiModifica}
									preEsistente={preEsistente}
								/>
							</>
						)}
						{!!iterConnessione.codice_pod && (
							<Divider
								textAlign="left"
								sx={{ mt: 63, mb: 1, color: theme.palette.primary.main }}
							>
								Distinta Prodotti Nuovi
							</Divider>
						)}
						{!!iterConnessione.codice_pod && (
							<IterConnessioneDistintaProdottiBox
								iterConnessione={iterConnessione}
								produttore={produttore}
								consentiModifica={consentiModifica}
								preEsistente={false}
							/>
						)}
						{!!iterConnessione.codice_pod && (
							<Divider
								textAlign="left"
								sx={{ mt: 4, mb: 1, color: theme.palette.primary.main }}
							>
								Allegati
							</Divider>
						)}
						{!!iterConnessione.codice_pod &&
							iterConnessione.listaDocumentiPerTipo && (
								<AllegatiPage
									listaDocumentiPerTipo={iterConnessione.listaDocumentiPerTipo}
									iterConnessioneId={iterConnessione.id || 0}
									consentiModifica={
										iterConnessione.stato != "N" || consentiModifica
									}
								/>
							)}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, md: 2 }}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />

					{utenteAutorizzato(utente, [
						PermissionTypes.CLIENTI,
						PermissionTypes.OPERATORI,
						// PermissionTypes.GESTORI_ITER,
					]) && <AzioniClienteCard iterConnessione={iterConnessione} />}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneDatiGeneraliTab;
