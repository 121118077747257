import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";

const baseUrl = (scadenza_id?: number | null, azione?: string) => {
	if (scadenza_id) {
		let url = `${process.env.API_URL}/api/allaccio/scadenze/${scadenza_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/allaccio/scadenze/";
	}
};

export function fetchScadenze(
	giorni_previsione?: number,
	clienteIter?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (
		giorni_previsione != undefined ||
		clienteIter != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (giorni_previsione != undefined) {
		url += `giorni_previsione=${giorni_previsione}&`;
	}
	if (clienteIter != undefined) {
		url += `cliente=${clienteIter}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
